import { UpdateClaimConciliatorEntity } from "../../domain/entities/update-claim-conciliator.entity";

export class UpdateClaimConciliatorModel extends UpdateClaimConciliatorEntity {

    override toJson(): Map<string, any> {
        let json: Map<string, any> = new Map();
        json.set('id', this.claimId);
        json.set('conciliator_id', this.conciliatorId);
        return json;
    }
}