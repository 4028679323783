import { Injectable } from "@angular/core";
import { UseCase } from "src/app/core/utils/usecase";
import { ForgotPasswordSendEmailEntity } from "../entities/forgot-password-send-email.entity";
import { Failure } from "src/app/core/utils/failure";
import { AuthRepositoryBase } from "../repositories/auth.repositorybase";

@Injectable()
export class ForgotPasswordSendEmailUseCase implements UseCase<ForgotPasswordSendEmailEntity, string | Failure> {
    constructor(private repository: AuthRepositoryBase) {
    }
    async execute(forgotPasswordSendEmail: ForgotPasswordSendEmailEntity): Promise<string | Failure> {
        return await this.repository.forgotPasswordSendEmail(forgotPasswordSendEmail);
    }
}
