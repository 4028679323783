<div class="form-container" >
<form [formGroup]="registerFormRol" (ngSubmit)="nextStep()" id="container-form">
  <h2 class="mb-4">Crear cuenta</h2>

  <div class="grid">
    <div class="col-12 md:col-12 pb-0">
  <p>Seleccione un rol</p>
    </div>

    <div class="col-12 md:col-6 pt-0">
      <p-selectButton id="rolButton" [options]="rolOptions" formControlName="rol" [multiple]="false" optionLabel="name"
        optionValue="rol"></p-selectButton>
    </div>

    <div class="col-12"></div>

  <div class="col-12 md:col-6 pt-0">
  <p-button class="nextButton" label="Siguiente" [disabled]="registerFormRol.invalid" type="submit"></p-button>
  </div>

    <div class="col-12 md:col-8">
  <span>¿Ya tienes una cuenta? <a (click)="goToLogin()" class="ml-2">Ingresar</a></span>
    </div>

  </div>

</form>
</div>
