import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ClaimEntity } from 'src/app/features/claims/domain/entities/claim.entity';

@Component({
  selector: 'app-action-buttons',
  templateUrl: './action-buttons.component.html',
  styleUrls: ['./action-buttons.component.css'],
})
export class ActionButtonsComponent {
  @Input() buttons: boolean = false;
  @Input() claim?: any;
  @Input() rol?: string;
  @Input() minuteId?: any;

  @Output() goBackEvent = new EventEmitter<void>();
  @Output() acceptRejectEvent = new EventEmitter<string>();
  @Output() newAudienceEvent = new EventEmitter<void>();
  @Output() newMinuteEvent = new EventEmitter<void>();

  onGoBack(): void {
    this.goBackEvent.emit();
  }

  onRecibeAceptDialog(action: string): void {
    this.acceptRejectEvent.emit(action);
  }

  onNewAudienceCheck(): void {
    this.newAudienceEvent.emit();
  }

  onNewMinuteCheck(): void {
    this.newMinuteEvent.emit();
  }
}
