import { ClaimCollectiveEntity } from '../../domain/entities/claim-colective.entity';

export class ClaimCollectiveModel extends ClaimCollectiveEntity {
  override toJson(): Map<string, any> {
    let json: Map<string, any> = new Map();
    json.set('id', this.id);
    json.set('user_id', this.userId);
    json.set('rol', this.rol);
    json.set('razon_social', this.razonSocial);
    json.set('cuit_company', this.cuitCompany);
    json.set('company_email', this.companyEmail);
    json.set('company_phone', this.companyPhone);
    json.set('street_company', this.streetCompany);
    json.set('number_company', this.numberCompany);
    json.set('floor_apartment_company', this.floorApartmentCompany);
    json.set('tower_company', this.towerCompany);
    json.set('postal_code_company', this.postalCodeCompany);
    json.set('province_company', this.provinceCompany);
    json.set('city_company', this.cityCompany);
    json.set('claimant_type_performance', this.claimantTypePerformance);
    json.set('department_id', this.departmentId);
    json.set('claimed_activity', this.claimedActivity);
    json.set('claimed_collective_agreement', this.claimedCollectiveAgreement);
    json.set('claimed_association', this.claimedAssociation);
    json.set('affected_workers', this.affectedWorkers);
    json.set('claimed_email', this.claimedEmail);
    json.set('claimed_files', this.claimedFiles);
    json.set('object_of_claim', this.objectOfClaim);
    json.set('selected_own_right', this.selectedOwnRight);
    json.set('WorkersCount', this.workersCount);
    json.set('full_name_lawyer', this.fullNameLawyer);
    json.set('registration_number', this.registrationNumber);
    json.set('lawyer_email', this.lawyerEmail);
    json.set('consent', this.consent);
    json.set('cuit_assignor', this.cuit_assignor);
    json.set('razon_social_assignor', this.razon_social_assignor);
    json.set('cuit_assignee', this.cuit_assignee);
    json.set('razon_social_assignee', this.razon_social_assignee);
    json.set('claim_type', this.claimType);
    json.set('confirm_information', this.confirmInformation);
    json.set('start_date', this.startDate);
    json.set('number_of_claim', this.numberOfClaim);
    json.set('is_borrador', this.isBorrador);
    json.set('status', this.status);
    json.set('conciliator_full_name', this.conciliator_full_name);
    return json;
  }

  static fromJson(data: Map<string, any>): ClaimCollectiveEntity {
    return new ClaimCollectiveModel(
      data.get('id'),
      data.get('user_id'),
      data.get('rol'),
      data.get('razon_social'),
      data.get('cuit_company'),
      data.get('company_email'),
      data.get('company_phone'),
      data.get('street_company'),
      data.get('number_company'),
      data.get('floor_apartment_company'),
      data.get('tower_company'),
      data.get('postal_code_company'),
      data.get('province_company'),
      data.get('city_company'),
      data.get('claimant_type_performance'),
      data.get('department_id'),
      data.get('claimed_activity'),
      data.get('claimed_collective_agreement'),
      data.get('claimed_association'),
      data.get('affected_workers'),
      data.get('claimed_email'),
      data.get('claimed_files'),
      data.get('object_of_claim'),
      data.get('selected_own_right'),
      data.get('WorkersCount'),
      data.get('full_name_lawyer'),
      data.get('registration_number'),
      data.get('lawyer_email'),
      data.get('consent'),
      data.get('cuit_assignor'),
      data.get('razon_social_assignor'),
      data.get('cuit_assignee'),
      data.get('razon_social_assignee'),
      data.get('claim_type'),
      data.get('confirm_information'),
      data.get('start_date'),
      data.get('number_of_claim'),
      data.get('is_borrador'),
      data.get('status'),
      data.get('conciliator_full_name')
    );
  }
}
