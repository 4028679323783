import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { GetMyDataUseCase } from 'src/app/features/user/domain/usecases/get-my-data.usecase';
import { UserService } from 'src/app/features/user/services/user.service';
import { Failure } from '../../utils/failure';
import { UserData } from 'src/app/features/user/domain/entities/user-data.entity';
import { UserRol } from '../../enums/user-rol.enum';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit, OnDestroy {
  userData?: UserData;
  userRol?: string;
  claimButtonText: string = 'Mis reclamos laborales';
  commisionRol: boolean = false;
  isSuperAdmin: boolean = false;
  private routerSubscription: Subscription | undefined;

  constructor(
    private router: Router,
    private userService: UserService,
    private getMyDataUseCase: GetMyDataUseCase
  ) { }

  ngOnInit(): void {
    this.initializeUserData();
  }

  ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  private async initializeUserData(): Promise<void> {
    await this.getMe();
  }

  private async getMe(): Promise<void> {
    let result = await this.getMyDataUseCase.execute();
    if (result instanceof Failure) {
      return;
    }
    this.userService.userData = result;
    this.userService.userData$.next(result);
    this.userService.userRol$.next(result.rol);
    this.userData = result;
    this.userRol = result.rol;
    this.setClaimButtonText();
    this.checkSuperAdminRole();
  }

  private setClaimButtonText(): void {
    const allowedRoles = [
      UserRol.ADMINISTRATOR,
      UserRol.COMISSION,
      UserRol.COMISSION_MEMBER,
      UserRol.CONCILIATOR
    ];

    if (this.userData?.rol && allowedRoles.includes(this.userData.rol as UserRol)) {
      this.claimButtonText = 'Reclamos laborales';
      this.commisionRol = true;
    } else {
      this.claimButtonText = 'Reclamos Laborales';
      this.commisionRol = false;
    }
  }

  private checkSuperAdminRole(): void {
    const allowedRole = [
      UserRol.SUPER_ADMIN
    ];
    if (this.userData?.rol && allowedRole.includes(this.userData.rol as UserRol)) {
      this.isSuperAdmin = true;
    } else {
      this.isSuperAdmin = false;
    }
  }

  logout(): void {
    sessionStorage.clear()
    this.commisionRol = false;
    this.isSuperAdmin = false;
    this.router.navigate(['/auth/login']);
  }
}
