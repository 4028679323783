import { UseCase } from "src/app/core/utils/usecase";
import { FrogotPasswordGenerateNewEntity } from "../entities/forgot-password-generate-new.entity";
import { Failure } from "src/app/core/utils/failure";
import { AuthRepositoryBase } from "../repositories/auth.repositorybase";
import { Injectable } from "@angular/core";

@Injectable()
export class ForgotPasswordGenerateNewUseCase implements UseCase<FrogotPasswordGenerateNewEntity, string | Failure> {
    constructor( private repository: AuthRepositoryBase) {
    }
    async execute(param: FrogotPasswordGenerateNewEntity): Promise<string | Failure> {
        return await this.repository.forgotPasswordGenerateNew(param);
    }
}