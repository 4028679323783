<form
  [formGroup]="formTypeResponsibility"
  (ngSubmit)="nextStep()"
  class="w-8 sm:w-8 md:w-8 lg:w-7"
  id="container-form"
>
  <div>
    <h2 class="mb-0">Crear cuenta</h2>
    <p>{{ selectedRol }}</p>
  </div>
  <div class="grid">
    <div
      class="custom-input col-12 md:col-12 lg:col-12 flex flex-column"
      *ngIf="selectedRol === 'Empleador'"
    >
      <div *ngFor="let responsibility of companyTypeResponsibility" class="containerInput-radioBtn">
        <p-radioButton
          [value]="responsibility.name"
          formControlName="type_responsibility"
        ></p-radioButton>
        <label [for]="responsibility.act" class="label-radioBtn ml-2">{{ responsibility.name }}</label>
      </div>
    </div>

    <div
      class="custom-input col-12 md:col-12 lg:col-12 flex flex-column"
      *ngIf="selectedRol === 'Representante gremial'"
    >
      <div
        *ngFor="let responsibility of unionRepresentativeTypeResponsibility"
        class="containerInput-radioBtn"
      >
        <p-radioButton
          [value]="responsibility.name"
          formControlName="type_responsibility"
        ></p-radioButton>
        <label [for]="responsibility.act" class="label-radioBtn ml-2">{{ responsibility.name }}</label>
      </div>
    </div>
  </div>
  <div>
    <p-button
      class="nextButton"
      label="Siguiente"
      [disabled]="formTypeResponsibility.invalid"
      type="submit"
    ></p-button>
    <span class="backLink"><a (click)="previousStep()">Volver</a></span>
  </div>
</form>