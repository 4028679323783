<div class="flex flex-column w-full  gap-3 p-4 grid">

  <ng-container *ngIf="!tableOrImg">
      <div class="imagenPag">
          <img id="imagen" src="../../../../../../assets/img/main/file-searching.svg" alt="" />
          <p class="descriptionPage">
              Ud. no ha generado ningún usuario
          </p>
      </div>
  </ng-container>

  <ng-container *ngIf="tableOrImg">
      <app-newUser></app-newUser>
  </ng-container>
</div>
