import { Injectable } from "@angular/core";
import { Failure } from "../../../../core/utils/failure";
import { User } from "../entities/user.entity";
import { AuthRepositoryBase } from "../repositories/auth.repositorybase";
import { UseCase } from "src/app/core/utils/usecase";
import { RegisterUserResponse } from "../entities/register-user-response.entity";

@Injectable()
export class RegisterUseCase implements UseCase<User, boolean | Failure> {
  constructor(private repository: AuthRepositoryBase) {
  }

  async execute(user: User): Promise<boolean | Failure> {
    return await this.repository.register(user);
  }
}