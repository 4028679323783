import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/features/user/services/user.service';
import { UserData } from 'src/app/features/user/domain/entities/user-data.entity';
import { UserRol } from 'src/app/core/enums/user-rol.enum';
import AESEncryptDecryptService from 'src/app/core/utils/crypto';
import { LocalStorageServiceBase } from 'src/app/core/services/localStorage-serviceBase';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  userData?: UserData = !this.userService.userData
    ? undefined
    : this.userService.userData;
  commisionRol: boolean = false;
  SuperAdminRol: boolean = false;
  private AES = new AESEncryptDecryptService();
  rol: string | null = this.AES.decrypt(
    this.localStorageService.loadStorageData('user_rol')
  );

  constructor(
    private userService: UserService,
    private localStorageService: LocalStorageServiceBase
  ) {}

  ngOnInit(): void {
    this.rol = this.AES.decrypt(
      this.localStorageService.loadStorageData('user_rol')
    );
    this.checkRol();
  }

  checkRol(): void {
    if (
      this.rol == UserRol.ADMINISTRATOR ||
      this.rol == UserRol.COMISSION ||
      this.rol == UserRol.COMISSION_MEMBER ||
      this.rol == UserRol.CONCILIATOR
    ) {
      this.commisionRol = true;
      return;
    } else {
      this.commisionRol = false;
    }
    if (this.rol == UserRol.SUPER_ADMIN) {
      this.SuperAdminRol = true;
    } else {
      this.SuperAdminRol = false;
    }
  }
}
