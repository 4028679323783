<div class="card flex justify-content-center w-full relative">
    <p-toast />
    <p-confirmDialog #cd>
        <ng-template pTemplate="headless" let-message class="congirmDialog">
            <div class="flex flex-column align-items-center p-5 surface-overlay border-round">
                <span class="labelTitle1">
                    {{ message.header }}
                </span>
                <div class=" flex justify-content-center align-items-center gap-2">
                    <i class="pi pi-exclamation-circle"></i>
                    <p class="text-base">{{ message.message }}</p>
                </div>
                <div class="flex flex-row justify-content-center align-items-center gap-2 mt-3 ">
                    <ng-container *ngIf="isNewCompany" class="col-12">
                        <button pButton label="Cancelar" (click)="cd.reject()" class="btn-cancelar col-6" style="border-color: #263245;
                            background-color: white;
                            color: #263245;
                            font-size: 0.8rem;">
                        </button>
                        <button pButton label="Guardar como borrador" (click)="cd.accept()" class="btn-siguiente col-6"
                            style="
                            background-color: #263245;
                            border-color: #263245;
                            font-size: 0.8rem;
                            ">
                        </button>
                    </ng-container>
                    <ng-container *ngIf="!isNewCompany" class="col-12">
                        <button pButton label="Cancelar" (click)="cd.reject()" class="btn-cancelar col-6" style="border-color: #263245;
                            background-color: white;
                            color: #263245;
                            font-size: 0.8rem;">
                        </button>
                        <button pButton label="Aceptar" (click)="cd.accept()" class="btn-siguiente col-6" style="
                            background-color: #263245;
                            border-color: #263245;
                            font-size: 0.8rem;
                            ">
                        </button>
                    </ng-container>
                </div>
            </div>
        </ng-template>
    </p-confirmDialog>
</div>
<div class="w-full relative p-4">
    <div class="container-top">
        <i routerLink="/main/companies" class="pi pi-angle-left"></i>
        <p><a href="" routerLink="/main/companies">Empresa</a></p>
        <p>/</p>
        <p *ngIf="isNewCompany"><a class="font-bold">Nueva empresa</a></p>
        <p *ngIf="!isNewCompany"><a class="font-bold">Editar empresa</a></p>
    </div>
    <div class="container-title">
        <p *ngIf="isNewCompany" class="title">Nueva empresa</p>
        <p *ngIf="!isNewCompany" class="title">Editar empresa</p>
        <p class="description" *ngIf="isNewCompany">
            Complete los datos requeridos para añadir una nueva empresa </p>
    </div>

    <div class="w-full">
        <p-tabView >
            <p-tabPanel class="w-full">
                <ng-template pTemplate="header" >
                    <div class="hidden md:block" >{{ isNewCompany ? 'Alta individual' : 'Datos de la empresa'}}</div>
                    <div class="block md:hidden">
                        <i class="pi pi-user text-base" pTooltip="Alta individual" tooltipPosition="right"></i>
                    </div>
                </ng-template>
                <app-individual-company (showMessage)="handleShowMessage($event)"></app-individual-company>
            </p-tabPanel>

            <p-tabPanel class="w-full"  *ngIf="isNewCompany">
                <ng-template pTemplate="header" >
                    <div class="hidden md:block" >Alta masiva</div>
                    <div class="block md:hidden">
                        <i class="pi pi-users text-base" pTooltip="Alta masiva" tooltipPosition="right"></i>
                    </div>
                </ng-template>
                <ng-template pTemplate="content">
                    <app-multi-company (showMessage)="handleShowMessage($event)"></app-multi-company>
                </ng-template>
            </p-tabPanel>
        </p-tabView>
    </div>
</div>
