import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignUpComponent } from './presentation/components/sign-up/sign-up.component';
import { AuthRoutingModule } from './auth-routing.module';
import { StepMailPasswordComponent } from './presentation/components/sign-up/step-mail-password/step-mail-password.component';
import { StepCompanyCuilComponent } from './presentation/components/sign-up/step-company-cuil/step-company-cuil.component';
import { StepLocationComponent } from './presentation/components/sign-up/step-location/step-location.component';
import { StepPersonalInformationComponent } from './presentation/components/sign-up/step-personal-information/step-personal-information.component';
import { StepRolComponent } from './presentation/components/sign-up/step-rol/step-rol.component';
import { ReactiveFormsModule } from '@angular/forms';
import { PrimengModule } from 'src/app/core/components/primeng/primeng.module';
import { HttpClientModule } from '@angular/common/http';
import { RegisterUseCase } from './domain/usecases/register.usecase';
import { HttpServiceBase } from 'src/app/core/services/http.service';
import { AuthRemoteDataSource, AuthRemoteDataSourceBase } from './data/data-source/auth-remote.datasource';
import { AuthRepositoryBase } from './domain/repositories/auth.repositorybase';
import { HttpService } from 'src/app/core/services/http.implementation';
import { AuthRepository } from './data/repositories/auth.repository';
import { LoginComponent } from './presentation/components/login/login.component';
import { LoginBaseComponent } from './presentation/pages/login-base/login-base.component';
import { LoginUseCase } from './domain/usecases/login.usercase';
import { LocalStorageServiceBase } from 'src/app/core/services/localStorage-serviceBase';
import { LocalStorageService } from 'src/app/core/services/local-storage.implementation.service';
import { StepMailValidationComponent } from './presentation/components/sign-up/step-mail-validation/step-mail-validation.component';
import { TypeResponsibilityComponent } from './presentation/components/sign-up/typeResponsibility/typeResponsibility.component';
import { StepTwoInputsComponent } from './presentation/components/sign-up/step-two-inputs/step-two-inputs.component';
import { SharedModule } from 'src/app/core/shared/shared.module';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ResetPasswordUseCase } from './domain/usecases/reset-password.useCase';
import { SendValidationEmailComponent } from './presentation/components/forgot-password/send-validation-email/send-validation-email.component';
import { GenerateNewPasswordComponent } from './presentation/components/forgot-password/generate-new-password/generate-new-password.component';
import { ForgotPasswordSendEmailUseCase } from './domain/usecases/forgot-password-send-email.useCase';
import { ForgotPasswordGenerateNewUseCase } from './domain/usecases/forgot-password-generate-new.useCase';

@NgModule({
  declarations: [
    SignUpComponent,
    LoginComponent,
    LoginBaseComponent,
    StepMailPasswordComponent,
    StepCompanyCuilComponent,
    StepLocationComponent,
    StepPersonalInformationComponent,
    StepRolComponent,
    StepMailValidationComponent,
    TypeResponsibilityComponent,
    StepTwoInputsComponent,
    SendValidationEmailComponent,
    GenerateNewPasswordComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    PrimengModule,
    AuthRoutingModule,
    HttpClientModule,
    SharedModule,
    ProgressSpinnerModule
  ],
  exports: [],
  providers: [
    LoginUseCase,
    { provide: HttpServiceBase, useClass: HttpService},
    { provide: AuthRemoteDataSourceBase, useClass: AuthRemoteDataSource},
    { provide: AuthRepositoryBase, useClass: AuthRepository },
    { provide: LocalStorageServiceBase, useClass: LocalStorageService},
    RegisterUseCase,
    ResetPasswordUseCase,
    ForgotPasswordSendEmailUseCase,
    ForgotPasswordGenerateNewUseCase
  ],
})
export class AuthModule {}
