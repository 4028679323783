import { ResetPasswordEntity } from "../../domain/entities/reset-password.entity";

export class ResetPasswordModel extends ResetPasswordEntity{
    override toJson(): Map<string, any> {
        let json: Map<string, any> = new Map();
        json.set('id', this.id);
        json.set('email', this.email);
        json.set('password', this.password);
        json.set('new_password', this.newPassword);
        json.set('repeated_password', this.repeatedPassword);
        return json;
    }
}