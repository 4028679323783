import { Injectable } from "@angular/core";
import { UseCase } from "src/app/core/utils/usecase";
import { Failure } from "src/app/core/utils/failure";
import { ProvinceRepositoryBase } from "../repositories/province-base.repository";
import { LocationEntity } from "../entities/location.entity";

@Injectable()
export class GetLocationsUseCase implements UseCase<string, LocationEntity[] | Failure> {

    constructor(private provinceRepository: ProvinceRepositoryBase){
        
    }

   async execute(id: string): Promise<LocationEntity[] | Failure> {
        return await this.provinceRepository.getLocationsByProvince(id);
   }
}