import { PaginationParams } from "src/app/core/interfaces/pagination-params.interface";
import { UseCase } from "src/app/core/utils/usecase";
import { CompanyPagination } from "../entities/company-pagination";
import { Failure } from "src/app/core/utils/failure";
import { CompanyrepositoryBase } from "../repositories/company-base.repository";
import { CompanyPaginationParams } from "../entities/company-pagination-params";
import { Injectable } from "@angular/core";

@Injectable()
export class GetAllPaginationCompaniesUseCase implements UseCase<PaginationParams, CompanyPagination | Failure> {

    constructor(private companyRepository: CompanyrepositoryBase) { }

    async execute(param: CompanyPaginationParams): Promise<CompanyPagination | Failure> {
        return await this.companyRepository.getPaginatedCompanies(param);
    }
} 