import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-spinner-load',
  templateUrl: './spinner-load.component.html',
  styleUrls: ['./spinner-load.component.css']
})
export class SpinnerLoadComponent {
  @Input() isLoading: boolean = false;
}
