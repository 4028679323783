import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { Rol } from 'src/app/core/interfaces/user.interface';

type ValidatorKeys =
  | 'uppercase'
  | 'lowercase'
  | 'minlength'
  | 'number'
  | 'specialChar';

@Component({
  selector: 'app-step-mail-password',
  templateUrl: './step-mail-password.component.html',
  styleUrls: ['./step-mail-password.component.css'],
})
export class StepMailPasswordComponent {
  @Input() initialData: any = {};
  @Output() emailPassword: EventEmitter<FormGroup> = new EventEmitter();
  @Output() back: EventEmitter<number> = new EventEmitter();
  @Input() selectedRol!: Rol;

  showPassword: boolean = false;
  isLoading: boolean = false;

  registerFormEmail: FormGroup = this.formBuilder.group(
    {
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, this.createPasswordValidator()]],
      repeatPassword: ['', Validators.required],
    },
    {
      validators: this.passwordMatchValidator,
    }
  );

  createPasswordValidator() {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!value) {
        return null;
      }
      const hasUpperCase = /[A-Z]+/.test(value);
      const hasLowerCase = /[a-z]+/.test(value);
      const hasNumeric = /[0-9]+/.test(value);
      const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(value);
      const passwordValid =
        hasUpperCase &&
        hasLowerCase &&
        hasNumeric &&
        hasSpecialChar &&
        value.length >= 8;
      return !passwordValid ? { invalidPassword: true } : null;
    };
  }

  checkPasswordValidators(value: string) {
    const validators: { [key in ValidatorKeys]: boolean } = {
      uppercase: /[A-Z]/.test(value),
      lowercase: /[a-z]/.test(value),
      minlength: value.length >= 8,
      number: /[0-9]/.test(value),
      specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(value),
    };

    for (const key in validators) {
      if (validators.hasOwnProperty(key)) {
        const element = document.getElementById(key);
        if (validators[key as ValidatorKeys]) {
          element?.classList.add('valid');
          element?.classList.remove('invalid');
        } else {
          element?.classList.add('invalid');
          element?.classList.remove('valid');
        }
      }
    }

    this.registerFormEmail.get('password')?.updateValueAndValidity();
  }

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    if (this.initialData) {
      this.registerFormEmail.patchValue(this.initialData);
    }
    this.registerFormEmail.get('password')?.valueChanges.subscribe((value) => {
      this.checkPasswordValidators(value);
    });
  }

  nextStep() {
    const emailControl = this.registerFormEmail.get('email');
    if (emailControl) {
      emailControl.setValue(emailControl.value.toLowerCase(), {
        emitEvent: false,
      });
    }
    if (this.registerFormEmail.invalid) return;
    this.emailPassword.emit(this.registerFormEmail.value);
  }

  previusStep() {
    this.back.emit(1);
  }

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }

  hasErrors(controlName: string, errorType: string) {
    return (
      this.registerFormEmail.get(controlName)?.hasError(errorType) &&
      this.registerFormEmail.get(controlName)?.touched
    );
  }

  equalsPasswords(pass1: string, pass2: string) {
    return (formGroup: AbstractControl): ValidationErrors | null => {
      const passA = formGroup.get(pass1)?.value;
      const passB = formGroup.get(pass2)?.value;

      if (passA !== pass2) {
        formGroup.get(pass2)?.setErrors({ noEquals: true });
        return { noEquals: true };
      }

      formGroup.get(pass2)?.setErrors(null);
      return null;
    };
  }

  passwordMatchValidator(control: AbstractControl): ValidationErrors | null {
    const password = control.get('password');
    const repeatPassword = control.get('repeatPassword');
    return password && repeatPassword && password.value !== repeatPassword.value
      ? { mismatch: true }
      : null;
  }
}
