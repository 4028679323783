<section class="grid flex flex-column w-full p-5">
  <div class="col-12 flex flex-row align-items-center gap-2 m-2">
    <i class="pi pi-angle-left"></i>
    <p>
      <a href="javascript:void(0)" (click)="goBack()">Mis reclamos laborales</a>
    </p>
    <p>/</p>
    <p><a class="font-bold">Detalle del reclamo</a></p>
  </div>
  <div class="col-12 flex justify-content-between align-items-center">
    <span class="titleSection font-bold text-xl mt-2 mb-2"
      >Detalle del reclamo</span
    >
  </div>

  <section id="claim-view">
    <article class="col-12 flex align-items-start gap-3">
      <div class="col-12 md:col-6 shadow-2 p-4 border-round-sm m-0">
        <div class="flex justify-content-between align-items-center">
          <span class="titleSection font-medium text-lg"
            >Número de reclamo: {{ claim?.numberOfClaim }}</span
          >
          <p [ngClass]="getStatusClass(claim?.status)">
            {{ claim?.status! | claimStatusDisplay }}
          </p>
        </div>
        <p>Fecha de inicio: {{ claim?.startDate | date : "dd/MM/yyyy" }}</p>
      </div>
    </article>

    <article class="col-12">
      <div class="shadow-2 p-4 border-round-sm">
        <span class="titleSection font-bold text-base mt- mb-2"
          >Datos de reclamante</span
        ><br />
        <div class="grid mt-2">
          <div class="col-6 md:col-3">
            <p>Razón Social</p>
            <p class="bold">
              {{ claim?.razonSocial ? claim?.razonSocial : "-" }}
            </p>
          </div>
          <div class="col-12 md:col-3">
            <p>CUIT</p>
            <p class="bold">
              {{ claim?.cuitCompany ? claim?.cuitCompany : "-" }}
            </p>
          </div>
          <div class="col-12 md:col-3">
            <p>Correo electrónico</p>
            <p class="bold">
              {{ claim?.companyEmail ? claim?.companyEmail : "-" }}
            </p>
          </div>
          <div class="col-6 md:col-3">
            <p>Teléfono</p>
            <p class="bold">
              {{ claim?.companyPhone ? claim?.companyPhone : "-" }}
            </p>
          </div>
          <div class="col-6 md:col-3">
            <p>Calle</p>
            <p class="bold">
              {{ claim?.streetCompany ? claim?.streetCompany : "-" }}
            </p>
          </div>
          <div class="col-6 md:col-3">
            <p>Número</p>
            <p class="bold">
              {{ claim?.numberCompany ? claim?.numberCompany : "-" }}
            </p>
          </div>
          <div class="col-6 md:col-3">
            <p>Piso/Depto</p>
            <p class="bold">
              {{
                claim?.floorApartmentCompany
                  ? claim?.floorApartmentCompany
                  : "-"
              }}
            </p>
          </div>
          <div class="col-6 md:col-3">
            <p>Torre</p>
            <p class="bold">
              {{ claim?.towerCompany ? claim?.towerCompany : "-" }}
            </p>
          </div>
          <div class="col-6 md:col-3">
            <p>Código postal</p>
            <p class="bold">
              {{ claim?.postalCodeCompany ? claim?.postalCodeCompany : "-" }}
            </p>
          </div>
          <div class="col-6 md:col-3">
            <p>Provincia</p>
            <p class="bold">
              {{ claimetProvince?.name ? claimetProvince?.name : "-" }}
            </p>
          </div>
          <div class="col-6 md:col-3">
            <p>Localidad</p>
            <p class="bold">
              {{ claim?.cityCompany ? claim?.cityCompany : "-" }}
            </p>
          </div>
        </div>
      </div>
    </article>

    <article class="col-12">
      <div class="shadow-2 p-4 border-round-sm">
        <span class="titleSection font-bold text-base mt-2 mb-2"
          >Datos del reclamado</span
        >
        <div class="grid mt-2">
          <div class="col-12 md:col-5">
            <p>Actividad</p>
            <p class="bold">
              {{ claim?.claimedActivity ? claim?.claimedActivity : "-" }}
            </p>
          </div>
          <div class="col-12 md:col-2">
            <p>Convenio Colectivo</p>
            <p class="bold">
              {{
                claim?.claimedCollectiveAgreement
                  ? claim?.claimedCollectiveAgreement
                  : "-"
              }}
            </p>
          </div>
          <div class="col-12 md:col-3">
            <p>Cantidad de trabajadores afectados por la medida</p>
            <p class="bold">
              {{ claim?.affectedWorkers ? claim?.affectedWorkers : "-" }}
            </p>
          </div>
          <div class="col-12 md:col-2">
            <p>Correo electrónico</p>
            <p class="bold">
              {{ claim?.claimedEmail ? claim?.claimedEmail : "-" }}
            </p>
          </div>
          <div class="col-12 md:col-10">
            <p>Asociación sindical</p>
            <p class="bold">
              {{ claim?.claimedAssociation ? claim?.claimedAssociation : "-" }}
            </p>
          </div>
          <div class="col-12">
            <p>Documentos</p>
            <div class="gap-2 flex mt-2 extra-doc-container">
              <div
                class="pdf-item flex align-items-center justify-content-between"
                *ngFor="let file of files"
              >
                <div class="pdf-info flex align-items-center">
                  <i class="pi pi-file-pdf"></i>
                  <span class="bold mt-0">{{ file }}</span>
                </div>
                <div class="pdf-actions">
                  <button (click)="viewImage(file)" title="Ver PDF">
                    <i class="pi pi-eye"></i>
                  </button>
                  <!-- (click)="downloadPdfMinute(pdf.id, pdf.fileActa)" -->
                  <button title="Descargar PDF">
                    <i class="pi pi-download"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>

    <article class="col-12">
      <div class="shadow-2 p-4 border-round-sm">
        <div class="grid mt-2">
          <div class="col-10">
            <span class="titleSection font-bold text-base mt-2 mb-2"
              >Motivos del conflicto</span
            >
          </div>
          <div class="col-12">
            <p>Objeto del reclamo</p>
            <p class="bold">
              {{ claim?.objectOfClaim ? claim?.objectOfClaim : "-" }}
            </p>
          </div>
          <div
            *ngIf="
              claim?.objectOfClaim &&
              (claim!.objectOfClaim.includes('CESION DE PERSONAL') ||
                claim!.objectOfClaim.includes(
                  'TRANSFERENCIA DEL ESTABLECIMIENTO'
                ))
            "
          >
            <div class="col-12">
              <p>CUIT Cedente</p>
              <p class="bold">
                {{ claim?.cuit_assignor }}
              </p>
            </div>
            <div class="col-12">
              <p>Razón Social</p>
              <p class="bold">
                {{ claim?.razon_social_assignor }}
              </p>
            </div>
            <div class="col-12">
              <p>CUIT Cesionario</p>
              <p class="bold">
                {{ claim?.cuit_assignee }}
              </p>
            </div>
            <div class="col-12">
              <p>Razón Social</p>
              <p class="bold">
                {{ claim?.razon_social_assignee }}
              </p>
            </div>
          </div>
          <div class="col-12 detail-claim">
            <p>Cantidad de trabajadores del establecimiento</p>
            <p class="bold">
              {{ claim?.workersCount ? claim?.workersCount : "-" }}
            </p>
          </div>
          <div class="col-12 detail-claim">
            <p>Modalidad de Presentación del Reclamo</p>
            <p class="bold">
              {{ claim?.selectedOwnRight ? claim?.selectedOwnRight : "-" }}
            </p>
          </div>
        </div>

        <hr class="mb-3" />
        <span class="titleSection font-bold text-base mt-2 mb-2"
          >Representación letrada</span
        >
        <div class="grid mt-2">
          <div class="col-6 md:col-3">
            <p>Apellido y Nombre</p>
            <p class="bold">
              {{ claim?.fullNameLawyer ? claim?.fullNameLawyer : "-" }}
            </p>
          </div>
          <div class="col-6 md:col-3">
            <p>Matrícula</p>
            <p class="bold">
              {{ claim?.registrationNumber ? claim?.registrationNumber : "-" }}
            </p>
          </div>
          <div class="col-6 md:col-3">
            <p>Correo electrónico</p>
            <p class="bold">
              {{ claim?.lawyerEmail ? claim?.lawyerEmail : "-" }}
            </p>
          </div>
        </div>
      </div>
    </article>

    <article class="col-12">
      <div class="shadow-2 p-4 border-round-sm">
        <span class="titleSection font-bold text-base mt-2 mb-4"
          >Historial de audiencias</span
        >
        <ng-container *ngIf="minutesForClaim?.length; else elseTemplate">
          <p class="mt-2 ml-4 font-bold">Fecha</p>
          <div class="gap-2 flex mt-2 flex flex-column">
            <div
              class="flex col-12 align-items-center justify-content-between"
              *ngFor="let pdf of minutesForClaim"
            >
              <div class="pdf-info flex align-items-center col-6">
                <i class="pi pi-file-pdf ml-8"></i>
                <span class="bold mt-0">{{ pdf.fileActa }}</span>
              </div>
              <div class="pdf-actions">
                <button title="Ver PDF">
                  <i class="pi pi-eye"></i>
                </button>
                <button title="Descargar PDF">
                  <i class="pi pi-download"></i>
                </button>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-template #elseTemplate>
          <p class="mt-2 ml-4 font-bold">No hay actas registradas</p>
        </ng-template>
      </div>
    </article>
  </section>
  <div
    *ngIf="
      !buttons ||
      claim?.status === 'Finalizado' ||
      claim?.status === 'Rechazado' ||
      claim?.status === 'Borrador'
    "
  >
    <div class="grid w-full flex justify-content-end mt-3">
      <div class="col-6 md:col-3 lg:col-3 xl:col-3">
        <p-button
          (click)="goBack()"
          label="Volver"
          class="btn-cancelar sm:text-xs"
        />
      </div>
    </div>
  </div>
  <app-action-buttons
    [buttons]="buttons"
    [claim]="claim"
    [rol]="userRol"
    [minuteId]="minuteId"
    (goBackEvent)="goBack()"
    (acceptRejectEvent)="recibeAceptDialog($event)"
    (newAudienceEvent)="newAudienceCheck()"
    (newMinuteEvent)="newMinuteCheck()"
  ></app-action-buttons>
</section>
<p-dialog
  header="Visualizador"
  [(visible)]="displayModal"
  [modal]="true"
  [style]="{ width: '80vw', height: '90vh' }"
  [draggable]="false"
  [resizable]="false"
>
  <object
    [data]="pdfUrl! | safeResourceUrl"
    type="application/pdf"
    width="100%"
    height="100%"
  >
    <p>No se puede mostrar el PDF.</p>
  </object>
</p-dialog>
<p-dialog
  header="Visualizador"
  [(visible)]="displayModalImage"
  [modal]="true"
  [style]="{ width: '80vw', height: '90vh' }"
  [draggable]="false"
  [resizable]="false"
>
  <object
    [data]="pdfUrl! | safeResourceUrl"
    type="image/jpeg"
    width="100%"
    height="100%"
  >
    <p>No se puede mostrar la imagen.</p>
  </object>
</p-dialog>

<div>
  <p-dialog
    header="Fijar Audiencia"
    [(visible)]="audienciaDialogVisible"
    [modal]="true"
    [closable]="false"
  >
    <app-audience
      (closePopup)="cancel($event)"
      [claim]="claimForAudience"
      (showMessage)="handleShowMessage($event)"
    ></app-audience>
  </p-dialog>
</div>

<div>
  <p-dialog
    header="Subir acta"
    [(visible)]="minuteDialogVisible"
    [modal]="true"
    [closable]="false"
  >
    <app-minute
      (closePopup)="cancel($event)"
      [claim]="claim"
      [audienceId]="minuteId"
      (showMessage)="handleShowMessage($event)"
    ></app-minute>
  </p-dialog>
  <div class="card flex justify-content-center w-full relative">
    <p-toast />
    <p-confirmDialog #cd>
      <ng-template pTemplate="headless" let-message class="congirmDialog">
        <div
          class="flex flex-column align-items-center p-5 surface-overlay border-round"
        >
          <span class="labelTitle1 text-lg font-semibold mb-1">
            {{ message.header }}
          </span>
          <div class="flex justify-content-center align-items-center gap-2">
            <i class="pi pi-exclamation-circle"></i>
            <p class="text-base">{{ message.message }}</p>
          </div>
          <div
            class="flex flex-row justify-content-center align-items-center gap-2 mt-3"
          >
            <ng-container class="col-12">
              <button
                pButton
                label="Cancelar"
                (click)="cd.reject()"
                class="btn-cancelar col-6"
                style="
                  border: 1px solid #263245;
                  background-color: white;
                  color: #263245;
                  font-size: 0.8rem;
                "
              ></button>
              <button
                pButton
                [label]="labelText"
                (click)="cd.accept()"
                class="btn-siguiente col-6 popup"
                style="
                  background-color: #263245;
                  border-color: #263245;
                  color: white !important;
                  font-size: 0.8rem;
                "
              ></button>
            </ng-container>
          </div>
        </div>
      </ng-template>
    </p-confirmDialog>
    <p-dialog
      header="Rechazar reclamo"
      [(visible)]="rejectDialogVisible"
      [modal]="true"
      [closable]="false"
    >
      <div class="p-fluid">
        <div class="p-field flex flex-column">
          <label class="font-semibold text-title" for="reason"
            >Motivo de rechazo</label
          >
          <input
            placeholder="Escriba aqui el motivo de rechazo del reclamo"
            id="reason"
            [(ngModel)]="rejectionReason"
            rows="5"
            class="inputtarea"
          />
        </div>
      </div>
      <div class="flex justify-content-end gap-2">
        <button
          pButton
          label="Cancelar"
          (click)="rejectDialogVisible = false"
          (click)="rejectionReason = ''"
          class="btn-cancelar"
          style="
            border: 1px solid #263245;
            background-color: white;
            color: #263245;
            font-size: 0.8rem;
          "
        ></button>
        <button
          pButton
          label="Rechazar reclamo"
          (click)="confirmRejection()"
        ></button>
      </div>
    </p-dialog>
  </div>
</div>
