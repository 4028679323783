import { environment } from 'src/environments/environment.dev';
import { HttpServiceBase } from 'src/app/core/services/http.service';
import { Injectable } from '@angular/core';
import { Failure } from 'src/app/core/utils/failure';
import { LocalStorageServiceBase } from 'src/app/core/services/localStorage-serviceBase';
import { HttpHeaders } from '@angular/common/http';
import { ClaimMultiEntity } from '../../domain/entities/claim-multi.entity';
import { ClaimsPaginationParams } from '../../domain/entities/claim-pagination-params.entity';
import { MultiPagination } from '../../domain/entities/multi-pagination.entity';
import { UpdateClaimConciliatorEntity } from '../../domain/entities/update-claim-conciliator.entity';

export abstract class ClaimMultiRemoteDataSourceBase {
  abstract createClaim(claim: ClaimMultiEntity): Promise<string | Failure>;
  abstract getMultiClaims(
    params: ClaimsPaginationParams
  ): Promise<MultiPagination | Failure>;
  abstract updateClaimConciliator(
    claim: UpdateClaimConciliatorEntity
  ): Promise<string | Failure>;
}

@Injectable()
export class ClaimMultiRemoteDataSource extends ClaimMultiRemoteDataSourceBase {
  private url: string = `${environment.apiUrl}/claimpluriindividual`;

  constructor(
    private httpService: HttpServiceBase,
    private localStorageService: LocalStorageServiceBase
  ) {
    super();
  }

  override async createClaim(
    claim: ClaimMultiEntity
  ): Promise<string | Failure> {
    let headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${this.localStorageService.read('access_token')}`
    );
    let result = await this.httpService.post(`${this.url}`, claim, headers);
    return result.get('response');
  }

  override async getMultiClaims(
    params: ClaimsPaginationParams
  ): Promise<MultiPagination | Failure> {
    let headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${this.localStorageService.read('access_token')}`
    );
    let result = await this.httpService.get(
      `${this.url}/get_all_claims_plurindivual${params.getPath()}`,
      headers
    );
    let response = result.get('response');
    return response;
  }

  override async updateClaimConciliator(
    claim: UpdateClaimConciliatorEntity
  ): Promise<string | Failure> {
    let headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${this.localStorageService.read('access_token')}`
    );
    let result = await this.httpService.put(`${this.url}/`, claim, headers);
    return result.get('response');
  }
}
