<div class="form-container">
  <p-toast></p-toast>

  <form
    [formGroup]="emailForgotPassword"
    (ngSubmit)="forgotPasswordSendEmail()"
    *ngIf="!emailSend"
    id="container-form"
  >
    <div class="grid">
      <div class="col-12 md:col-6">
        <h2>Restablecer contraseña</h2>
      </div>

      <div class="col-12 md:col-12">
        <p class="titleSection font-normal text-xs">
          Ingrese su correo electrónico para poder restablecer su contraseña.
        </p>
      </div>

      <div class="col-12 md:col-8">
        <label class="labelTitle mb-1" for="email">Correo electrónico*</label>
      </div>

      <div class="col-12 md:col-8">
        <input
          pInputText
          id="email"
          class="emailInput col-12"
          type="email"
          maxlength="40"
          autocomplete="off"
          placeholder="Ingrese su correo electronico"
          formControlName="email"
          [ngClass]="{
            'ng-invalid ng-dirty':
              hasErrors('email', 'required') || hasErrors('email', 'email')
          }"
        />
        <div
          class="text-danger p-error mt-2"
          *ngIf="hasErrors('email', 'required')"
        >
          * Este campo es obligatorio.
        </div>
        <div
          class="text-danger p-error mt-2"
          *ngIf="hasErrors('email', 'invalidEmail')"
        >
          * El correo debe tener un formato válido.
        </div>
      </div>

      <div *ngIf="!isLoading" class="col-12 md:col-8">
        <p-button
          class="btnContinue nextButton"
          label="Continuar"
          [disabled]="emailForgotPassword.invalid || isButtonClicked"
          type="submit"
        ></p-button>
        <span class="backLink"
          ><a [routerLink]="['/auth/login']">Volver</a></span
        >
      </div>

      <div *ngIf="isLoading" class="col-12 md:col-8 xl:col-8 d-flex mt-2">
        <app-spinner></app-spinner>
      </div>
    </div>
  </form>
  <div *ngIf="emailSend" class="information col-10">
    <img
      src="../../../../../../../assets/img/register/check-account.png"
      alt=""
    />
    <span>Se envió un correo electrónico para reestablecer su contraseña.</span>
  </div>
</div>
