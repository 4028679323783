import { UseCase } from "src/app/core/utils/usecase";
import { AudienceEntity } from "../entities/audience.entity";
import { Failure } from "src/app/core/utils/failure";
import { AudienceRepositoryBase } from "../repositories/aucience-base.repository";
import { Injectable } from "@angular/core";

@Injectable()
export class GetNextSetAudienceUseCase implements UseCase<string, AudienceEntity | Failure> {
    
    constructor(private audienceRepository: AudienceRepositoryBase) {}
   
    async execute(): Promise<AudienceEntity | Failure> {
        return await this.audienceRepository.getNextSetAudience();
    }    
}