import { Injectable } from '@angular/core';
import {  Router } from '@angular/router';
import { LocalStorageServiceBase } from './localStorage-serviceBase';
import { SessionModel } from 'src/app/features/auth/data/models/session.model';
import { Session } from 'src/app/features/auth/domain/entities/session.entity';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService extends LocalStorageServiceBase {

  constructor(private router: Router) { 
    super();
  }

  override read(key: string): string | null | undefined {
    return sessionStorage.getItem(key);
  }

  override save(session: Session): void {
    const expiresIn = new Date().setSeconds(session.expires_in!);
    const refreshExpiresIn = new Date().setSeconds(session.refresh_expires_in!);
    const not_before_policy = new Date().setSeconds(session.not_before_policy!);

    sessionStorage.setItem('access_token', session.access_token!);
    sessionStorage.setItem('expires_in', expiresIn.toString());
    sessionStorage.setItem('refresh_token', session.refresh_token!);
    sessionStorage.setItem('refresh_expires_in', refreshExpiresIn.toString())
    sessionStorage.setItem('token_type', session.token_type!);
    sessionStorage.setItem('not_before_policy', not_before_policy.toString())
    sessionStorage.setItem('scope', session.scope!);
  }

  override delete(): void {
   sessionStorage.clear()
  }

  override isAuthenticated(): boolean {
    if(sessionStorage.getItem('access_token')){
      return true;
    } else return false;
  }

  override isTokenExpired(): boolean {
    const refreshExpiresIn = sessionStorage.getItem('refresh_expires_in');
    if (refreshExpiresIn) {
      const expiraRefreshDate = new Date(Number(refreshExpiresIn));
      expiraRefreshDate.setMinutes(expiraRefreshDate.getMinutes() - 5);
      return expiraRefreshDate <= new Date();
    }
    return false;
  }

  override loadStorageData(itemName: string): string {
    let data = undefined;
    if (localStorage.getItem(itemName)) {
      const stringData = localStorage.getItem(itemName) || '';
      data = JSON.parse(stringData);
    } else if (sessionStorage.getItem(itemName)) {
      const stringData = sessionStorage.getItem(itemName) || '';
      data = JSON.parse(stringData);
    }
    return data;
  }

  override saveStorageData(itemName: string, data: any, session?: boolean) {
    if (session) sessionStorage.setItem(itemName, JSON.stringify(data));
    else localStorage.setItem(itemName, JSON.stringify(data));
  }

}
