import { Entity } from "src/app/core/utils/entity";

export abstract class ComisionUser extends Entity {
    constructor(
      public rol: string,
      public fullName: string,
      public name: string,
      public lastName: string,
      public cuil: string,
      public phone: string,
      public dni: string,
      public province: string,
      public city: string,
      public street: string,
      public number: number,
      public postalCode: number,
      public email: string,
      public password: string,
      public is_administrator: boolean,
      public is_comision: boolean,
      public is_conciliator: boolean,
      public neighborhood: string,
      public floor_apartment?: string,
      public tower?: string,
      public id?: string,
    ) {
      super();
    }
  }