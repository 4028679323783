import { Entity } from 'src/app/core/utils/entity';

export abstract class ClaimCollectiveEntity extends Entity {
  constructor(
    // Usuario
    public id: string,
    public userId: string,
    public rol: string,

    // Datos de Reclamante
    public razonSocial: string,
    public cuitCompany: string,
    public companyEmail: string,
    public companyPhone: string,
    public streetCompany: string,
    public numberCompany: string,
    public floorApartmentCompany: string,
    public towerCompany: string,
    public postalCodeCompany: string,
    public provinceCompany: string,
    public cityCompany: string,
    public claimantTypePerformance: string,
    public departmentId: string[],

    // Datos de Reclamado
    public claimedActivity: string,
    public claimedCollectiveAgreement: string,
    public claimedAssociation: string,
    public affectedWorkers: string,
    public claimedEmail: string,
    public claimedFiles: string[],

    // Motivos de Conflicto
    public objectOfClaim: string[],
    public selectedOwnRight: string,
    public workersCount: string,
    public fullNameLawyer: string,
    public registrationNumber: string,
    public lawyerEmail: string,
    public consent: boolean,
    public cuit_assignor: string,
    public razon_social_assignor: string,
    public cuit_assignee: string,
    public razon_social_assignee: string,

    // Información de Reclamo
    public claimType: string,
    public confirmInformation: boolean,
    public startDate: Date,
    public numberOfClaim: string,
    public isBorrador : boolean,
    public status : string,
    public conciliator_full_name? : string
  ) {
    super();
  }
}
