import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserData } from 'src/app/features/user/domain/entities/user-data.entity';
import { UserService } from 'src/app/features/user/services/user.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Toast } from 'primeng/toast';
import { Failure } from 'src/app/core/utils/failure';
import AESEncryptDecryptService from 'src/app/core/utils/crypto';
import { LocalStorageServiceBase } from 'src/app/core/services/localStorage-serviceBase';
import { GetMyDataUseCase } from 'src/app/features/user/domain/usecases/get-my-data.usecase';
import { Location } from '@angular/common';
import { ClaimEntity } from 'src/app/features/claims/domain/entities/claim.entity';
import { UpdateClaimUseCase } from 'src/app/features/claims/domain/usecases/update-claim.usecase';
import { ClaimUseCase } from 'src/app/features/claims/domain/usecases/get-claim.usecase';
import { CreateClaimUseCase } from 'src/app/features/claims/domain/usecases/create-claim.usecase';
import { ClaimModel } from 'src/app/features/claims/data/models/claim.model';

@Component({
  selector: 'app-claim',
  templateUrl: './claim.component.html',
  styleUrls: ['./claim.component.css'],
  providers: [MessageService, Toast, ConfirmationService],
})
export class ClaimComponent implements OnInit {
  @Output() loadingChange = new EventEmitter<boolean>();

  claimId?: string | null;
  claim?: ClaimEntity;
  activeIndex: number = 0;
  userData?: UserData;
  formComplete: number = 0;
  private AES = new AESEncryptDecryptService();
  isNewClaim: boolean = false;
  forceCreate: boolean = false;
  userId?: string;
  isLoading: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private updateClaimUseCase: UpdateClaimUseCase,
    private createClaimUseCase: CreateClaimUseCase,
    private claimUseCase: ClaimUseCase,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private localStorageService: LocalStorageServiceBase,
    private myData: GetMyDataUseCase,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.loadingChange.emit(this.isLoading);
    this.userId = this.AES.decrypt(
      this.localStorageService.loadStorageData('user_id')
    );
    this.claimId = this.route.snapshot.paramMap.get('id');
    if (this.router.url.includes('main/new')) {
      this.isNewClaim = true;
    }
    this.getClaim();
    this.getMyData();
  }

  claimForm: FormGroup = this.formBuilder.group({
    claimetLastname: ['', [Validators.required]],
    claimetName: ['', [Validators.required]],
    claimetCuil: ['', [Validators.required]],
    claimetEmail: [
      '',
      [Validators.required, Validators.email, Validators.maxLength(100)],
    ],
    claimetPhone: ['', Validators.required],
    claimetProvince: ['', Validators.required],
    claimetCity: ['', [Validators.required]],
    claimetNeighborhood: [''],
    claimetStreet: ['', [Validators.required]],
    claimetNumber: ['', [Validators.required]],
    claimetPostalCode: ['', [Validators.required]],
    claimetFloorApartament: [''],
    claimetTower: [''],
    claimetSelectedTypeOfJob: ['', Validators.required],
    consent: ['', [Validators.required]],
    claimetWorkday: ['', [Validators.required]],
    claimetGrossRemuneration: ['', [Validators.required]],
    claimetTypeRemuneration: ['', [Validators.required]],
    claimetDateAdmission: [, [Validators.required]],
    claimetEgressDate: [],
    claimetNumberAgreement: ['', [Validators.required]],
    claimetYear: [, [Validators.required]],
    claimetCategory: ['', [Validators.required]],
    claimetCurrentEmploymentStatus: ['', [Validators.required]],
    claimetReasonForDisengagement: [],
    claimetCauseDisengagement: [],
    claimetFileDni: [],
    claimetFilePaycheck: [],
    claimedTypeOfPerson: [, Validators.required],
    claimedNameLastNameOrBusinessName: [
      ,
      [Validators.required, Validators.maxLength(100)],
    ],
    claimedCuil: [, Validators.required],
    claimedEmail: [
      ,
      [Validators.required, Validators.email, Validators.maxLength(100)],
    ],
    claimedPhone: [, [Validators.required, Validators.maxLength(10)]],
    claimedStreet: [, [Validators.required, Validators.maxLength(50)]],
    claimedNumber: [, [Validators.required, Validators.maxLength(5)]],
    claimedFloorApartment: [],
    claimedTower: [],
    claimedPostalCode: [, [Validators.required, Validators.maxLength(10)]],
    claimedNeighborhood: [],
    claimedProvince: ['', Validators.required],
    claimedCity: [, Validators.required],
    selecterObjectOfClaim: [, [Validators.required]],
    confirmInformation: [],
    textClaimDetails: ['', [Validators.required]],
    extraDocumentationPdf: [],
    selectedOwnRight: [, [Validators.required]],
    lawyerFullName: ['', [Validators.required]],
    lawyerRegistrationNumber: ['', [Validators.required]],
    lawyerEmail: ['', [Validators.required]],
    user_id: [''],
    id: [''],
    proceedings: [''],
    status: [''],
    created: [''],
    department_id: [''],
    is_borrador: [false],
    Rol: ['', [Validators.required]],
  });

  async getClaim(): Promise<void> {
    if (!this.claimId) return;
    let result = await this.claimUseCase.execute(this.claimId!);
    if (result instanceof Failure) {
      return;
    }
    this.claim = result;
  }

  async getMyData(): Promise<void> {
    let result = await this.myData.execute();
    if (result instanceof Failure) {
      return;
    }
    this.userData = result;
  }

  receiveData(data: FormGroup) {
    this.claimForm.patchValue(data);

    this.formComplete++;
  }

  onSubmit(data: FormGroup) {
    if (this.isNewClaim) {
      this.createClaim(data);
    } else if (this.claim?.status === 'Borrador') {
      this.updateClaimBorrador(data);
    } else if (this.claim?.status === 'Pendiente') {
      this.updateClaim(data);
    }
  }

  onForceCreation(force: boolean) {
    this.forceCreate = force;
  }

  activeStep(index: number) {
    this.activeIndex = index;
  }

  async createClaim(data: FormGroup): Promise<void> {
    this.isLoading = true;
    this.claimForm.patchValue(data);
    this.claimForm
      .get('user_id')
      ?.setValue(
        this.AES.decrypt(this.localStorageService.loadStorageData('user_id'))
      );
    this.claimForm.get('Rol')?.setValue(this.userData?.rol);
    try {
      let result = await this.createClaimUseCase.execute(this.createNewClaim());
      if (result instanceof Failure) {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: result.message,
        });
        return;
      }
      this.messageService.add({
        severity: 'success',
        summary: 'Reclamo creado',
        detail: 'Su nuevo reclamo se creó exitosamente.',
      });

      setTimeout(() => {
        this.router.navigate(['/main/home']);
      }, 1100);
    } catch (error) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error inesperado',
        detail: 'Ha ocurrido un error. Inténtelo nuevamente.',
      });
    } finally {
      this.isLoading = false;
    }
  }

  async updateClaim(data: FormGroup): Promise<void> {
    this.claimForm.patchValue(data);
    this.claimForm
      .get('user_id')
      ?.setValue(
        this.AES.decrypt(this.localStorageService.loadStorageData('user_id'))
      );
    this.claimForm.get('id')?.setValue(this.claimId);
    this.claimForm.get('Rol')?.setValue(this.userData?.rol);
    this.setFilesToSupportFormat();
    this.isLoading = true;
    try {
      let result = await this.updateClaimUseCase.execute(this.createNewClaim());
      if (result instanceof Failure) {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: result.message,
        });
        return;
      }
      this.messageService.add({
        severity: 'success',
        summary: 'Reclamo actualizado',
        detail: 'Su reclamo se actualizó exitosamente.',
      });
      setTimeout(() => {
        this.router.navigate(['/main/home']);
      }, 2000);
    } catch (error) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error inesperado',
        detail: 'Ha ocurrido un error. Inténtelo nuevamente.',
      });
    } finally {
      this.isLoading = false;
    }
  }

  async updateClaimBorrador(data: FormGroup): Promise<void> {
    this.claimForm.patchValue(data);
    this.claimForm
      .get('user_id')
      ?.setValue(
        this.AES.decrypt(this.localStorageService.loadStorageData('user_id'))
      );
    this.claimForm.get('Rol')?.setValue(this.userData?.rol);
    this.claimForm.get('id')?.setValue(this.claimId);
    this.setFilesToSupportFormat();
    this.isLoading = true;
    try {
      let result = await this.updateClaimUseCase.execute(this.createNewClaim());
      if (result instanceof Failure) {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: result.message,
        });
        return;
      }
      this.messageService.add({
        severity: 'success',
        summary: 'Reclamo creado',
        detail: 'Su nuevo reclamo se creó exitosamente.',
      });
      setTimeout(() => {
        this.router.navigate(['/main/home']);
      }, 2000);
    } catch (error) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error inesperado',
        detail: 'Ha ocurrido un error. Inténtelo nuevamente.',
      });
    } finally {
      this.isLoading = false;
    }
  }

  async saveDraft(data: FormGroup): Promise<void> {
    this.isLoading = true;
    try {
      const originalValidators = new Map();
      Object.keys(this.claimForm.controls).forEach((key) => {
        originalValidators.set(key, this.claimForm.get(key)?.validator);
        this.claimForm.get(key)?.clearValidators();
        this.claimForm.get(key)?.updateValueAndValidity({ emitEvent: false });
      });
      this.claimForm.patchValue(data);
      this.claimForm.patchValue({
        ...data.value,
        user_id: this.AES.decrypt(
          this.localStorageService.loadStorageData('user_id')
        ),
        is_borrador: true,
        id: this.claim?.id,
      });

      this.setFilesToSupportFormat();
      this.claimForm.get('Rol')?.setValue(this.userData?.rol);
      let result;
      if (this.claimId) {
        this.claimForm.patchValue({ id: this.claimId });
        result = await this.updateClaimUseCase.execute(this.createDraftClaim());
      } else {
        result = await this.createClaimUseCase.execute(this.createDraftClaim());
      }

      if (result instanceof Failure) {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: result.message || 'Ocurrió un error al guardar el borrador',
        });
        return;
      }

      this.messageService.add({
        severity: 'success',
        summary: 'Guardado',
        detail: this.claimId
          ? 'Borrador actualizado exitosamente'
          : 'Borrador guardado exitosamente',
        life: 3000,
      });

      setTimeout(() => {
        this.router.navigate(['/main/claims']);
      }, 1100);
    } catch (error) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Ocurrió un error al guardar el borrador',
      });
    } finally {
      this.isLoading = false;
    }
  }

  createDraftClaim(): ClaimModel {
    const formValues = this.claimForm.getRawValue();
    const modelo = new ClaimModel(
      formValues.department_id || null,
      formValues.claimetLastname || null,
      formValues.claimetName || null,
      formValues.claimetCuil || null,
      formValues.claimetEmail || null,
      formValues.claimetPhone || null,
      formValues.claimetProvince || null,
      formValues.claimetCity || null,
      formValues.claimetNeighborhood || null,
      formValues.claimetStreet || null,
      formValues.claimetNumber || null,
      formValues.claimetPostalCode || null,
      formValues.claimetSelectedTypeOfJob || null,
      formValues.consent || null,
      formValues.claimetWorkday || null,
      formValues.claimetGrossRemuneration?.toString() || null,
      formValues.claimetTypeRemuneration || null,
      formValues.claimetDateAdmission || null,
      formValues.claimetNumberAgreement?.toString() || null,
      formValues.claimetYear || null,
      formValues.claimetCategory || null,
      formValues.claimetCurrentEmploymentStatus || null,
      formValues.claimetFileDni || [],
      formValues.claimetFilePaycheck || [],
      formValues.claimedTypeOfPerson || null,
      formValues.claimedNameLastNameOrBusinessName || null,
      formValues.claimedCuil?.toString() || null,
      formValues.claimedEmail || null,
      formValues.claimedPhone?.toString() || null,
      formValues.claimedStreet || null,
      formValues.claimedNumber?.toString() || null,
      formValues.claimedPostalCode || null,
      formValues.claimedNeighborhood || null,
      formValues.claimedProvince || null,
      formValues.claimedCity || null,
      formValues.selecterObjectOfClaim || null,
      formValues.textClaimDetails || null,
      formValues.extraDocumentationPdf || [],
      formValues.selectedOwnRight || null,
      formValues.lawyerFullName || null,
      formValues.lawyerRegistrationNumber?.toString() || null,
      formValues.lawyerEmail || null,
      formValues.user_id || null,
      formValues.claimetFloorApartament || null,
      formValues.claimetTower || null,
      formValues.confirmInformation || null,
      formValues.claimetEgressDate || null,
      formValues.claimetReasonForDisengagement || null,
      formValues.claimetCauseDisengagement || null,
      formValues.claimedFloorApartment || null,
      formValues.claimedTower || null,
      formValues.proceedings || null,
      formValues.expediente_claim || null,
      formValues.number_of_claim || null,
      formValues.status || null,
      formValues.created || null,
      formValues.reasonForRejection || null,
      formValues.conciliatorId || null,
      formValues.conciliatorFullName || null,
      formValues.is_borrador || null,
      formValues.department_number || null,
      formValues.department_name || null,
      formValues.id || null,
      formValues.Rol || null
    );
    return modelo;
  }

  async showCancelModal(isActive: boolean) {
    if (this.claim?.status === 'Borrador' || this.claim?.status === undefined) {
      if (!isActive) return;
      this.confirmationService.confirm({
        header: 'Salir del reclamo',
        message:
          'Su progreso se guardara automáticamente como borrador si desea salir. Podrá retomar cuando lo desee.',
        accept: async () => {
          try {
            await this.saveDraft(this.claimForm.value);
          } catch (error) {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail:
                'No se pudo guardar el borrador. Por favor intente nuevamente.',
            });
          }
        },
      });
    } else {
      this.confirmationService.confirm({
        header: 'Salir del reclamo',
        message:
          '¿Estás seguro de que deseas salir del reclamo? Se perderán los cambios no guardados.',
        accept: async () => {
          this.router.navigate([this.location.back()]);
        },
      });
    }
  }

  setFilesToSupportFormat() {
    const handleFileOrBase64 = (value: any): string[] => {
      const result: string[] = [];
      if (!value) return result;

      if (Array.isArray(value)) {
        value.forEach((item) => {
          if (typeof item === 'string' && item.includes('base64')) {
            result.push(item);
          } else if (item?.base64) {
            result.push(item.base64);
          } else if (typeof item === 'string') {
            result.push(item);
          }
        });
      } else if (typeof value === 'string' && value.includes('base64')) {
        result.push(value);
      } else if (value?.base64) {
        result.push(value.base64);
      } else if (typeof value === 'string') {
        result.push(value);
      }
      return result;
    };

    const dniFiles = handleFileOrBase64(
      this.claimForm.get('claimetFileDni')?.value
    );
    const extraDocFiles = handleFileOrBase64(
      this.claimForm.get('extraDocumentationPdf')?.value
    );
    const paycheckFile = handleFileOrBase64(
      this.claimForm.get('claimetFilePaycheck')?.value
    );

    if (dniFiles.length > 0) {
      this.claimForm.get('claimetFileDni')?.setValue(dniFiles);
    }
    if (extraDocFiles.length > 0) {
      this.claimForm.get('extraDocumentationPdf')?.setValue(extraDocFiles);
    }
    if (paycheckFile.length > 0) {
      this.claimForm.get('claimetFilePaycheck')?.setValue(paycheckFile);
    }
  }

  createNewClaim(): ClaimModel {
    return new ClaimModel(
      this.claimForm.get('department_id')?.value,
      this.claimForm.get('claimetLastname')?.value,
      this.claimForm.get('claimetName')?.value,
      this.claimForm.get('claimetCuil')?.value,
      this.claimForm.get('claimetEmail')?.value,
      this.claimForm.get('claimetPhone')?.value,
      this.claimForm.get('claimetProvince')?.value,
      this.claimForm.get('claimetCity')?.value,
      this.claimForm.get('claimetNeighborhood')?.value,
      this.claimForm.get('claimetStreet')?.value,
      this.claimForm.get('claimetNumber')?.value,
      this.claimForm.get('claimetPostalCode')?.value,
      this.claimForm.get('claimetSelectedTypeOfJob')?.value,
      this.claimForm.get('consent')?.value,
      this.claimForm.get('claimetWorkday')?.value,
      this.claimForm.get('claimetGrossRemuneration')?.value.toString(),
      this.claimForm.get('claimetTypeRemuneration')?.value,
      this.claimForm.get('claimetDateAdmission')?.value,
      this.claimForm.get('claimetNumberAgreement')?.value.toString(),
      this.claimForm.get('claimetYear')?.value,
      this.claimForm.get('claimetCategory')?.value,
      this.claimForm.get('claimetCurrentEmploymentStatus')?.value,
      this.claimForm.get('claimetFileDni')?.value,
      this.claimForm.get('claimetFilePaycheck')?.value,
      this.claimForm.get('claimedTypeOfPerson')?.value,
      this.claimForm.get('claimedNameLastNameOrBusinessName')?.value,
      this.claimForm.get('claimedCuil')?.value.toString(),
      this.claimForm.get('claimedEmail')?.value,
      this.claimForm.get('claimedPhone')?.value.toString(),
      this.claimForm.get('claimedStreet')?.value,
      this.claimForm.get('claimedNumber')?.value.toString(),
      this.claimForm.get('claimedPostalCode')?.value,
      this.claimForm.get('claimedNeighborhood')?.value,
      this.claimForm.get('claimedProvince')?.value,
      this.claimForm.get('claimedCity')?.value,
      this.claimForm.get('selecterObjectOfClaim')?.value,
      this.claimForm.get('textClaimDetails')?.value,
      this.claimForm.get('extraDocumentationPdf')?.value ?? '',
      this.claimForm.get('selectedOwnRight')?.value,
      this.claimForm.get('lawyerFullName')?.value,
      this.claimForm.get('lawyerRegistrationNumber')?.value.toString(),
      this.claimForm.get('lawyerEmail')?.value,
      this.claimForm.get('user_id')?.value,
      this.claimForm.get('claimetFloorApartament')?.value,
      this.claimForm.get('claimetTower')?.value,
      this.claimForm.get('confirmInformation')?.value ?? '',
      this.claimForm.get('claimetEgressDate')?.value,
      this.claimForm.get('claimetReasonForDisengagement')?.value,
      this.claimForm.get('claimetCauseDisengagement')?.value,
      this.claimForm.get('claimedFloorApartment')?.value,
      this.claimForm.get('claimedTower')?.value,
      this.claimForm.get('proceedings')?.value,
      this.claimForm.get('expediente_claim')?.value,
      this.claimForm.get('number_of_claim')?.value,
      this.claimForm.get('status')?.value,
      this.claimForm.get('created')?.value,
      this.claimForm.get('reasonForRejection')?.value,
      this.claimForm.get('conciliatorId')?.value,
      this.claimForm.get('conciliatorFullName')?.value,
      this.claimForm.get('is_borrador')?.value,
      this.claimForm.get('department_number')?.value,
      this.claimForm.get('department_name')?.value,
      this.claimForm.get('id')?.value,
      this.claimForm.get('Rol')?.value
    );
  }
}
