import { PaginationParams } from 'src/app/core/interfaces/pagination-params.interface';
import { UseCase } from 'src/app/core/utils/usecase';
import { ClaimPagination } from '../entities/claim-pagination.entity';
import { Failure } from 'src/app/core/utils/failure';
import { ClaimRepositoryBase } from '../repositories/claim-base.repository';
import { ClaimsPaginationParams } from '../entities/claim-pagination-params.entity';
import { Injectable } from '@angular/core';

@Injectable()
export class GetPaginatedAllClaimsByConciliatorUseCase
  implements UseCase<PaginationParams, ClaimPagination | Failure>
{
  constructor(private claimRepository: ClaimRepositoryBase) {}

  async execute(
    param: ClaimsPaginationParams
  ): Promise<ClaimPagination | Failure> {
    return await this.claimRepository.getAllPaginationClaimsByConciliatorId(
      param
    );
  }
}
