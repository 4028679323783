import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MessageService } from 'primeng/api';
import { ProvinceEntity } from 'src/app/core/services/location/domain/entities/province.entity';
import { LocalitiesEntity } from 'src/app/features/claims/data/models/location.model';
import { Departament } from 'src/app/features/user/presentation/components/New-User-SuperAdmin/newUser.component';
import { ClaimMultiSingleComponent } from '../claim-multi-single.component';
import { OfLegalAgeValidator } from 'src/app/core/utils/validator';
import { LocationEntity } from 'src/app/core/services/location/domain/entities/location.entity';
import { GetProvinceUseCase } from 'src/app/core/services/location/domain/usecases/get-province.usecase';
import { GetLocationsUseCase } from 'src/app/core/services/location/domain/usecases/get-locations.usecase';
import { GetDepartmentsUseCase } from 'src/app/core/services/location/domain/usecases/get-departments.usecase';

@Component({
  selector: 'app-claimant-details-multi',
  templateUrl: './claimant-details-multi.component.html',
  styleUrls: ['./claimant-details-multi.component.css'],
})
export class ClaimantDetailsMultiComponent {
  @Output() claimantDetailsForm = new EventEmitter<FormGroup>();
  @Output() stepIndex: EventEmitter<number> = new EventEmitter();
  @Output() activeModal: EventEmitter<boolean> = new EventEmitter();
  @Input() claim?: ClaimMultiSingleComponent;

  formGroup!: FormGroup;

  provinces: ProvinceEntity[] = [];
  localities: LocalitiesEntity[] = [];
  departments: Departament[] = [];

  isDragging: boolean = false;
  maxDate: Date;
  minDate: Date = new Date(1930, 0, 1);
  pdfFiles: any[] = [];

  constructor(
    private messageService: MessageService,
    private getProvince: GetProvinceUseCase,
    private getDepartmentsUsecase: GetDepartmentsUseCase,
    private getLocations: GetLocationsUseCase,
    private formBuilder: FormBuilder
  ) {
    this.maxDate = new Date();
  }

  async ngOnInit(): Promise<void> {
    this.initForms();
    await this.initializeComponent();
  }

  private initForms(): void {
    this.formGroup = this.formBuilder.group({
      razonSocial: ['', Validators.required],
      companyCuit: ['', Validators.required],
      companyEmail: ['', [Validators.required, Validators.email]],
      companyPhone: [
        '',
        [
          Validators.required,
          Validators.minLength(7),
          Validators.maxLength(15),
        ],
      ],
      streetCompany: ['', Validators.required],
      numberCompany: ['', Validators.required],
      floorApartmentCompany: [''],
      towerCompany: [''],
      postalCodeCompany: [
        '',
        [
          Validators.required,
          Validators.maxLength(10),
          Validators.minLength(4),
          Validators.pattern(/^[0-9]*$/),
        ],
      ],
      provinceCompany: ['', Validators.required],
      cityCompany: ['', Validators.required],
      claimantTypePerformance: ['', Validators.required],
      departmentId: ['', Validators.required],
      lastName: ['', Validators.required],
      name: ['', Validators.required],
      cuil: ['', Validators.required],
      claimantPosition: ['', Validators.required],
      phone: ['', Validators.required],
      email: ['', Validators.required],
      birthday: ['', OfLegalAgeValidator()],
      consent: [false, [Validators.requiredTrue]],
      file: ['', Validators.required],
    });
  }

  async initializeComponent(): Promise<void> {
    this.loadProvinces();
    this.loadDepartments();
    this.formGroup
      .get('provinceCompany')
      ?.valueChanges.subscribe((selectedProvince: any) => {
        this.loadLocalities(selectedProvince);
        this.formGroup.patchValue(
          { provincia: selectedProvince },
          { emitEvent: false }
        );
      });
  }

  async loadProvinces() {
    try {
      const result = await this.getProvince.execute();
      if (Array.isArray(result)) {
        this.provinces = result;
      }
    } catch (error) {
      console.error('Error fetching provinces', error);
    }
  }

  async loadLocalities(provinceId: string) {
    try {
      const localities = await this.getLocations.execute(provinceId);
      if (Array.isArray(localities)) {
        const localitiesList = this.putIdToLocalitiesList(localities);

        this.localities = localitiesList;
      }
    } catch (error) {
      console.error('Error fetching localities', error);
    }
  }

  putIdToLocalitiesList(list: LocationEntity[]): LocalitiesEntity[] {
    let listWithId: LocalitiesEntity[] = [];
    for (let i = 0; i < list.length; i++) {
      let city: LocalitiesEntity = {
        id: i,
        name: list[i].name,
      };
      listWithId.push(city);
    }
    return listWithId;
  }

  async loadDepartments() {
    const departments = await this.getDepartmentsUsecase.execute();
    if (Array.isArray(departments)) {
      this.departments = this.putIdToDepartmentsList(departments);
    }
  }

  putIdToDepartmentsList(list: Departament[]): Departament[] {
    let listWithId: Departament[] = [];
    for (let i = 0; i < list.length; i++) {
      let department: Departament = {
        id: list[i].id,
        name: list[i].name,
      };
      listWithId.push(department);
    }
    return listWithId;
  }

  onDragOver(event: Event) {
    event.preventDefault();
    this.isDragging = true;
  }

  onDropSuccess(event: any, file: string): any {
    const files = event.dataTransfer?.files;

    if (!files || files.length === 0) return;

    event.preventDefault();
    this.isDragging = false;
    this.onFileChange(files);
  }

  private onFileChange(files: any) {
    const newFiles = Array.from(files);
    this.pdfFiles = (this.pdfFiles || []).concat(newFiles);
  }

  onChange(event: any, file: string): any {
    const input = event.target as HTMLInputElement;
    const files = event.target.files;
    const fileForm = file;

    if (files.length > 0) {
      if (this.pdfFiles.length > 11) {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail:
            'Solo puedes subir hasta 12 archivos (JPG, WEBP, HEIF o PDF).',
        });
        return false;
      }

      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        if (
          ![
            'image/jpeg',
            'image/png',
            'image/webp',
            'image/heif',
            'application/pdf',
          ].includes(file.type)
        ) {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Solo se permiten archivos JPG, WEBP, HEIF o PDF.',
          });
          return false;
        }

        this.convertToBase64(file, fileForm);
      }

      input.value = '';
    }
  }
  private async convertToBase64(file: any, fileForm: string) {
    const reader = new FileReader();
    reader.onload = () => {
      const base64String = reader.result?.toString();
      if (base64String) {
        this.assignFilesReceipt(file, base64String);
      }
    };
    reader.onerror = (error) => {
      console.error('FileReader error:', error);
    };
    reader.readAsDataURL(file);
  }

  private assignFilesReceipt(file: any, base64String: string) {
    if (this.pdfFiles.length >= 12) return;

    this.pdfFiles.push({ name: file.name, base64: base64String });

    const filePaycheckBase64Array = this.pdfFiles.map((file) => file.base64);
    this.formGroup.get('file')?.setValue(filePaycheckBase64Array);
    this.formGroup.get('file')?.markAsTouched();
    this.formGroup.get('file')?.updateValueAndValidity();
  }

  deleteFilePfd(index: number) {
    // this.pdfFiles.splice(index, 1);
    // const fileReceiptBase64Array = this.pdfFiles.map((file) => file.base64);
    // this.formClaimetDetails
    //   .get('claimetFilePaycheck')
    //   ?.setValue(fileReceiptBase64Array.length ? fileReceiptBase64Array : null);
    // this.formClaimetDetails.get('claimetFilePaycheck')?.markAsTouched();
    // this.formClaimetDetails.get('claimetFilePaycheck')?.markAsDirty();
  }

  private CountValidatorPaycheck(
    control: AbstractControl
  ): { [key: string]: any } | null {
    return control.value?.length >= 1 && control.value?.length <= 12
      ? null
      : { fileCount: true };
  }

  hasErrors(controlName: string, errorType: string) {
    return (
      this.formGroup.get(controlName)?.hasError(errorType) &&
      this.formGroup.get(controlName)?.touched
    );
  }

  onConsentChange(event: any): void {
    this.formGroup.controls['consent'].setValue(event.checked);
  }

  nextStep() {
    this.claimantDetailsForm.emit(this.formGroup);
    this.stepIndex.emit(1);
  }

  cancel() {
    this.claimantDetailsForm.emit(this.formGroup);
    this.activeModal.emit(true);
  }
}
