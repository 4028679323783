<form [formGroup]="audienceForm" (ngSubmit)="createAudience()" class="w-full h-full">
    <div class="grid w-full">
        <label class="col-12 text-sm font-semibold">Paso 1 </label>
        <p class="col-12 text-xs font-medium m-0 pt-0">Programar una Meet en Google Calendar</p>
        <div class="col-7">
            <button type="button" pButton label="Agendar en Google Calendar" class="btn-meet"
                (click)="openGoogleCalendar()"
                style="background-color: #e1e4e9; color: #263245; border-color: #263245;">
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" id="google-calendar">
                    <path fill="#4285f4" d="M22,4.5v6H10v11H4V6.5a2.0059,2.0059,0,0,1,2-2Z"></path>
                    <polygon fill="#ea4435" points="22 27.5 22 21.5 28 21.5 22 27.5"></polygon>
                    <rect width="6" height="12" x="22" y="9.5" fill="#ffba00"></rect>
                    <rect width="6" height="12" x="13" y="18.5" fill="#00ac47" transform="rotate(90 16 24.5)">
                    </rect>
                    <path fill="#0066da" d="M28,6.5v4H22v-6h4A2.0059,2.0059,0,0,1,28,6.5Z"></path>
                    <path fill="#188038" d="M10,21.5v6H6a2.0059,2.0059,0,0,1-2-2v-4Z"></path>
                    <path fill="#4285f4"
                        d="M15.69,17.09c0,.89-.66,1.79-2.15,1.79a3.0026,3.0026,0,0,1-1.52-.39l-.08-.06.29-.82.13.08a2.3554,2.3554,0,0,0,1.17.34,1.191,1.191,0,0,0,.88-.31.8586.8586,0,0,0,.25-.65c-.01-.73-.68-.99-1.31-.99h-.54v-.81h.54c.45,0,1.12-.22,1.12-.82,0-.45-.31-.71-.85-.71a1.8865,1.8865,0,0,0-1.04.34l-.14.1-.28-.79.07-.06a2.834,2.834,0,0,1,1.53-.45c1.19,0,1.72.73,1.72,1.45a1.4369,1.4369,0,0,1-.81,1.3A1.52,1.52,0,0,1,15.69,17.09Z">
                    </path>
                    <polygon fill="#4285f4"
                        points="18.71 12.98 18.71 18.79 17.73 18.79 17.73 14 16.79 14.51 16.58 13.69 17.95 12.98 18.71 12.98">
                    </polygon>
                </svg>
            </button>
        </div>
        <label class="col-12 text-sm font-semibold">Paso 2 </label>
        <p class="col-12 text-xs font-medium m-0 pt-0">Luego cargue los datos para notificar de la audiencia a las
            partes:</p>

        <div class="grid w-full	ml-1 mt-3">
            <div class=" col-12 flex flex-column">
                <label class="labelTitle">Título de la audiencia*</label>
                <input pInputText maxlength="50" formControlName="audienceTitle"
                    [ngClass]="{'ng-invalid ng-dirty': hasErrors('audienceTitle', 'required') }" />
                <div class="text-danger p-error" *ngIf="hasErrors('audienceTitle', 'required')">
                    Este campo es obligatorio
                </div>
            </div>

            <div class="col-12 flex gap-1">
                <div class="col-6 flex flex-column p-0">
                    <label class="labelTitle">Fecha*</label>
                    <p-calendar class="" [iconDisplay]="'input'" [showIcon]="true" inputId="icondisplay"
                        placeholder="DD/MM/AAAA" dateFormat="dd/mm/yy" formControlName="date" [minDate]="minDate"
                        [ngClass]="{'ng-invalid ng-dirty': hasErrors('date', 'required')}" appendTo="body"></p-calendar>
                    <div class="text-danger p-error" *ngIf="hasErrors('date', 'required')">
                        Este campo es obligatorio
                    </div>
                </div>
                <div class="col-6 flex flex-column p-0">
                    <label class="labelTitle">Hora*</label>
                    <p-calendar inputId="calendar-timeonly" [timeOnly]="true" formControlName="time"
                        [ngClass]="{'ng-invalid ng-dirty': hasErrors('time', 'required')}" />
                    <div class="text-danger p-error" *ngIf="hasErrors('time', 'required')">
                        Este campo es obligatorio
                    </div>
                </div>
            </div>

            <div class="col-12 flex gap-1">
                <div class="col-6 p-0">
                    <label class="labelTitle">Responsable de la audiencia*</label>
                    <input type="text" maxlength="100" pInputText formControlName="conciliatorFullName" readonly />
                </div>
                <div class="col-6 p-0">
                    <label class="labelTitle">Link de Google Meet*</label>
                    <input type="text" maxlength="100" pInputText formControlName="linkGoogleMeet"
                        [ngClass]="{'ng-invalid ng-dirty': hasErrors('time', 'required')}" />
                    <div class="text-danger p-error" *ngIf="hasErrors('linkGoogleMeet', 'required')">
                        Este campo es obligatorio
                    </div>
                </div>
            </div>
        </div>

        <div class="grid w-full flex justify-content-end ml-1 mt-3">
            <div class="col-3">
                <button type="button" pButton label="Cancelar" class="btn-cancelar" (click)="cancel()"
                    style="background-color: whitesmoke; color: #263245; border: #263245;"></button>
            </div>
            <div class="col-3">
                <button type="submit" pButton class="btn-siguiente"
                    [disabled]="audienceForm.invalid || isButtonClicked">Notificar</button>
            </div>
        </div>
    </div>
</form>
