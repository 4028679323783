import { UseCase } from "src/app/core/utils/usecase";
import { MinuteEntity } from "../entities/minute.entity";
import { Failure } from "src/app/core/utils/failure";
import { MinuteRepositoryBase } from "../repositories/minute-base.repository";
import { Injectable } from "@angular/core";
import { MinutePdf } from "../entities/minutePdf.entity";

@Injectable()
export class CreateMinuteUseCase implements UseCase<MinutePdf, string | Failure> {
    constructor(private minuteRepository: MinuteRepositoryBase) {}
    
    async execute(param: MinutePdf): Promise<string | Failure> {
        return await this.minuteRepository.createMinute(param);
    }
}