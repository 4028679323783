import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MessageService } from 'primeng/api';
import { LocalStorageServiceBase } from 'src/app/core/services/localStorage-serviceBase';
import { GetDepartmentsUseCase } from 'src/app/core/services/location/domain/usecases/get-departments.usecase';
import AESEncryptDecryptService from 'src/app/core/utils/crypto';
import { Failure } from 'src/app/core/utils/failure';

import { UserData } from 'src/app/features/user/domain/entities/user-data.entity';
import { GetAllConciliatorsUseCase } from 'src/app/features/user/domain/usecases/get-all-conciliators.usecase';
import { Departament } from 'src/app/features/user/presentation/components/New-User-SuperAdmin/newUser.component';
import { AudienceFilterModel } from '../../../data/models/audience-filter.model';

@Component({
  selector: 'app-audience-filter',
  templateUrl: './audience-filter.component.html',
  styleUrls: ['./audience-filter.component.css'],
})
export class AudienceFilterComponent implements OnInit {
  departmentList: Departament[] = [];
  conciliatorList: UserData[] = [];

  selectedDate?: Date;
  selectedDepartment?: string;
  selectedconciliator?: string;
  userId?: string;
  generalFilter: string = '';

  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() filters: EventEmitter<AudienceFilterModel> =
    new EventEmitter<AudienceFilterModel>();

  private AES = new AESEncryptDecryptService();
  constructor(
    private getAllDepartments: GetDepartmentsUseCase,
    private getAllConciliators: GetAllConciliatorsUseCase,
    private localStorageService: LocalStorageServiceBase,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.userId = this.AES.decrypt(
      this.localStorageService.loadStorageData('user_rol')
    );
    this.getDepartments();
    this.getConciliators();
  }

  async getDepartments(): Promise<void> {
    const result: Departament[] | Failure =
      await this.getAllDepartments.execute();

    if (Array.isArray(result)) {
      this.departmentList = result;
    } else {
      this.errorMessage('error', 'Departamentos');
    }
  }

  async getConciliators(): Promise<void> {
    const result: UserData[] | Failure =
      await this.getAllConciliators.execute();

    if (Array.isArray(result)) {
      this.conciliatorList = result;
    } else {
      this.errorMessage('error', 'Conciliadores');
    }
  }

  private errorMessage(severity: string, name: string): void {
    this.messageService.add({
      severity: severity,
      summary: name,
      detail: `No se pudieron recuperar los ${name.toLocaleLowerCase()}`,
    });
  }

  hasFilters(): boolean {
    return (
      !!this.generalFilter ||
      !!this.selectedDate ||
      !!this.selectedDepartment ||
      !!this.selectedconciliator
    );
  }

  cleanFilter(): void {
    this.selectedconciliator = undefined;
    this.selectedDepartment = undefined;
    this.selectedDate = undefined;
    this.generalFilter = '';
    this.cancel.emit();
  }

  applyFilters(): void {
    this.filters.emit({
      conciliatorId: this.selectedconciliator,
      departmentId: this.selectedDepartment,
      fromDate: this.selectedDate,
      generalFilters: this.generalFilter,
    });
  }
}
