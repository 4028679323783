import { environment } from "src/environments/environment.dev";
import { UserData } from "../../domain/entities/user-data.entity";
import { HttpServiceBase } from "src/app/core/services/http.service";
import { UserDataModel } from "../models/user-data.model";
import { Injectable } from "@angular/core";
import { LocalStorageServiceBase } from "src/app/core/services/localStorage-serviceBase";
import { HttpHeaders } from "@angular/common/http";
import { Failure } from "src/app/core/utils/failure";
import { UserPaginationParams } from "../../domain/entities/user-pagination-params.entity";
import { UserPagination } from "../../domain/entities/user-pagination.entity";
import { DeleteUserEntity } from "../../domain/entities/delete-user.entity";
import { UserPaginationModel } from "../models/user-pagination.model";
import { ComisionUser } from "../../domain/entities/comision-user.entity";

export abstract class UserRemoteDataSourceBase {
    abstract getMyData(): Promise<UserData>;
    abstract getAllConciliators(): Promise<UserData[] | Failure>;

    abstract getUser(id: string): Promise<UserData | Failure>;
    abstract getPaginatedUser(params:UserPaginationParams): Promise<UserPagination>;
    abstract getAllPaginationUser(params:UserPaginationParams): Promise<UserPagination | Failure>;
    abstract updateUser(user:ComisionUser): Promise<string | Failure>;
    abstract deleteUser(deleteClaim:DeleteUserEntity): Promise<string | Failure>;
    abstract createUser(user:ComisionUser): Promise<string | Failure>;
}

@Injectable()
export class UserRemoteDataSource extends UserRemoteDataSourceBase {

    private url: string = `${environment.apiUrl}/User`;

    constructor(private httpService: HttpServiceBase,
        private localStorageService: LocalStorageServiceBase) {
        super();
    }

    override async getMyData(): Promise<UserData> {
        let headers = new HttpHeaders().set('Authorization', `Bearer ${this.localStorageService.read('access_token')}`);
        let result = await this.httpService.get(`${this.url}/my-data`, headers);
        let map = new Map<string, any>(Object.entries(result.get('response')));
        return UserDataModel.fromJson(map);
    }

    override async getAllConciliators(): Promise<UserData[] | Failure> {
        let headers = new HttpHeaders().set('Authorization', `Bearer ${this.localStorageService.read('access_token')}`);
        let result = await this.httpService.get(`${this.url}/get_all_conciliators`, headers);
        return result.get('response');
    }

    override async  getPaginatedUser(params:UserPaginationParams): Promise<UserPagination> {
      let headers = new HttpHeaders().set('Authorization', `Bearer ${this.localStorageService.read('access_token')}`);
      let result = await this.httpService.get(`${this.url}/user/${params.getPath()}`, headers);
      let response = result.get('response');
      let map = new Map<string, any>(Object.entries(response));
      return UserPaginationModel.fromJson(map);
    }

    override async  getAllPaginationUser(params:UserPaginationParams): Promise<UserPagination> {
      let headers = new HttpHeaders().set('Authorization', `Bearer ${this.localStorageService.read('access_token')}`);
      let result = await this.httpService.get(`${this.url}/get_all_users_comision/${params.getPath()}`, headers);
      let response = result.get('response');
      let map = new Map<string, any>(Object.entries(response));
      return UserPaginationModel.fromJson(map);
    }

    override async getUser(id: string): Promise<UserData> {
      let headers = new HttpHeaders().set('Authorization', `Bearer ${this.localStorageService.read('access_token')}`);
      let result = await this.httpService.get(`${this.url}/get_user_comision/?id=${id}`, headers);
      let response = result.get('response');
      let entries = Object.entries(response);
      let map = new Map<string, any>(entries);
      return UserDataModel.fromJson(map);
    }

    override async updateUser(user: ComisionUser): Promise<string | Failure> {
      let headers = new HttpHeaders().set('Authorization', `Bearer ${this.localStorageService.read('access_token')}`);
      let result = await this.httpService.put(`${this.url}/update_user_comision?id=${user.id}`, user, headers);
      return result.get('response');
    }

    override async deleteUser(deleteUser: DeleteUserEntity): Promise<string | Failure> {
      let headers = new HttpHeaders().set('Authorization', `Bearer ${this.localStorageService.read('access_token')}`);
      let result = await this.httpService.delete(`${this.url}/delete_users`, deleteUser, headers);
      return result.get('response');
    }

    override async createUser(user: ComisionUser): Promise<string | Failure> {
      let headers = new HttpHeaders().set('Authorization', `Bearer ${this.localStorageService.read('access_token')}`);
      let result = await this.httpService.post(`${this.url}/create_user_comision`, user, headers);
      return result.get('response');
    }
}
