import { Entity } from "src/app/core/utils/entity";

export abstract class ProvinceEntity extends Entity {
    constructor(
        public id: string,
        public name: string,
    ) {
        super();
    }
}
