<section class="grid flex flex-column w-full p-5">
  <div class="card flex justify-content-center w-full relative">
    <p-toast />
    <p-confirmDialog #cd>
      <ng-template pTemplate="headless" let-message class="congirmDialog">
        <div
          class="flex flex-column align-items-center p-5 surface-overlay border-round"
        >
          <span class="labelTitle1 text-lg font-semibold mb-1">
            {{ message.header }}
          </span>
          <div class="flex justify-content-center align-items-center gap-2">
            <i class="pi pi-exclamation-circle"></i>
            <p class="text-base">{{ message.message }}</p>
          </div>
          <div
            class="flex flex-row justify-content-center align-items-center gap-2 mt-3"
          >
            <ng-container class="col-12">
              <button
                pButton
                label="Cancelar"
                (click)="cd.reject()"
                class="btn-cancelar col-6"
                style="
                  border: 1px solid #263245;
                  background-color: white;
                  color: #263245;
                  font-size: 0.8rem;
                "
              ></button>
              <button
                pButton
                [label]="labelText"
                (click)="cd.accept()"
                class="btn-siguiente col-6 popup"
                style="
                  background-color: #263245;
                  border-color: #263245;
                  color: white !important;
                  font-size: 0.8rem;
                "
              ></button>
            </ng-container>
          </div>
        </div>
      </ng-template>
    </p-confirmDialog>
    <p-dialog
      header="Rechazar reclamo"
      [(visible)]="rejectDialogVisible"
      [modal]="true"
      [closable]="false"
    >
      <div class="p-fluid">
        <div class="p-field flex flex-column">
          <label class="font-semibold text-title" for="reason"
            >Motivo de rechazo</label
          >
          <input
            placeholder="Escriba aqui el motivo de rechazo del reclamo"
            id="reason"
            [(ngModel)]="rejectionReason"
            rows="5"
            class="inputtarea"
          />
        </div>
      </div>
      <div class="flex justify-content-end gap-2">
        <button
          pButton
          label="Cancelar"
          (click)="rejectDialogVisible = false"
          (click)="rejectionReason = ''"
          class="btn-cancelar"
          style="
            border: 1px solid #263245;
            background-color: white;
            color: #263245;
            font-size: 0.8rem;
          "
        ></button>
        <button
          pButton
          label="Rechazar reclamo"
          (click)="confirmRejection()"
        ></button>
      </div>
    </p-dialog>
  </div>

  <div>
    <p-dialog
      header="Fijar Audiencia"
      [(visible)]="audienciaDialogVisible"
      [modal]="true"
      [closable]="false"
    >
      <app-audience
        (closePopup)="cancel($event)"
        [claim]="claim"
        (showMessage)="handleShowMessage($event)"
      ></app-audience>
    </p-dialog>
  </div>

  <div>
    <p-dialog
      header="Subir acta"
      [(visible)]="minuteDialogVisible"
      [modal]="true"
      [closable]="false"
    >
      <app-minute
        (loadingChange)="handleLoadingChange($event)"
        (closePopup)="cancel($event)"
        [claim]="claim"
        [audienceId]="minuteId"
        (showMessage)="handleShowMessage($event)"
      ></app-minute>
    </p-dialog>
  </div>

  <div class="col-12 flex flex-row align-items-center gap-2 m-2">
    <i class="pi pi-angle-left"></i>
    <p>
      <a href="javascript:void(0)" (click)="goBack()">Mis reclamos laborales</a>
    </p>
    <p>/</p>
    <p><a class="font-bold">Detalle del reclamo</a></p>
  </div>
  <div class="col-12 flex justify-content-between align-items-center">
    <span class="titleSection font-bold text-xl mt-2 mb-2"
      >Detalle del reclamo</span
    >
  </div>

  <section id="claim-view">
    <article class="col-12 flex align-items-start gap-3">
      <div class="col-12 md:col-6 shadow-2 p-4 border-round-sm m-0">
        <div class="flex justify-content-between align-items-center">
          <span class="titleSection font-medium text-lg"
            >Número de reclamo: {{ claim?.number_of_claim }}</span
          >
          <p [ngClass]="getStatusClass(claim?.status)">
            {{ claim?.status! | claimStatusDisplay }}
          </p>
        </div>
        <p>Fecha de inicio: {{ claim?.created | date : "dd/MM/yyyy" }}</p>
      </div>
    </article>

    <article class="col-12">
      <div class="shadow-2 p-4 border-round-sm">
        <div class="grid mt-2">
          <div class="col-10">
            <span class="titleSection font-bold text-base mt-2 mb-2"
              >Detalle del reclamo</span
            >
          </div>

          <div class="col-6 md:col-2">
            <p-button
              icon="pi pi-download"
              [style]="{ background: '#263245' }"
              label="Descargar"
              (click)="downloadAsPdf()"
            ></p-button>
          </div>

          <div class="col-12">
            <p>Objeto del reclamo</p>
            <p class="bold">
              {{
                claim?.selecterObjectOfClaim
                  ? claim?.selecterObjectOfClaim
                  : "-"
              }}
            </p>
          </div>
          <div class="col-12">
            <p>Detalle del reclamo</p>
            <p class="bold">
              {{ claim?.textClaimDetails ? claim?.textClaimDetails : "-" }}
            </p>
          </div>
          <div class="col-12">
            <p>Departamento</p>
            <p class="bold">
              {{ claim?.department_name ? claim?.department_name : "-" }}
            </p>
          </div>
          <div class="col-12">
            <p>Documentación extra</p>
            <div class="gap-2 flex mt-2 extra-doc-container">
              <div
                class="pdf-item flex align-items-center justify-content-between"
                *ngFor="let pdf of pdfExtraDocumantationList"
              >
                <div class="pdf-info flex align-items-center">
                  <i class="pi pi-file-pdf"></i>
                  <span class="bold mt-0">{{ pdf }}</span>
                </div>
                <div class="pdf-actions">
                  <button (click)="viewImage(pdf)" title="Ver PDF">
                    <i class="pi pi-eye"></i>
                  </button>
                  <button (click)="downloadPdf(pdf)" title="Descargar PDF">
                    <i class="pi pi-download"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr class="mb-3" />
        <span class="titleSection font-bold text-base mt-2 mb-2"
          >Representación letrada</span
        >
        <div class="grid mt-2">
          <div class="col-6 md:col-3">
            <p>Apellido y Nombre</p>
            <p class="bold">
              {{ claim?.lawyerFullName ? claim?.lawyerFullName : "-" }}
            </p>
          </div>
          <div class="col-6 md:col-3">
            <p>Matrícula</p>
            <p class="bold">
              {{
                claim?.lawyerRegistrationNumber
                  ? claim?.lawyerRegistrationNumber
                  : "-"
              }}
            </p>
          </div>
          <div class="col-6 md:col-3">
            <p>Correo electrónico</p>
            <p class="bold">
              {{ claim?.lawyerEmail ? claim?.lawyerEmail : "-" }}
            </p>
          </div>
        </div>
      </div>
    </article>
    <article class="col-12">
      <div class="shadow-2 p-4 border-round-sm">
        <span class="titleSection font-bold text-base mt-2 mb-2"
          >Datos de reclamante</span
        >
        <div class="grid mt-2">
          <div class="col-6 md:col-3">
            <p>Apellido</p>
            <p class="bold">
              {{ claim?.claimetLastname ? claim?.claimetLastname : "-" }}
            </p>
          </div>
          <div class="col-6 md:col-3">
            <p>Nombre</p>
            <p class="bold">
              {{ claim?.claimetName ? claim?.claimetName : "-" }}
            </p>
          </div>
          <div class="col-12 md:col-3">
            <p>CUIL</p>
            <p class="bold">
              {{ claim?.claimetCuil ? claim?.claimetCuil : "-" }}
            </p>
          </div>
          <div class="col-12 md:col-3">
            <p>Correo electrónico</p>
            <p class="bold">
              {{ claim?.claimetEmail ? claim?.claimetEmail : "-" }}
            </p>
          </div>
          <div class="col-6 md:col-3">
            <p>Teléfono</p>
            <p class="bold">
              {{ claim?.claimetPhone ? claim?.claimetPhone : "-" }}
            </p>
          </div>

          <div class="col-6 md:col-3">
            <p>Calle</p>
            <p class="bold">
              {{ claim?.claimetStreet ? claim?.claimetStreet : "-" }}
            </p>
          </div>
          <div class="col-6 md:col-3">
            <p>Número</p>
            <p class="bold">
              {{ claim?.claimetNumber ? claim?.claimetNumber : "-" }}
            </p>
          </div>
          <div class="col-6 md:col-3">
            <p>Piso/Depto</p>
            <p class="bold">
              {{
                claim?.claimetFloorApartament
                  ? claim?.claimetFloorApartament
                  : "-"
              }}
            </p>
          </div>
          <div class="col-6 md:col-3">
            <p>Torre</p>
            <p class="bold">
              {{ claim?.claimetTower ? claim?.claimetTower : "-" }}
            </p>
          </div>
          <div class="col-6 md:col-3">
            <p>Código postal</p>
            <p class="bold">
              {{ claim?.claimetPostalCode ? claim?.claimetPostalCode : "-" }}
            </p>
          </div>
          <!-- <div class="col-6 md:col-3">
                          <p>Barrio</p>
                          <p class="bold">{{claim?.claimetNeighborhood}}</p>
                      </div> -->
          <div class="col-6 md:col-3">
            <p>Localidad</p>
            <p class="bold">
              {{ claim?.claimetCity ? claim?.claimetCity : "-" }}
            </p>
          </div>
          <div class="col-6 md:col-3">
            <p>Provincia</p>
            <p class="bold">
              {{ claimetProvince?.name ? claimetProvince?.name : "-" }}
            </p>
          </div>

          <div class="col-12">
            <p>DNI frente y dorso</p>
            <div class="gap-2 flex mt-2">
              <div
                class="pdf-item flex align-items-center justify-content-between"
                *ngFor="let pdf of pdfDNIList"
              >
                <div class="pdf-info flex align-items-center">
                  <i class="pi pi-file-pdf"></i>
                  <span class="bold mt-0">{{ pdf }}</span>
                </div>
                <div class="pdf-actions">
                  <button (click)="viewImage(pdf)" title="Ver PDF">
                    <i class="pi pi-eye"></i>
                  </button>
                  <button (click)="downloadPdf(pdf)" title="Descargar PDF">
                    <i class="pi pi-download"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr class="mt-3 mb-3" />
        <div class="grid">
          <div class="col-12 md:col-3">
            <p>Tipo de trabajo</p>
            <p class="bold">
              {{
                claim?.claimetSelectedTypeOfJob
                  ? claim?.claimetSelectedTypeOfJob
                  : "-"
              }}
            </p>
          </div>
          <div class="col-12 md:col-3">
            <p>Estado actual de la relación laboral</p>
            <p class="bold">
              {{
                claim?.claimetCurrentEmploymentStatus
                  ? claim?.claimetCurrentEmploymentStatus
                  : "-"
              }}
            </p>
          </div>
          <div class="col-12 md:col-3">
            <p>Motivo de desvinculación</p>
            <p class="bold">
              {{
                claim?.claimetReasonForDisengagement
                  ? claim?.claimetReasonForDisengagement
                  : "-"
              }}
            </p>
          </div>
          <div class="col-12 md:col-3">
            <p>Causa de desvinculación</p>
            <p class="bold">
              {{
                claim?.claimetCauseDisengagement
                  ? claim?.claimetCauseDisengagement
                  : "-"
              }}
            </p>
          </div>
          <div class="col-6 md:col-3">
            <p>Jornada laboral</p>
            <p class="bold">
              {{ claim?.claimetWorkday ? claim?.claimetWorkday : "-" }}
            </p>
          </div>
          <div class="col-6 md:col-3">
            <p>Remuneración bruta</p>
            <p class="bold">
              ${{
                claim?.claimetGrossRemuneration
                  ? claim?.claimetGrossRemuneration
                  : "-"
              }}
            </p>
          </div>
          <div class="col-12 md:col-3">
            <p>Tipo de Remuneración</p>
            <p class="bold">
              {{
                claim?.claimetTypeRemuneration
                  ? claim?.claimetTypeRemuneration
                  : "-"
              }}
            </p>
          </div>
          <div class="col-6 md:col-3">
            <p>Fecha de ingreso</p>
            <p class="bold">
              {{
                claim?.claimetDateAdmission
                  ? (claim?.claimetDateAdmission | date : "dd/MM/yyyy")
                  : "-"
              }}
            </p>
          </div>
          <div class="col-6 md:col-3">
            <p>Fecha de egreso</p>
            <p class="bold">
              {{
                claim?.claimetEgressDate
                  ? (claim?.claimetEgressDate | date : "dd/MM/yyyy")
                  : "-"
              }}
            </p>
          </div>
          <div class="col-12 md:col-3">
            <p>Convenio colectivo</p>
            <p class="bold">CCT - Camioneros Santa Fé</p>
          </div>
          <div class="col-12">
            <p>Recibo de sueldo</p>
            <div class="gap-2 flex mt-2 extra-doc-container">
              <div
                class="pdf-item flex align-items-center justify-content-between"
                *ngFor="let pdf of pdfPayCheckList"
              >
                <div class="pdf-info flex align-items-center">
                  <i class="pi pi-file-pdf"></i>
                  <span class="bold mt-0">{{ pdf }}</span>
                </div>
                <div class="pdf-actions">
                  <button (click)="viewImage(pdf)" title="Ver PDF">
                    <i class="pi pi-eye"></i>
                  </button>
                  <button (click)="downloadPdf(pdf)" title="Descargar PDF">
                    <i class="pi pi-download"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>

    <article class="col-12">
      <div class="shadow-2 p-4 border-round-sm">
        <span class="titleSection font-bold text-base mt-2 mb-2"
          >Datos del reclamado</span
        >
        <div class="grid mt-2">
          <div class="col-12 md:col-3">
            <p>Tipo de persona</p>
            <p class="bold">
              {{
                claim?.claimedTypeOfPerson ? claim?.claimedTypeOfPerson : "-"
              }}
            </p>
          </div>
          <div class="col-12 md:col-3">
            <p>Apellido y nombre o Razón social</p>
            <p class="bold">
              {{
                claim?.claimedNameLastNameOrBusinessName
                  ? claim?.claimedNameLastNameOrBusinessName
                  : "-"
              }}
            </p>
          </div>
          <div class="col-12 md:col-3">
            <p>CUIT/CUIL</p>
            <p class="bold">
              {{ claim?.claimedCuil ? claim?.claimedCuil : "-" }}
            </p>
          </div>
          <div class="col-12 md:col-3">
            <p>Correo electrónico</p>
            <p class="bold">
              {{ claim?.claimedEmail ? claim?.claimedEmail : "-" }}
            </p>
          </div>
          <div class="col-12 md:col-3">
            <p>Teléfono</p>
            <p class="bold">
              {{ claim?.claimedPhone ? claim?.claimedPhone : "-" }}
            </p>
          </div>
          <div class="col-6 md:col-3">
            <p>Calle</p>
            <p class="bold">
              {{ claim?.claimedStreet ? claim?.claimedStreet : "-" }}
            </p>
          </div>
          <div class="col-6 md:col-3">
            <p>Número</p>
            <p class="bold">
              {{ claim?.claimedNumber ? claim?.claimedNumber : "-" }}
            </p>
          </div>
          <div class="col-6 md:col-3">
            <p>Piso/Depto</p>
            <p class="bold">
              {{
                claim?.claimedFloorApartment
                  ? claim?.claimedFloorApartment
                  : "-"
              }}
            </p>
          </div>
          <div class="col-6 md:col-3">
            <p>Torre</p>
            <p class="bold">
              {{ claim?.claimedTower ? claim?.claimedTower : "-" }}
            </p>
          </div>
          <div class="col-6 md:col-3">
            <p>Código postal</p>
            <p class="bold">
              {{ claim?.claimedPostalCode ? claim?.claimedPostalCode : "-" }}
            </p>
          </div>
          <!-- <div class="col-6 md:col-3">
                          <p>Barrio</p>
                          <p class="bold">{{claim?.claimedNeighborhood}}</p>
                      </div> -->
          <div class="col-6 md:col-3">
            <p>Provincia</p>
            <p class="bold">
              {{ claimedProvince?.name ? claimedProvince?.name : "-" }}
            </p>
          </div>
          <div class="col-6 md:col-3">
            <p>Localidad</p>
            <p class="bold">
              {{ claim?.claimedCity ? claim?.claimedCity : "-" }}
            </p>
          </div>
        </div>
      </div>
    </article>

    <article class="col-12">
      <div class="shadow-2 p-4 border-round-sm">
        <span class="titleSection font-bold text-base mt-2 mb-4"
          >Historial de audiencias</span
        >
        <ng-container *ngIf="minutesForClaim?.length; else elseTemplate">
          <p class="mt-2 ml-4 font-bold">Fecha</p>
          <div class="gap-2 flex mt-2 flex flex-column">
            <div
              class="flex col-12 align-items-center justify-content-between"
              *ngFor="let pdf of minutesForClaim"
            >
              <div class="pdf-info flex align-items-center col-6">
                <p>{{ pdf.date | date : "dd-MM-yyyy" }}</p>
                <i class="pi pi-file-pdf ml-8"></i>
                <span class="bold mt-0">{{ pdf.fileActa }}</span>
              </div>
              <div class="pdf-actions">
                <button
                  (click)="viewMinutePdf(pdf.id, pdf.fileActa)"
                  title="Ver PDF"
                >
                  <i class="pi pi-eye"></i>
                </button>
                <button
                  (click)="downloadPdfMinute(pdf.id, pdf.fileActa)"
                  title="Descargar PDF"
                >
                  <i class="pi pi-download"></i>
                </button>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-template #elseTemplate>
          <p class="mt-2 ml-4 font-bold">No hay actas registradas</p>
        </ng-template>
      </div>
    </article>
  </section>

  <div
    *ngIf="
      !buttons ||
      claim?.status === 'Finalizado' ||
      claim?.status === 'Rechazado' ||
      claim?.status === 'Borrador'
    "
  >
    <div class="grid w-full flex justify-content-end mt-3">
      <div class="col-6 md:col-3 lg:col-3 xl:col-3">
        <p-button
          (click)="goBack()"
          label="Volver"
          class="btn-cancelar sm:text-xs"
        />
      </div>
    </div>
  </div>

  <app-action-buttons
    [buttons]="buttons"
    [claim]="claim"
    [rol]="userRol"
    [minuteId]="minuteId"
    (goBackEvent)="goBack()"
    (acceptRejectEvent)="recibeAceptDialog($event)"
    (newAudienceEvent)="newAudienceCheck()"
    (newMinuteEvent)="newMinuteCheck()"
  ></app-action-buttons>
</section>

<p-dialog
  header="Visualizador"
  [(visible)]="displayModal"
  [modal]="true"
  [style]="{ width: '80vw', height: '90vh' }"
  [draggable]="false"
  [resizable]="false"
>
  <object
    [data]="pdfUrl! | safeResourceUrl"
    type="application/pdf"
    width="100%"
    height="100%"
  >
    <p>No se puede mostrar el PDF.</p>
  </object>
</p-dialog>
<p-dialog
  header="Visualizador"
  [(visible)]="displayModalImage"
  [modal]="true"
  [style]="{ width: '80vw', height: '90vh' }"
  [draggable]="false"
  [resizable]="false"
>
  <object
    [data]="pdfUrl! | safeResourceUrl"
    type="image/jpeg"
    width="100%"
    height="100%"
  >
    <p>No se puede mostrar la imagen.</p>
  </object>
</p-dialog>
