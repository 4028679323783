import { UseCase } from 'src/app/core/utils/usecase';
import { DeleteClaimEntity } from '../entities/delete-claim.entity';
import { Failure } from 'src/app/core/utils/failure';
import { Injectable } from '@angular/core';
import { ClaimColectiveRepositoryBase } from '../repositories/cliam-colective-base.repository';

@Injectable()
export class DeleteCollectiveClaimUseCase
  implements UseCase<DeleteClaimEntity, string | Failure>
{
  constructor(private claimRepository: ClaimColectiveRepositoryBase) {}

  async execute(param: DeleteClaimEntity): Promise<string | Failure> {
    return await this.claimRepository.deleteClaim(param);
  }
}
