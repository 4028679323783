import { UpdateClaimConciliatorEntity } from "../../domain/entities/update-claim-conciliator.entity";

export class UpdateClaimColectiveConciliatorModel extends UpdateClaimConciliatorEntity {

    override toJson(): Map<string, any> {
        let json: Map<string, any> = new Map();
        json.set('ClaimId', this.claimId);
        json.set('ConciliatorId', this.conciliatorId);
        return json;
    }
}