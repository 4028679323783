import { Entity } from "./entity";

export abstract class Pagination<T> extends Entity { 
    constructor(
      public  response: T,
      public  numberOfPages: number,
      public  totalQuantity: number
    ) {
      super();
     }
  }