import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MessageService } from 'primeng/api';
import { Failure } from 'src/app/core/utils/failure';
import { ClaimCollectiveEntity } from 'src/app/features/claims/domain/entities/claim-colective.entity';
import { CompanyEntity } from 'src/app/features/company/domain/entities/company.entity';
import { GetCompanyCuilUseCase } from 'src/app/features/company/domain/usecases/get-company-colective.usecase';

interface ObjectClaim {
  id: number;
  name: string;
  isChecked: boolean;
  disabled: boolean;
}

@Component({
  selector: 'app-claim-details-colective',
  templateUrl: './claim-details-colective.component.html',
  styleUrls: ['./claim-details-colective.component.css'],
})
export class ClaimDetailsColectiveComponent {
  @Output() claimantDetailsForm = new EventEmitter<FormGroup>();
  @Output() stepIndex: EventEmitter<number> = new EventEmitter();
  @Output() activeModal: EventEmitter<boolean> = new EventEmitter();
  @Input() claim?: ClaimCollectiveEntity;

  formGroup!: FormGroup;

  activeMessages: Boolean = false;
  checked: boolean = false;
  isDisabledCesion: boolean = false;
  isDisabledTransfer: boolean = false;
  activeChecks: string[] = [];
  showLawyerInputs: boolean = false;
  public isEdit: boolean = false;

  companyForm: FormGroup;
  companyForm1: FormGroup;
  errorMessageCompanyForm: string = '';
  errorMessageCompanyForm1: string = '';

  objectsOftheClaim: ObjectClaim[] = [
    { id: 1, name: 'DESPIDOS', isChecked: false, disabled: false },
    {
      id: 2,
      name: 'REDUCCION DE JORNADA',
      isChecked: false,
      disabled: false,
    },
    {
      id: 3,
      name: 'SUSPENSIONES',
      isChecked: false,
      disabled: false,
    },
    {
      id: 4,
      name: 'SUSPENSIONES ART. 223 BIS',
      isChecked: false,
      disabled: false,
    },
    {
      id: 5,
      name: 'CESION DE PERSONAL',
      isChecked: false,
      disabled: false,
    },
    {
      id: 6,
      name: 'REESTRUCTURACION DE REMUNERACIONES',
      isChecked: false,
      disabled: false,
    },
    {
      id: 7,
      name: 'TRANSFERENCIA DEL ESTABLECIMIENTO',
      isChecked: false,
      disabled: false,
    },
    {
      id: 8,
      name: 'MEDIDAS DE ACCION DIRECTA DE LOS TRABAJADORES',
      isChecked: false,
      disabled: false,
    },
    { id: 9, name: 'OTRO', isChecked: false, disabled: false },
  ];

  ownRight: any[] = [
    { id: 1, name: 'POR DERECHO PROPIO' },
    { id: 1, name: 'CON PATROCINIO LETRADO' },
  ];

  constructor(
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private getCompanyUseCase: GetCompanyCuilUseCase
  ) {
    this.companyForm = new FormGroup({
      cuitCedente: new FormControl(''),
      razonSocialCedente: new FormControl({ value: '', disabled: true }),
    });
    this.companyForm1 = new FormGroup({
      cuitCedente: new FormControl(''),
      razonSocialCedente: new FormControl({ value: '', disabled: true }),
    });
  }

  async ngOnInit(): Promise<void> {
    this.initForms();
    this.checkedObject();

    this.formGroup.controls['selectedOwnRight'].valueChanges.subscribe(
      (selectedValue) => {
        this.showLawyerInputs = selectedValue === 'CON PATROCINIO LETRADO';
        this.setValidatorsBasedOnRole();
      }
    );
    if (this.claim) {
      await this.initializeFormWithClaimData();
      this.isEdit = true;
    }
    this.formGroup.controls['objectOfClaim'].valueChanges.subscribe(
      (selectedValue) => {
        this.activeMessages = selectedValue === 'CON PATROCINIO LETRADO';
        this.setValidatorsObjectOfClaim();
      }
    );
  }

  private initForms(): void {
    this.formGroup = this.formBuilder.group({
      objectOfClaim: [, Validators.required],
      selectedOwnRight: ['', Validators.required],
      workersCount: ['', Validators.required],
      fullNameLawyer: [''],
      registrationNumber: [''],
      lawyerEmail: [''],
      consent: [false, Validators.requiredTrue],
      cuit_assignor: ['', [Validators.required]],
      razon_social_assignor: ['', [Validators.required]],
      cuit_assignee: ['', [Validators.required]],
      razon_social_assignee: ['', [Validators.required]],
    });
  }

  async initializeFormWithClaimData(): Promise<void> {
    if (!this.formGroup) {
      return;
    }
    this.formGroup.patchValue({
      objectOfClaim: this.claim?.objectOfClaim || '',
      selectedOwnRight: this.claim?.selectedOwnRight || '',
      workersCount: this.claim?.workersCount || '',
      fullNameLawyer: this.claim?.fullNameLawyer || '',
      registrationNumber: this.claim?.registrationNumber || '',
      lawyerEmail: this.claim?.lawyerEmail || '',
      consent: this.claim?.consent ?? false,
      cuit_assignor: this.claim?.cuit_assignor || '',
      razon_social_assignor: this.claim?.razon_social_assignor || '',
      cuit_assignee: this.claim?.cuit_assignee || '',
      razon_social_assignee: this.claim?.razon_social_assignee || '',
    });
  }

  checkedObject() {
    if (this.claim?.objectOfClaim) {
      const selecterObjectOfClaim = this.claim.objectOfClaim;
      this.activeChecks = Array.isArray(selecterObjectOfClaim)
        ? selecterObjectOfClaim
        : [];
    }
  }

  setValidatorsBasedOnRole() {
    if (
      this.formGroup.get('selectedOwnRight')?.value === 'CON PATROCINIO LETRADO'
    ) {
      this.formGroup
        .get('fullNameLawyer')
        ?.setValidators([Validators.required, Validators.minLength(2)]);
      this.formGroup
        .get('registrationNumber')
        ?.setValidators([Validators.required, Validators.maxLength(10)]);
      this.formGroup
        .get('lawyerEmail')
        ?.setValidators([Validators.required, Validators.email]);
    } else {
      this.formGroup.get('fullNameLawyer')?.clearValidators();
      this.formGroup.get('registrationNumber')?.clearValidators();
      this.formGroup.get('lawyerEmail')?.clearValidators();
    }
    this.formGroup.get('fullNameLawyer')?.updateValueAndValidity();
    this.formGroup.get('registrationNumber')?.updateValueAndValidity();
    this.formGroup.get('lawyerEmail')?.updateValueAndValidity();
  }

  setValidatorsObjectOfClaim() {
    const objectOfClaim = this.formGroup.get('objectOfClaim')?.value;
    const triggeredObjects = ['CESION DE PERSONAL', 'TRANSFERENCIA DEL ESTABLECIMIENTO'];
  
    const hasTriggeredObject = Array.isArray(objectOfClaim) 
      ? objectOfClaim.some(item => triggeredObjects.includes(item))
      : false;
  
    if (hasTriggeredObject) {
      this.formGroup.get('cuit_assignor')?.setValidators([Validators.required]);
      this.formGroup.get('razon_social_assignor')?.setValidators([Validators.required]);
      this.formGroup.get('cuit_assignee')?.setValidators([Validators.required]);
      this.formGroup.get('razon_social_assignee')?.setValidators([Validators.required]);
    } else {
      this.formGroup.get('cuit_assignor')?.clearValidators();
      this.formGroup.get('razon_social_assignor')?.clearValidators();
      this.formGroup.get('cuit_assignee')?.clearValidators();
      this.formGroup.get('razon_social_assignee')?.clearValidators();
    }
  
    this.formGroup.get('cuit_assignor')?.updateValueAndValidity();
    this.formGroup.get('razon_social_assignor')?.updateValueAndValidity();
    this.formGroup.get('cuit_assignee')?.updateValueAndValidity();
    this.formGroup.get('razon_social_assignee')?.updateValueAndValidity();
  }

  async searchCompany(
    form: FormGroup,
    errorVar: 'errorMessageCompanyForm' | 'errorMessageCompanyForm1'
  ): Promise<void> {
    const cuit = form.get('cuitCedente')?.value;

    if (!cuit) {
      return;
    }

    let result = await this.getCompanyUseCase.execute(cuit);

    if (result instanceof Failure) {
      this[errorVar] = 'No se encontraron resultados.';
      return;
    }

    this[errorVar] = '';

    if (errorVar === 'errorMessageCompanyForm') {
      this.formGroup.patchValue({
        cuit_assignor: cuit,
        razon_social_assignor: result.razon_social,
      });
    } else {
      this.formGroup.patchValue({
        cuit_assignee: cuit,
        razon_social_assignee: result.razon_social,
      });
    }
  }

  onKeyDown(event: KeyboardEvent): void {
    if (event.key >= '0' && event.key <= '9') {
      event.preventDefault();
    }
  }

  onConsentChange(event: any): void {
    this.formGroup.controls['consent'].setValue(event.checked);
  }

  hasErrors(controlName: string, errorType: string) {
    return (
      this.formGroup.get(controlName)?.hasError(errorType) &&
      this.formGroup.get(controlName)?.touched
    );
  }

  nextStep() {
    this.checkSponsorship();
    this.claimantDetailsForm.emit(this.formGroup);
    this.stepIndex.emit(2);
  }
  checkSponsorship() {
    const objectOfClaim = this.formGroup.get('objectOfClaim')?.value;
    const triggeredObjects = ['CESION DE PERSONAL', 'TRANSFERENCIA DEL ESTABLECIMIENTO'];
  
    const hasTriggeredObject = Array.isArray(objectOfClaim) 
      ? !objectOfClaim.some(item => triggeredObjects.includes(item))
      : true;
  
    if (hasTriggeredObject) {
      this.formGroup.get('cuit_assignor')?.setValue('');
      this.formGroup.get('razon_social_assignor')?.setValue('');
      this.formGroup.get('cuit_assignee')?.setValue('');
      this.formGroup.get('razon_social_assignee')?.setValue('');
    }
  }

  onCheckboxChange() {
    if (this.checked) {
      this.objectsOftheClaim[5].disabled = true;
      this.objectsOftheClaim[7].disabled = true;
    } else {
      if (this.isDisabledTransfer) {
        this.objectsOftheClaim[5].disabled = false;
        this.objectsOftheClaim[7].disabled = true;
      }
      if (this.isDisabledCesion) {
        this.objectsOftheClaim[5].disabled = true;
        this.objectsOftheClaim[7].disabled = false;
      }
    }
  }

  disableIndemnificationCheck(object: ObjectClaim): void {
    if (object.name === 'CESION DE PERSONAL' && object.disabled == false) {
      this.objectsOftheClaim[6].disabled = !this.objectsOftheClaim[6].disabled;
      this.isDisabledCesion = true;
      this.isDisabledTransfer = false;
    }
    if (
      object.name === 'TRANSFERENCIA DEL ESTABLECIMIENTO' &&
      object.disabled == false
    ) {
      this.objectsOftheClaim[4].disabled = !this.objectsOftheClaim[4].disabled;
      this.isDisabledTransfer = true;
      this.isDisabledCesion = false;
    }

    const result = this.activeChecks.find(
      (x) =>
        x === 'TRANSFERENCIA DEL ESTABLECIMIENTO' || x === 'CESION DE PERSONAL'
    );

    if (result) {
      this.activeMessages = true;
    } else {
      this.activeMessages = false;
    }
  }

  back() {
    this.stepIndex.emit(1);
  }

  cancel() {
    this.claimantDetailsForm.emit(this.formGroup);
    this.activeModal.emit(true);
  }
}
