import { Failure, UnhandledFailure } from "src/app/core/utils/failure";
import { UserData } from "../../domain/entities/user-data.entity";
import { UserRepositoryBase } from "../../domain/repositories/user-base.repository";
import { UserRemoteDataSourceBase } from "../data-sources/user-remote.datasource";
import { Injectable } from "@angular/core";
import { UserPaginationParams } from "../../domain/entities/user-pagination-params.entity";
import { UserPagination } from "../../domain/entities/user-pagination.entity";
import { DeleteUserEntity } from "../../domain/entities/delete-user.entity";
import { ComisionUser } from "../../domain/entities/comision-user.entity";
@Injectable()
export class UserRepository extends UserRepositoryBase {

    constructor(private userRemoteDataSource: UserRemoteDataSourceBase) {
        super();
    }

    override async getMyData(): Promise<UserData | Failure> {
        try {
            return await this.userRemoteDataSource.getMyData();
        } catch (error: any) {
            return new UnhandledFailure(error, 500);
        }
    }

    override async getAllConciliators(): Promise<UserData[] | Failure> {
        try {
            return await this.userRemoteDataSource.getAllConciliators();
        } catch (error: any) {
            return new UnhandledFailure();
        }
    }

    override async getUser(id: string): Promise<UserData | Failure> {
      try {
        return await this.userRemoteDataSource.getUser(id);
      } catch (error: any) {
        return new UnhandledFailure(error.message, error.code);
      }
    }

    override async getPaginationUser(params: UserPaginationParams): Promise<UserPagination | Failure> {
      try {
        return await this.userRemoteDataSource.getPaginatedUser(params);
      } catch (error: any) {
        return new UnhandledFailure(error.message, error.code);
      };
    }

    override async getAllPaginationUser(params: UserPaginationParams): Promise<UserPagination | Failure> {
      try {
        return await this.userRemoteDataSource.getAllPaginationUser(params);
      } catch (error: any) {
        return new UnhandledFailure(error.message, error.code);
      };
    }

    override async updateUser(user: ComisionUser): Promise<string | Failure> {
      try {
        return await this.userRemoteDataSource.updateUser(user);
      } catch (error: any) {
        return new UnhandledFailure(error.message, error.code);
      }
    }

    override async deleteUser(deleteUser: DeleteUserEntity): Promise<string | Failure> {
      try {
        return await this.userRemoteDataSource.deleteUser(deleteUser);
      } catch (error: any) {
        return new UnhandledFailure(error.message, error.code);
      }
    }

    override async createUser(user: ComisionUser): Promise<string | Failure> {
      try {
        return await this.userRemoteDataSource.createUser(user);
      } catch (error: any) {        
        return new UnhandledFailure(error.error.message, error.code);
      }
    }

}
