import { ClaimPagination } from '../../domain/entities/claim-pagination.entity';
import { environment } from 'src/environments/environment.dev';
import { HttpServiceBase } from 'src/app/core/services/http.service';
import { Injectable } from '@angular/core';
import { ClaimPaginationModel } from '../models/claim-pagination.model';
import { ClaimsPaginationParams } from '../../domain/entities/claim-pagination-params.entity';
import { ClaimEntity } from '../../domain/entities/claim.entity';
import { Failure } from 'src/app/core/utils/failure';
import { ClaimModel } from '../models/claim.model';
import { LocalStorageServiceBase } from 'src/app/core/services/localStorage-serviceBase';
import { HttpHeaders } from '@angular/common/http';
import { DeleteClaimEntity } from '../../domain/entities/delete-claim.entity';
import { GetFileEntity } from '../../domain/entities/get-file.entity';
import { UpdateClaimStatusEntity } from '../../domain/entities/update-claim-satus.entity';
import { UpdateClaimConciliatorEntity } from '../../domain/entities/update-claim-conciliator.entity';
import { GetFileBaseEntity } from '../../domain/entities/get-file-base.entity';
import { ClaimCollectiveModel } from '../models/claim-colective.model';

export abstract class ClaimRemoteDataSourceBase {
  abstract getClaim(id: string): Promise<ClaimEntity | Failure>;
  abstract getPaginatedClaims(
    params: ClaimsPaginationParams
  ): Promise<ClaimPagination>;
  abstract getAllPaginationClaims(
    params: ClaimsPaginationParams
  ): Promise<ClaimPagination | Failure>;
  abstract updateClaim(claim: ClaimEntity): Promise<string | Failure>;
  abstract deleteClaim(
    deleteClaim: DeleteClaimEntity
  ): Promise<string | Failure>;
  abstract createClaim(claim: ClaimEntity): Promise<string | Failure>;
  abstract getFile(params: GetFileEntity): Promise<string | Failure>;
  abstract getFileBase(params: GetFileBaseEntity): Promise<string | Failure>;
  abstract getAllPendingClaims(): Promise<string | Failure>;
  abstract updateClaimStatus(
    claim: UpdateClaimStatusEntity
  ): Promise<string | Failure>;
  abstract updateClaimConciliator(
    claim: UpdateClaimConciliatorEntity
  ): Promise<string | Failure>;
  abstract getAllPaginationClaimsByConciliatorId(
    params: ClaimsPaginationParams
  ): Promise<ClaimPagination | Failure>;
}

@Injectable()
export class ClaimRemoteDataSource extends ClaimRemoteDataSourceBase {
  private url: string = `${environment.apiUrl}/claim`;

  constructor(
    private httpService: HttpServiceBase,
    private localStorageService: LocalStorageServiceBase
  ) {
    super();
  }

  override async getPaginatedClaims(
    params: ClaimsPaginationParams
  ): Promise<ClaimPagination> {
    let headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${this.localStorageService.read('access_token')}`
    );
    let result = await this.httpService.get(
      `${this.url}/user/${params.getPath()}`,
      headers
    );
    let response = result.get('response');
    let map = new Map<string, any>(Object.entries(response));
    return ClaimPaginationModel.fromJson(map);
  }

  override async getAllPaginationClaims(
    params: ClaimsPaginationParams
  ): Promise<ClaimPagination> {
    let headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${this.localStorageService.read('access_token')}`
    );
    let result = await this.httpService.get(
      `${this.url}/get_all_claims/${params.getPath()}`,
      headers
    );
    let response = result.get('response');
    let map = new Map<string, any>(Object.entries(response));
    return ClaimPaginationModel.fromJson(map);
  }

  override async getClaim(id: string): Promise<ClaimEntity> {
    let headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${this.localStorageService.read('access_token')}`
    );
    let result = await this.httpService.get(`${this.url}/${id}`, headers);
    let response = result.get('response');
    let entries = Object.entries(response);
    let map = new Map<string, any>(entries);
    return ClaimModel.fromJson(map);
  }

  override async updateClaim(claim: ClaimEntity): Promise<string | Failure> {
    let headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${this.localStorageService.read('access_token')}`
    );
    let result = await this.httpService.put(
      `${this.url}/update?id=${claim.id}`,
      claim,
      headers
    );
    return result.get('response');
  }

  override async deleteClaim(
    deleteClaim: DeleteClaimEntity
  ): Promise<string | Failure> {
    let headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${this.localStorageService.read('access_token')}`
    );
    let result = await this.httpService.delete(
      `${this.url}/delete`,
      deleteClaim,
      headers
    );
    return result.get('response');
  }

  override async createClaim(claim: ClaimEntity): Promise<string | Failure> {
    let headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${this.localStorageService.read('access_token')}`
    );
    let result = await this.httpService.post(`${this.url}`, claim, headers);
    return result.get('response');
  }

  override async getFile(params: GetFileEntity): Promise<string | Failure> {
    let headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${this.localStorageService.read('access_token')}`
    );
    let result = await this.httpService.get(
      `${this.url}/get_file_as_base64/${params.getPath()}`,
      headers
    );
    return result.get('response');
  }

  override async getFileBase(
    params: GetFileBaseEntity
  ): Promise<string | Failure> {
    let headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${this.localStorageService.read('access_token')}`
    );
    let urlWithReplacement = this.url.replace('/claim', '/Acta');
    let result = await this.httpService.get(
      `${urlWithReplacement}/get_file_as_base64_acta_minute${params.getPath()}`,
      headers
    );
    return result.get('response');
  }

  override async getAllPendingClaims(): Promise<string | Failure> {
    let headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${this.localStorageService.read('access_token')}`
    );
    let result = await this.httpService.get(
      `${this.url}/get_all_pending_claims`,
      headers
    );
    return result.get('response');
  }

  override async updateClaimStatus(
    claim: UpdateClaimStatusEntity
  ): Promise<string | Failure> {
    let headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${this.localStorageService.read('access_token')}`
    );
    let result = await this.httpService.put(
      `${this.url}/update_status?id=${claim.claimId}`,
      claim,
      headers
    );
    return result.get('response');
  }

  override async updateClaimConciliator(
    claim: UpdateClaimConciliatorEntity
  ): Promise<string | Failure> {
    let headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${this.localStorageService.read('access_token')}`
    );
    let result = await this.httpService.put(
      `${this.url}/change_conciliator?claim_id=${claim.claimId}`,
      claim,
      headers
    );
    return result.get('response');
  }

  override async getAllPaginationClaimsByConciliatorId(
    params: ClaimsPaginationParams
  ): Promise<ClaimPagination | Failure> {
    let headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${this.localStorageService.read('access_token')}`
    );
    let result = await this.httpService.get(
      `${this.url}/get_all_claims_of_conciliator/${params.getPath()}`,
      headers
    );
    let response = result.get('response');
    let map = new Map<string, any>(Object.entries(response));
    return ClaimPaginationModel.fromJson(map);
  }
}
