import { Entity } from 'src/app/core/utils/entity';
import { ClaimFilterModel } from '../../data/models/claim-filter.model';

export abstract class ClaimsPaginationParams extends Entity {
  constructor(
    public page: number,
    public quantity: number,
    public userId?: string,
    public filters?: ClaimFilterModel,
    public idEmployerAndOthersColective?: string,
    public idAdminAndOthersColective?: string
  ) {
    super();
  }

  abstract getPath(): string;
}
