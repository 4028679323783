import { Directive, HostListener, ElementRef } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appMeetLinkExtractor]',
})
export class MeetLinkExtractorDirective {
  private meetUrlPattern =
    /https:\/\/meet\.google\.com\/([a-z]{3}-[a-z]{4}-[a-z]{3})/;

  constructor(private el: ElementRef, private control: NgControl) {}

  @HostListener('input', ['$event'])
  onInput(event: Event): void {
    const inputValue = this.el.nativeElement.value;
    const match = inputValue.match(this.meetUrlPattern);

    if (match) {
      this.control.control?.setValue(match[0], { emitEvent: false });
    } else {
      this.control.control?.setErrors({ invalidMeetLink: true });
    }
  }
}
