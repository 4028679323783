<form
  (ngSubmit)="nextStep()"
  class="w-full h-full relative mt-6"
  [formGroup]="formClaimedDetails"
>
  <section class="personalInformation">
    <div class="grid w-full mb-2 mt-3">
      <div class="col-12 md:col-4 lg:col-3 xl:col-3 sm:col-6">
        <label class="labelTitle"
          >Apellido y nombre<span class="span">*</span></label
        >
        <input
          placeholder="Apellido y nombre"
          class="disabled"
          pInputText
          maxlength="50"
          formControlName="claimedNameLastNameOrBusinessName"
          [ngClass]="{
            'ng-invalid ng-dirty':
              hasErrors('claimedNameLastNameOrBusinessName', 'required') ||
              hasErrors('claimedNameLastNameOrBusinessName', 'maxlength') ||
              hasErrors('claimedNameLastNameOrBusinessName', 'pattern')
          }"
        />
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('claimedNameLastNameOrBusinessName', 'required')"
        >
          Este campo es obligatorio
        </div>
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('claimedNameLastNameOrBusinessName', 'maxlength')"
        >
          Este campo no puede superar los 50 caracteres.
        </div>
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('claimedNameLastNameOrBusinessName', 'pattern')"
        >
          El campo solo acepta letras.
        </div>
      </div>

      <div class="col-12 md:col-4 lg:col-3 xl:col-3 sm:col-6">
        <label class="labelTitle">CUIL<span class="span">*</span></label>
        <p-inputMask
          class="inputLarge"
          mask="99-99999999-9"
          placeholder="99-99999999-9"
          formControlName="claimedCuil"
          [ngClass]="{
            'ng-invalid ng-dirty': hasErrors('claimedCuil', 'required')
          }"
        ></p-inputMask>
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('claimedCuil', 'required')"
        >
          Este campo es obligatorio
        </div>
      </div>
      <div class="col-12 md:col-4 lg:col-3 xl:col-3 sm:col-6">
        <label class="labelTitle"
          >Correo electronico<span class="span">*</span></label
        >
        <input
          placeholder="Correo electronico"
          class="disabled"
          type="text"
          pInputText
          type="email"
          maxlength="50"
          formControlName="claimedEmail"
          [ngClass]="{
            'ng-invalid ng-dirty':
              hasErrors('claimedEmail', 'required') ||
              hasErrors('claimedEmail', 'maxlength') ||
              hasErrors('claimedEmail', 'email')
          }"
        />
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('claimedEmail', 'required')"
        >
          Este campo es obligatorio
        </div>
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('claimedEmail', 'email')"
        >
          Formato de email incorrecto
        </div>
      </div>

      <div class="col-12 md:col-4 lg:col-3 xl:col-3 sm:col-6">
        <label class="labelTitle">Teléfono<span class="span">*</span></label>
        <input
          placeholder="Teléfono"
          pInputText
          class="disabled"
          formControlName="claimedPhone"
          maxlength="15"
          [ngClass]="{
            'ng-invalid ng-dirty':
              hasErrors('claimedPhone', 'minlength') ||
              hasErrors('claimedPhone', 'maxlength') ||
              hasErrors('claimedPhone', 'pattern')
          }"
        />
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('claimedPhone', 'minlength')"
        >
          Este campo no puede poseer menos de 7 caracteres.
        </div>
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('claimedPhone', 'maxlength')"
        >
          Este campo no puede poseer más de 15 caracteres.
        </div>
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('claimedPhone', 'pattern')"
        >
          El campo solo acepta números. Las letras o caracteres especiales no
          son permitidos.
        </div>
      </div>
      <div class="col-12 md:col-4 lg:col-3 xl:col-3 sm:col-6">
        <label class="labelTitle">Calle<span class="span">*</span></label>
        <input
          placeholder="Calle"
          class="disabled"
          type="text"
          maxlength="50"
          formControlName="claimedStreet"
          pInputText
          [ngClass]="{
            'ng-invalid ng-dirty':
              hasErrors('claimedStreet', 'required') ||
              hasErrors('claimedStreet', 'maxlength')
          }"
        />
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('claimedStreet', 'required')"
        >
          Este campo es obligatorio
        </div>
      </div>
      <div class="col-12 md:col-4 lg:col-3 xl:col-3 sm:col-6">
        <label class="labelTitle">Número<span class="span">*</span></label>
        <p-inputNumber
          placeholder="Número"
          mode="decimal"
          inputId="withoutgrouping"
          [useGrouping]="false"
          formControlName="claimedNumber"
          maxlength="5"
          [ngClass]="{
            'ng-invalid ng-dirty':
              hasErrors('claimedNumber', 'required') ||
              hasErrors('claimedNumber', 'maxlength')
          }"
        >
        </p-inputNumber>
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('claimedNumber', 'required')"
        >
          Este campo es obligatorio
        </div>
      </div>
      <div class="col-12 md:col-4 lg:col-3 xl:col-3 sm:col-6">
        <label class="labelTitle">Piso/Depto</label>
        <input
          placeholder="Piso/Depto"
          class="disabled"
          class="disabled"
          type="text"
          pInputText
          formControlName="claimedFloorApartment"
          maxlength="10"
        />
      </div>

      <div class="col-12 md:col-4 lg:col-3 xl:col-3 sm:col-6">
        <label class="labelTitle">Torre</label>
        <input
          placeholder="Torre"
          class="disabled"
          type="text"
          pInputText
          formControlName="claimedTower"
          maxlength="30"
        />
      </div>

      <div class="col-12 md:col-4 lg:col-3 xl:col-3 sm:col-6">
        <label class="labelTitle"
          >Código postal<span class="span">*</span></label
        >
        <input
          placeholder="Código postal"
          class="disabled"
          type="text"
          pInputText
          formControlName="claimedPostalCode"
          maxlength="10"
          [ngClass]="{
            'ng-invalid ng-dirty':
              hasErrors('claimedPostalCode', 'required') ||
              hasErrors('claimedPostalCode', 'maxlength') ||
              hasErrors('claimedPostalCode', 'minlength') ||
              hasErrors('claimetPostalCode', 'pattern')
          }"
        />
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('claimedPostalCode', 'required')"
        >
          Este campo es obligatorio
        </div>
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('claimedPostalCode', 'minlength')"
        >
          Máximo 10 caracteres permitidos
        </div>
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('claimedPostalCode', 'minlength')"
        >
          Mínimo 4 caracteres requeridos
        </div>
        <div
          *ngIf="hasErrors('claimedPostalCode', 'pattern')"
          class="text-danger p-error"
        >
          Solo se permiten letras y números
        </div>
      </div>
      <div class="col-12 md:col-4 lg:col-3 xl:col-3 sm:col-6">
        <label class="labelTitle" for="claimetProvince" optionLabel="name"
          >Provincia<span class="span">*</span></label
        >
        <p-dropdown
          id="claimedProvince"
          [options]="provinces"
          optionLabel="name"
          optionValue="id"
          placeholder="Seleccione su Provincia"
          formControlName="claimedProvince"
          [ngClass]="{
            'ng-invalid ng-dirty': hasErrors('claimedProvince', 'required')
          }"
        >
        </p-dropdown>
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('claimedProvince', 'required')"
        >
          Este campo es obligatorio
        </div>
      </div>
      <div class="col-12 md:col-4 lg:col-3 xl:col-3 sm:col-6">
        <label class="labelTitle">Localidad<span class="span">*</span></label>
        <p-dropdown
          [options]="localities"
          optionLabel="name"
          optionValue="name"
          [showClear]="false"
          placeholder="Seleccione su localidad"
          formControlName="claimedCity"
          [ngClass]="{
            'ng-invalid ng-dirty': hasErrors('claimedCity', 'required')
          }"
        >
        </p-dropdown>
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('claimedCity', 'required')"
        >
          Este campo es obligatorio
        </div>
      </div>
    </div>
  </section>
  <p-divider></p-divider>

  <section class="employmentRelationshipDetails mt-3">
    <span class="titleSection">Datos de la relación laboral</span>

    <div class="grid w-full mt-3">
      <div class="col-12 md:col- lg:col-3 xl:col-3">
        <label class="text-sm font-bold mr-3"
          >Tipo de trabajo<span class="span">*</span></label
        >
      </div>
      <div
        class="col-12 md:col-6 lg:col-6 xl:col-6 flex flex-column xl:flex-row md:flex-row lg:flex-row gap-1"
      >
        <div *ngFor="let job of typeOfJob" class="flex align-items-center">
          <p-radioButton
            [inputId]="job.key"
            [value]="job.name"
            formControlName="claimetSelectedTypeOfJob"
          ></p-radioButton>
          <label [for]="job.key" class="text-sm ml-2 gap-1 mr-3">{{
            job.name
          }}</label>
        </div>
        <div
          class="text-danger p-error mb-1"
          *ngIf="hasErrors('claimetSelectedTypeOfJob', 'required')"
        >
          Este campo es obligatorio
        </div>
      </div>
    </div>

    <div class="grid w-full mt-3">
      <div class="col-12 md:col-3 lg:col-3 xl:col-3">
        <label class="text-sm font-bold mr-3"
          >Estado actual de la relación laboral<span class="span"
            >*</span
          ></label
        >
      </div>

      <div
        class="col-12 md:col-4 lg:col-6 xl:col-6 flex flex-column xl:flex-row md:flex-row lg:flex-row gap-1"
      >
        <div
          *ngFor="let a of currentEmploymentsStatus"
          class="flex align-items-center"
        >
          <p-radioButton
            [inputId]="a.id"
            [value]="a.name"
            formControlName="claimetCurrentEmploymentStatus"
          ></p-radioButton>
          <label [for]="a.id" class="text-sm ml-2 gap-1 mr-3">{{
            a.name
          }}</label>
        </div>
        <div
          class="text-danger p-error mb-1"
          *ngIf="hasErrors('claimetCurrentEmploymentStatus', 'required')"
        >
          Este campo es obligatorio
        </div>
      </div>

      <div
        class="col-12 flex flex-column xl:flex-row md:flex-row lg:flex-row gap-1 mt-2"
        *ngIf="showConditionalReason"
      >
        <div
          *ngFor="let a of reasonsForDisengagement"
          class="flex align-items-center"
        >
          <p-radioButton
            [inputId]="a.id"
            [value]="a.name"
            formControlName="claimetReasonForDisengagement"
          ></p-radioButton>
          <label [for]="a.id" class="text-sm ml-2 gap-1 mr-3">{{
            a.name
          }}</label>
        </div>
        <div
          class="text-danger p-error mb-1"
          *ngIf="hasErrors('claimetReasonForDisengagement', 'required')"
        >
          Este campo es obligatorio
        </div>
      </div>

      <div
        class="col-12 flex flex-column xl:flex-row md:flex-row lg:flex-row gap-1 mt-2"
        *ngIf="this.showConditionalCause"
      >
        <div
          *ngFor="let a of causesDisengagement"
          class="flex align-items-center"
        >
          <p-radioButton
            [inputId]="a.id"
            [value]="a.name"
            formControlName="claimetCauseDisengagement"
          ></p-radioButton>
          <label [for]="a.id" class="text-sm ml-2 gap-1 mr-3">{{
            a.name
          }}</label>
        </div>
        <div
          class="text-danger p-error mb-1"
          *ngIf="hasErrors('claimetCauseDisengagement', 'required')"
        >
          Este campo es obligatorio
        </div>
      </div>
    </div>

    <div class="grid w-full mt-6">
      <div class="col-12 md:col-2">
        <label class="labelTitle">
          Jornada laboral<span class="span">*</span>
        </label>
        <input
          type="text"
          maxlength="80"
          pInputText
          formControlName="claimetWorkday"
          placeholder="Jornada laboral"
          [ngClass]="{
            'ng-invalid ng-dirty': hasErrors('claimetWorkday', 'required')
          }"
        />
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('claimetWorkday', 'required')"
        >
          Este campo es obligatorio
        </div>
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('claimetWorkday', 'pattern')"
        >
          El campo solo acepta números y letras. Los caracteres especiales no
          son permitidos.
        </div>
      </div>

      <div class="col-12 md:col-2">
        <label class="labelTitle"
          >Remuneración bruta<span class="span">*</span></label
        >
        <p-inputNumber
          mode="decimal"
          placeholder="Remuneración bruta"
          inputId="withoutgrouping"
          [useGrouping]="false"
          class="w-full"
          maxlength="10"
          formControlName="claimetGrossRemuneration"
          maxlength="10"
          [ngClass]="{
            'ng-invalid ng-dirty':
              hasErrors('claimetGrossRemuneration', 'required') ||
              hasErrors('claimetGrossRemuneration', 'maxlength')
          }"
        >
        </p-inputNumber>
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('claimetGrossRemuneration', 'minlength')"
        >
          El campo solo acepta un máximo de 10 dígitos.
        </div>

        <div
          class="text-danger p-error"
          *ngIf="hasErrors('claimetGrossRemuneration', 'required')"
        >
          Este campo es obligatorio
        </div>
      </div>

      <div class="col-12 md:col-2">
        <label class="labelTitle"
          >Tipo de remuneración<span class="span">*</span></label
        >
        <p-dropdown
          [options]="typeRemunerations"
          optionLabel="type"
          optionValue="type"
          [showClear]="false"
          placeholder="Elija una opción"
          formControlName="claimetTypeRemuneration"
          [ngClass]="{
            'ng-invalid ng-dirty': hasErrors(
              'claimetTypeRemuneration',
              'required'
            )
          }"
        ></p-dropdown>
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('claimetTypeRemuneration', 'required')"
        >
          Este campo es obligatorio
        </div>
      </div>

      <div class="col-12 md:col-2">
        <label class="labelTitle"
          >Fecha de ingreso<span class="span">*</span></label
        >
        <p-calendar
          formControlName="claimetDateAdmission"
          [iconDisplay]="'input'"
          [showIcon]="true"
          [maxDate]="maxDateIngreso"
          [defaultDate]="maxDateIngreso!"
          inputId="icondisplay"
          placeholder="DD/MM/AAAA "
          slotChar="dd/mm/yyyy"
          dateFormat="dd/mm/yy"
          [ngClass]="{
            'ng-invalid ng-dirty': hasErrors('claimetDateAdmission', 'required')
          }"
        ></p-calendar>
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('claimetDateAdmission', 'required')"
        >
          Este campo es obligatorio
        </div>
        <div
          class="p-error-date"
          *ngIf="formClaimedDetails.errors?.['dateErr']"
        >
          La fecha ingresada es invalida respecto a la fecha de egreso.
        </div>
      </div>

      <div class="col-12 md:col-2" *ngIf="showConditionalReason">
        <label class="labelTitle"
          >Fecha de egreso<span class="span">*</span></label
        >
        <p-calendar
          formControlName="claimetEgressDate"
          [iconDisplay]="'input'"
          [showIcon]="true"
          [maxDate]="maxDate"
          [minDate]="formClaimedDetails.get('claimetDateAdmission')?.value"
          inputId="icondisplay"
          placeholder="DD/MM/AAAA "
          slotChar="dd/mm/yyyy"
          dateFormat="dd/mm/yy"
          [disabled]="
            formClaimedDetails.controls['claimetCurrentEmploymentStatus']
              .value === 'Vigente'
          "
          [ngClass]="{
            'ng-invalid ng-dirty': hasErrors('claimetEgressDate', 'required') ||  formClaimedDetails.errors?.['dateErr']
          }"
        ></p-calendar>
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('claimetEgressDate', 'required')"
        >
          Este campo es obligatorio
        </div>
      </div>
    </div>

    <div class="grid w-full mt-6">
      <div class="col">
        <label class="labelTitle mt-3 text-sm font-bold"
          >Convenio colectivo:</label
        >
      </div>
    </div>
    <div class="grid w-full">
      <p class="font-bold pl-2">CCT - Camioneros Santa Fé</p>
    </div>
  </section>

  <div class="grid w-full flex justify-content-end mt-3">
    <div class="col-6 md:col-2 lg:col-2 xl:col-2">
      <p-button
        (click)="cancel()"
        label="Cancelar"
        class="btn-cancelar sm:text-xs"
      />
    </div>

    <div class="col-6 md:col-2 lg:col-2 xl:col-2">
      <p-button
        (click)="back()"
        label="Atrás"
        class="btn-cancelar sm:text-xs"
      />
    </div>

    <div class="col-6 md:col-2 lg:col-2 xl:col-2">
      <p-button
        label="Siguiente"
        class="btn-siguiente"
        type="submit"
        [disabled]="formClaimedDetails.invalid"
      ></p-button>
    </div>
  </div>
</form>
