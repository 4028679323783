import { Entity } from '../../../../core/utils/entity';

export abstract class Session extends Entity {
  constructor(
    public access_token?: string,
    public expires_in?: number,
    public refresh_token?: string,
    public refresh_expires_in?: number,
    public token_type?: string,
    public not_before_policy?: number,
    public scope?: string
  ) {
    super();
  }
}
