import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Toast } from 'primeng/toast';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.css'],
  providers: [MessageService, Toast, ConfirmationService]
})
export class CompaniesComponent implements OnInit {

  isNewCompany: boolean = true;
  activeIndex: number = 0;

  constructor(
    private messageService: MessageService,
    private router: Router
  ) {}
  
  ngOnInit(): void {
    if (this.router.url.includes('main/company/update')) {
    this.isNewCompany = false;
    }    
  }

  handleShowMessage(event: { severity: string, summary: string, detail: string }) {
    this.messageService.add({
      severity: event.severity,
      summary: event.summary,
      detail: event.detail,
    });
  }

}
