import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageServiceBase } from 'src/app/core/services/localStorage-serviceBase';
import AESEncryptDecryptService from 'src/app/core/utils/crypto';

@Component({
  selector: 'app-claim-types',
  templateUrl: './claim-types.component.html',
  styleUrls: ['./claim-types.component.css'],
})
export class ClaimTypesComponent {
  visible: boolean = false;
  dialogStyle: any = { width: '40rem' };

  userRole? : string
  private AES = new AESEncryptDecryptService();

  constructor(
    private router: Router,
    private localStorageService: LocalStorageServiceBase
  ) {}

  ngOnInit() {
    this.updateDialogSize();
    window.addEventListener('resize', () => this.updateDialogSize());
    this.userRole = this.AES.decrypt(
      this.localStorageService.loadStorageData('user_rol')
    );
  }

  updateDialogSize() {
    if (window.innerWidth < 576) {
      this.dialogStyle = { width: '100vw', maxWidth: '100vw', margin: '0' };
    } else {
      this.dialogStyle = { width: '40rem' };
    }
  }

  showDialog() {
    if (this.userRole === 'Trabajador') {
      this.router.navigate(['/main/newLaboralClaim']);
    } else {
      this.visible = true;
    }
  }

  selectClaimType(type: string) {
    switch (type) {
      case 'individual':
        this.router.navigate(['/main/newClaimEmployer']);
        break;
        case 'plurindividual':
          this.router.navigate(['/main/newClaimMulti']);
        break;
      default:
        this.router.navigate(['/main/newColectiveClaim']);
        break;
    }
  }
}
