import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { UserService } from 'src/app/features/user/services/user.service';
import { Failure } from 'src/app/core/utils/failure';
import { UserData } from 'src/app/features/user/domain/entities/user-data.entity';
import { TableLazyLoadEvent } from 'primeng/table';
import { ClaimStatus } from 'src/app/core/enums/claim-status.enum';
import { UserRol } from 'src/app/core/enums/user-rol.enum';
import { Router } from '@angular/router';
import { Toast } from 'primeng/toast';
import AESEncryptDecryptService from 'src/app/core/utils/crypto';
import { LocalStorageServiceBase } from 'src/app/core/services/localStorage-serviceBase';
import { Pagination } from 'src/app/core/interfaces/pagination.interface';
import { ClaimFilterModel } from 'src/app/features/claims/data/models/claim-filter.model';
import { ClaimPagination } from 'src/app/features/claims/domain/entities/claim-pagination.entity';
import { ClaimData } from 'src/app/features/claims/domain/entities/claim-data.entity';
import { ClaimEntity } from 'src/app/features/claims/domain/entities/claim.entity';
import { GetPaginatedClaimsUseCase } from 'src/app/features/claims/domain/usecases/get-paginated-claims.usecase';
import { ClaimServiceService } from 'src/app/features/claims/services/claim-service.service';
import { DeleteClaimUseCase } from 'src/app/features/claims/domain/usecases/delete-claim.usecase';
import { ClaimUseCase } from 'src/app/features/claims/domain/usecases/get-claim.usecase';
import { ClaimsPaginationParamsModel } from 'src/app/features/claims/data/models/claim-pagination-params.model';
import { DeleteClaimModel } from 'src/app/features/claims/data/models/delete-claim.model';

@Component({
  selector: 'app-claims-table',
  templateUrl: './claims-table.component.html',
  styleUrls: ['./claims-table.component.css'],
  providers: [MessageService, Toast, ConfirmationService],
})
export class ClaimsTableComponent implements OnInit {
  @Input() claims!: ClaimPagination | undefined;
  @Input() userData?: UserData;
  @Output() activeClaims: EventEmitter<boolean> = new EventEmitter();

  public skeleton: boolean = true;
  public skeletons: any = [{}, {}, {}, {}, {}, {}, {}, {}, {}];
  public pagination: Pagination = {
    totalQuantity: 0,
    numberOfPages: 0,
    itemsPerPage: 5,
    actualPage: 1,
  };
  filters?: ClaimFilterModel;

  userClaims?: ClaimData[];
  selectedClaimId?: string;
  isLoading: boolean = true;
  claim?: ClaimEntity;
  private AES = new AESEncryptDecryptService();
  homeView: boolean = false;
  userId?: string;
  rol?: string;

  public items: MenuItem[] = [];
  public comisionThreeOptionsItems: MenuItem[] = [
    {
      label: 'Editar',
      icon: 'pi pi-pencil',
      command: () => this.editClaim(),
    },
    {
      label: 'Fijar nueva audiencia',
      icon: 'pi pi-eye',
      command: () => this.setNewAudience(),
    },
  ];

  public threeOptionsItems: MenuItem[] = [
    {
      label: 'Ver reclamo',
      icon: 'pi pi-eye',
      command: () => this.viewClaim(),
    },
    {
      label: 'Editar reclamo',
      icon: 'pi pi-pencil',
      command: () => this.editClaim(),
    },
    {
      label: 'Eliminar reclamo',
      icon: 'pi pi-trash',
      command: () => this.showOptionPopup(),
    },
  ];

  public twoOptionsItems: MenuItem[] = [
    {
      label: 'Ver reclamo',
      icon: 'pi pi-eye',
      command: () => this.viewClaim(),
    },
    {
      label: 'Eliminar reclamo',
      icon: 'pi pi-trash',
      command: () => this.showOptionPopup(),
    },
  ];

  public singleItem: MenuItem[] = [
    {
      label: 'Ver reclamo',
      icon: 'pi pi-eye',
      command: () => this.viewClaim(),
    },
  ];

  constructor(
    private getPaginatedClaimsUseCase: GetPaginatedClaimsUseCase,
    private userService: UserService,
    private claimService: ClaimServiceService,
    private router: Router,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private deleteClaimUseCase: DeleteClaimUseCase,
    private claimUseCase: ClaimUseCase,
    private localStorageService: LocalStorageServiceBase
  ) {}

  ngOnInit(): void {
    this.userId = this.AES.decrypt(
      this.localStorageService.loadStorageData('user_id')
    );
    this.initClaims();
    if (this.router.url.includes('main/home')) {
      this.homeView = true;
    }
    if (this.userClaims) {
      this.activeClaims.emit(true);
    } else {
      this.activeClaims.emit(false);
    }
    this.checkRol();
  }

  checkRol() {
    this.rol = this.AES.decrypt(
      this.localStorageService.loadStorageData('user_rol')
    );
  }

  initUserData(): void {
    this.userData = this.userService.userData;
  }

  initClaims(): void {
    if (!this.userId) return;
    this.getDataPaginated();
  }

  async getDataPaginated(): Promise<void> {
    let params = new ClaimsPaginationParamsModel(
      this.pagination.actualPage,
      this.pagination.itemsPerPage,
      this.userId,
      this.filters
    );
    let result = await this.getPaginatedClaimsUseCase.execute(params);
    if (result instanceof Failure) {
      this.skeleton = false;
      return;
    }
    this.userClaims = result.response;
    this.pagination.totalQuantity = result.totalQuantity;
    if (this.userClaims) {
      setTimeout(() => {
        this.skeleton = false;
      }, 500);
    }
  }

  async deleteClaim(): Promise<void> {
    if (
      !(this.claim?.status === 'Pendiente' || this.claim?.status === 'Borrador')
    ) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Su reclamo esta en curso, no es posible eliminar',
      });
      return;
    }
    this.isLoading = true;
    let claimDeleteEntity = new DeleteClaimModel(this.selectedClaimId!);
    let result = await this.deleteClaimUseCase.execute(claimDeleteEntity);
    this.isLoading = false;
    if (result instanceof Failure) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: result.message,
      });
      return;
    }
    this.messageService.add({
      severity: 'success',
      summary: 'Reclamo eliminado',
      detail: 'Su reclamo se elimino exitosamente.',
    });
    setTimeout(() => {
      this.router.navigate(['/main/claims']);
    }, 1100);
  }

  async getClaim(): Promise<void> {
    let result = await this.claimUseCase.execute(this.selectedClaimId!);
    if (result instanceof Failure) {
      return;
    }
    this.claim = result;
    this.checkRol();
  }

  changePage(event: any) {
    this.pagination.actualPage = event.page + 1;
    this.pagination.itemsPerPage = event.rows;
    this.skeleton = true;
    this.getDataPaginated();
  }

  getStatusClass(status: string): string {
    switch (status) {
      case ClaimStatus.DRAFT:
        return 'status-draft';
      case ClaimStatus.PENDING:
        return 'status-pending';
      case ClaimStatus.REFUSED:
        return 'status-refused';
      case ClaimStatus.FINALIZED:
        return 'status-finalized';
      case ClaimStatus.IN_INTERNAL_PROCESS:
        return 'status-in-internal-process';
      case ClaimStatus.AUDIENCE_SET:
      case 'Audiencia Fijada':
        return 'status-audience-set';
      case ClaimStatus.ADMITTED:
        return 'status-admited';
      default:
        return '';
    }
  }

  sortByDate(claims: ClaimData[]): ClaimData[] {
    return claims.sort((a, b) => {
      const dateA = new Date(a.startDate).getTime();
      const dateB = new Date(b.startDate).getTime();
      return dateB - dateA;
    });
  }

  selectMenuItemsByRol(status: string) {
    if (this.userData?.rol === UserRol.COMISSION) {
      this.selectAmountComisionItems(status);
    }
    this.selectAmountItems(status);
  }

  selectAmountComisionItems(status: string) {
    switch (status) {
      case ClaimStatus.AUDIENCE_SET:
        this.items = this.comisionThreeOptionsItems;
        break;
      default:
        this.items = this.singleItem;
        break;
    }
  }

  selectAmountItems(status: string) {
    switch (status) {
      case ClaimStatus.PENDING:
        this.items = this.threeOptionsItems;
        break;
      case ClaimStatus.DRAFT:
        this.items = this.threeOptionsItems;
        break;
      default:
        this.items = this.singleItem;
        break;
    }
  }

  selectClaim(id: string) {
    this.selectedClaimId = id;
    this.getClaim();
  }

  editClaim(): void {
    if (this.selectedClaimId && this.rol === 'Trabajador') {
      this.router.navigate(['main/claim/update', this.selectedClaimId]);
    } else if (this.selectedClaimId && this.rol === 'Empleador') {
      this.router.navigate(['main/claim/update-claim', this.selectedClaimId]);
    }
  }

  viewClaim(): void {
    if (this.selectedClaimId) {
      this.router.navigate(['/main/claims', this.selectedClaimId]);
    }
  }

  setNewAudience() {}

  showOptionPopup() {
    this.confirmationService.confirm({
      header: 'Eliminar reclamo',
      message: `¿Está seguro de que desea eliminar el reclamo N° ${this.claim?.expediente_claim}? Se perderán todos los datos.`,
      accept: () => {
        this.deleteClaim();
        setTimeout(() => {
          this.router.navigate(['/main/claims']);
        }, 1100);
      },
    });
  }

  onFiltersApplied(filters: any): void {
    this.filters = filters;
    this.pagination.actualPage = 1;
    this.getDataPaginated();
  }

  onFilterCancel(): void {
    this.pagination.actualPage = 1;
    this.pagination.actualPage = 1;
    this.filters = undefined;
    this.getDataPaginated();
  }
}
