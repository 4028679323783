import { Entity } from "src/app/core/utils/entity";

export abstract class CreateAudienceEntity extends Entity {
    constructor(
        public date: string,
        public time: string,
        public audienceTitle: string,
        public claimId: string,       
        public link_google_meet: string,        
        public claimetId?: string,
    ) {
        super();
    }
}