import { Entity } from "src/app/core/utils/entity";

export abstract class FrogotPasswordGenerateNewEntity extends Entity {
    constructor(
        public email: string,
        public token: string,
        public newPassword: string,
        public repeatedPassword: string 
    ) {
        super();
    }
}