import { Component } from '@angular/core';

@Component({
  selector: 'app-login-image',
  templateUrl: './login-image.component.html',
  styleUrls: ['./login-image.component.css']
})
export class LoginImageComponent {

}
