import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LocationEntity } from 'src/app/core/services/location/domain/entities/location.entity';
import { ProvinceEntity } from 'src/app/core/services/location/domain/entities/province.entity';
import { GetDepartmentsUseCase } from 'src/app/core/services/location/domain/usecases/get-departments.usecase';
import { GetLocationsUseCase } from 'src/app/core/services/location/domain/usecases/get-locations.usecase';
import { GetProvinceUseCase } from 'src/app/core/services/location/domain/usecases/get-province.usecase';
import { LocalitiesEntity } from 'src/app/features/claims/data/models/location.model';
import { ClaimCollectiveEntity } from 'src/app/features/claims/domain/entities/claim-colective.entity';
import { Departament } from 'src/app/features/user/presentation/components/New-User-SuperAdmin/newUser.component';

@Component({
  selector: 'app-claimant-details-colective',
  templateUrl: './claimant-details-colective.component.html',
  styleUrls: ['./claimant-details-colective.component.css'],
})
export class ClaimantDetailsColectiveComponent {
  @Output() claimantDetailsForm = new EventEmitter<FormGroup>();
  @Output() stepIndex: EventEmitter<number> = new EventEmitter();
  @Output() activeModal: EventEmitter<boolean> = new EventEmitter();
  @Input() claim?: ClaimCollectiveEntity;

  formGroup!: FormGroup;

  provinces: ProvinceEntity[] = [];
  localities: LocalitiesEntity[] = [];
  departments: Departament[] = [];

  constructor(
    private getProvince: GetProvinceUseCase,
    private getDepartmentsUsecase: GetDepartmentsUseCase,
    private getLocations: GetLocationsUseCase,
    private formBuilder: FormBuilder
  ) {}

  async ngOnInit(): Promise<void> {
    this.initForms();
    await this.initializeComponent();
  }
  private initForms(): void {
    this.formGroup = this.formBuilder.group({
      razonSocial: ['', Validators.required],
      cuitCompany: ['', Validators.required],
      companyEmail: ['', [Validators.required, Validators.email]],
      companyPhone: [
        '',
        [
          Validators.required,
          Validators.minLength(7),
          Validators.maxLength(15),
        ],
      ],
      streetCompany: ['', Validators.required],
      numberCompany: ['', Validators.required],
      floorApartmentCompany: [''],
      towerCompany: [''],
      postalCodeCompany: [
        '',
        [
          Validators.required,
          Validators.maxLength(10),
          Validators.minLength(4),
          Validators.pattern(/^[0-9]*$/),
        ],
      ],
      provinceCompany: ['', Validators.required],
      cityCompany: ['', Validators.required],
      claimantTypePerformance: ['', Validators.required],
      departmentId: ['', Validators.required],
    });
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes['claim'] && changes['claim'].currentValue) {
      await this.initializeFormWithClaimData();
    }
  }

  async initializeComponent(): Promise<void> {
    this.loadProvinces();
    this.loadDepartments();
    this.formGroup
      .get('provinceCompany')
      ?.valueChanges.subscribe((selectedProvince: any) => {
        this.loadLocalities(selectedProvince);
        this.formGroup.patchValue(
          { provincia: selectedProvince },
          { emitEvent: false }
        );
      });
  }

  async initializeFormWithClaimData(): Promise<void> {
    this.formGroup.patchValue({
      razonSocial: this.claim?.razonSocial || '',
      cuitCompany: this.claim?.cuitCompany || '',
      companyEmail: this.claim?.companyEmail || '',
      companyPhone: this.claim?.companyPhone || '',
      streetCompany: this.claim?.streetCompany || '',
      numberCompany: this.claim?.numberCompany || '',
      floorApartmentCompany: this.claim?.floorApartmentCompany || '',
      towerCompany: this.claim?.towerCompany || '',
      postalCodeCompany: this.claim?.postalCodeCompany || '',
      provinceCompany: this.claim?.provinceCompany || '',
      cityCompany: this.claim?.cityCompany || '',
      claimantTypePerformance: this.claim?.claimantTypePerformance || '',
      departmentId: this.claim?.departmentId || '',
    });
  }

  async loadProvinces() {
    try {
      const result = await this.getProvince.execute();
      if (Array.isArray(result)) {
        this.provinces = result;
      }
    } catch (error) {
      console.error('Error fetching provinces', error);
    }
  }

  async loadLocalities(provinceId: string) {
    try {
      const localities = await this.getLocations.execute(provinceId);
      if (Array.isArray(localities)) {
        const localitiesList = this.putIdToLocalitiesList(localities);

        this.localities = localitiesList;
      }
    } catch (error) {
      console.error('Error fetching localities', error);
    }
  }

  putIdToLocalitiesList(list: LocationEntity[]): LocalitiesEntity[] {
    let listWithId: LocalitiesEntity[] = [];
    for (let i = 0; i < list.length; i++) {
      let city: LocalitiesEntity = {
        id: i,
        name: list[i].name,
      };
      listWithId.push(city);
    }
    return listWithId;
  }

  async loadDepartments() {
    const departments = await this.getDepartmentsUsecase.execute();
    if (Array.isArray(departments)) {
      this.departments = this.putIdToDepartmentsList(departments);
    }
  }

  putIdToDepartmentsList(list: Departament[]): Departament[] {
    let listWithId: Departament[] = [];
    for (let i = 0; i < list.length; i++) {
      let department: Departament = {
        id: list[i].id,
        name: list[i].name,
      };
      listWithId.push(department);
    }
    return listWithId;
  }

  hasErrors(controlName: string, errorType: string) {
    return (
      this.formGroup.get(controlName)?.hasError(errorType) &&
      this.formGroup.get(controlName)?.touched
    );
  }

  nextStep() {
    this.claimantDetailsForm.emit(this.formGroup);
    this.stepIndex.emit(1);
  }

  cancel() {
    this.claimantDetailsForm.emit(this.formGroup);
    this.activeModal.emit(true);
  }
}
