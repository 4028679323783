<p-toast />
<div class="w-full">
  <form
    [formGroup]="personalDataForm"
    (ngSubmit)="updateUserData()"
    class="w-full"
  >
    <div class="grid mt-2">
      <!-- Apellido -->
      <div class="col-12 md:col-4 xl:col-3">
        <label class="labelTitle" for="apellido"
          >Apellido<span class="label"> *</span></label
        >
        <input
          id="apellido"
          placeholder="Apellido"
          type="text"
          pInputText
          formControlName="last_name"
          [ngClass]="{
            'ng-invalid ng-dirty':
              hasErrors('last_name', 'required') ||
              hasErrors('last_name', 'maxlength')
          }"
        />
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('last_name', 'required')"
        >
          Este campo es obligatorio
        </div>
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('last_name', 'maxlength')"
        >
          Este campo no puede poseer más de 50 caracteres
        </div>
      </div>

      <!-- Nombre -->
      <div class="col-12 md:col-4 xl:col-3">
        <label class="labelTitle" for="nombre"
          >Nombre<span class="label"> *</span></label
        >
        <input
          id="nombre"
          placeholder="Nombre"
          type="text"
          pInputText
          formControlName="name"
          [ngClass]="{
            'ng-invalid ng-dirty':
              hasErrors('name', 'required') || hasErrors('name', 'maxlength')
          }"
        />
        <div class="text-danger p-error" *ngIf="hasErrors('name', 'required')">
          Este campo es obligatorio
        </div>
        <div class="text-danger p-error" *ngIf="hasErrors('name', 'maxlength')">
          Este campo no puede poseer más de 50 caracteres
        </div>
      </div>

      <!-- CUIL -->
      <div class="col-12 md:col-4 xl:col-3">
        <label class="labelTitle" for="cuil"
          >CUIL<span class="label"> *</span></label
        >
        <input
          id="cuil"
          placeholder="CUIL"
          type="text"
          pInputText
          formControlName="cuil"
          [ngClass]="{
            'ng-invalid ng-dirty':
              hasErrors('cuil', 'required') || hasErrors('cuil', 'pattern')
          }"
        />
        <div class="text-danger p-error" *ngIf="hasErrors('cuil', 'required')">
          Este campo es obligatorio
        </div>
        <div class="text-danger p-error" *ngIf="hasErrors('cuil', 'pattern')">
          El campo solo acepta números.
        </div>
      </div>

      <ng-container
        *ngIf="
          this.rolUser !== 'Administrador' &&
          this.rolUser !== 'Conciliador' &&
          this.rolUser !== 'Miembro de la comisión'
        "
      >
        <!-- Fecha de Nacimiento -->
        <div class="col-12 md:col-4 xl:col-3">
          <label class="labelTitle" for="fechaNacimiento"
            >Fecha de Nacimiento<span class="label"> *</span></label
          >
          <p-calendar
            formControlName="birth_date"
            [iconDisplay]="'input'"
            [showIcon]="true"
            inputId="icondisplay"
            slotChar="dd/mm/yyyy"
            dateFormat="dd/mm/yy"
            [ngClass]="{
              'ng-invalid ng-dirty': hasErrors('birth_date', 'required')
            }"
          ></p-calendar>
          <div
            class="text-danger p-error"
            *ngIf="hasErrors('birth_date', 'required')"
          >
            Este campo es obligatorio
          </div>
        </div>

        <!-- Sexo -->
        <div class="col-12 md:col-4 xl:col-3">
          <label class="labelTitle" for="sexo"
            >Sexo<span class="label"> *</span></label
          >
          <p-dropdown
            id="sexo"
            [options]="sexOptions"
            formControlName="gender"
            placeholder="Seleccione Sexo"
            [ngClass]="{
              'ng-invalid ng-dirty': hasErrors('gender', 'required')
            }"
          ></p-dropdown>
          <div
            class="text-danger p-error"
            *ngIf="hasErrors('gender', 'required')"
          >
            Este campo es obligatorio
          </div>
        </div>

        <!-- Estado Civil -->
        <div class="col-12 md:col-4 xl:col-3">
          <label class="labelTitle" for="estadoCivil"
            >Estado Civil<span class="label"> *</span></label
          >
          <p-dropdown
            id="estadoCivil"
            [options]="civilStatusOptions"
            optionLabel="label"
            optionValue="value"
            formControlName="civil_status"
            placeholder="Seleccione Estado Civil"
            [ngClass]="{
              'ng-invalid ng-dirty': hasErrors('civil_status', 'required')
            }"
          ></p-dropdown>
          <div
            class="text-danger p-error"
            *ngIf="hasErrors('civil_status', 'required')"
          >
            Este campo es obligatorio
          </div>
        </div>
      </ng-container>

      <!-- Correo Electrónico -->
      <div class="col-12 md:col-4 xl:col-3">
        <label class="labelTitle" for="correo"
          >Correo Electrónico<span class="label"> *</span></label
        >
        <input
          id="correo"
          placeholder="Correo Electrónico"
          type="email"
          pInputText
          formControlName="email"
          [ngClass]="{
            'ng-invalid ng-dirty':
              hasErrors('correo', 'required') || hasErrors('correo', 'email')
          }"
        />
        <div class="text-danger p-error" *ngIf="hasErrors('email', 'required')">
          Este campo es obligatorio
        </div>
        <div class="text-danger p-error" *ngIf="hasErrors('email', 'email')">
          Formato de correo inválido
        </div>
      </div>

      <!-- Teléfono -->
      <div class="col-12 md:col-4 xl:col-3">
        <label class="labelTitle" for="telefono"
          >Teléfono<span class="label"> *</span></label
        >
        <input
          id="telefono"
          placeholder="Teléfono"
          type="text"
          pInputText
          formControlName="phone"
          [ngClass]="{
            'ng-invalid ng-dirty':
              hasErrors('phone', 'required') ||
              hasErrors('phone', 'minlength') ||
              hasErrors('phone', 'maxlength') ||
              hasErrors('phone', 'pattern')
          }"
        />
        <div class="text-danger p-error" *ngIf="hasErrors('phone', 'required')">
          Este campo es obligatorio
        </div>
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('phone', 'minlength')"
        >
          El teléfono debe tener al menos 7 caracteres
        </div>
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('phone', 'maxlength')"
        >
          El teléfono no puede superar 15 caracteres
        </div>
        <div class="text-danger p-error" *ngIf="hasErrors('phone', 'pattern')">
          El campo solo acepta números.
        </div>
      </div>

      <!-- Dirección -->
      <div class="col-12 md:col-4 xl:col-3">
        <label class="labelTitle" for="calle"
          >Calle<span class="label"> *</span></label
        >
        <input
          id="calle"
          placeholder="Calle"
          type="text"
          pInputText
          formControlName="street"
          [ngClass]="{
            'ng-invalid ng-dirty':
              hasErrors('calle', 'required') || hasErrors('street', 'maxlength')
          }"
        />
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('street', 'required')"
        >
          Este campo es obligatorio
        </div>
      </div>

      <!-- Número -->
      <div class="col-12 md:col-4 xl:col-3">
        <label class="labelTitle" for="numero"
          >Número<span class="label"> *</span></label
        >
        <input
          id="numero"
          placeholder="Número"
          type="text"
          pInputText
          formControlName="number"
          [ngClass]="{
            'ng-invalid ng-dirty':
              hasErrors('numero', 'required') || hasErrors('numero', 'pattern')
          }"
        />
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('number', 'required')"
        >
          Este campo es obligatorio
        </div>
        <div class="text-danger p-error" *ngIf="hasErrors('number', 'pattern')">
          Solo se permiten números
        </div>
      </div>

      <!-- Piso/Depto -->
      <div class="col-12 md:col-4 xl:col-3">
        <label class="labelTitle" for="pisoDepto">Piso/Depto</label>
        <input
          id="pisoDepto"
          placeholder="Piso/Depto"
          type="text"
          pInputText
          formControlName="floor_apartment"
          [ngClass]="{
            'ng-invalid ng-dirty': hasErrors('floor_apartment', 'maxlength')
          }"
        />
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('floor_apartment', 'maxlength')"
        >
          Máximo 10 caracteres
        </div>
      </div>

      <!-- Provincia -->
      <div class="col-12 md:col-4 xl:col-3">
        <label class="labelTitle" for="provincia"
          >Provincia<span class="label"> *</span></label
        >
        <p-dropdown
          id="provincia"
          [options]="provinces"
          optionLabel="name"
          optionValue="id"
          formControlName="province"
          placeholder="Seleccione Provincia"
          [ngClass]="{
            'ng-invalid ng-dirty': hasErrors('province', 'required')
          }"
        ></p-dropdown>
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('province', 'required')"
        >
          Este campo es obligatorio
        </div>
      </div>

      <!-- Localidad -->
      <div class="col-12 md:col-4 xl:col-3">
        <label class="labelTitle"
          >Localidad<span class="span"
            ><span class="label"> *</span></span
          ></label
        >
        <p-dropdown
          [options]="localities"
          optionLabel="name"
          optionValue="name"
          [showClear]="false"
          placeholder="Seleccione su localidad"
          formControlName="city"
          [ngClass]="{
            'ng-invalid ng-dirty': hasErrors('city', 'required')
          }"
        >
        </p-dropdown>
        <div class="text-danger p-error" *ngIf="hasErrors('city', 'required')">
          Este campo es obligatorio
        </div>
      </div>

      <!-- Código Postal -->
      <div class="col-12 md:col-4 xl:col-3">
        <label class="labelTitle" for="codigoPostal"
          >Código Postal<span class="label"> *</span></label
        >
        <input
          id="codigoPostal"
          placeholder="Código Postal"
          type="text"
          pInputText
          formControlName="postal_code"
          [ngClass]="{
            'ng-invalid ng-dirty':
              hasErrors('postal_code', 'required') ||
              hasErrors('postal_code', 'minlength') ||
              hasErrors('postal_code', 'maxlength')
          }"
        />
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('postal_code', 'required')"
        >
          Este campo es obligatorio
        </div>
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('postal_code', 'minlength')"
        >
          Mínimo 4 caracteres
        </div>
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('postal_code', 'maxlength')"
        >
          Máximo 10 caracteres
        </div>
      </div>

      <!-- Botones -->
      <div class="grid w-full flex justify-content-end mt-7">
        <div class="col-6 md:col-2 lg:col-2 xl:col-2">
          <p-button
            (click)="cancel()"
            label="Cancelar"
            class="btn-cancelar sm:text-xs"
          />
        </div>

        <div class="col-6 md:col-2 lg:col-2 xl:col-2 pr-0">
          <p-button
            label="Guardar Cambios"
            class="btn-siguiente"
            type="submit"
            [disabled]="personalDataForm.invalid"
          ></p-button>
        </div>
      </div>
    </div>
  </form>
</div>
