import { Component, OnInit } from '@angular/core';
import { UserData } from '../../../domain/entities/user-data.entity';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ResetPasswordUseCase } from 'src/app/features/auth/domain/usecases/reset-password.useCase';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { UserService } from '../../../services/user.service';
import { Failure } from 'src/app/core/utils/failure';
import { ResetPasswordModel } from 'src/app/features/auth/data/models/reset-password.model';

type ValidatorKeys = 'uppercase' | 'lowercase' | 'minlength' | 'number' | 'specialChar';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit{
  showPassword: boolean = false;
  userData?: UserData;
  isLoading : boolean = false;
  visibility = {
    password: false,
    newPassword: false,
    confirmPassword: false
  }

  resetPasswordForm: FormGroup = this.formBuilder.group({
    id: [''],
    email: [''],
    password: [, Validators.required],
    newPassword: [, Validators.required],
    repeatedPassword: [, Validators.required]
  }, {
    validators: this.combinedPasswordValidator
  });

  constructor(private formBuilder: FormBuilder,
    private resetPasswordUseCase: ResetPasswordUseCase,
    private router: Router,
    private messageService: MessageService,
    private userService: UserService) { }

  ngOnInit() {
    this.resetPasswordForm.get('newPassword')?.valueChanges.subscribe(value => {
      this.checkPasswordValidators(value);
    });
    this.userData = this.userService.userData;
  }

  async changePassword() {
    if (this.resetPasswordForm.invalid) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Por favor, complete el formulario correctamente.' });
      return;
    }

    this.isLoading = true;
    let result: string | Failure = await this.resetPasswordUseCase.execute(this.createChangePasswordModel());

    if (result instanceof Failure) {
      this.isLoading = false;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: result.message });
    } else {
      this.isLoading = false;
      this.messageService.add({
        severity: 'success',
        summary: 'Cambio exitoso',
        detail: 'Su contraseña ha sido actualizada con éxito',
      });
      setTimeout(() => {
        this.router.navigate(['/main/home']);
      }, 2000);
    }
  }

  checkPasswordValidators(value: string) {
    const validators: { [key in ValidatorKeys]: boolean } = {
      uppercase: /[A-Z]/.test(value),
      lowercase: /[a-z]/.test(value),
      minlength: value.length >= 8,
      number: /[0-9]/.test(value),
      specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(value)
    };

    let allValid = true;

    for (const key in validators) {
      if (validators.hasOwnProperty(key)) {
        const element = document.getElementById(key);
        if (validators[key as ValidatorKeys]) {
          element?.classList.add('valid');
          element?.classList.remove('invalid');
        } else {
          element?.classList.add('invalid');
          element?.classList.remove('valid');
          allValid = false;
        }
      }
    }

    if (allValid) {
      this.resetPasswordForm.get('newPassword')?.setErrors(null);
    } else {
      this.resetPasswordForm.get('newPassword')?.setErrors({ 'invalidPassword': true });
    }
  }

  togglePasswordVisibility(input: string) {
    switch (input) {
      case "newPassword":
        this.visibility.newPassword = !this.visibility.newPassword; 
        break;
      case "confirmPassword":
        this.visibility.confirmPassword = !this.visibility.confirmPassword; 
        break;
      case "password":
        this.visibility.password = !this.visibility.password;
        break;
      default:
        break;
    }

  }

  passwordMatchValidator( control: AbstractControl){
    return control.get('newPassword')?.value === control.get('repeatedPassword')?.value
    ? null
    : { mismatch: true}
  }

  combinedPasswordValidator(control: AbstractControl): ValidationErrors | null {
     const password = control.get('password')?.value;
    const newPassword = control.get('newPassword')?.value;
    const repeatedPassword = control.get('repeatedPassword')?.value;

    const errors: ValidationErrors = {};

    if (newPassword === password) {
      errors['sameAsOldPassword'] = true;
    }

    if (newPassword !== repeatedPassword) {
      errors['passwordMismatch'] = true;
    }

    return Object.keys(errors).length ? errors : null;
  }

  hasError(controlName: string, errorName: string): boolean | null {
    const control = this.resetPasswordForm.get(controlName);
    return control && control.touched && control.hasError(errorName);
  }

  hasFormError(errorName: string): boolean | undefined {
    const form = this.resetPasswordForm;
    if (errorName === 'passwordMismatch') {
      const repeatedPasswordControl = form.get('repeatedPassword');
      return form && form.hasError(errorName) && (repeatedPasswordControl?.touched || repeatedPasswordControl?.dirty);
    } else if (errorName === 'sameAsOldPassword') {
      const newPasswordControl = form.get('newPassword');
      return form && form.hasError(errorName) && (newPasswordControl?.touched || newPasswordControl?.dirty);
    }
    return false;
  }

  createChangePasswordModel(): ResetPasswordModel {
    return new ResetPasswordModel(
      this.userData?.id!,
      this.userData?.email!,
      this.resetPasswordForm.get('password')?.value,
      this.resetPasswordForm.get('newPassword')?.value,
      this.resetPasswordForm.get('repeatedPassword')?.value
    );
  }

}
