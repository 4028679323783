<form [formGroup]="registerFormEmail" (ngSubmit)="nextStep()" class="w-8 sm:w-8 md:w-8 lg:w-7" id="container-form">
  <h2 class="mb-0">Crear cuenta</h2>
  <p> {{selectedRol}} </p>

  <div class="grid mt-1">
    <div class="custom-input col-12 md:col-12 lg:col-12 flex flex-column ">
      <label for="lastName">Correo electrónico*</label>
      <input pInputText id="email" type="email" maxlength="40" autocomplete="off"
        placeholder="Ingrese su correo electronico" formControlName="email" [ngClass]="{
          'ng-invalid ng-dirty':
            hasErrors('email', 'required') || hasErrors('email', 'email')
        }" />
      <div class="text-danger p-error" *ngIf="hasErrors('email', 'required')">
        Este campo es obligatorio
      </div>
      <div class="text-danger p-error" *ngIf="hasErrors('email', 'email')">
        Formato de email incorrecto
      </div>
    </div>
  </div>

  <div class="information">
    <img src="../../../../../../../assets/img/login/informationIcon.png" alt="" />
    <span>Recuerde que el correo electrónico por usted declarado, será el medio de
      recepción de todo tipo de notificación por parte de la Comisión</span>
  </div>

  <div class="grid mt-1">
    <div class="custom-input col-12 md:col-12 lg:col-12 flex flex-column ">
      <label for="username" id="laber-span">Contraseña</label>
      <span class="p-input-icon-right">
        <i style="color: #667085" [ngClass]="showPassword ? 'pi pi-eye' : 'pi pi-eye-slash'"
          (click)="togglePasswordVisibility()"></i>
        <input pInputText id="password" type="{{ showPassword ? 'text' : 'password' }}"
          maxlength="32" placeholder="Escribir Contraseña" autocomplete="off" formControlName="password" [ngClass]="{
          'ng-invalid ng-dirty':
            hasErrors('password', 'required') ||
            hasErrors('password', 'pattern') ||
            hasErrors('password', 'minlength')
        }" />
      </span>
      <div class="p-error" *ngIf="hasErrors('password', 'required')">
        La contraseña debe cumplir con todos los requisitos.
      </div>
      <div class="p-error" *ngIf="hasErrors('password', 'pattern')">
        La contraseña debe poseer como mínimo 8 caracteres, una mayúscula, una minúscula, un carácter especial y un
        carácter numérico.
      </div>
      <div class="p-error" *ngIf="hasErrors('password', 'minlength')">
        La contraseña debe poseer como mínimo 8 caracteres.
      </div>
    </div>
  </div>

  <div class="grid mt-1">
    <div class="custom-input col-12 md:col-12 lg:col-12 flex flex-column ">
      <label for="repeatPassword" id="laber-span">Repetir Contraseña</label>
      <span class="p-input-icon-right">
        <i style="color: #667085" [ngClass]="showPassword ? 'pi pi-eye' : 'pi pi-eye-slash'"
          (click)="togglePasswordVisibility()"></i>
        <input pInputText id="repeatPassword" type="{{ showPassword ? 'text' : 'password' }}"
          maxlength="32" placeholder="Escribir Contraseña" autocomplete="off" formControlName="repeatPassword"
          [ngClass]="{
          'ng-invalid ng-dirty': hasErrors('password', 'required')
        }" />
      </span>
      <div class="p-error" *ngIf="registerFormEmail?.errors?.['mismatch'] && registerFormEmail.touched">
        La contraseña debe coincidir.
      </div>
    </div>
  </div>
  <div class="col-12 flex flex-column">
    <span class="mt-2 passValidators" id="uppercase"><i class="pi pi-check-circle"></i> Al menos un
        carácter en
        mayúscula</span>
    <span class="mt-2 passValidators" id="lowercase"><i class="pi pi-check-circle"></i> Al menos un
        carácter en
        minúscula</span>
    <span class="mt-2 passValidators" id="minlength"><i class="pi pi-check-circle"></i> Mínimo 8
        caracteres</span>
    <span class="mt-2 passValidators" id="number"><i class="pi pi-check-circle"></i> Mínimo un
        número</span>
    <span class="mt-2 passValidators" id="specialChar"><i class="pi pi-check-circle"></i> Mínimo un
        carácter
        especial</span>
</div>
  <p-button class="nextButton" label="Finalizar"
    [disabled]="registerFormEmail.invalid || registerFormEmail.get('password')?.hasError('invalidPassword')" type="submit">
</p-button>
  <span class="backLink"><a (click)="previusStep()">Volver</a></span>
</form>
