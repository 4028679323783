import { EmployeesDto } from "../../domain/entities/claim-multi.entity";

export class EmployeesModel extends EmployeesDto {
  override toJson(): Map<string, any> {
    let json: Map<string, any> = new Map();
    json.set('name_claimed', this.nameClaimed);
    json.set('claimed_cuil', this.claimedCuil);
    json.set('claimed_email', this.claimedCuil);
    json.set('claimed_street', this.claimedStreet);
    json.set('claimed_number', this.claimedNumber);
    json.set('claimed_floor_apartment', this.claimedFloorApartment);
    json.set('claimed_tower', this.claimedTower);
    json.set('claimed_postal_code', this.claimedPostalCode);
    json.set('claimed_province', this.claimedProvince);
    json.set('claimed_city', this.claimedCity);
    json.set('claimed_dni', this.claimedDni);
    return json;
  }

  static fromJson(data: Map<string, any>): EmployeesDto {
    return new EmployeesModel(
      data.get('name_claimed'),
      data.get('claimed_cuil'),
      data.get('claimed_street'),
      data.get('claimed_number'),
      data.get('claimed_floor_apartment'),
      data.get('claimed_tower'),
      data.get('claimed_postal_code'),
      data.get('claimed_province'),
      data.get('claimed_city'),
      data.get('claimed_dni'),
    );
  }
}
