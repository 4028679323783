<div class="flex flex-column w-full h-full gap-3 p-4">
  <div class="nuevoReclamo">
    <p class="font-semibold text-xl title">{{titleText}}</p>
    <div class="flex flex-column md:flex-row align-items-center justify-content-end gap-3">

      <div *ngIf="rol === 'Trabajador'" class="col-12 md:col-2 text-right flex">
        <p-button class="btn-new-claim w-full" label="Nuevo reclamo laboral" icon="pi pi-plus"
                  routerLink="/main/newLaboralClaim"></p-button>
      </div>
    </div>
  </div>

  <ng-container>
    <app-claims-table *ngIf="!isCommisionRol"></app-claims-table>
    <app-claims-comision-table *ngIf="isCommisionRol"></app-claims-comision-table>
  </ng-container>
</div>
