<form [formGroup]="registerFormCompany" (ngSubmit)="nextStep()" class="w-8 sm:w-8 md:w-8 lg:w-7" id="container-form">

    <h2 class="mb-0">Crear cuenta</h2>
    <p> {{selectedRol}} </p>
        <div class="grid mt-1">
            <div class="custom-input col-12 md:col-12 lg:col-12 flex flex-column ">
                <label for="company" *ngIf="!companyField()">Nombre de la empresa*</label>
                <label for="company" *ngIf="companyField()">Razón social *</label>
                <input pInputText id="company" aria-describedby="username-help" placeholder="Ingrese su empresa empleadora"
                    formControlName="company" maxlength="100"
                    [ngClass]="{'ng-invalid ng-dirty': hasErrors('company', 'required')}" />
                <div class="text-danger p-error" *ngIf="hasErrors('company', 'required')">
                    Este campo es obligatorio
                </div>
            </div>
        </div>
    
        <div class="grid mt-1">
            <div class="custom-input col-12 md:col-12 lg:col-12 flex flex-column ">
                <label for="company_cuil" *ngIf="!companyField()">CUIT de la empresa empleadora*</label> 
                <label for="company_cuil" *ngIf="companyField()">CUIT *</label>
                <p-inputMask mask="99-99999999-9" placeholder="99-99999999-9" formControlName="company_cuil" [ngClass]="{
                  'ng-invalid ng-dirty': hasErrors('company_cuil', 'required')
                }"></p-inputMask>
                <div class="text-danger p-error" *ngIf="hasErrors('company_cuil', 'required')">
                    Este campo es obligatorio
                </div>
            </div>
        </div>
        <div class="grid mt-1" *ngIf="companyField()">
            <div class="custom-input col-12 md:col-12 lg:col-12 ">
                <label for="phone_company">Número de teléfono</label>
                <input pInputText id="phone_company" aria-describedby="username-help" placeholder="Ingrese telefono"
                    formControlName="phone_company" maxlength="15"
                    [ngClass]="{'ng-invalid ng-dirty': hasErrors('phone_company', 'maxlength')}" />
                <div class="text-danger p-error" *ngIf="hasErrors('phone_company', 'minlength')">
                    Este campo no puede poseer menos de 9 caracteres
                </div>
                <div class="text-danger p-error" *ngIf="hasErrors('phone_company', 'maxlength')">
                    Este campo no puede poseer más de 15 caracteres
                </div>
                <div class="text-danger p-error" *ngIf="hasErrors('phone_company', 'pattern')">
                    El campo solo acepta números. Las letras no son permitidas.
                </div>
            </div>
        </div>

    <p-button class="nextButton" label="Siguiente" [disabled]="registerFormCompany.invalid" type="submit"></p-button>
    <span class="backLink"><a (click)="previusStep()">Volver</a></span>
</form>