import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Toast } from 'primeng/toast';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProvinceEntity } from 'src/app/core/services/location/domain/entities/province.entity';
import { LocationEntity } from 'src/app/core/services/location/domain/entities/location.entity';
import { GetProvinceUseCase } from 'src/app/core/services/location/domain/usecases/get-province.usecase';
import { GetLocationsUseCase } from 'src/app/core/services/location/domain/usecases/get-locations.usecase';
import { ClaimEntity } from 'src/app/features/claims/domain/entities/claim.entity';

interface TypeOfPerson {
  name: string;
  key: string;
}

interface Localities {
  id: number;
  name: string;
}

@Component({
  selector: 'app-claimed-details-form',
  templateUrl: './claimed-details-form.component.html',
  styleUrls: ['./claimed-details-form.component.css'],
  providers: [MessageService, Toast, ConfirmationService],
})
export class ClaimedDetailsFormComponent implements OnInit, OnChanges {
  visibleCancel: boolean = false;

  @Output() claimed: EventEmitter<FormGroup> = new EventEmitter();
  @Output() stepIndex: EventEmitter<number> = new EventEmitter();
  @Output() activeModal: EventEmitter<boolean> = new EventEmitter();

  @Input() claim?: ClaimEntity;

  isEditMode: boolean = false;
  provinces: ProvinceEntity[] = [];
  localities: Localities[] = [];

  typeOfPerson: TypeOfPerson[] = [
    { name: 'PERSONA FÍSICA', key: 'physicalPerson' },
    { name: 'PERSONA JURÍDICA', key: 'legalPerson' },
  ];

  constructor(
    private formBuilder: FormBuilder,
    private getProvince: GetProvinceUseCase,
    private getLocations: GetLocationsUseCase
  ) {}

  ngOnInit(): void {
    this.loadProvinces();
    this.formClaimedDetails
      .get('claimedProvince')
      ?.valueChanges.subscribe((selectedProvince: any) => {
        this.formClaimedDetails.patchValue({ claimedCity: '' });
        if (selectedProvince && selectedProvince.id) {
          this.loadLocalities(selectedProvince.id);
          this.formClaimedDetails.patchValue({
            claimedProvince: selectedProvince.name,
          });
        }
        this.loadLocalities(selectedProvince);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['claim'] && changes['claim'].currentValue) {
      this.isEditMode = true;
      this.initializeFormWithClaimData();
      this.loadLocalities(
        this.formClaimedDetails.get('claimedProvince')?.value
      );
    }
  }

  formClaimedDetails: FormGroup = this.formBuilder.group({
    claimedTypeOfPerson: ['', Validators.required],
    claimedNameLastNameOrBusinessName: [
      '',
      [
        Validators.required,
        Validators.maxLength(50),
        Validators.pattern(/^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]*$/),
      ],
    ],
    claimedCuil: ['', Validators.required],
    claimedEmail: [
      '',
      [Validators.required, Validators.email, Validators.maxLength(100)],
    ],
    claimedPhone: [
      '',
      [
        Validators.minLength(7),
        Validators.maxLength(15),
        Validators.pattern(/^[0-9]+$/),
      ],
    ],
    claimedStreet: ['', Validators.maxLength(50)],
    claimedNumber: ['', [Validators.required, Validators.maxLength(5)]],
    claimedFloorApartment: ['', [Validators.maxLength(10)]],
    claimedTower: ['', [Validators.maxLength(30)]],
    claimedPostalCode: [
      '',
      [
        Validators.required,
        Validators.maxLength(10),
        Validators.minLength(4),
        Validators.pattern(/^[0-9]*$/),
      ],
    ],
    claimedNeighborhood: [''],
    claimedProvince: ['', Validators.required],
    claimedCity: ['', Validators.required],
  });

  private initializeFormWithClaimData(): void {
    if (!this.claim) return;
    if (this.isEditMode) {
      this.formClaimedDetails.patchValue({
        claimedTypeOfPerson: this.claim.claimedTypeOfPerson || '',
        claimedNameLastNameOrBusinessName:
          this.claim.claimedNameLastNameOrBusinessName || '',
        claimedCuil: this.claim.claimedCuil || '',
        claimedEmail: this.claim.claimedEmail || '',
        claimedPhone: this.claim.claimedPhone || '',
        claimedStreet: this.claim.claimedStreet || '',
        claimedNumber: this.claim.claimedNumber || '',
        claimedFloorApartment: this.claim.claimedFloorApartment || '',
        claimedTower: this.claim.claimedTower || '',
        claimedPostalCode: this.claim.claimedPostalCode || '',
        claimedNeighborhood: this.claim.claimedNeighborhood || '',
        claimedProvince: this.claim.claimedProvince || '',
        claimedCity: this.claim.claimedCity || '',
      });
    }
  }

  hasErrors(controlName: string, errorType: string) {
    return (
      this.formClaimedDetails.get(controlName)?.hasError(errorType) &&
      this.formClaimedDetails.get(controlName)?.touched
    );
  }

  nextStep() {
    if (this.formClaimedDetails.invalid) return;
    this.claimed.emit(this.formClaimedDetails.value);
    this.stepIndex.emit(2);
  }

  cancel() {
    this.claimed.emit(this.formClaimedDetails.value);
    this.activeModal.emit(true);
  }

  back() {
    this.stepIndex.emit(0);
  }

  async loadProvinces() {
    try {
      const result = await this.getProvince.execute();
      if (Array.isArray(result)) {
        this.provinces = result;
      }
    } catch (error) {
      console.error('Error fetching provinces', error);
    }
  }

  async loadLocalities(provinceId: string) {
    try {
      const localities = await this.getLocations.execute(provinceId);
      if (Array.isArray(localities)) {
        this.localities = this.putIdToLocalitiesList(localities);
      }
    } catch (error) {
      console.error('Error fetching localities', error);
    }
  }

  putIdToLocalitiesList(list: LocationEntity[]): Localities[] {
    let listWithId: Localities[] = [];
    for (let i = 0; i < list.length; i++) {
      let city: Localities = {
        id: i,
        name: list[i].name,
      };
      listWithId.push(city);
    }
    return listWithId;
  }
}
