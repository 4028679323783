import { ClaimData } from "../../domain/entities/claim-data.entity";
import { ClaimPagination } from "../../domain/entities/claim-pagination.entity";
import { ClaimDataModel } from "./claim-data.model";

export class ClaimPaginationModel extends ClaimPagination {
    override toJson(): Map<string, any> {
      throw new Error("Method not implemented.");
    } 
  
    static fromJson(data: Map<string, any>): ClaimPagination {      
      let claims = this.getClaimsModels(data);
      return new ClaimPaginationModel(
        claims,
        data.get('numberOfPages'),
        data.get('totalQuantity')
      );
    }
  
    private static  getClaimsModels(data:Map<string,any>): ClaimData[]{
      let response = data.get('response');
      let positions : ClaimData[] = [];
       
      for (const claimData of response) {
        let entries = Object.entries(claimData);
        let map = new Map<string, any>(entries);
        positions.push(ClaimDataModel.fromJson(map));
      }  
      return positions;
    }
  }