import { Entity } from 'src/app/core/utils/entity';
import { AudienceFilterModel } from '../../data/models/audience-filter.model';

export abstract class AudiencePaginationParams extends Entity {
  constructor(
    public page: number,
    public quantity: number,
    public userId?: string,
    public filters?: AudienceFilterModel
  ) {
    super();
  }

  abstract getPath(): string;
}
