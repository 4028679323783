import { Component, OnInit } from '@angular/core';
import { Pagination } from 'src/app/core/interfaces/pagination.interface';
import { ClaimFilterModel } from 'src/app/features/claims/data/models/claim-filter.model';
import { UserRol } from 'src/app/core/enums/user-rol.enum';
import { Router } from '@angular/router';
import { Failure } from 'src/app/core/utils/failure';
import { ClaimServiceService } from 'src/app/features/claims/services/claim-service.service';
import { ClaimEntity } from 'src/app/features/claims/domain/entities/claim.entity';
import { ClaimUseCase } from 'src/app/features/claims/domain/usecases/get-claim.usecase';
import { LocalStorageServiceBase } from 'src/app/core/services/localStorage-serviceBase';
import AESEncryptDecryptService from 'src/app/core/utils/crypto';
import { UserData } from 'src/app/features/user/domain/entities/user-data.entity';
import { GetAllConciliatorsUseCase } from 'src/app/features/user/domain/usecases/get-all-conciliators.usecase';
import { ClaimsPaginationParamsModel } from 'src/app/features/claims/data/models/claim-pagination-params.model';
import { GetPaginatedAllClaimsUseCase } from 'src/app/features/claims/domain/usecases/get-pagination-all-claims.usecase';
import { GetColectiveClaimsUseCase } from 'src/app/features/claims/domain/usecases/get-colective-claim.usercase';
import { ClaimStatus } from 'src/app/core/enums/claim-status.enum';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { UpdateClaimStatusModel } from 'src/app/features/claims/data/models/update-claim-status.model';
import { UpdateClaimStatusEntity } from 'src/app/features/claims/domain/entities/update-claim-satus.entity';
import { UpdateCollectiveClaimStatusUseCase } from 'src/app/features/claims/domain/usecases/update-collective-status.usecase';
import { UpdateClaimConciliatorEntity } from 'src/app/features/claims/domain/entities/update-claim-conciliator.entity';
import { GetPaginatedAllClaimsByConciliatorUseCase } from 'src/app/features/claims/domain/usecases/get-pagination-all-cliams-by-conciliator.usecase';
import { ClaimPagination } from 'src/app/features/claims/domain/entities/claim-pagination.entity';
import { ClaimData } from 'src/app/features/claims/domain/entities/claim-data.entity';
import { UpdateClaimConciliatorUseCase } from 'src/app/features/claims/domain/usecases/update-claim-conciliator.usecase';
import { UpdateClaimColectiveConciliatorUseCase } from 'src/app/features/claims/domain/usecases/update-claim-colective-conciliator.usecase';
import { UpdateClaimColectiveConciliatorModel } from 'src/app/features/claims/data/models/update-colective.model';
import { DeleteClaimModel } from 'src/app/features/claims/data/models/delete-claim.model';
import { DeleteCollectiveClaimUseCase } from 'src/app/features/claims/domain/usecases/delete-collective-claim';
import { Toast } from 'primeng/toast';
import { ClaimCollectiveEntity } from 'src/app/features/claims/domain/entities/claim-colective.entity';

@Component({
  selector: 'app-claims-colective-table',
  templateUrl: './claims-colective-table.component.html',
  styleUrls: ['./claims-colective-table.component.css'],
  providers: [MessageService, Toast, ConfirmationService],
})
export class ClaimsColectiveTableComponent implements OnInit {
  public homeView: boolean = false;

  public pagination: Pagination = {
    totalQuantity: 0,
    numberOfPages: 0,
    itemsPerPage: 5,
    actualPage: 1,
  };
  paginatedData: ClaimPagination | undefined = undefined;

  public skeleton: boolean = false;
  public skeletons: any = [{}, {}, {}, {}, {}];

  public filters?: ClaimFilterModel;

  public userClaims?: any[];
  public selectedClaimId?: string;
  public claim?: ClaimEntity;
  newClaims?: number;

  public conciliators: UserData[] = [];
  public status: string[] = [
    ClaimStatus.PENDING,
    ClaimStatus.REFUSED,
    ClaimStatus.ADMITTED,
    ClaimStatus.AUDIENCE_SET,
    ClaimStatus.IN_INTERNAL_PROCESS,
    ClaimStatus.FINALIZED,
  ];
  public originalStatus?: string;

  newStatusClaimUpdate?: UpdateClaimStatusModel;
  newConciliatorClaimUpdate?: UpdateClaimColectiveConciliatorModel;

  audienciaDialogVisible: boolean = false;
  claimSelected?: ClaimCollectiveEntity;

  public rol?: string;
  public userId?: string;

  public items: MenuItem[] = [
    {
      label: 'Ver reclamo',
      icon: 'pi pi-eye',
      command: () => this.viewClaim(),
    },
  ];

  private AES = new AESEncryptDecryptService();

  constructor(
    private router: Router,
    private getPaginatedAllClaimsUseCase: GetPaginatedAllClaimsUseCase,
    private localStorageService: LocalStorageServiceBase,
    private getAllConciliatorsUseCase: GetAllConciliatorsUseCase,
    private getColectiveClaimsUsecase: GetColectiveClaimsUseCase,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private claimService: ClaimServiceService,
    private updateClaimStatusUseCase: UpdateCollectiveClaimStatusUseCase,
    private updateClaimConciliatorUseCase: UpdateClaimColectiveConciliatorUseCase,
    private getAllPAginatedClaimsofConciliatorUseCase: GetPaginatedAllClaimsByConciliatorUseCase,
    private deleteClaimUseCase: DeleteCollectiveClaimUseCase
  ) {}

  ngOnInit() {
    this.userId = this.AES.decrypt(
      this.localStorageService.loadStorageData('user_id')
    );
    this.rol = this.AES.decrypt(
      this.localStorageService.loadStorageData('user_rol')
    );
    if (this.rol == 'Administrador') this.getAllConciliators();
    if (this.router.url.includes('main/home')) {
      this.homeView = true;
    }
    this.initClaims();
  }

  async initClaims() {
    this.skeleton = true;
    let params;
    if (this.rol == 'Administrador' || this.rol == 'Conciliador') {
      params = new ClaimsPaginationParamsModel(
        this.pagination.actualPage,
        this.pagination.itemsPerPage,
        undefined,
        this.filters,
        this.userId,
        undefined
      );
    } else {
      params = new ClaimsPaginationParamsModel(
        this.pagination.actualPage,
        this.pagination.itemsPerPage,
        undefined,
        this.filters,
        undefined,
        this.userId
      );
    }
    let result;
    result = await this.getColectiveClaimsUsecase.execute(params);
    if (result instanceof Failure) {
      this.skeleton = false;
      return;
    }
    if (result) {
      this.userClaims = result.response;
      this.pagination.totalQuantity = result.totalQuantity;
      setTimeout(() => {
        this.skeleton = false;
      }, 500);
    }
  }

  selectClaim(id: string) {
    this.selectedClaimId = id;
  }

  dropdownClick(claim: any) {
    this.updateStatusOptionsByStatusClaim(claim.status);
    this.originalStatus = claim.status;
    this.selectedClaimId = claim.id;
  }

  updateConciliatorCheck(event: any) {
    const conciliatorName = event.value.id
      ? `${event.value.name} ${event.value.last_name}`
      : 'eliminar el conciliador actual';

    this.confirmationService.confirm({
      header: 'Actualizar conciliador',
      message: `¿Está seguro de que desea ${
        event.value.id ? 'actualizar el conciliador actual por' : ''
      } ${conciliatorName}?`,
      accept: () => {
        this.newConciliatorClaimUpdate =
          new UpdateClaimColectiveConciliatorModel(
            this.selectedClaimId!,
            event.value.id
          );
        this.updateClaimConciliator(this.newConciliatorClaimUpdate);
      },
      reject: () => {},
    });
  }

  isConciliatorChangeAllowed(status: string): boolean {
    const allowedStatuses = ['Pendiente', 'Admitido'];
    return allowedStatuses.includes(status);
  }

  async updateClaimConciliator(
    claim: UpdateClaimConciliatorEntity
  ): Promise<void> {
    let result = await this.updateClaimConciliatorUseCase.execute(claim);
    if (result instanceof Failure) {
      this.messageService.add({
        severity: 'error',
        summary: 'Ocurrió un problema.',
        detail: 'Conciliador no actualizado.',
        life: 900,
      });
      return;
    }
    this.messageService.add({
      severity: 'success',
      summary: 'Conciliador actualizado',
      detail: 'El conciliador se actualizó exitosamente.',
    });
    this.initClaims();
  }

  updateStatusOptionsByStatusClaim(status: string) {
    switch (status) {
      case ClaimStatus.PENDING:
        this.status = [ClaimStatus.REFUSED, ClaimStatus.ADMITTED];
        break;
      case ClaimStatus.IN_INTERNAL_PROCESS:
        this.status = [ClaimStatus.FINALIZED];
        break;
      case 'Tramite Interno':
        this.status = [ClaimStatus.FINALIZED];
        break;
      case ClaimStatus.AUDIENCE_SET:
        this.status = [ClaimStatus.IN_INTERNAL_PROCESS];
        break;
      case 'Audiencia Fijada':
        this.status = [ClaimStatus.IN_INTERNAL_PROCESS];
        break;
      default:
        this.status = [];
        break;
    }
  }

  sortByDate(claims: ClaimData[]): ClaimData[] {
    return claims.sort((a, b) => {
      const dateA = new Date(a.startDate).getTime();
      const dateB = new Date(b.startDate).getTime();
      return dateB - dateA;
    });
  }

  updateStatusCheck(event: any) {
    this.confirmationService.confirm({
      header: 'Actualizar estado',
      message: `¿Está seguro de que desea actualizar el estado del reclamo?`,
      accept: () => {
        this.newStatusClaimUpdate = new UpdateClaimStatusModel(
          this.selectedClaimId!,
          event.value
        );
        this.updateClaimStatus(this.newStatusClaimUpdate);
      },
      reject: () => {
        this.initClaims();
      },
    });
  }

  async updateClaimStatus(claim: UpdateClaimStatusEntity): Promise<void> {
    let result = await this.updateClaimStatusUseCase.execute(claim);
    if (result instanceof Failure) {
      this.messageService.add({
        severity: 'error',
        summary: 'Ocurrió un problema.',
        detail: 'Estado no actualizado.',
        life: 900,
      });
      this.initClaims();
      return;
    }
    this.messageService.add({
      severity: 'success',
      summary: 'Estado actualizado',
      detail: 'El estado se actualizó exitosamente.',
    });
    this.initClaims();
  }

  async getAllConciliators(): Promise<void> {
    const result = await this.getAllConciliatorsUseCase.execute();
    if (result instanceof Failure) {
      return;
    }
    const noAssignedOption: UserData = {
      id: '',
      name: 'No Asignado',
      lastName: '',
      rol: '',
      cuil: '',
      gender: '',
      birthDate: new Date(),
      province: '',
      phone: '',
      email: '',
      civilStatus: '',
      city: '',
      street: '',
      number: '',
      floorApartment: '',
      postalCode: 0,
      company: '',
      companyCuil: '',
      dni: '',
      neighborhood: '',
      toJson: function (): Map<string, any> {
        throw new Error('Function not implemented.');
      },
    };
    this.conciliators = [noAssignedOption, ...result];
  }

  getStatusClass(status: string): string {
    switch (status) {
      case ClaimStatus.DRAFT:
        return 'status-draft';
      case ClaimStatus.PENDING:
        return 'status-pending';
      case ClaimStatus.REFUSED:
        return 'status-refused';
      case ClaimStatus.FINALIZED:
        return 'status-finalized';
      case ClaimStatus.IN_INTERNAL_PROCESS:
        return 'status-in-internal-process';
      case 'Tramite Interno':
        return 'status-in-internal-process';
      case 'En trámite interno':
        return 'status-in-internal-process';
      case ClaimStatus.AUDIENCE_SET:
        return 'status-audience-set';
      case 'Audiencia fijada':
        return 'status-audience-set';
      case ClaimStatus.ADMITTED:
        return 'status-admited';
      default:
        return '';
    }
  }

  getStatusClassForPrincipalView(status: string): string {
    switch (status) {
      case ClaimStatus.DRAFT:
        return 'status-draft-principal';
      case ClaimStatus.PENDING:
        return 'status-pending-principal';
      case ClaimStatus.REFUSED:
        return 'status-refused-principal';
      case ClaimStatus.FINALIZED:
        return 'status-finalized-principal';
      case ClaimStatus.IN_INTERNAL_PROCESS:
        return 'status-in-internal-process-principal';
      case 'Tramite Interno':
        return 'status-in-internal-process-principal';
      case 'En trámite interno':
        return 'status-in-internal-process-principal';
      case ClaimStatus.AUDIENCE_SET:
        return 'status-audience-set-principal';
      case 'Audiencia fijada':
        return 'status-audience-set-principal';
      case ClaimStatus.ADMITTED:
        return 'status-admited-principal';
      case ClaimStatus.ADMITTED:
        return 'status-admited';
      default:
        return '';
    }
  }

  changePage(event: any) {
    this.pagination.actualPage = event.page + 1;
    this.pagination.itemsPerPage = event.rows;
    this.skeleton = true;
    this.initClaims();
  }

  onFiltersApplied(filters: ClaimFilterModel) {
    this.filters = filters;
    this.pagination.actualPage = 1;
    this.initClaims();
  }

  onFilterCancel(): void {
    this.pagination.actualPage = 1;
    this.filters = undefined;
    this.initClaims();
  }

  editClaim(): void {
    if (this.selectedClaimId && this.rol === 'Empleador') {
      this.router.navigate([
        'main/claim/update-colective',
        this.selectedClaimId,
      ]);
    }
  }

  viewClaim(): void {
    if (this.selectedClaimId) {
      this.router.navigate(['/main/claims', this.selectedClaimId]);
    }
  }

  returnSelectedClaim(claim: ClaimCollectiveEntity) {
    this.claimSelected = Object.assign({}, claim);
  }

  showdeleteClaim(status: string) {
    this.confirmationService.confirm({
      header: 'Eliminar reclamo',
      message: `¿Está seguro de que desea eliminar el reclamo N° ${this.claim?.expediente_claim}? Se perderán todos los datos.`,
      accept: () => {
        this.deleteClaim(status);
        setTimeout(() => {
          this.router.navigate(['/main/claims']);
        }, 1100);
      },
    });
  }

  selectMenuItemsByRol(status: string) {
    this.selectAmountItems(status, this.rol!);
  }

  cancel(event: any) {
    this.claimSelected = undefined;
    this.audienciaDialogVisible = false;
  }

  createAudienceOk(event: any) {
    this.initClaims();
  }

  handleShowMessage(event: {
    severity: string;
    summary: string;
    detail: string;
  }) {
    this.messageService.add({
      severity: event.severity,
      summary: event.summary,
      detail: event.detail,
    });
  }

  selectAmountItems(status: string, roles: string) {
    const deleteExists = this.items.some(
      (item) => item.label === 'Eliminar reclamo'
    );
    const editExists = this.items.some(
      (item) => item.label === 'Editar reclamo'
    );
    const setExists = this.items.some(
      (item) =>
        item.label === 'Fijar audiencia' ||
        item.label === 'Fijar nueva audiencia'
    );

    if (
      (status === ClaimStatus.PENDING || status === ClaimStatus.DRAFT) &&
      this.rol == 'Empleador'
    ) {
      if (!deleteExists) {
        this.items.push({
          label: 'Eliminar reclamo',
          icon: 'pi pi-trash',
          command: () => {
            this.showdeleteClaim(status);
          },
        });
      }
      if (!editExists) {
        this.items.push({
          label: 'Editar reclamo',
          icon: 'pi pi-pencil',
          command: () => this.editClaim(),
        });
      }
    } else {
      this.items = this.items.filter(
        (item) =>
          item.label !== 'Eliminar reclamo' && item.label !== 'Editar reclamo'
      );
    }

    if (
      (status === ClaimStatus.ADMITTED ||
        status === ClaimStatus.IN_INTERNAL_PROCESS ||
        status === 'En trámite interno' ||
        status === 'Tramite interno') &&
      (this.rol == 'Administrador' || this.rol == 'Conciliador')
    ) {
      if (!setExists) {
        this.items.push({
          label:
            status === ClaimStatus.ADMITTED
              ? 'Fijar audiencia'
              : 'Fijar nueva audiencia',
          icon: 'pi pi-calendar-plus',
          command: () => this.setNewAudience(),
        });
      }
    } else {
      this.items = this.items.filter(
        (item) =>
          item.label !== 'Fijar audiencia' &&
          item.label !== 'Fijar nueva audiencia'
      );
    }
  }

  setNewAudience() {
    this.audienciaDialogVisible = true;
  }

  async deleteClaim(status: string): Promise<void> {
    if (status !== 'Pendiente' && status !== 'Borrador') {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Su reclamo esta en curso, no es posible eliminar',
      });
      return;
    }
    let claimDeleteEntity = new DeleteClaimModel(this.selectedClaimId!);
    let result = await this.deleteClaimUseCase.execute(claimDeleteEntity);
    if (result instanceof Failure) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: result.message,
      });
      return;
    }
    this.messageService.add({
      severity: 'success',
      summary: 'Reclamo eliminado',
      detail: 'Su reclamo se elimino exitosamente.',
    });
    this.initClaims();
  }
}
