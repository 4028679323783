import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import typeOfRemuneration from '../../../../../../core/jsons/typeOfRemuneration.json';
import { ProvinceEntity } from 'src/app/core/services/location/domain/entities/province.entity';
import { LocationEntity } from 'src/app/core/services/location/domain/entities/location.entity';
import { GetProvinceUseCase } from 'src/app/core/services/location/domain/usecases/get-province.usecase';
import { GetLocationsUseCase } from 'src/app/core/services/location/domain/usecases/get-locations.usecase';
import { ClaimEntity } from 'src/app/features/claims/domain/entities/claim.entity';
import { GetFileUseCase } from 'src/app/features/claims/domain/usecases/get-file.usecase';
import { GetFileModel } from 'src/app/features/claims/data/models/get-file.model';
import { Failure } from 'src/app/core/utils/failure';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { CalendarTranslationService } from 'src/app/core/services/translate-calendar.service';
import { UserData } from 'src/app/features/user/domain/entities/user-data.entity';
import { Toast } from 'primeng/toast';
import { Departament } from 'src/app/features/user/presentation/components/New-User-SuperAdmin/newUser.component';
import { GetDepartmentsUseCase } from 'src/app/core/services/location/domain/usecases/get-departments.usecase';
import { GetDepartmentsByConciliadorUseCase } from 'src/app/core/services/location/domain/usecases/get-departments-by-conciliador.usecase';
import { GetDepartmentsByIdUseCase } from 'src/app/core/services/location/domain/usecases/get-department-by-id.usescase';

interface TypeRemeneration {
  id: number;
  type: string;
}

interface Localities {
  id: number;
  name: string;
}

interface Base64 {
  name: string;
  base64: string;
}

@Component({
  selector: 'app-claimant-details-form',
  templateUrl: './claimant-details-form.component.html',
  styleUrls: ['./claimant-details-form.component.css'],
  providers: [ConfirmationService, MessageService, MessageService, Toast],
})
export class ClaimantDetailsFormComponent implements OnInit, OnChanges {
  @Output() claimantDetailsForm: EventEmitter<FormGroup> = new EventEmitter();
  @Output() stepIndex: EventEmitter<number> = new EventEmitter();
  @Output() activeModal: EventEmitter<boolean> = new EventEmitter();

  @Input() claim?: ClaimEntity;
  @Input() claimId?: string | null;
  @Input() userData?: UserData;

  typeRemunerations: TypeRemeneration[] = [...typeOfRemuneration];
  uploadedFiles: any[] = [];
  visibleCancel: boolean = false;
  acceptsElectronicDomicile: boolean = false;
  showConditionalReason: boolean = false;
  showConditionalCause: boolean = false;
  disableFileImageUpload: boolean = false;
  disableFilePdfUpload: boolean = false;
  maxDate: Date | undefined;
  maxDateIngreso: Date | undefined;
  minDate: Date | undefined;

  maxDateForDateAdmission: Date | null = null;
  minDateForEgressDate: Date | null = null;
  updatingDateAdmission = false;
  updatingEgressDate = false;

  isDragging: boolean = false;
  imageFiles: any[] = [];
  pdfFiles: any[] = [];
  departments!: Departament[];
  progress: number = 0;

  provinces: ProvinceEntity[] = [];
  localities: Localities[] = [];
  selectedProvince?: ProvinceEntity;
  selectedDepartment?: Departament;
  public isEditMode: boolean = false;

  workdayOptions = [
    { label: 'Part-Time', value: 'part-time' },
    { label: 'Full-Time', value: 'full-time' },
  ];

  typeOfJob: any[] = [
    { name: 'Registrado', key: 'register' },
    { name: 'Parcialmente registrado', key: 'partRegister' },
  ];

  currentEmploymentsStatus: any[] = [
    { id: 1, name: 'Vigente' },
    { id: 2, name: 'Disuelta' },
  ];
  reasonsForDisengagement: any[] = [
    { id: 1, name: 'Renuncia' },
    { id: 2, name: 'Despido indirecto' },
    { id: 3, name: 'Mutuo acuerdo' },
    { id: 4, name: 'Despido' },
  ];
  causesDisengagement: any[] = [
    { id: 1, name: 'Invocación de causa' },
    { id: 2, name: 'Sin causa' },
    { id: 3, name: 'Articulo 247' },
  ];

  constructor(
    private formBuilder: FormBuilder,
    private getProvince: GetProvinceUseCase,
    private getLocations: GetLocationsUseCase,
    private getFileUseCase: GetFileUseCase,
    private calendarTranslationService: CalendarTranslationService,
    private messageService: MessageService,
    private getDepartmentsUsecase: GetDepartmentsUseCase,
    private getDepartmentByConciliador: GetDepartmentsByConciliadorUseCase,
    private getDepartmentsByIdUseCase: GetDepartmentsByIdUseCase
  ) {
    this.maxDate = new Date();
    this.maxDateIngreso = new Date();
    this.maxDateIngreso.setMonth(this.maxDateIngreso.getMonth() - 1);

    this.formClaimetDetails
      .get('claimetDateAdmission')
      ?.valueChanges.subscribe((date) => {
        if (!this.updatingDateAdmission) {
          this.updatingEgressDate = true;
          this.minDateForEgressDate = date;
          this.formClaimetDetails
            .get('claimetEgressDate')
            ?.updateValueAndValidity();
          this.updatingEgressDate = false;
        }
      });

    this.formClaimetDetails
      .get('claimetEgressDate')
      ?.valueChanges.subscribe((date) => {
        if (!this.updatingEgressDate) {
          this.updatingDateAdmission = true;
          this.maxDateForDateAdmission = date;
          this.formClaimetDetails
            .get('claimetDateAdmission')
            ?.updateValueAndValidity();
          this.updatingDateAdmission = false;
        }
      });
  }

  async ngOnInit(): Promise<void> {
    await this.initializeComponent();
    this.loadUserData();
  }

  async initializeComponent(): Promise<void> {
    await this.loadProvinces();

    this.formClaimetDetails.controls[
      'claimetCurrentEmploymentStatus'
    ].valueChanges.subscribe((selectedValue) => {
      this.showConditionalReason = selectedValue === 'Disuelta';
      this.showConditionalCause = false;
    });

    this.formClaimetDetails.controls[
      'claimetReasonForDisengagement'
    ].valueChanges.subscribe((selectedValue) => {
      this.showConditionalCause = selectedValue === 'Despido';
    });
    this.loadDepartments();

    this.calendarTranslationService.configureCalendarTranslations();

    this.formClaimetDetails
      .get('claimetProvince')
      ?.valueChanges.subscribe((selectedProvince: any) => {
        this.loadLocalities(selectedProvince);
        this.loadLocalities(selectedProvince);
        this.formClaimetDetails.patchValue(
          { provincia: selectedProvince },
          { emitEvent: false }
        );
      });
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes['userData'] && changes['userData'].currentValue) {
      this.loadUserData();
    }

    if (changes['claim'] && changes['claim'].currentValue) {
      this.isEditMode = true;
      await this.initializeFormWithClaimData();
    }
  }

  formClaimetDetails: FormGroup = this.formBuilder.group({
    claimetLastname: [
      '',
      [
        Validators.required,
        Validators.maxLength(50),
        Validators.pattern(/^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]*$/),
      ],
    ],
    claimetName: [
      '',
      [
        Validators.required,
        Validators.maxLength(50),
        Validators.pattern(/^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]*$/),
      ],
    ],
    claimetCuil: ['', [Validators.required]],
    claimetEmail: [
      '',
      [Validators.required, Validators.email, Validators.maxLength(100)],
    ],
    claimetPhone: [
      '',
      [
        Validators.required,
        Validators.minLength(7),
        Validators.maxLength(15),
        Validators.pattern(/^[0-9]+$/),
      ],
    ],
    claimetProvince: ['', Validators.required],
    claimetCity: ['', [Validators.required]],
    claimetNeighborhood: [''],
    claimetStreet: ['', [Validators.required, Validators.maxLength(50)]],
    claimetNumber: ['', [Validators.required, Validators.maxLength(5)]],
    claimetPostalCode: [
      '',
      [
        Validators.required,
        Validators.maxLength(10),
        Validators.minLength(4),
        Validators.pattern(/^[a-zA-Z0-9]*$/),
      ],
    ],
    claimetFloorApartament: ['', [Validators.maxLength(10)]],
    claimetTower: ['', [Validators.maxLength(30)]],
    consent: [false, [Validators.requiredTrue]],
    claimetSelectedTypeOfJob: ['', [Validators.required]],
    claimetWorkday: [
      '',
      [
        Validators.required,
        Validators.maxLength(80),
        Validators.pattern(/^[a-zA-Z0-9\s-]*$/),
      ],
    ],
    claimetGrossRemuneration: [
      '',
      [Validators.required, Validators.maxLength(10)],
    ],
    claimetTypeRemuneration: ['', [Validators.required]],
    claimetDateAdmission: ['', [Validators.required]],
    claimetEgressDate: [],
    claimetNumberAgreement: ['aa2024'],
    claimetYear: ['0000'],
    claimetCategory: ['aa'],
    claimetCurrentEmploymentStatus: ['', [Validators.required]],
    claimetReasonForDisengagement: [''],
    claimetCauseDisengagement: [''],
    claimetFileDni: [, [this.CountValidatorDni]],
    claimetFilePaycheck: [, [this.CountValidatorPaycheck]],
    department_id: [''],
  });

  private loadUserData() {
    if (this.userData) {
      this.formClaimetDetails.patchValue({
        claimetLastname: this.userData.lastName,
        claimetName: this.userData.name,
        claimetCuil: this.userData.cuil,
        claimetEmail: this.userData.email,
        claimetPhone: this.userData.phone,
        claimetProvince: this.userData.province,
        claimetCity: this.userData.city,
        claimetNeighborhood:
          this.userData.neighborhood || this.claim?.claimetNeighborhood,
        claimetStreet: this.userData.street,
        claimetNumber: this.userData.number.toString(),
        claimetPostalCode: this.userData.postalCode,
        claimetFloorApartament: this.userData.floorApartment,
        claimetTower: this.userData.tower,
      });
    }
  }

  async loadDepartments() {
    const departments = await this.getDepartmentsUsecase.execute();
    if (Array.isArray(departments)) {
      this.departments = this.putIdToDepartmentsList(departments);
    }
  }

  onConsentChange(event: any): void {
    this.formClaimetDetails.controls['consent'].setValue(event.checked);
  }
  

  putIdToDepartmentsList(list: Departament[]): Departament[] {
    let listWithId: Departament[] = [];
    for (let i = 0; i < list.length; i++) {
      let department: Departament = {
        id: list[i].id,
        name: list[i].name,
      };
      listWithId.push(department);
    }
    return listWithId;
  }

  yearValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      if (value && (value.toString().length !== 4 || isNaN(value))) {
        return { invalidYear: true };
      }
      return null;
    };
  }

  onKeyDown(event: KeyboardEvent): void {
    const input = event.target as HTMLInputElement;
    if (
      event.key === 'Backspace' ||
      event.key === 'Delete' ||
      event.key === 'ArrowLeft' ||
      event.key === 'ArrowRight'
    ) {
      return;
    }

    if (
      input.value.length >= 4 &&
      input.selectionStart === input.selectionEnd
    ) {
      event.preventDefault();
    }

    if (isNaN(Number(event.key))) {
      event.preventDefault();
    }
  }

  private async initializeFormWithClaimData(): Promise<void> {
    if (!this.claim) return;
    if (this.isEditMode) {
      this.selectedDepartment = await this.laodDepartmentsByConciliador2(
        this.claim.department_id
      );
      this.formClaimetDetails.patchValue({
        claimetLastname: this.claim.claimetLastname || '',
        claimetName: this.claim.claimetName || '',
        claimetCuil: this.claim.claimetCuil || '',
        claimetEmail: this.claim.claimetEmail || '',
        claimetPhone: this.claim.claimetPhone || '',
        claimetProvince: this.claim.claimetProvince || '',
        claimetCity: this.claim.claimetCity || '',
        claimetNeighborhood: this.claim.claimetNeighborhood || '',
        claimetStreet: this.claim.claimetStreet || '',
        claimetNumber: this.claim.claimetNumber || '',
        claimetPostalCode: this.claim.claimetPostalCode || '',
        claimetFloorApartament: this.claim.claimetFloorApartament || '',
        claimetTower: this.claim.claimetTower || '',
        consent: this.claim.consent || false,
        claimetSelectedTypeOfJob: this.claim.claimetSelectedTypeOfJob || '',
        claimetWorkday: this.claim.claimetWorkday || '',
        claimetGrossRemuneration: this.claim.claimetGrossRemuneration || '',
        claimetTypeRemuneration: this.claim.claimetTypeRemuneration || '',
        claimetDateAdmission: this.claim.claimetDateAdmission || '',
        claimetEgressDate: this.claim.claimetEgressDate || '',
        claimetNumberAgreement: this.claim.claimetNumberAgreement || '',
        claimetYear: this.claim.claimetYear || '',
        claimetCategory: this.claim.claimetCategory || '',
        claimetCurrentEmploymentStatus:
          this.claim.claimetCurrentEmploymentStatus || '',
        claimetReasonForDisengagement:
          this.claim.claimetReasonForDisengagement || '',
        claimetCauseDisengagement: this.claim.claimetCauseDisengagement || '',
        claimetFileDni: this.claim.claimetFileDni || '',
        claimetFilePaycheck: this.claim.claimetFilePaycheck || '',
        department_id: this.claim.department_id || '',
      });
      this.formClaimetDetails
        .get('claimetDateAdmission')
        ?.setValue(
          this.parseDate(
            this.formClaimetDetails.get('claimetDateAdmission')?.value
          )
        );
      this.formClaimetDetails
        .get('claimetEgressDate')
        ?.setValue(
          this.parseDate(
            this.formClaimetDetails.get('claimetEgressDate')?.value
          )
        );

      await this.initializeFormWithSavedFiles();
    }
  }

  async getFileDni(param: GetFileModel): Promise<string> {
    let result = await this.getFileUseCase.execute(param);
    if (result instanceof Failure) {
      return result.message;
    }
    return result;
  }

  // async getFileBase(param: GetFileBaseModel): Promise<string> {
  //   let result = await this.getFileBaseUseCase.execute(param);
  //   if (result instanceof Failure) {
  //     return result.message;
  //   }
  //   return result;
  // }

  async laodDepartmentsByConciliador(id: string) {
    const departments = await this.getDepartmentByConciliador.execute(id);
    if (Array.isArray(departments)) {
      const result = departments.map((x) => x.id);
      return result;
    }
    return [];
  }

  async laodDepartmentsByConciliador2(
    id: string
  ): Promise<Departament | undefined> {
    const result = await this.getDepartmentsByIdUseCase.execute(id);

    if (!(result instanceof Failure)) {
      return result;
    }
    return undefined;
  }

  onDragOver(event: Event) {
    event.preventDefault();
    this.isDragging = true;
  }

  onDropSuccess(event: any, file: string): any {
    const files = event.dataTransfer?.files;
  
    if (!files || files.length === 0) return;
  
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
  
      if (!['image/jpeg', 'image/webp', 'image/heif', 'application/pdf'].includes(file.type)) {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Solo se permiten archivos JPG, WEBP, HEIF, PDF O PNG.',
        });
        return false;
      }
    }
  
    event.preventDefault();
    this.isDragging = false;
    if (file === 'dni') {
      this.onFileChangeDni(files);
    } else {
      this.onFileChange(files);
    }
  }
  
  onChange(event: any, file: string): any {
    const input = event.target as HTMLInputElement;
    const files = event.target.files;
    const fileForm = file;
  
    if (files.length > 0) {
      if (file === 'dni' && this.imageFiles.length + files.length > 2) {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Solo puedes subir hasta 2 archivos (JPG, WEBP, HEIF o PDF).',
        });
        return false;
      }
  
      if (file !== 'dni' && this.pdfFiles.length + files.length > 12) {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Solo puedes subir hasta 12 archivos (JPG, WEBP, HEIF o PDF).',
        });
        return false;
      }
  
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
  
        if (!['image/jpeg', 'image/webp', 'image/heif', 'application/pdf'].includes(file.type)) {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Solo se permiten archivos JPG, WEBP, HEIF o PDF.',
          });
          return false;
        }
  
        this.convertToBase64(file, fileForm);
      }
  
      input.value = '';
    }
  }
  
  
  private onFileChangeDni(files: any) {
    this.progress = 0;
      const newFiles = Array.from(files);
      this.imageFiles = (this.imageFiles || []).concat(newFiles).slice(-2);

  }
  private onFileChange(files: any) {
    this.progress = 0;
      const newFiles = Array.from(files);
      this.pdfFiles = (this.pdfFiles || []).concat(newFiles);
  }
  
  private CountValidatorPaycheck(control: AbstractControl): { [key: string]: any } | null {
    return control.value?.length >= 1 && control.value?.length <= 12
      ? null
      : { fileCount: true };
  }
    

  private CountValidatorDni(
    control: AbstractControl
  ): { [key: string]: any } | null {
    return control.value?.length >= 1 && control.value?.length <= 2
      ? null
      : { fileCountDni: true };
  }
  

  private assignFiles(file: any, base64String: string) {
    if (this.imageFiles.length >= 2) return; 
  
    this.imageFiles.push({ name: file.name, base64: base64String });
  
    const fileDniBase64Array = this.imageFiles.map((file) => file.base64);
    this.formClaimetDetails.get('claimetFileDni')?.setValue(fileDniBase64Array);
    this.formClaimetDetails.get('claimetFileDni')?.markAsTouched();
    this.formClaimetDetails.get('claimetFileDni')?.updateValueAndValidity();
  }
  
  

  private assignFilesReceipt(file: any, base64String: string) {
    if (this.pdfFiles.length >= 12) return;
  
    this.pdfFiles.push({ name: file.name, base64: base64String });
  
    const filePaycheckBase64Array = this.pdfFiles.map((file) => file.base64);
    this.formClaimetDetails.get('claimetFilePaycheck')?.setValue(filePaycheckBase64Array);
    this.formClaimetDetails.get('claimetFilePaycheck')?.markAsTouched();
    this.formClaimetDetails.get('claimetFilePaycheck')?.updateValueAndValidity();
  }
  

  private async convertToBase64(file: any, fileForm: string) {
    const reader = new FileReader();
    reader.onload = () => {
      const base64String = reader.result?.toString();
      if (base64String) {
        if (fileForm === 'dni') {
          this.assignFiles(file, base64String);
        } else {
          this.assignFilesReceipt(file, base64String);
        }
      }
    };
    reader.onerror = (error) => {
      console.error('FileReader error:', error);
    };
    reader.readAsDataURL(file);
  }
  

  deleteFileImage(index: number) {
    this.imageFiles.splice(index, 1);
    const fileDniBase64Array = this.imageFiles.map((image) => image.base64);
    this.formClaimetDetails.get('claimetFileDni')?.setValue(fileDniBase64Array.length ? fileDniBase64Array : null);
    this.formClaimetDetails.get('claimetFileDni')?.markAsTouched();
    this.formClaimetDetails.get('claimetFileDni')?.markAsDirty();
    this.checkFileImageCount();
  }
  
  deleteFilePfd(index: number) {
    this.pdfFiles.splice(index, 1);
    const fileReceiptBase64Array = this.imageFiles.map((file) => file.base64);
    this.formClaimetDetails.get('claimetFilePaycheck')?.setValue(fileReceiptBase64Array.length ? fileReceiptBase64Array : null);
    this.formClaimetDetails.get('claimetFilePaycheck')?.markAsTouched();
    this.formClaimetDetails.get('claimetFilePaycheck')?.markAsDirty();
  }

  private checkFileImageCount() {
    this.disableFileImageUpload = this.imageFiles.length >= 2;
  }


  hasErrors(controlName: string, errorType: string) {
    return (
      this.formClaimetDetails.get(controlName)?.hasError(errorType) &&
      this.formClaimetDetails.get(controlName)?.touched
    );
  }

  nextStep() {
    if (this.formClaimetDetails.invalid) return;
    this.claimantDetailsForm.emit(this.formClaimetDetails.value);
    this.stepIndex.emit(1);
  }

  cancel() {
    this.claimantDetailsForm.emit(this.formClaimetDetails.value);
    this.activeModal.emit(true);
  }

  onYearSelect(event: any): void {
    const selectedDate = new Date(event);
    const selectedYear = selectedDate.getFullYear().toString();
    this.formClaimetDetails.get('claimetYear')?.setValue(selectedYear);
  }

  async loadProvinces() {
    try {
      const result = await this.getProvince.execute();
      if (Array.isArray(result)) {
        this.provinces = result;
      }
    } catch (error) {
      console.error('Error fetching provinces', error);
    }
  }

  async loadLocalities(provinceId: string) {
    try {
      const localities = await this.getLocations.execute(provinceId);
      if (Array.isArray(localities)) {
        this.localities = this.putIdToLocalitiesList(localities);
      }
    } catch (error) {
      console.error('Error fetching localities', error);
    }
  }

  putIdToLocalitiesList(list: LocationEntity[]): Localities[] {
    let listWithId: Localities[] = [];
    for (let i = 0; i < list.length; i++) {
      let city: Localities = {
        id: i,
        name: list[i].name,
      };
      listWithId.push(city);
    }
    return listWithId;
  }

  minDateValidator(minDate: Date) {
    return (control: FormControl) => {
      const controlDate = new Date(control.value);
      if (control.value && controlDate < minDate) {
        return { minDate: true };
      }
      return null;
    };
  }

  private async initializeFormWithSavedFiles(): Promise<void> {
    try {
      const dniValues =
        this.formClaimetDetails.get('claimetFileDni')?.value || [];
      if (!this.claim?.claimetFileDni || dniValues.length === 0) {
        console.warn('No hay archivos DNI para procesar.');
        return;
      }

      const dniFilesPromises = dniValues.map((_: any, i: number) => {
        const dniFile = new GetFileModel(
          this.claim?.id!,
          this.claim?.claimetFileDni[i]!
        );
        return this.getFile(dniFile)
          .then((bas64) => {
            if (bas64) {
              return {
                name: dniFile.fileName,
                base64: 'data:image/png;base64,' + bas64,
              };
            } else {
              console.error('Archivo DNI vacío o inválido.');
              return null;
            }
          })
          .catch((error) => {
            console.error('Error al obtener archivo DNI:', error);
            return null;
          });
      });

      const resolvedDniFiles = (await Promise.all(dniFilesPromises)).filter(
        (file) => file !== null
      );
      this.imageFiles = resolvedDniFiles;

      if (this.claim?.claimetFilePaycheck?.length) {
        try {
          this.pdfFiles = []; 

          let i = 0;
          for (const fileName of this.claim.claimetFilePaycheck) {
            const paycheckFile = new GetFileModel(this.claim.id!, fileName);
            const base64 = await this.getFile(paycheckFile);
          
            if (base64) {
              let base64Objet: Base64 = {
                name: 'Recibo de sueldo ' + (i + 1), 
                base64: 'data:application/pdf;base64,' + base64,
              };
              
              this.pdfFiles.push(base64Objet);
              i++;
            } else {
              console.error(`Archivo ${fileName} vacío o inválido.`);
            }
          }
          
      
          if (this.pdfFiles.length === 0) {
            console.warn('Ningún archivo válido fue procesado.');
          }
        } catch (error) {
          console.error('Error al obtener archivos de recibo de sueldo:', error);
          this.pdfFiles = [];
        }
      } else {
        console.warn('No se encontraron archivos de recibo de sueldo para procesar.');
      }
      

      this.formClaimetDetails.get('claimetFileDni')?.setValue(this.imageFiles);
      this.formClaimetDetails
        .get('claimetFilePaycheck')
        ?.setValue(this.pdfFiles);
    } catch (error) {
      console.error(
        'Error general al inicializar archivos en el formulario:',
        error
      );
    }
  }

  async getFile(param: GetFileModel): Promise<string> {
    let result = await this.getFileUseCase.execute(param);
    if (result instanceof Failure) {
      return result.message;
    }
    return result;
  }

  parseDate(date: string): Date {
    if (!date) {
      return new Date(1930, 0, 1);
    }
    let part = date.split(/\/| |:/);
    return new Date(
      parseInt(part[2]),
      parseInt(part[0]) - 1,
      parseInt(part[1]),
      parseInt(part[3]),
      parseInt(part[4]),
      parseInt(part[5])
    );
  }

  showError() {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: 'Message Content',
    });
  }
}
