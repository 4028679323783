import { UseCase } from "src/app/core/utils/usecase";
import { Failure } from "src/app/core/utils/failure";
import { Injectable } from "@angular/core";
import { ClaimColectiveRepositoryBase } from "../repositories/cliam-colective-base.repository";
import { ClaimCollectiveModel } from "../../data/models/claim-colective.model";

@Injectable()
export class UpdateClaimColectiveUseCase implements UseCase<ClaimCollectiveModel, string | Failure> {
    constructor(private claimRepository: ClaimColectiveRepositoryBase) { }

    async execute(param: ClaimCollectiveModel): Promise<string | Failure> {
        return await this.claimRepository.updateClaim(param);
    }
}