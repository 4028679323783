<div class="form-container">
  <form
    [formGroup]="registerFormRol"
    (ngSubmit)="nextStep()"
    id="container-form"
  >
    <div class="grid">
      <div class="col-12">
        <h2 class="mb-4 text-start">Crear cuenta</h2>
      </div>

      <div class="col-12 md:col-6 pt-0">
        <div class="flex flex-column gap-2">
          <span>Seleccione un rol</span>
          <p-selectButton
            id="rolButton"
            [options]="rolOptions"
            formControlName="rol"
            [multiple]="false"
            optionLabel="name"
            optionValue="rol"
          ></p-selectButton>
        </div>
      </div>

      <div class="col-12"></div>

      <div class="col-12 md:col-6 pt-0">
        <p-button
          class="nextButton"
          label="Siguiente"
          [disabled]="registerFormRol.invalid"
          type="submit"
        ></p-button>
        <span
          >¿Ya tienes una cuenta?
          <a (click)="goToLogin()" class="ml-2">Ingresar</a></span
        >
      </div>
    </div>
  </form>
</div>
