import { Component, EventEmitter, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Toast } from 'primeng/toast';
import { LocationEntity } from 'src/app/core/services/location/domain/entities/location.entity';
import { ProvinceEntity } from 'src/app/core/services/location/domain/entities/province.entity';
import { GetDepartmentsUseCase } from 'src/app/core/services/location/domain/usecases/get-departments.usecase';
import { GetLocationsUseCase } from 'src/app/core/services/location/domain/usecases/get-locations.usecase';
import { GetProvinceUseCase } from 'src/app/core/services/location/domain/usecases/get-province.usecase';
import { LocalitiesEntity } from 'src/app/features/claims/data/models/location.model';
import { Departament } from 'src/app/features/user/presentation/components/New-User-SuperAdmin/newUser.component';

@Component({
  selector: 'app-claimed-details-multi',
  templateUrl: './claimed-details-multi.component.html',
  styleUrls: ['./claimed-details-multi.component.css'],
  providers: [MessageService, Toast, ConfirmationService],
})
export class ClaimedDetailsMultiComponent {
  @Output() claimantDetailsForm = new EventEmitter<FormGroup>();
  @Output() stepIndex: EventEmitter<number> = new EventEmitter();
  @Output() activeModal: EventEmitter<boolean> = new EventEmitter();

  formWorker!: FormGroup;
  isDragging: boolean = false;
  imageFiles: any[] = [];
  disableFileImageUpload: boolean = false;

  workers: Worker[] = [];

  provinces: ProvinceEntity[] = [];
  localities: LocalitiesEntity[] = [];
  departments: Departament[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private getProvince: GetProvinceUseCase,
    private getDepartmentsUsecase: GetDepartmentsUseCase,
    private getLocations: GetLocationsUseCase,
    private confirmationService: ConfirmationService
  ) {}

  async ngOnInit(): Promise<void> {
    this.initForms();
    await this.initializeComponent();
  }

  private initForms(): void {
    this.formWorker = this.formBuilder.group({
      nameClaimed: ['', Validators.required],
      claimedCuil: ['', Validators.required],
      claimedStreet: ['', Validators.required],
      claimedNumber: ['', Validators.required],
      claimedFloorApartment: [''],
      claimedTower: [''],
      claimedPostalCode: ['', Validators.required],
      claimedProvince: ['', Validators.required],
      claimedCity: ['', Validators.required],
      claimedDni: ['', Validators.required],
    });
  }

  async initializeComponent(): Promise<void> {
    this.loadProvinces();
    this.loadDepartments();
    this.formWorker
      .get('claimedProvince')
      ?.valueChanges.subscribe((selectedProvince: any) => {
        this.loadLocalities(selectedProvince);
        this.formWorker.patchValue(
          { provincia: selectedProvince },
          { emitEvent: false }
        );
      });
  }

  async loadProvinces() {
    try {
      const result = await this.getProvince.execute();
      if (Array.isArray(result)) {
        this.provinces = result;
      }
    } catch (error) {
      console.error('Error fetching provinces', error);
    }
  }

  async loadLocalities(provinceId: string) {
    if (!provinceId) return;
    try {
      const localities = await this.getLocations.execute(provinceId);
      if (Array.isArray(localities)) {
        const localitiesList = this.putIdToLocalitiesList(localities);

        this.localities = localitiesList;
      }
    } catch (error) {
      console.error('Error fetching localities', error);
    }
  }

  putIdToLocalitiesList(list: LocationEntity[]): LocalitiesEntity[] {
    let listWithId: LocalitiesEntity[] = [];
    for (let i = 0; i < list.length; i++) {
      let city: LocalitiesEntity = {
        id: i,
        name: list[i].name,
      };
      listWithId.push(city);
    }
    return listWithId;
  }

  async loadDepartments() {
    const departments = await this.getDepartmentsUsecase.execute();
    if (Array.isArray(departments)) {
      this.departments = this.putIdToDepartmentsList(departments);
    }
  }

  putIdToDepartmentsList(list: Departament[]): Departament[] {
    let listWithId: Departament[] = [];
    for (let i = 0; i < list.length; i++) {
      let department: Departament = {
        id: list[i].id,
        name: list[i].name,
      };
      listWithId.push(department);
    }
    return listWithId;
  }

  hasErrors(controlName: string, errorType: string) {
    return (
      this.formWorker.get(controlName)?.hasError(errorType) &&
      this.formWorker.get(controlName)?.touched
    );
  }

  addWorker(): void {
      const newWorker: Worker = this.formWorker.value;
      this.workers.push(newWorker);
      this.formWorker.reset();
      this.imageFiles = [];

      this.messageService.add({
        severity: 'success',
        summary: 'Éxito',
        detail: 'Trabajador agregado correctamente',
      });
  }

  getProvinceName(provinceId: string): string {
    const province = this.provinces.find((p) => p.id === provinceId);
    return province ? province.name : provinceId;
  }

  removeWorker(worker: Worker): void {
    this.confirmationService.confirm({
      header: 'Eliminar trabajador',
      message: '¿Estás seguro de que deseas eliminar este trabajaor?',
      accept: async () => {
        this.workers = this.workers.filter((w) => w !== worker);
        this.messageService.add({
          severity: 'success',
          summary: 'Eliminado',
          detail: 'Trabajador eliminado de la lista',
        });
      },
    });
  }

  onDragOver(event: Event) {
    event.preventDefault();
    this.isDragging = true;
  }

  onDropSuccess(event: any, file: string): any {
    const files = event.dataTransfer?.files;

    if (!files || files.length === 0) return;

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      if (
        ![
          'image/jpeg',
          'image/png',
          'image/webp',
          'image/heif',
          'application/pdf',
        ].includes(file.type)
      ) {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Solo se permiten archivos JPG, WEBP, HEIF, PDF O PNG.',
        });
        return false;
      }
    }

    event.preventDefault();
    this.isDragging = false;
    this.onFileChangeDni(files);
  }

  onChange(event: any, file: string): any {
    const input = event.target as HTMLInputElement;
    const files = event.target.files;
    const fileForm = file;

    if (files.length > 0) {
      if (file === 'dni' && this.imageFiles.length + files.length > 2) {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Solo puedes subir hasta 2 archivos (JPG, WEBP, HEIF o PDF).',
        });
        return false;
      }

      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        if (
          ![
            'image/jpeg',
            'image/png',
            'image/webp',
            'image/heif',
            'application/pdf',
          ].includes(file.type)
        ) {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Solo se permiten archivos JPG, WEBP, HEIF o PDF.',
          });
          return false;
        }

        this.convertToBase64(file, fileForm);
      }

      input.value = '';
    }
  }

  private onFileChangeDni(files: any) {
    const newFiles = Array.from(files);
    this.imageFiles = (this.imageFiles || []).concat(newFiles).slice(-2);
  }

  private async convertToBase64(file: any, fileForm: string) {
    const reader = new FileReader();
    reader.onload = () => {
      const base64String = reader.result?.toString();
      if (base64String) {
        this.assignFiles(file, base64String);
      }
    };
    reader.onerror = (error) => {
      console.error('FileReader error:', error);
    };
    reader.readAsDataURL(file);
  }

  deleteFileImage(index: number) {
    this.imageFiles.splice(index, 1);
    const fileDniBase64Array = this.imageFiles.map((image) => image.base64);
    this.formWorker
      .get('claimedDni')
      ?.setValue(fileDniBase64Array.length ? fileDniBase64Array : null);
    this.formWorker.get('claimedDni')?.markAsTouched();
    this.formWorker.get('claimedDni')?.markAsDirty();
    this.checkFileImageCount();
  }

  private assignFiles(file: any, base64String: string) {
    if (this.imageFiles.length >= 2) return;

    this.imageFiles.push({ name: file.name, base64: base64String });

    const fileDniBase64Array = this.imageFiles.map((file) => file.base64);
    this.formWorker.get('claimedDni')?.setValue(fileDniBase64Array);
    this.formWorker.get('claimedDni')?.markAsTouched();
    this.formWorker.get('claimedDni')?.updateValueAndValidity();
  }

  private checkFileImageCount() {
    this.disableFileImageUpload = this.imageFiles.length >= 2;
  }

  onSubmit() {
    const formGroup = new FormGroup({
      employees: new FormControl(this.workers),
    });

    this.claimantDetailsForm.emit(formGroup);
    this.stepIndex.emit(2);
  }

  back() {
    this.stepIndex.emit(0);
  }

  cancel() {
    const formGroup = new FormGroup({
      employees: new FormControl(this.workers),
    });

    this.claimantDetailsForm.emit(formGroup);
    this.activeModal.emit(true);
  }
}
