import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function OfLegalAgeValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null; 
    }

    const birthDate = new Date(control.value);
    const today = new Date();
    const age = today.getFullYear() - birthDate.getFullYear();

    if (age < 18 || (age === 18 && today < new Date(birthDate.setFullYear(today.getFullYear())))) {
      return { underage: true };
    }

    return null;
  };
}
