import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from "@angular/common/http";
import { HttpServiceBase } from "./http.service";
import { Injectable } from "@angular/core";
import { ApiResponse } from "../utils/api.response";
import { DataConstraints } from "./data-constraints";
import { firstValueFrom } from "rxjs";
import { Entity } from "../utils/entity";

@Injectable()
export class HttpService extends HttpServiceBase {
    constructor(private httpCLient: HttpClient){
      super();
    }
  
    override get(url: string, headers?: HttpHeaders): Promise<Map<string, any>> {
      return new Promise((resolve, reject) => {
        this.httpCLient.get<Map<string, any>>(url, {headers: headers}).subscribe({
          next: (response: ApiResponse) => {
            const map = new Map<string, any>(Object.entries(response));
            resolve(map);
          },
          error: (error: HttpErrorResponse) => {
            reject(error);
          },
        });
      });
    }

    override getAll(url: string):Promise<Map<string, any>[]> {
      return new Promise((resolve, reject) => {
        this.httpCLient.get<ApiResponse[]>(url).subscribe({
          next: (response: ApiResponse[]) => {
            const maps: Map<string, any>[] = [];
            for (const json of response) {
              let map = new Map<string,any>(Object.entries(json));
              maps.push(map);
            }
            resolve(maps);
          },
          error: (error: HttpErrorResponse) => {
            reject(error);
          },
        });
      });
    }

    override async post(url: string, data: DataConstraints, headers?: HttpHeaders | undefined): Promise<Map<string, any>> {          
      let body = this.getBody(data);
      let result = await firstValueFrom(this.httpCLient.post<ApiResponse>(url, body, { headers: headers }));
      let map = new Map<string, any>(Object.entries(result));
      
      return map;
    }

    override async put(url: string, data: Entity, headers?: HttpHeaders | undefined): Promise<Map<string, any>> {
      let body = this.getJson(data.toJson());        
      let result = await firstValueFrom(this.httpCLient.put<ApiResponse>(url, body, { headers: headers }));
      let map = new Map<string, any>(Object.entries(result));
      return map;
    }

    override async delete(url: string, data?: Entity, headers?: HttpHeaders | undefined): Promise<Map<string, any>> {
      let body = data ? this.getBody(data) : undefined;
      let result = await firstValueFrom(this.httpCLient.delete<ApiResponse>(url, { headers: headers, body: body }));
      let map = new Map<string, any>(Object.entries(result));
      return map;
    }  

    private getBody(data: DataConstraints): any { 
      if (data instanceof HttpParams) return data;
      if (data instanceof Array) { 
        return data.map(map =>  this.getJson(map.toJson()));
      };
      return this.getJson(data.toJson());
    }
  
    private getJson(map: Map<string, any>): any {
      const json: { [key: string]: any } = {};
      map.forEach((value, key) => {
        json[key] = this.getValueByObject(value, key);
      });
      return json;
    }  

    private getValueByObject(value:any, key:string): any { 
      if (value instanceof Map) {
        return this.getJson(value);
      }
      if (value instanceof Array) { 
        return  this.getArrayJson(value);
      } 
      return value;
    }  

    private getArrayJson(values:any[]): any { 
      let arrayValues = [];
      for (const object of values) {
        if (object instanceof Map) {
          arrayValues.push(this.getJson(object));
        } else if (typeof object == 'string') {
          arrayValues.push(object);
        } else { 
          arrayValues.push(this.getArrayJson(object));
        }
      }
      return arrayValues;
    }

    override async postWihtFormData(url: string, data: FormData, headers?: HttpHeaders | undefined): Promise<Map<string, any>> {
      let result = await firstValueFrom(this.httpCLient.post<ApiResponse>(url, data, { headers: headers }));
      let map = new Map<string, any>(Object.entries(result));      
      return map;
    }
  }