import { Failure, UnhandledFailure } from 'src/app/core/utils/failure';
import { Injectable } from '@angular/core';
import { ClaimCollectiveEntity } from '../../domain/entities/claim-colective.entity';
import { ClaimColectiveRemoteDataSource } from '../data-source/cliam-colective-remote.datasource';
import { ClaimColectiveRepositoryBase } from '../../domain/repositories/cliam-colective-base.repository';
import { ClaimsPaginationParams } from '../../domain/entities/claim-pagination-params.entity';
import { ClaimPagination } from '../../domain/entities/claim-pagination.entity';
import { ColectivePagination } from '../../domain/entities/colective-pagination.entity';
import { UpdateClaimStatusEntity } from '../../domain/entities/update-claim-satus.entity';
import { UpdateClaimConciliatorEntity } from '../../domain/entities/update-claim-conciliator.entity';
import { GetFileEntity } from '../../domain/entities/get-file.entity';
import { DeleteClaimEntity } from '../../domain/entities/delete-claim.entity';

@Injectable()
export class ClaimColectiveRepository extends ClaimColectiveRepositoryBase {
  constructor(private claimRemote: ClaimColectiveRemoteDataSource) {
    super();
  }

  override async getClaim(
    id: string
  ): Promise<ClaimCollectiveEntity | Failure> {
    try {
      return await this.claimRemote.getClaim(id);
    } catch (error: any) {
      return new UnhandledFailure(error.message, error.code);
    }
  }

  override async createClaim(
    claim: ClaimCollectiveEntity
  ): Promise<string | Failure> {
    try {
      return await this.claimRemote.createClaim(claim);
    } catch (error: any) {
      return new UnhandledFailure(error.message, error.code);
    }
  }

  override async updateClaim(
    claim: ClaimCollectiveEntity
  ): Promise<string | Failure> {
    try {
      return await this.claimRemote.updateClaim(claim);
    } catch (error: any) {
      return new UnhandledFailure(error.message, error.code);
    }
  }
  override async getColectiveClaims(
    params: ClaimsPaginationParams
  ): Promise<ColectivePagination | Failure> {
    try {
      return await this.claimRemote.getColectiveClaims(params);
    } catch (error: any) {
      return new UnhandledFailure(error.message, error.code);
    }
  }

  override async updateClaimStatus(
    claim: UpdateClaimStatusEntity
  ): Promise<string | Failure> {
    try {
      return await this.claimRemote.updateClaimStatus(claim);
    } catch (error: any) {
      return new UnhandledFailure(error.message, error.code);
    }
  }

  override async updateClaimConciliator(
    claim: UpdateClaimConciliatorEntity
  ): Promise<string | Failure> {
    try {
      return await this.claimRemote.updateClaimConciliator(claim);
    } catch (error: any) {
      return new UnhandledFailure(error.message, error.code);
    }
  }

  override async getFile(params: GetFileEntity): Promise<string | Failure> {
    try {
      return await this.claimRemote.getFile(params);
    } catch (error: any) {
      return new UnhandledFailure(error.message, error.code);
    }
  }

  override async deleteClaim(
    deleteClaim: DeleteClaimEntity
  ): Promise<string | Failure> {
    try {
      return await this.claimRemote.deleteClaim(deleteClaim);
    } catch (error: any) {
      return new UnhandledFailure(error.message, error.code);
    }
  }
}
