import { MinutePdf } from "../../domain/entities/minutePdf.entity";


export class MinutePdfModel extends MinutePdf {
    override toJson(): Map<string, any> {
        let json: Map<string, any> = new Map();
        json.set('file_Acta', this.file_Acta);
        json.set('claim_id', this.claim_id);
        json.set('date', this.date);
        return json;
    }
}