import { Component, Input, OnInit } from '@angular/core';
import { UserRol } from 'src/app/core/enums/user-rol.enum';
import { LocalStorageServiceBase } from 'src/app/core/services/localStorage-serviceBase';
import AESEncryptDecryptService from 'src/app/core/utils/crypto';
import { Failure } from 'src/app/core/utils/failure';
import { UserData } from 'src/app/features/user/domain/entities/user-data.entity';
import { UserService } from 'src/app/features/user/services/user.service';

@Component({
  selector: 'app-SuperAdmin-home',
  templateUrl: './SuperAdmin-home.component.html',
  styleUrls: ['./SuperAdmin-home.component.css']
})
export class SuperAdminHomeComponent implements OnInit {
  @Input() userData?: UserData;
  private AES = new AESEncryptDecryptService();
  rol: string = this.AES.decrypt(this.localStorageService.loadStorageData('user_rol'));
  userId: string = this.AES.decrypt(this.localStorageService.loadStorageData('user_id'));

  isComisionMemberRol: boolean = false;
  isAdministratorRol: boolean = false;
  isConciliatorRol: boolean = false;
  isComisionRol: boolean = false;
  isSuperAdminRol: boolean = false;

  constructor(
    private userService: UserService,
    private localStorageService: LocalStorageServiceBase,
  ) { }

  ngOnInit(): void {
    this.setRol();

    if (this.isSuperAdminRol ) {
    } else {
    }
  }

  setRol(){
    switch (this.rol) {
      case UserRol.SUPER_ADMIN:
        return this.isSuperAdminRol = true;
      default:
        return;
    }
  }

  getUserData(): void {
    this.userService.userData$.subscribe(userData => {
      if (!userData) return;
      this.userData = userData;
      this.rol = this.userData?.rol;
    });
  }

}
