import { UseCase } from "src/app/core/utils/usecase";
import { UserData } from "../entities/user-data.entity";
import { Failure } from "src/app/core/utils/failure";
import { UserRepositoryBase } from "../repositories/user-base.repository";
import { Injectable } from "@angular/core";

@Injectable()
export class GetMyDataUseCase implements UseCase<undefined, UserData | Failure> {

    constructor(private userRepository: UserRepositoryBase) {}

    async execute(param?: undefined): Promise<UserData | Failure> {
        return await this.userRepository.getMyData();
    }
}