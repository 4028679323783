import { Injectable } from '@angular/core';
import { MinuteRepositoryBase } from '../../domain/repositories/minute-base.repository';
import { MinuteEntity } from '../../domain/entities/minute.entity';
import { Failure, UnhandledFailure } from 'src/app/core/utils/failure';
import { MinuteRemoteDataSourceBase } from '../data-source/minute-remote.datasource';
import { MinutePdf } from '../../domain/entities/minutePdf.entity';

@Injectable()
export class MinuteRepository extends MinuteRepositoryBase {
  constructor(private minuteRemote: MinuteRemoteDataSourceBase) {
    super();
  }

  override async createMinute(minute: MinutePdf): Promise<string | Failure> {
    try {
      return await this.minuteRemote.createMinute(minute);
    } catch (error: any) {
      if (error.status === 0 || error.status !== 500) {
        return new UnhandledFailure(
          'Error en la carga. Limite permitido 30MB.',
          error.code
        );
      }
      return new UnhandledFailure(error.error?.message, error.status);
    }
  }

  override async getMinutesByClaimId(
    id: string
  ): Promise<Failure | MinuteEntity[]> {
    try {
      return await this.minuteRemote.getMinutesByClaimId(id);
    } catch (error: any) {
      return new UnhandledFailure(error.error.message, error.code);
    }
  }
}
