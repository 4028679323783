import { Injectable } from "@angular/core";
import { environment } from 'src/environments/environment.dev';
import { Credentials } from '../../domain/entities/credentials.entity';
import { Session } from '../../domain/entities/session.entity';
import { User } from "../../domain/entities/user.entity";
import { UserModel } from "../models/user.model";
import { SessionModel } from '../models/session.model';
import { HttpService } from 'src/app/core/services/http.implementation';
import { CredentialsModel } from '../models/credentials.model';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { HttpServiceBase } from 'src/app/core/services/http.service';
import { RegisterUserResponse } from "../../domain/entities/register-user-response.entity";
import { RegisterUserResponseModel } from "../models/register-user-response.model";
import { Failure } from "src/app/core/utils/failure";
import { LocalStorageServiceBase } from "src/app/core/services/localStorage-serviceBase";
import { ResetPasswordEntity } from "../../domain/entities/reset-password.entity";
import { ForgotPasswordSendEmailEntity } from "../../domain/entities/forgot-password-send-email.entity";
import { FrogotPasswordGenerateNewEntity } from "../../domain/entities/forgot-password-generate-new.entity";

export abstract class AuthRemoteDataSourceBase {
  abstract login(credential: Credentials): Promise<Session>;
  abstract register(user: User): Promise<Map<string, any>>;
  abstract resetPassword(resetPassword: ResetPasswordEntity): Promise<string>;
  abstract forgotPasswordSendEmail(email: ForgotPasswordSendEmailEntity): Promise<string | Failure>;
  abstract forgotPasswordGenerateNew(password: FrogotPasswordGenerateNewEntity): Promise<string | Failure>;
}

@Injectable()
export class AuthRemoteDataSource extends AuthRemoteDataSourceBase {
  private apiUrl: string = environment.apiUrl
  private url: string = environment.kcUrl;
  private clientId = environment.client_id;
  private clientSecret = environment.client_secret;
  private grantType = environment.grant_type;

  constructor(private http: HttpServiceBase, private localStorageService: LocalStorageServiceBase) {
    super();
  }

  override async login(credentials: Credentials): Promise<Session> {
    const bodyParams = new HttpParams()
      .set('client_id', this.clientId)
      .set('client_secret', this.clientSecret)
      .set('grant_type', this.grantType!)
      .set('username', credentials.email!)
      .set('password', credentials.password!);

    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/x-www-form-urlencoded',
    );

    let result = await this.http.post(`${this.url}/token`, bodyParams, headers);
    return SessionModel.fromJson(result);
  }

  override async register(user: User): Promise<Map<string, any>> {
    let result = await this.http.post(`${this.apiUrl}/User`, user);
    return result;
  }

  override async resetPassword(resetPassword: ResetPasswordEntity): Promise<string> {
    let headers = new HttpHeaders().set('Authorization', `Bearer ${this.localStorageService.read('access_token')}`);
    let result = await this.http.put(`${this.apiUrl}/User/update_password`, resetPassword, headers);
    return result.get('respose');
  }

  override async forgotPasswordSendEmail(email: ForgotPasswordSendEmailEntity): Promise<string | Failure> {
    let result = await this.http.get(`${this.apiUrl}/User/notification_for_resetting_password?email=${email.getPath()}`);
    return result.get('respose');
  }

  override async forgotPasswordGenerateNew(password: FrogotPasswordGenerateNewEntity): Promise<string | Failure> {
    let result = await this.http.put(`${this.apiUrl}/User/reset_password`, password);
    return result.get('response');
  }
}

