import { Pipe, PipeTransform } from '@angular/core';

const statusDisplayNames: { [key: string]: string } = {
  Borrador: 'Borrador',
  Pendiente: 'Pendiente',
  Rechazado: 'Rechazado',
  Finalizado: 'Finalizado',
  EnTramiteInterno: 'En trámite interno',
  AudienciaFijada: 'Audiencia fijada',
  Admitido: 'Admitido',
};

@Pipe({
  name: 'claimStatusDisplay'
})
export class ClaimStatusDisplayPipe implements PipeTransform {

  transform(value: string ): string {  
    return statusDisplayNames[value] || value;
  }
}
