<div class="">
  <p-toast />
  <p-confirmDialog #cd>
    <ng-template pTemplate="headless" let-message>
      <div
        class="flex flex-column align-items-center justify-content-center p-5 surface-overlay border-round"
      >
        <span class="col-12 font-semibold text-xl">
          {{ message.header }}
        </span>
        <div
          class="col-12 flex align-items-center justify-content-center gap-3"
        >
          <i class="pi pi-exclamation-circle text-lg"></i>
          <p class="">{{ message.message }}</p>
        </div>
        <div class="col-12 flex gap-3 justify-content-end">
          <button
            pButton
            label="Cancelar"
            (click)="cd.reject()"
            class="btn-cancelar"
            style="
              border: 1px solid #263245;
              background-color: white;
              color: #263245;
              font-size: 0.8rem;
            "
          ></button>
          <button
            pButton
            label="Eliminar empresa"
            (click)="cd.accept()"
            class="btn-siguiente"
            style="
              background-color: #c93b3b;
              border-color: #c93b3b;
              font-size: 0.8rem;
            "
          ></button>
        </div>
      </div>
    </ng-template>
  </p-confirmDialog>
</div>

<div *ngIf="!companyList" class="col-12">
  <ng-container class="empty-home-container">
    <div class="flex flex-column align-items-center">
      <img
        id="image"
        src="../../../../../../assets/img/main/file-searching.svg"
        alt=""
      />
      <p>Aún no se han añadido empresas. Agregue una a continuación.</p>
      <p-button
        class="btn-new-claim"
        label="Nueva empresa"
        icon="pi pi-plus"
        routerLink="/main/newCompany"
      ></p-button>
    </div>
  </ng-container>
</div>

<section class="m-1 pb-2 sm:mx-6 sm:my-3">
  <div *ngIf="companyList" class="flex flex-column w-full h-full gap-3 pt-4">
    <div class="nuevoReclamo">
      <div *ngIf="!homeView" class="flex flex-column p-3">
        <p class="font-semibold text-xl title">Empresas</p>
        <div
          class="flex grid w-full align-items-center justify-content-between"
        >
          <div class="busqueda flex col-4">
            <span class="p-input-icon-left">
              <i class="pi pi-search"></i>
              <input
                type="text"
                pInputText
                placeholder="Buscar"
                class="inputBuscador"
                [(ngModel)]="searchTerm"
                (ngModelChange)="onSearchChange()"
              />
            </span>
          </div>
          <div class="col-3 text-right">
            <p-button
              class="btn-new-claim"
              label="Nueva empresa"
              icon="pi pi-plus"
              routerLink="/main/newCompany"
            ></p-button>
          </div>
        </div>
      </div>

      <div class="grid w-full relative p-3">
        <p-table
          [tableStyle]="{ 'min-width': '100%' }"
          class="col-12"
          [rowHover]="true"
          *ngIf="skeleton"
          [value]="skeletons"
        >
          <ng-template pTemplate="header">
            <tr class="font-semibold text-xs">
              <th class="text-left">Fecha</th>
              <th class="text-left">Hora</th>
              <th *ngIf="!homeView" class="text-left">Título de audiencia</th>
              <th *ngIf="!homeView" class="text-left">Conciliador</th>
              <th class="text-center">N° de reclamo</th>
              <th *ngIf="!homeView" class="text-center">N° de expediente</th>
              <th class="text-left">Reclamante</th>
              <th class="text-left">Reclamado</th>
              <th></th>
              <th></th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-skeleton>
            <tr>
              <td class="text-center"><p-skeleton></p-skeleton></td>
              <td class="text-center"><p-skeleton></p-skeleton></td>
              <td class="text-center"><p-skeleton></p-skeleton></td>
              <td class="text-center"><p-skeleton></p-skeleton></td>
              <td class="text-center"><p-skeleton></p-skeleton></td>
              <td class="text-center"><p-skeleton></p-skeleton></td>
              <td class="text-center"><p-skeleton></p-skeleton></td>
              <td class="text-center" *ngIf="!homeView">
                <p-skeleton></p-skeleton>
              </td>
              <td class="text-center" *ngIf="!homeView">
                <p-skeleton></p-skeleton>
              </td>
              <td class="text-center" *ngIf="!homeView">
                <p-skeleton></p-skeleton>
              </td>
            </tr>
          </ng-template>
        </p-table>

        <p-table
          *ngIf="!skeleton"
          [value]="companyList"
          (onLazyLoad)="loadRecords($event)"
          [totalRecords]="totalQuantity"
          [tableStyle]="{ 'min-width': '100%' }"
          [rows]="itemsPerPage"
          [showCurrentPageReport]="true"
          currentPageReportTemplate="{first}-{last} de {totalRecords} resultados"
          [rowsPerPageOptions]="[5, 10, 25, 50]"
        >
          <ng-template pTemplate="header">
            <tr class="font-semibold text-xs">
              <th>Razon Social</th>
              <th>CUIT</th>
              <th>Correo electónico</th>
              <th>Localidad</th>
              <th>Teléfono</th>
              <th></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-company>
            <tr class="table-row">
              <td>
                {{ company.razon_social }}
              </td>
              <td>{{ company.cuit }}</td>
              <td>{{ company.email }}</td>
              <td>{{ company.city }}</td>
              <td>{{ company.phone }}</td>
              <td class="text-right">
                <p-menu
                  #menu
                  [popup]="true"
                  [model]="items"
                  appendTo="body"
                ></p-menu>
                <button
                  type="button"
                  pButton
                  icon="pi pi-ellipsis-v"
                  (click)="menu.toggle($event); selectcompany(company.id)"
                  class="p-button-rounded p-button-text"
                ></button>
              </td>
            </tr>
          </ng-template>
        </p-table>
        <p-paginator
          class="col-12"
          *ngIf="!homeView"
          (onPageChange)="changePage($event)"
          [first]="first"
          [rows]="defaultRows"
          [totalRecords]="totalQuantity"
          [rowsPerPageOptions]="[5, 10, 25, 50]"
        >
        </p-paginator>
      </div>
    </div>
  </div>
</section>
