import { UseCase } from 'src/app/core/utils/usecase';
import { UpdateClaimStatusEntity } from '../entities/update-claim-satus.entity';
import { Failure } from 'src/app/core/utils/failure';
import { Injectable } from '@angular/core';
import { ClaimColectiveRepositoryBase } from '../repositories/cliam-colective-base.repository';

@Injectable()
export class UpdateCollectiveClaimStatusUseCase
  implements UseCase<UpdateClaimStatusEntity, string | Failure>
{
  constructor(private claimRepository: ClaimColectiveRepositoryBase) {}

  async execute(param: UpdateClaimStatusEntity): Promise<string | Failure> {
    return await this.claimRepository.updateClaimStatus(param);
  }
}
