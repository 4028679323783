import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { UserService } from 'src/app/features/user/services/user.service';
import { Failure } from 'src/app/core/utils/failure';
import { UserData } from 'src/app/features/user/domain/entities/user-data.entity';
import { TableLazyLoadEvent } from 'primeng/table';
import { Router } from '@angular/router';
import { Toast } from 'primeng/toast';
import AESEncryptDecryptService from 'src/app/core/utils/crypto';
import { LocalStorageServiceBase } from 'src/app/core/services/localStorage-serviceBase';
import { UserPagination } from '../../../domain/entities/user-pagination.entity';
import { UserPaginationParamsModel } from '../../../data/models/user-pagination-params.model';
import { GetPaginatedAllUserUseCase } from '../../../domain/usecases/get-pagination-all-user.usecase';
import { DeleteUserModel } from '../../../data/models/delete-user.model';
import { DeleteUserUseCase } from '../../../domain/usecases/delete-user.usecase';
import { DeleteUserEntity } from '../../../domain/entities/delete-user.entity';
import { UserUseCase } from '../../../domain/usecases/get-user.usecase';

@Component({
  selector: 'app-users-table',
  templateUrl: './users-table.component.html',
  styleUrls: ['./users-table.component.css'],
  providers: [MessageService, Toast, ConfirmationService]
})
export class UsersTableComponent implements OnInit {
  @Input() users?: UserPagination;
  @Input() userData?: UserData;
  @Output() activeUsers: EventEmitter<boolean> = new EventEmitter();

  defaultRows: number = 5;
  defaultPage: number = 0;
  public totalQuantity: number = 0;
  public first: number = 0;
  public itemsPerPage: number = 5;
  public amountRegistersPage = this.itemsPerPage;

  usersList: UserData[] = [];
  paginatedData: UserPagination | undefined = undefined;
  selectedUserId?: string;
  isLoading: boolean = true;
  private AES = new AESEncryptDecryptService();
  homeView: boolean = false;
  userId?: string;

  public items: MenuItem[] = [];
  public twoOptionsItems: MenuItem[] = [
    {
      label: 'Editar usuario',
      icon: 'pi pi-pencil',
      command: () => this.editUser(),
    },
    {
      label: 'Eliminar usuario',
      icon: 'pi pi-trash',
      command: () => this.showOptionPopup(),
    },
  ];

  constructor(
    private getPaginatedAllUserUseCase: GetPaginatedAllUserUseCase,
    private deleteUserUseCase: DeleteUserUseCase,
    private UserUseCase: UserUseCase,
    private userService: UserService,
    private router: Router,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private localStorageService: LocalStorageServiceBase) { }

  ngOnInit(): void {
    this.userId = this.AES.decrypt(this.localStorageService.loadStorageData('user_id'));
    this.initUserData();
    this.initUsers();
    if (this.router.url.includes('main/home')) {
      this.homeView = true;
    }
  }

  initUserData(): void {
    this.userData = this.userService.userData;
  }

  initUsers(): void {
    if (!this.userId) {
      return;
    }
    this.getDataPaginated(this.defaultPage, this.defaultRows);
  }

  loadRecords(event: TableLazyLoadEvent) {
    const page = event.first !== undefined ? event.first / (event.rows || this.defaultRows) : this.defaultPage;
    const quantity = event.rows || this.defaultRows;
    this.getDataPaginated(page, quantity);
  }

  async getDataPaginated(page: number, quantity: number): Promise<void> {
    this.isLoading = true;
    let params = new UserPaginationParamsModel(page, quantity);
    try {
      let result = await this.getPaginatedAllUserUseCase.execute(params);
      if (result instanceof Failure) {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to fetch users. Please try again.',
        });
        return;
      }
      this.paginatedData = result;
      this.usersList = result.response || [];
      this.totalQuantity = result.totalQuantity;
    } catch (error) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'An unexpected error occurred. Please try again.',
      });
    } finally {
      this.isLoading = false;
    }
  }

  changePage(event: any) {
    this.first = event.first; this.defaultPage = event.page + 1;
    this.amountRegistersPage = event.rows;
    this.itemsPerPage = event.rows
    this.getDataPaginated(this.defaultPage, this.itemsPerPage);
  }

  async deleteUser(): Promise<void> {
    this.isLoading = true;
    let DeleteUserEntity = new DeleteUserModel(this.selectedUserId!);
    let result = await this.deleteUserUseCase.execute(DeleteUserEntity);
    this.isLoading = false;
    if (result instanceof Failure) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: result.message,
      });
      return;
    }
    this.messageService.add({
      severity: 'success',
      summary: 'Usuario eliminado',
      detail: 'Su usuario se elimino exitosamente.',
    });
    setTimeout(() => {
      if(!this.homeView) {
        this.router.navigate(['/main/users']);
      }
    }, 1100);
    this.getDataPaginated(this.defaultPage, this.defaultRows);
  }

  async getUser(): Promise<void> {
    let result = await this.UserUseCase.execute(this.selectedUserId!);
    if (result instanceof Failure) {
      return;
    }
    this.userData = result;
  }

  selectUser(id: string) {
    this.selectedUserId = id;
  }

  selectMenuItems() {
    this.items = this.twoOptionsItems;
  }

  editUser(): void {
    if (this.selectedUserId) {
      this.router.navigate(['main/user/update', this.selectedUserId]);
    }
  }

  showOptionPopup() {
    this.confirmationService.confirm({
      header: 'Eliminar usuario',
      message:
        `¿Está seguro de que desea eliminar el usuario? Se perderán todos los datos.`,
      accept: () => {
        this.deleteUser();
      },
    });
  }
}
