<section class="m-1 pb-2 sm:mx-6 sm:my-3">
  <div  *ngIf="audience">
      <ng-container >
        <div class="alert">
          <div class="flex gap-2 align-items-center">
            <img src="../../../../../../../assets/img/main/info-icon.png" alt="" class="info-icon">
            <span>Tiene una audiencia fijada para el día {{ audience.date | date: 'd \'de\' MMMM \'de\' yyyy' }} a las {{ audience.date  | date: 'HH:mm' }}</span>
          </div>

          <div class=" w-full flex justify-content-end pr-2 pb-2 ">
            <p-button label="Link a Google Meet" class="btn-google" 
            [disabled]="!audienceToday"
            (click)="openMeet()">

                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"
                    enable-background="new 0 0 141.7 141.7" viewBox="0 0 141.7 141.7" id="google-meet">
                    <path fill="#00832d"
                        d="M78,70.9l10.5,12l14.2,9.1l2.5-21l-2.5-20.5l-14.4,8L78,70.9z">
                    </path>
                    <path fill="#0066da"
                        d="M16.8,90v17.9c0,4.1,3.3,7.4,7.4,7.4h17.9l3.7-13.5L42.2,90l-12.3-3.7L16.8,90z">
                    </path>
                    <path fill="#e94235" d="M42.2,26.4L16.8,51.7l13,3.7l12.3-3.7l3.6-11.6L42.2,26.4z">
                    </path>
                    <path fill="#2684fc" d="M42.2,51.7H16.8V90h25.3V51.7z"></path>
                    <path fill="#00ac47"
                        d="M118.8,37.1l-16.2,13.3V92l16.2,13.3c2.4,1.9,6,0.2,6-2.9V40C124.9,36.9,121.3,35.1,118.8,37.1z M78,70.9V90H42.2v25.3h53.1c4.1,0,7.4-3.3,7.4-7.4V92L78,70.9z">
                    </path>
                    <path fill="#ffba00"
                        d="M95.3,26.4H42.2v25.3H78v19.1l24.7-20.5V33.8C102.7,29.7,99.4,26.4,95.3,26.4z">
                    </path>
                </svg></p-button>
    </div>
        </div>
      </ng-container>
  </div>

  <div class="shadow-2 p-2 mt-2 surface-card border-round-sm">
    <div class="sm:flex justify-content-between sm:mt-4 mb-3">
      <p class="font-semibold text-xl text-center sm:text-left">Últimos reclamos laborales</p>
      <div class="flex align-items-center justify-content-between ">
      </div>
    </div>
    <ng-container>
      <app-claims-table [userData]="userData" (activeClaims)="isActiveClaims($event)"></app-claims-table>
    </ng-container>
  </div>
</section>
