import { UseCase } from "src/app/core/utils/usecase";
import { Failure } from "src/app/core/utils/failure";
import { Injectable } from "@angular/core";
import { ResetPasswordEntity } from "../entities/reset-password.entity";
import { AuthRepositoryBase } from "../repositories/auth.repositorybase";

@Injectable()
export class ResetPasswordUseCase implements UseCase<ResetPasswordEntity, string | Failure>{
    constructor(private repository: AuthRepositoryBase) {
    }
    async execute(resetPassword: ResetPasswordEntity): Promise<string | Failure> {
        return await this.repository.resetPassword(resetPassword);
    }
}