import { UseCase } from "src/app/core/utils/usecase";
import { Failure } from "src/app/core/utils/failure";
import { AudienceRepositoryBase } from "../repositories/aucience-base.repository";
import { Injectable } from "@angular/core";
import { CreateAudienceEntity } from "../entities/create-audience.entity";

@Injectable()
export class CreateAudienceUseCase implements UseCase<CreateAudienceEntity, string | Failure> {
    constructor(private audienceRepository: AudienceRepositoryBase) {}
    
    async execute(param: CreateAudienceEntity): Promise<string | Failure> {
        return await this.audienceRepository.createAudience(param);
    }
}