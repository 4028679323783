import { Injectable } from "@angular/core";
import { UseCase } from "src/app/core/utils/usecase";
import { ProvinceEntity } from "../entities/province.entity";
import { Failure } from "src/app/core/utils/failure";
import { ProvinceRepositoryBase } from "../repositories/province-base.repository";

@Injectable()
export class GetProvinceUseCase implements UseCase<undefined, ProvinceEntity[] | Failure> {

    constructor(private provinceRepository: ProvinceRepositoryBase){}

    async execute(): Promise<ProvinceEntity[] | Failure> {
        return await this.provinceRepository.getProvinces()
    }
}