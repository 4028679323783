import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginBaseComponent } from './presentation/pages/login-base/login-base.component';
import { LoginComponent } from './presentation/components/login/login.component';
import { SignUpComponent } from './presentation/components/sign-up/sign-up.component';
import { SendValidationEmailComponent } from './presentation/components/forgot-password/send-validation-email/send-validation-email.component';
import { GenerateNewPasswordComponent } from './presentation/components/forgot-password/generate-new-password/generate-new-password.component';

const routes: Routes = [
  {
    path: '',
    component: LoginBaseComponent,
    children: [
      { path: 'login', component: LoginComponent },
      { path: 'register', component: SignUpComponent },
      { path: 'send-validation-email', component: SendValidationEmailComponent },
      { path: 'generate-new-password', component: GenerateNewPasswordComponent },
      { path: '**', redirectTo: 'login' }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule {}
