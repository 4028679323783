import { CompanyEntity } from "../../domain/entities/company.entity";

export class CompanyModel extends CompanyEntity {
    
    override toJson(): Map<string, any> {
        let map: Map<string, any> = new Map<string, any>();
        map.set("razon_social", this.razon_social);
        map.set("cuit", this.cuit);
        map.set("phone", this.phone);
        map.set("province", this.province);
        map.set("city", this.city);
        map.set("street", this.street);
        map.set("number", this.number);
        map.set("postal_code", this.postal_code);
        map.set("email", this.email);
        map.set("neighborhood", this.neighborhood);
        map.set("floor_apartment", this.floor_apartment);
        map.set("tower", this.tower);
        map.set("id", this.id);
       return map;
    }

    static fromJson(data: Map<string, any>): CompanyEntity {
        return new CompanyModel(
            data.get('razon_social'),
            data.get('cuit'),
            data.get('phone'),
            data.get('province'),
            data.get('city'),
            data.get('street'),
            data.get('number'),
            data.get('postal_code'),
            data.get('email'),
            data.get('neighborhood'),
            data.get('floor_apartment'),
            data.get('tower'),
            data.get('id')
        );
    }


}