import { Component } from '@angular/core';

@Component({
  selector: 'app-audiences',
  templateUrl: './audiences.component.html',
  styleUrls: ['./audiences.component.css']
})
export class AudiencesComponent {
  tableOrImg: boolean = true;
}
