import { UseCase } from "src/app/core/utils/usecase";
import { GetFileEntity } from "../entities/get-file.entity";
import { Failure } from "src/app/core/utils/failure";
import { ClaimRepositoryBase } from "../repositories/claim-base.repository";
import { Injectable } from "@angular/core";

@Injectable()
export class GetFileUseCase implements UseCase<GetFileEntity, string | Failure> {

    constructor(private claimRepository: ClaimRepositoryBase) {}

    async execute(param: GetFileEntity): Promise<string | Failure> {
        return await this.claimRepository.getFile(param);
    }
}