<span>Cargue un archivo .CSV que contenga los datos de las nuevas empresas. Si quiere descargar un ejemplo de plantilla, haga <a (click)="downloadCsv()"> click aquí</a></span>
<form [formGroup]="companiesForm" (ngSubmit)="onSubmit()" >

    <div class="grid w-full mt-4">
  
        <div class="col-12 md:col-8 lg:col-8 xl:col-6">
          <div [ngClass]="
                    isDragging
                      ? 'isDragging dropzone-container h-6rem col-12 '
                      : 'dropzone-container h-6rem col-12  '
                  " (dragover)="onDragOver($event)" (drop)="onDropSuccess($event)">
            <input #inputFile type="file" multiple="true" (change)="onChange($event)" accept=".csv, .xlsx"
              maxFileSize="1000000" [disabled]="disableFileImageUpload" />
  
            <div class="w-full flex align-items-center justify-content-between col-12">
              <img class="hidden md:block" src="../../../../../../../assets/img/main/file-update.svg" />
              <span class="descriptionContent hidden md:block">Arrastre sus documentos aquí o</span>
              <p-button pRipple (click)="inputFile.click()" #upload class="btnUploadFile mr-1"
                [disabled]="disableFileImageUpload">Elegir archivo</p-button>
  
            </div>
          </div>
          <span class="descriptionContent">Archivos permitidos: .CSV</span>
        </div>
  
        <div class="col-12 md:col-4 lg:col-4 xl:col-6 conteiner-UploadRight">
          <div class="imageContainer" *ngFor="let image of csvFile; let i = index">
            <img src="../../../../../../../assets/img/main/icon-image.svg" alt="" class="icon-imagen" />
            <span class="descriptionContent">{{ image.name }}</span>
            <p-button icon="pi pi-times" [rounded]="true" [text]="true" severity="danger"
              (onClick)="deleteFileImage(i)"></p-button>
          </div>
        </div>
      </div>

      <div class="grid w-full flex justify-content-end div-buttons">
        <div class="col-6 md:col-2 lg:col-2 xl:col-2 ">
          <p-button (click)="cancel()" label="Cancelar" class="btn-cancelar sm:text-xs" />
        </div>
    
        <div class="col-6 md:col-2 lg:col-2 xl:col-2 ">
          <p-button label="Guardar" class="btn-siguiente " [disabled]="companiesForm.invalid"
            type="submit"></p-button>
        </div>
      </div>

</form>