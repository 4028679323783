import { Session } from "../../domain/entities/session.entity";

export class SessionModel extends Session {
  constructor(access_token?: string,
    expires_in?: number,
    refresh_token?: string,
    refresh_expires_in?: number,
    token_type?: string,
    not_before_policy?: number,
    scope?: string){
      super(access_token, expires_in, refresh_token,refresh_expires_in, token_type, not_before_policy, scope)
    };
  
  override toJson(): Map<string, any> {
    let map: Map<string, any>= new Map<string, any>();
    map.set("access_token", this.access_token);
    map.set("expires_in", this.expires_in);
    map.set("refresh_token", this.refresh_token);
    map.set("refresh_expires_in", this.refresh_expires_in);
    map.set("token_type", this.token_type);
    map.set("not_before_policy", this.not_before_policy);
    map.set("scope", this.scope);
    return map;
  }

  static fromJson(param: Map<string, any>): Session {
    return new SessionModel(
      param.get("access_token"),
      Number(param.get("expires_in")),
      param.get("refresh_token"),
      Number(param.get("refresh_expires_in")),
      param.get("token_type"),
      Number(param.get("not_before_policy")),
      param.get("scope"),
    );
  }
}