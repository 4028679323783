import { CompanyPagination } from "../../domain/entities/company-pagination";
import { CompanyEntity } from "../../domain/entities/company.entity";
import { CompanyModel } from "./company.model";

export class CompanyPaginationModel extends CompanyPagination {
    override toJson(): Map<string, any> {
      throw new Error("Method not implemented.");
    }

    static fromJson(data: Map<string, any>): CompanyPagination {
      let user = this.getCompanyModels(data);
      return new CompanyPaginationModel(
        user,
        data.get('numberOfPages'),
        data.get('totalQuantity')
      );
    }

    private static  getCompanyModels(data:Map<string,any>): CompanyEntity[]{
      let response = data.get('response');
      let positions : CompanyEntity[] = [];

      for (const CompanyEntity of response) {
        let entries = Object.entries(CompanyEntity);
        let map = new Map<string, any>(entries);
        positions.push(CompanyModel.fromJson(map));
      }
      return positions;
    }
  }