import { environment } from 'src/environments/environment.dev';
import { HttpServiceBase } from 'src/app/core/services/http.service';
import { Injectable } from '@angular/core';
import { Failure } from 'src/app/core/utils/failure';
import { LocalStorageServiceBase } from 'src/app/core/services/localStorage-serviceBase';
import { HttpHeaders } from '@angular/common/http';
import { ClaimCollectiveEntity } from '../../domain/entities/claim-colective.entity';
import { ClaimsPaginationParams } from '../../domain/entities/claim-pagination-params.entity';
import { ClaimPagination } from '../../domain/entities/claim-pagination.entity';
import { ClaimPaginationModel } from '../models/claim-pagination.model';
import { ColectivePagination } from '../../domain/entities/colective-pagination.entity';
import { ClaimColectivePaginationModel } from '../models/colective-pagination-params';
import { UpdateClaimStatusEntity } from '../../domain/entities/update-claim-satus.entity';
import { UpdateClaimConciliatorEntity } from '../../domain/entities/update-claim-conciliator.entity';
import { ClaimCollectiveModel } from '../models/claim-colective.model';
import { GetFileEntity } from '../../domain/entities/get-file.entity';
import { DeleteClaimEntity } from '../../domain/entities/delete-claim.entity';

export abstract class ClaimColectiveRemoteDataSourceBase {
  abstract getClaim(id: string): Promise<ClaimCollectiveEntity | Failure>;
  abstract createClaim(claim: ClaimCollectiveEntity): Promise<string | Failure>;
  abstract updateClaim(claim: ClaimCollectiveEntity): Promise<string | Failure>;
  abstract getFile(params: GetFileEntity): Promise<string | Failure>;
  abstract getColectiveClaims(
    params: ClaimsPaginationParams
  ): Promise<ColectivePagination | Failure>;
  abstract updateClaimStatus(
    claim: UpdateClaimStatusEntity
  ): Promise<string | Failure>;
  abstract updateClaimConciliator(
    claim: UpdateClaimConciliatorEntity
  ): Promise<string | Failure>;
  abstract deleteClaim(
    deleteClaim: DeleteClaimEntity
  ): Promise<string | Failure>;
}

@Injectable()
export class ClaimColectiveRemoteDataSource extends ClaimColectiveRemoteDataSourceBase {
  private url: string = `${environment.apiUrl}/claimcollective`;

  constructor(
    private httpService: HttpServiceBase,
    private localStorageService: LocalStorageServiceBase
  ) {
    super();
  }
  override async getClaim(id: string): Promise<ClaimCollectiveEntity> {
    let headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${this.localStorageService.read('access_token')}`
    );
    let result = await this.httpService.get(`${this.url}/${id}`, headers);
    let response = result.get('response');
    let entries = Object.entries(response);
    let map = new Map<string, any>(entries);
    return ClaimCollectiveModel.fromJson(map);
  }

  override async createClaim(
    claim: ClaimCollectiveEntity
  ): Promise<string | Failure> {
    let headers = new HttpHeaders().set(
      'Authorization',

      `Bearer ${this.localStorageService.read('access_token')}`
    );
    let result = await this.httpService.post(`${this.url}`, claim, headers);
    return result.get('response');
  }

  override async updateClaim(
    claim: ClaimCollectiveEntity
  ): Promise<string | Failure> {
    let headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${this.localStorageService.read('access_token')}`
    );

    let result = await this.httpService.put(
      `${this.url}/update_claim_collective?id=${claim.id}`,
      claim,
      headers
    );
    return result.get('response');
  }

  override async getFile(params: GetFileEntity): Promise<string | Failure> {
    let headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${this.localStorageService.read('access_token')}`
    );
    let result = await this.httpService.get(
      `${this.url}/get_file_as_base64_collective/${params.getPath()}`,
      headers
    );
    return result.get('response');
  }

  override async getColectiveClaims(
    params: ClaimsPaginationParams
  ): Promise<ColectivePagination | Failure> {
    let headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${this.localStorageService.read('access_token')}`
    );
    let result = await this.httpService.get(
      `${this.url}/get_all_claims_collectivo${params.getPath()}`,
      headers
    );
    let response = result.get('response');
    return response;
  }

  override async updateClaimStatus(
    claim: UpdateClaimStatusEntity
  ): Promise<string | Failure> {
    let headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${this.localStorageService.read('access_token')}`
    );
    let result = await this.httpService.put(
      `${this.url}/update_status_collective?id=${claim.claimId}`,
      claim,
      headers
    );
    return result.get('response');
  }

  override async updateClaimConciliator(
    claim: UpdateClaimConciliatorEntity
  ): Promise<string | Failure> {
    let headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${this.localStorageService.read('access_token')}`
    );
    let result = await this.httpService.put(
      `${this.url}/change_conciliator_claim_collective`,
      claim,
      headers
    );
    return result.get('response');
  }

  override async deleteClaim(
    deleteClaim: DeleteClaimEntity
  ): Promise<string | Failure> {
    let headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${this.localStorageService.read('access_token')}`
    );
    let result = await this.httpService.delete(
      `${this.url}/delete_claim_collective`,
      deleteClaim,
      headers
    );
    return result.get('response');
  }
}
