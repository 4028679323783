import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ClaimEntity } from 'src/app/features/claims/domain/entities/claim.entity';
import { UserData } from 'src/app/features/user/domain/entities/user-data.entity';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Toast } from 'primeng/toast';
import { ProvinceEntity } from 'src/app/core/services/location/domain/entities/province.entity';
import { GetProvinceUseCase } from 'src/app/core/services/location/domain/usecases/get-province.usecase';
import { Departament } from 'src/app/features/user/presentation/components/New-User-SuperAdmin/newUser.component';
import { GetDepartmentsUseCase } from 'src/app/core/services/location/domain/usecases/get-departments.usecase';
import { GetLocationsUseCase } from 'src/app/core/services/location/domain/usecases/get-locations.usecase';
import { LocationEntity } from 'src/app/core/services/location/domain/entities/location.entity';
import { GetDepartmentsByConciliadorUseCase } from 'src/app/core/services/location/domain/usecases/get-departments-by-conciliador.usecase';
import { GetDepartmentsByIdUseCase } from 'src/app/core/services/location/domain/usecases/get-department-by-id.usescase';
import { Failure } from 'src/app/core/utils/failure';
import { GetFileModel } from 'src/app/features/claims/data/models/get-file.model';
import { GetFileUseCase } from 'src/app/features/claims/domain/usecases/get-file.usecase';
import { OfLegalAgeValidator } from 'src/app/core/utils/validator';

interface Localities {
  id: number;
  name: string;
}
interface Base64 {
  name: string;
  base64: string;
}

@Component({
  selector: 'app-claimant-details-employer',
  templateUrl: './claimant-details-employer.component.html',
  styleUrls: ['./claimant-details-employer.component.css'],
  providers: [ConfirmationService, MessageService, Toast],
})
export class ClaimantDetailsEmployerComponent {
  @Output() claimantDetailsForm: EventEmitter<FormGroup> = new EventEmitter();
  @Output() stepIndex: EventEmitter<number> = new EventEmitter();
  @Output() activeModal: EventEmitter<boolean> = new EventEmitter();

  @Input() claim?: ClaimEntity;
  @Input() userData?: UserData;

  maxDate: Date;
  minDate: Date = new Date(1930, 0, 1);

  isDragging: boolean = false;
  pdfFiles: any[] = [];
  imageFiles: any[] = [];
  provinces: ProvinceEntity[] = [];
  localitiesClaimant: Localities[] = [];
  localitiesUser: Localities[] = [];

  departments!: Departament[];
  isEditMode: boolean = false;
  selectedDepartment?: Departament;

  constructor(
    private formBuilder: FormBuilder,
    private getProvince: GetProvinceUseCase,
    private getLocations: GetLocationsUseCase,
    private getFileUseCase: GetFileUseCase,
    private messageService: MessageService,
    private getDepartmentsUsecase: GetDepartmentsUseCase,
    private getDepartmentByConciliador: GetDepartmentsByConciliadorUseCase,
    private getDepartmentsByIdUseCase: GetDepartmentsByIdUseCase
  ) {
    this.maxDate = new Date();
  }

  formClaimetDetails: FormGroup = this.formBuilder.group({
    claimetCompany: ['', [Validators.required]],
    claimetCompanyCuit: ['', [Validators.required]],
    company_email: ['', [Validators.required, Validators.email]],
    claimetCompanyPhone: [
      '',
      [
        Validators.required,
        Validators.minLength(7),
        Validators.maxLength(15),
        Validators.pattern(/^[0-9]+$/),
      ],
    ],
    claimetStreet: ['', [Validators.required, Validators.maxLength(50)]],
    claimetNumber: ['', [Validators.required, Validators.maxLength(5)]],
    claimetFloorApartament: ['', [Validators.maxLength(10)]],
    claimetTower: ['', [Validators.maxLength(30)]],
    claimetPostalCode: [
      '',
      [
        Validators.required,
        Validators.maxLength(10),
        Validators.minLength(4),
        Validators.pattern(/^[a-zA-Z0-9]*$/),
      ],
    ],
    claimetProvince: ['', Validators.required],
    claimetCity: ['', [Validators.required]],
    department_id: ['', [Validators.required]],
    claimetPosition: ['', [Validators.required]],
    consent: [false, [Validators.requiredTrue]],
    claimetLastname: [
      '',
      [
        Validators.required,
        Validators.maxLength(50),
        Validators.pattern(/^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]*$/),
      ],
    ],
    claimetName: [
      '',
      [
        Validators.required,
        Validators.maxLength(50),
        Validators.pattern(/^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]*$/),
      ],
    ],
    claimetCuil: ['', [Validators.required]],
    claimetTypePerformance: ['', [Validators.required]],
    claimetPhone: [
      '',
      [
        Validators.required,
        Validators.minLength(7),
        Validators.maxLength(15),
        Validators.pattern(/^[0-9]+$/),
      ],
    ],
    claimetEmail: [
      '',
      [Validators.required, Validators.email, Validators.maxLength(100)],
    ],
    claimetFilePaycheck: [, [this.CountValidatorPaycheck]],
    claimetStreetUser: [''],
    claimetNumberUser: [''],
    claimetFloorApartmentUser: [''],
    claimetPostalCodeUser: [''],
    claimetLocalityUser: ['', [Validators.required]],
    claimetProvinceUser: ['', [Validators.required]],
    claimetBirthDateUser: ['', OfLegalAgeValidator()],
  });

  async ngOnInit(): Promise<void> {
    await this.initializeComponent();
    this.loadUserData();
    this.formClaimetDetails
      .get('claimetCurrentEmploymentStatus')
      ?.valueChanges.subscribe((status) => {
        const date = this.formClaimetDetails.get('claimetEgressDate');

        if (status === 'Disuelta') {
          date?.setValidators([Validators.required]);
        } else {
          date?.clearValidators();
        }

        date?.updateValueAndValidity();
      });
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes['userData'] && changes['userData'].currentValue) {
      this.loadUserData();
    }

    if (changes['claim'] && changes['claim'].currentValue) {
      this.isEditMode = true;
      await this.initializeFormWithClaimData();
    }
  }

  async initializeComponent(): Promise<void> {
    await this.loadProvinces();
    this.loadDepartments();
    this.formClaimetDetails
      .get('claimetProvince')
      ?.valueChanges.subscribe((selectedProvince: any) => {
        this.loadLocalities(selectedProvince, 'claimant');
        this.formClaimetDetails.patchValue(
          { provincia: selectedProvince },
          { emitEvent: false }
        );
      });
    this.formClaimetDetails
      .get('claimetProvinceUser')
      ?.valueChanges.subscribe((selectedProvince: any) => {
        this.loadLocalities(selectedProvince, 'user');
        this.formClaimetDetails.patchValue(
          { provincia: selectedProvince },
          { emitEvent: false }
        );
      });
  }

  private loadUserData() {
    if (this.userData) {
      this.formClaimetDetails.patchValue({
        claimetCompany: this.userData.razon_social,
        claimetCompanyCuit: this.userData.company_cuit,
        claimetCompanyPhone: this.claim?.claimetCompanyPhone
          ? this.claim?.claimetCompanyPhone
          : this.userData.phone_company,
        claimetLastname: this.userData.lastName,
        claimetName: this.userData.name,
        claimetCuil: this.userData.cuil,
        claimetEmail: this.userData.email,
        claimetPhone: this.userData.phone,
        claimetTypePerformance: this.userData.tipo_actuacion,
        claimetPosition: this.userData.cargo_empresa,
      });
    }
  }

  private async initializeFormWithClaimData(): Promise<void> {
    if (!this.claim) return;
    if (this.isEditMode) {
      this.selectedDepartment = await this.laodDepartmentsByConciliador2(
        this.claim.department_id
      );
      this.formClaimetDetails.patchValue({
        claimetLastname: this.claim.claimetLastname || '',
        claimetName: this.claim.claimetName || '',
        claimetCuil: this.claim.claimetCuil || '',
        claimetEmail: this.claim.claimetEmail || '',
        claimetPhone: this.claim.claimetCompanyPhone || '',
        claimetProvince: this.claim.claimetProvince || '',
        claimetCity: this.claim.claimetCity || '',
        claimetNeighborhood: this.claim.claimetNeighborhood || '',
        claimetStreet: this.claim.claimetStreet || '',
        claimetNumber: this.claim.claimetNumber || '',
        claimetPostalCode: this.claim.claimetPostalCode || '',
        claimetFloorApartament: this.claim.claimetFloorApartament || '',
        claimetTower: this.claim.claimetTower || '',
        consent: this.claim.consent || false,
        claimetSelectedTypeOfJob: this.claim.claimetSelectedTypeOfJob || '',
        claimetWorkday: this.claim.claimetWorkday || '',
        claimetGrossRemuneration: this.claim.claimetGrossRemuneration || '',
        claimetTypeRemuneration: this.claim.claimetTypeRemuneration || '',
        claimetDateAdmission: this.claim.claimetDateAdmission || '',
        claimetEgressDate: this.claim.claimetEgressDate || '',
        claimetNumberAgreement: this.claim.claimetNumberAgreement || '',
        claimetYear: this.claim.claimetYear || '',
        claimetCategory: this.claim.claimetCategory || '',
        claimetCurrentEmploymentStatus:
          this.claim.claimetCurrentEmploymentStatus || '',
        claimetReasonForDisengagement:
          this.claim.claimetReasonForDisengagement || '',
        claimetCauseDisengagement: this.claim.claimetCauseDisengagement || '',
        claimetFileDni: this.claim.claimetFileDni || '',
        claimetFilePaycheck: this.claim.claimetFilePaycheck || '',
        department_id: this.claim.department_id || '',
        company_email: this.claim.company_email || '',
        claimetStreetUser: this.claim.claimetStreetUser || '',
        claimetNumberUser: this.claim.claimetNumberUser || '',
        claimetFloorApartmentUser: this.claim.claimetFloorApartmentUser || '',
        claimetPostalCodeUser: this.claim.claimetPostalCodeUser || '',
        claimetLocalityUser: this.claim.claimetLocalityUser || '',
        claimetProvinceUser: this.claim.claimetProvinceUser || '',
        claimetBirthDateUser: this.claim.claimetBirthDateUser || '',
      });
      this.formClaimetDetails
        .get('claimetDateAdmission')
        ?.setValue(
          this.parseDate(
            this.formClaimetDetails.get('claimetDateAdmission')?.value
          )
        );
      this.formClaimetDetails
        .get('claimetEgressDate')
        ?.setValue(
          this.parseDate(
            this.formClaimetDetails.get('claimetEgressDate')?.value
          )
        );
      this.formClaimetDetails
        .get('claimetBirthDateUser')
        ?.setValue(
          this.parseDateBirth(
            this.formClaimetDetails.get('claimetBirthDateUser')?.value
          )
        );

      await this.initializeFormWithSavedFiles();
    }
  }

  private async initializeFormWithSavedFiles(): Promise<void> {
    try {
      if (this.claim?.claimetFilePaycheck?.length) {
        try {
          this.pdfFiles = [];

          let i = 0;
          for (const fileName of this.claim.claimetFilePaycheck) {
            const paycheckFile = new GetFileModel(this.claim.id!, fileName);
            const base64 = await this.getFile(paycheckFile);

            if (base64) {
              let base64Objet: Base64 = {
                name: 'Recibo de sueldo ' + (i + 1),
                base64: 'data:application/pdf;base64,' + base64,
              };

              this.pdfFiles.push(base64Objet);
              i++;
            } else {
              console.error(`Archivo ${fileName} vacío o inválido.`);
            }
          }

          if (this.pdfFiles.length === 0) {
            console.warn('Ningún archivo válido fue procesado.');
          }
        } catch (error) {
          console.error(
            'Error al obtener archivos de recibo de sueldo:',
            error
          );
          this.pdfFiles = [];
        }
      } else {
        console.warn(
          'No se encontraron archivos de recibo de sueldo para procesar.'
        );
      }
      this.formClaimetDetails
        .get('claimetFilePaycheck')
        ?.setValue(this.pdfFiles);
    } catch (error) {
      console.error(
        'Error general al inicializar archivos en el formulario:',
        error
      );
    }
  }

  async laodDepartmentsByConciliador2(
    id: string
  ): Promise<Departament | undefined> {
    const result = await this.getDepartmentsByIdUseCase.execute(id);

    if (!(result instanceof Failure)) {
      return result;
    }
    return undefined;
  }

  onConsentChange(event: any): void {
    this.formClaimetDetails.controls['consent'].setValue(event.checked);
  }

  async loadProvinces() {
    try {
      const result = await this.getProvince.execute();
      if (Array.isArray(result)) {
        this.provinces = result;
      }
    } catch (error) {
      console.error('Error fetching provinces', error);
    }
  }

  async loadDepartments() {
    const departments = await this.getDepartmentsUsecase.execute();
    if (Array.isArray(departments)) {
      this.departments = this.putIdToDepartmentsList(departments);
    }
  }

  putIdToDepartmentsList(list: Departament[]): Departament[] {
    let listWithId: Departament[] = [];
    for (let i = 0; i < list.length; i++) {
      let department: Departament = {
        id: list[i].id,
        name: list[i].name,
      };
      listWithId.push(department);
    }
    return listWithId;
  }

  async loadLocalities(provinceId: string, type: 'claimant' | 'user') {
    try {
      const localities = await this.getLocations.execute(provinceId);
      if (Array.isArray(localities)) {
        const localitiesList = this.putIdToLocalitiesList(localities);

        if (type === 'claimant') {
          this.localitiesClaimant = localitiesList;
        } else {
          this.localitiesUser = localitiesList;
        }
      }
    } catch (error) {
      console.error('Error fetching localities', error);
    }
  }

  putIdToLocalitiesList(list: LocationEntity[]): Localities[] {
    let listWithId: Localities[] = [];
    for (let i = 0; i < list.length; i++) {
      let city: Localities = {
        id: i,
        name: list[i].name,
      };
      listWithId.push(city);
    }
    return listWithId;
  }

  private CountValidatorPaycheck(
    control: AbstractControl
  ): { [key: string]: any } | null {
    return control.value?.length >= 1 && control.value?.length <= 12
      ? null
      : { fileCount: true };
  }

  nextStep() {
    if (this.formClaimetDetails.invalid) return;
    this.claimantDetailsForm.emit(this.formClaimetDetails.value);
    this.stepIndex.emit(1);
  }

  hasErrors(controlName: string, errorType: string) {
    return (
      this.formClaimetDetails.get(controlName)?.hasError(errorType) &&
      this.formClaimetDetails.get(controlName)?.touched
    );
  }

  cancel() {
    this.claimantDetailsForm.emit(this.formClaimetDetails.value);
    this.activeModal.emit(true);
  }

  onDragOver(event: Event) {
    event.preventDefault();
    this.isDragging = true;
  }

  onDropSuccess(event: any, file: string): any {
    const files = event.dataTransfer?.files;

    if (!files || files.length === 0) return;

    event.preventDefault();
    this.isDragging = false;
    this.onFileChange(files);
  }

  private onFileChange(files: any) {
    const newFiles = Array.from(files);
    this.pdfFiles = (this.pdfFiles || []).concat(newFiles);
  }

  onChange(event: any, file: string): any {
    const input = event.target as HTMLInputElement;
    const files = event.target.files;
    const fileForm = file;

    if (files.length > 0) {
      if (this.pdfFiles.length > 11) {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail:
            'Solo puedes subir hasta 12 archivos (JPG, WEBP, HEIF o PDF).',
        });
        return false;
      }

      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        if (
          ![
            'image/jpeg',
            'image/png',
            'image/webp',
            'image/heif',
            'application/pdf',
          ].includes(file.type)
        ) {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Solo se permiten archivos JPG, WEBP, HEIF o PDF.',
          });
          return false;
        }

        this.convertToBase64(file, fileForm);
      }

      input.value = '';
    }
  }

  private async convertToBase64(file: any, fileForm: string) {
    const reader = new FileReader();
    reader.onload = () => {
      const base64String = reader.result?.toString();
      if (base64String) {
        this.assignFilesReceipt(file, base64String);
      }
    };
    reader.onerror = (error) => {
      console.error('FileReader error:', error);
    };
    reader.readAsDataURL(file);
  }

  private assignFilesReceipt(file: any, base64String: string) {
    if (this.pdfFiles.length >= 12) return;

    this.pdfFiles.push({ name: file.name, base64: base64String });

    const filePaycheckBase64Array = this.pdfFiles.map((file) => file.base64);
    this.formClaimetDetails
      .get('claimetFilePaycheck')
      ?.setValue(filePaycheckBase64Array);
    this.formClaimetDetails.get('claimetFilePaycheck')?.markAsTouched();
    this.formClaimetDetails
      .get('claimetFilePaycheck')
      ?.updateValueAndValidity();
  }

  deleteFilePfd(index: number) {
    this.pdfFiles.splice(index, 1);
    const fileReceiptBase64Array = this.pdfFiles.map((file) => file.base64);
    this.formClaimetDetails
      .get('claimetFilePaycheck')
      ?.setValue(fileReceiptBase64Array.length ? fileReceiptBase64Array : null);
    this.formClaimetDetails.get('claimetFilePaycheck')?.markAsTouched();
    this.formClaimetDetails.get('claimetFilePaycheck')?.markAsDirty();
  }

  async getFile(param: GetFileModel): Promise<string> {
    let result = await this.getFileUseCase.execute(param);
    if (result instanceof Failure) {
      return result.message;
    }
    return result;
  }

  parseDate(date: string): Date {
    if (!date) {
      return new Date(1930, 0, 1);
    }
    let part = date.split(/\/| |:/);
    return new Date(
      parseInt(part[2]),
      parseInt(part[0]) - 1,
      parseInt(part[1]),
      parseInt(part[3]),
      parseInt(part[4]),
      parseInt(part[5])
    );
  }
  parseDateBirth(date: string): Date {
    if (!date) {
      return new Date(1930, 0, 1);
    }
    return new Date(date);
  }
}
