import { Entity } from "src/app/core/utils/entity";

export abstract class LocationEntity extends Entity {
    constructor(
        public name: string
    ) {
        super();
    }
}

