import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from '../components/sidebar/sidebar.component';
import { PrimengModule } from '../components/primeng/primeng.module';
import { AuthRoutingModule } from 'src/app/features/auth/auth-routing.module';
import { SpinnerComponent } from '../components/spinner/spinner.component';
import {LoginImageComponent} from "../../features/auth/presentation/pages/login-base/login-image/login-image.component";



@NgModule({
  declarations: [
    SidebarComponent,
    SpinnerComponent,
    LoginImageComponent
  ],
  imports: [
    CommonModule,
    PrimengModule,
    AuthRoutingModule
  ],
  exports: [
    SidebarComponent,
    SpinnerComponent,
    LoginImageComponent
  ]
})
export class SharedModule { }
