import { Failure } from 'src/app/core/utils/failure';
import { AudienceEntity } from '../../domain/entities/audience.entity';
import { Injectable } from '@angular/core';
import { HttpServiceBase } from 'src/app/core/services/http.service';
import { LocalStorageServiceBase } from 'src/app/core/services/localStorage-serviceBase';
import { environment } from 'src/environments/environment.dev';
import { HttpHeaders } from '@angular/common/http';
import { AudiencePaginationParams } from '../../domain/entities/audience-pagination-params.entity';
import { AudiencePagination } from '../../domain/entities/audience-pagination.entity';
import { AudiencePaginationModel } from '../models/audience-pagination.model';
import { DeleteAudienceEntity } from '../../domain/entities/delete-audience.entity';
import { CreateAudienceEntity } from '../../domain/entities/create-audience.entity';

export abstract class AudienceRemoteDataSourceBase {
  abstract getAudience(id: string): Promise<AudienceEntity | Failure>;
  abstract getPaginationAudiences(
    params: AudiencePaginationParams
  ): Promise<AudiencePagination | Failure>;
  abstract getPaginationAudiencesofConciliator(
    params: AudiencePaginationParams
  ): Promise<AudiencePagination | Failure>;
  abstract getAllNextAudiences(
    params: AudiencePaginationParams
  ): Promise<AudiencePagination | Failure>;
  abstract createAudience(
    audience: CreateAudienceEntity
  ): Promise<string | Failure>;
  abstract deleteAudience(
    deleteAudience: DeleteAudienceEntity
  ): Promise<string | Failure>;
  abstract updateAudience(audience: AudienceEntity): Promise<string | Failure>;
  abstract getNextHomeAudienceOfConciliator(
    id: string
  ): Promise<AudienceEntity | Failure>;
  abstract getNextAudienceOfConciliator(
    params: AudiencePaginationParams
  ): Promise<AudiencePagination | Failure>;
  abstract getAllAudiencesByIdConciliadorPagination(
    params: AudiencePaginationParams
  ): Promise<AudiencePagination | Failure>;
  abstract getNextAudienceOfClaimant(
    id: string
  ): Promise<AudienceEntity | Failure>;
  abstract getNextSetAudience(): Promise<AudienceEntity | Failure>;
}

@Injectable()
export class AudienceRemoteDataSource extends AudienceRemoteDataSourceBase {
  private url: string = `${environment.apiUrl}/audience`;

  constructor(
    private httpService: HttpServiceBase,
    private localStorageService: LocalStorageServiceBase
  ) {
    super();
  }

  override async getAudience(id: string): Promise<AudienceEntity | Failure> {
    let headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${this.localStorageService.read('access_token')}`
    );
    let result = await this.httpService.get(`${this.url}/${id}`, headers);
    return result.get('response');
  }

  override async getPaginationAudiences(
    params: AudiencePaginationParams
  ): Promise<AudiencePagination | Failure> {
    let headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${this.localStorageService.read('access_token')}`
    );
    let result = await this.httpService.get(
      `${this.url}/get_all_audiences${params.getPath()}`,
      headers
    );
    let response = result.get('response');
    let map = new Map<string, any>(Object.entries(response));
    return AudiencePaginationModel.fromJson(map);
  }
  override async getAllNextAudiences(
    params: AudiencePaginationParams
  ): Promise<AudiencePagination | Failure> {
    let headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${this.localStorageService.read('access_token')}`
    );
    let result = await this.httpService.get(
      `${this.url}/get_all_future_audiences${params.getPath()}`,
      headers
    );
    let response = result.get('response');
    let map = new Map<string, any>(Object.entries(response));
    return AudiencePaginationModel.fromJson(map);
  }

  override async getPaginationAudiencesofConciliator(
    params: AudiencePaginationParams
  ): Promise<AudiencePagination | Failure> {
    let headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${this.localStorageService.read('access_token')}`
    );
    let result = await this.httpService.get(
      `${
        this.url
      }/get_next_conciliator_audiences?id_conciliador=${params.getPath()}`,
      headers
    );
    let response = result.get('response');
    let map = new Map<string, any>(Object.entries(response));
    return AudiencePaginationModel.fromJson(map);
  }

  override async createAudience(
    audience: CreateAudienceEntity
  ): Promise<string | Failure> {
    let headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${this.localStorageService.read('access_token')}`
    );
    let result = await this.httpService.post(
      `${this.url}/create`,
      audience,
      headers
    );
    return result.get('response');
  }

  override async deleteAudience(
    deleteAudience: DeleteAudienceEntity
  ): Promise<string | Failure> {
    let headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${this.localStorageService.read('access_token')}`
    );
    let result = await this.httpService.delete(
      `${this.url}/delete_audiences`,
      deleteAudience,
      headers
    );
    return result.get('response');
  }

  override async updateAudience(
    audience: AudienceEntity
  ): Promise<string | Failure> {
    let headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${this.localStorageService.read('access_token')}`
    );
    let result = await this.httpService.put(
      `${this.url}/update`,
      audience,
      headers
    );
    return result.get('response');
  }

  override async getNextHomeAudienceOfConciliator(
    id: string
  ): Promise<AudienceEntity | Failure> {
    let headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${this.localStorageService.read('access_token')}`
    );
    let result = await this.httpService.get(
      `${this.url}/get_next_conciliator_audience?id_conciliador=${id}`,
      headers
    );
    return result.get('response');
  }

  override async getNextAudienceOfConciliator(
    params: AudiencePaginationParams
  ): Promise<AudiencePagination | Failure> {
    let headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${this.localStorageService.read('access_token')}`
    );
    let result = await this.httpService.get(
      `${
        this.url
      }/get_next_conciliator_audience?id_conciliador=${params.getPath()}`,
      headers
    );
    let response = result.get('response');
    let map = new Map<string, any>(Object.entries(response));
    return AudiencePaginationModel.fromJson(map);
  }

  override async getAllAudiencesByIdConciliadorPagination(
    params: AudiencePaginationParams
  ): Promise<AudiencePagination | Failure> {
    let headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${this.localStorageService.read('access_token')}`
    );
    let result = await this.httpService.get(
      `${
        this.url
      }/get_all_audiences_by_conciliator_id?conciliator_id=${params.getPath()}`,
      headers
    );
    let response = result.get('response');
    let map = new Map<string, any>(Object.entries(response));
    return AudiencePaginationModel.fromJson(map);
  }

  override async getNextAudienceOfClaimant(
    id: string
  ): Promise<AudienceEntity | Failure> {
    let headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${this.localStorageService.read('access_token')}`
    );
    let result = await this.httpService.get(
      `${this.url}/get_next_audience_by_idClaimant?id=${id}`,
      headers
    );
    return result.get('response');
  }

  override async getNextSetAudience(): Promise<AudienceEntity | Failure> {
    let headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${this.localStorageService.read('access_token')}`
    );
    let result = await this.httpService.get(
      `${this.url}/get_next_audience`,
      headers
    );
    return result.get('response');
  }
}
