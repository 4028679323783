<p-tabView *ngIf="this.rol === 'Empleador'; else singleTable">
  <p-tabPanel header="Reclamos individuales">
    <ng-container *ngTemplateOutlet="claimsTable"></ng-container>
  </p-tabPanel>
  <p-tabPanel header="Reclamos colectivos">
    <app-claims-colective-table></app-claims-colective-table>
  </p-tabPanel>
  <p-tabPanel header="Reclamos plurindividuales">
    <app-claims-multi-single-table></app-claims-multi-single-table>
  </p-tabPanel>
</p-tabView>

<ng-template #singleTable>
  <ng-container *ngTemplateOutlet="claimsTable"></ng-container>
</ng-template>

<ng-template #claimsTable>
  <section>
    <!-- empty claims -->
    <div *ngIf="!userClaims">
      <ng-container>
        <div class="emptyAudienciaText">
          <img
            id="image"
            src="../../../../../../assets/img/main/file-searching.svg"
            alt="imagen de archivo"
          />
          <p>
            Ud. no ha generado ningún reclamo <br />
            o no tiene fecha de audiencia fijada
          </p>
        </div>
      </ng-container>
    </div>
    <!-- delete claim -->
    <p-confirmDialog #cd>
      <ng-template pTemplate="headless" let-message>
        <div
          class="flex flex-column align-items-center justify-content-center p-5 surface-overlay border-round"
        >
          <span class="col-12 font-semibold text-xl">
            {{ message.header }}
          </span>
          <div
            class="col-12 flex align-items-center justify-content-center gap-3"
          >
            <i class="pi pi-exclamation-circle text-lg"></i>
            <p class="">{{ message.message }}</p>
          </div>
          <div class="col-12 flex gap-3 justify-content-end">
            <button
              pButton
              label="Cancelar"
              (click)="cd.reject()"
              class="btn-cancelar"
              style="
                border: 1px solid #263245;
                background-color: white;
                color: #263245;
                font-size: 0.8rem;
              "
            ></button>
            <button
              pButton
              label="Eliminar reclamo"
              (click)="cd.accept()"
              class="delete btn-siguiente"
              style="
                background-color: #c93b3b;
                border-color: #c93b3b;
                font-size: 0.8rem;
              "
            ></button>
          </div>
        </div>
      </ng-template>
    </p-confirmDialog>

    <!-- claim table -->
    <div *ngIf="userClaims">
      <p-table [rowHover]="true" *ngIf="skeleton" [value]="skeletons">
        <ng-template pTemplate="header">
          <tr class="font-bold text-xs sm:text-md">
            <th class="text-center">Reclamado</th>
            <th class="text-center">Número de Reclamo</th>
            <th class="text-center">Fecha de inicio</th>
            <th class="text-center">Estado</th>
            <th class="text-center"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-skeleton>
          <tr>
            <td class="text-center"><p-skeleton></p-skeleton></td>
            <td class="text-center"><p-skeleton></p-skeleton></td>
            <td class="text-center"><p-skeleton></p-skeleton></td>
            <td class="text-center"><p-skeleton></p-skeleton></td>
            <td class="text-center"><p-skeleton></p-skeleton></td>
          </tr>
        </ng-template>
      </p-table>

      <p-table
        *ngIf="!skeleton"
        #dtClaims
        [value]="userClaims"
        [tableStyle]="{ 'min-width': '40rem' }"
      >
        <ng-template pTemplate="caption" class="col-12" *ngIf="!homeView">
          <app-claim-filter
            class="modal-content"
            (filters)="onFiltersApplied($event)"
            (cancel)="onFilterCancel()"
          ></app-claim-filter>
        </ng-template>

        <ng-template pTemplate="header">
          <tr class="font-bold text-xs sm:text-md">
            <th class="text-center">Reclamado</th>
            <th class="text-center">Número de Reclamo</th>
            <th class="text-center">Fecha de inicio</th>
            <th class="text-center">Estado</th>
            <th class="text-center"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-claim>
          <tr class="table-row text-xs sm:text-sm">
            <td class="text-center">
              {{
                claim.nameLastNameOrBusinessNameClaimed
                  ? (claim.nameLastNameOrBusinessNameClaimed | truncate)
                  : "-"
              }}
            </td>
            <td class="text-center">
              {{ claim.number_of_claim ? claim.number_of_claim : "-" }}
            </td>
            <td class="text-center">
              {{ claim.startDate | date : "dd/MM/yyyy" }}
            </td>
            <td class="text-center">
              <p [ngClass]="getStatusClass(claim.status)">
                {{ claim.status | claimStatusDisplay }}
              </p>
            </td>
            <td class="text-center">
              <p-menu
                #menu
                [popup]="true"
                [model]="items"
                appendTo="body"
              ></p-menu>
              <button
                type="button"
                pButton
                icon="pi pi-ellipsis-v"
                (click)="
                  selectMenuItemsByRol(claim.status);
                  menu.toggle($event);
                  selectClaim(claim.id)
                "
                class="p-button-rounded p-button-text"
                style="width: 19% !important"
              ></button>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="5">
              <div class="flex flex-column align-items-center">
                <img
                  src="../../../../../../assets/img/main/file-searching.svg"
                  alt="imagen de archivo"
                  style="width: 200px; height: 250px"
                />
                <p>No se encontraron resultados para la busqueda</p>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>

      <p-paginator
        *ngIf="!homeView"
        (onPageChange)="changePage($event)"
        [first]="(pagination.actualPage - 1) * pagination.itemsPerPage"
        [rows]="pagination.itemsPerPage"
        [totalRecords]="pagination.totalQuantity"
        [rowsPerPageOptions]="[5, 10, 25, 50]"
      >
      </p-paginator>
    </div>
  </section>
</ng-template>
<p-toast />
