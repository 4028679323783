<div class="ml-5 mt-4 flex flex-column">
    <div class="nuevoReclamo">
        <p class="font-semibold text-xl title">Audiencias</p>


    </div>
    <ng-container *ngIf="!tableOrImg">
        <div class="imagenPag">
            <img id="imagen" src="../../../../../../assets/img/main/file-searching.svg" alt="" />
            <p class="descriptionPage">
                Ud. no ha generado ningún reclamo o no tiene fecha de audiencia fijada
            </p>
        </div>
    </ng-container>

    <ng-container *ngIf="tableOrImg">
        <app-audience-table></app-audience-table>
    </ng-container>
</div>
