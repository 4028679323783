import { ForgotPasswordSendEmailEntity } from "../../domain/entities/forgot-password-send-email.entity";

export class ForgotPasswordSendEmailModel extends ForgotPasswordSendEmailEntity {
    
    override getPath(): string {
        let url: string = this.email;
        return url;
    }

    override toJson(): Map<string, any> {
        let json: Map<string, any> = new Map();
        json.set('email', this.email);
        return json;
    }
}