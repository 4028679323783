<div class="">
  <p-toast />
  <p-confirmDialog #cd>
    <ng-template pTemplate="headless" let-message>
      <div
        class="flex flex-column align-items-center justify-content-center p-5 surface-overlay border-round"
      >
        <span class="col-12 font-semibold text-xl">
          {{ message.header }}
        </span>
        <div
          class="col-12 flex align-items-center justify-content-center gap-3"
        >
          <i class="pi pi-exclamation-circle text-lg"></i>
          <p class="">{{ message.message }}</p>
        </div>
        <div class="col-12 flex gap-3 justify-content-end">
          <button
            pButton
            label="Cancelar"
            (click)="cd.reject()"
            class="btn-cancelar"
            style="
              border: 1px solid #263245;
              background-color: white;
              color: #263245;
              font-size: 0.8rem;
            "
          ></button>
          <button
            pButton
            label="Eliminar usuario"
            (click)="cd.accept()"
            class="delete btn-siguiente"
            style="
              background-color: #c93b3b;
              border-color: #c93b3b;
              font-size: 0.8rem;
            "
          ></button>
        </div>
      </div>
    </ng-template>
  </p-confirmDialog>
</div>

<section class="m-1 pb-2 sm:mx-6 sm:my-3">
  <div *ngIf="!homeView" class="flex flex-column p-3">
    <p class="font-semibold text-xl title">Usuarios</p>
    <div class="flex grid w-full align-items-center justify-content-between">
      <div class="busqueda flex col-4">
        <span class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input
            type="text"
            #inputElement
            pInputText
            placeholder="Buscar por Nombre, Apellido Cuil, Email, Rol"
            class="inputBuscador"
          />
          <!-- (input)="dtUsers.filterGlobal(inputElement.value, 'contains')" -->
        </span>
      </div>
      <div class="col-3 text-right">
        <p-button
          class="btn-new-claim"
          label="Nuevo usuario"
          icon="pi pi-plus"
          routerLink="/main/createUsers"
        ></p-button>
      </div>
    </div>
  </div>

  <div class="grid w-full relative p-3">
    <p-table
      [tableStyle]="{ 'min-width': '100%' }"
      class="col-12"
      [rowHover]="true"
      *ngIf="skeleton"
      [value]="skeletons"
    >
      <ng-template pTemplate="header">
        <tr class="font-semibold text-xs">
          <th class="text-left">Fecha</th>
          <th class="text-left">Hora</th>
          <th *ngIf="!homeView" class="text-left">Título de audiencia</th>
          <th *ngIf="!homeView" class="text-left">Conciliador</th>
          <th class="text-center">N° de reclamo</th>
          <th *ngIf="!homeView" class="text-center">N° de expediente</th>
          <th class="text-left">Reclamante</th>
          <th class="text-left">Reclamado</th>
          <th></th>
          <th></th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-skeleton>
        <tr>
          <td class="text-center"><p-skeleton></p-skeleton></td>
          <td class="text-center"><p-skeleton></p-skeleton></td>
          <td class="text-center"><p-skeleton></p-skeleton></td>
          <td class="text-center"><p-skeleton></p-skeleton></td>
          <td class="text-center"><p-skeleton></p-skeleton></td>
          <td class="text-center"><p-skeleton></p-skeleton></td>
          <td class="text-center"><p-skeleton></p-skeleton></td>
          <td class="text-center" *ngIf="!homeView">
            <p-skeleton></p-skeleton>
          </td>
          <td class="text-center" *ngIf="!homeView">
            <p-skeleton></p-skeleton>
          </td>
          <td class="text-center" *ngIf="!homeView">
            <p-skeleton></p-skeleton>
          </td>
        </tr>
      </ng-template>
    </p-table>

    <p-table
      *ngIf="!skeleton"
      #dtUsers
      [value]="usersList"
      (onLazyLoad)="loadRecords($event)"
      [tableStyle]="{ 'min-width': '100%' }"
      currentPageReportTemplate="{first}-{last} resultados | Total {totalRecords}"
      class="col-12"
      [rows]="itemsPerPage"
      [totalRecords]="totalQuantity"
      (onPage)="changePage($event)"
      [globalFilterFields]="['lastName', 'name', 'cuil', 'email', 'rol']"
    >
      >
      <ng-template pTemplate="header">
        <tr class="font-semibold text-xs">
          <th>Apellido y Nombre</th>
          <th *ngIf="!homeView">CUIL</th>
          <th>Correo Electrónico</th>
          <th>Localidad</th>
          <th *ngIf="!homeView">Teléfono</th>
          <th>Rol</th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-user>
        <tr class="table-row">
          <td>{{ user.lastName }}, {{ user.name }}</td>
          <td *ngIf="!homeView">
            {{ user.cuil }}
          </td>
          <td>{{ user.email }}</td>
          <td>{{ user.city }}</td>
          <td *ngIf="!homeView">
            {{ user.phone }}
          </td>
          <td>{{ user.rol }}</td>
          <td class="text-right">
            <button
              type="button"
              (click)="
                selectMenuItems(); menu.toggle($event); selectUser(user.id)
              "
              pButton
              icon="pi pi-ellipsis-v"
              class="p-button-rounded p-button-text"
            ></button>
            <p-menu
              #menu
              [popup]="true"
              [model]="items"
              appendTo="body"
            ></p-menu>
          </td>
        </tr>
      </ng-template>
    </p-table>
    <p-paginator
      class="col-12"
      *ngIf="!homeView"
      (onPageChange)="changePage($event)"
      [first]="first"
      [rows]="defaultRows"
      [totalRecords]="totalQuantity"
      [rowsPerPageOptions]="[5, 10, 25, 50]"
    >
    </p-paginator>
  </div>
</section>
