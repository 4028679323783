import { AudiencePagination } from "../../domain/entities/audience-pagination.entity";
import { AudienceEntity } from "../../domain/entities/audience.entity";
import { AudienceModel } from "./aucience.model";

export class AudiencePaginationModel extends AudiencePagination {
    override toJson(): Map<string, any> {
        throw new Error("Method not implemented.");
    }

    static fromJson(data: Map<string, any>): AudiencePagination {
        let audiences = this.getAudiencesModel(data);
        return new AudiencePaginationModel(
            audiences,
            data.get('numberOfPages'),
            data.get('totalQuantity')
        )
    }

    private static getAudiencesModel(data: Map<string,any>): AudienceEntity[] {
        let response = data.get('response');
        let positions: AudienceEntity[] = [];

        for (const audienceEntity of response) {
            let entries = Object.entries(audienceEntity);
            let map = new Map<string,any>(entries);
            positions.push(AudienceModel.fromJson(map));
        }
        return positions;
    }

}