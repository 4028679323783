import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClaimEntity } from '../../../../domain/entities/claim.entity';
import { ClaimModel } from '../../../../data/models/claim.model';
import { ClaimUseCase } from '../../../../domain/usecases/get-claim.usecase';
import { Failure, UnhandledFailure } from 'src/app/core/utils/failure';
import { ClaimStatus } from 'src/app/core/enums/claim-status.enum';
import { ProvinceEntity } from 'src/app/core/services/location/domain/entities/province.entity';
import { GetProvinceUseCase } from 'src/app/core/services/location/domain/usecases/get-province.usecase';
import AESEncryptDecryptService from 'src/app/core/utils/crypto';
import { LocalStorageServiceBase } from 'src/app/core/services/localStorage-serviceBase';
import { UserRol } from 'src/app/core/enums/user-rol.enum';
import { ConfirmationService, MessageService } from 'primeng/api';
import { UpdateClaimStatusModel } from '../../../../data/models/update-claim-status.model';
import { UpdateClaimStatusEntity } from '../../../../domain/entities/update-claim-satus.entity';
import { UpdateClaimStatusUseCase } from '../../../../domain/usecases/update-claim-status.usecase';
import { Toast } from 'primeng/toast';
import { GetFileModel } from '../../../../data/models/get-file.model';
import { GetFileUseCase } from '../../../../domain/usecases/get-file.usecase';
import { GetFileBaseUseCase } from '../../../../domain/usecases/get-file-base.usecase';
import { GetFileBaseModel } from '../../../../data/models/get-file-base.model';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { GetMinutesByClaimIdUseCase } from 'src/app/features/minutes/domain/usecases/get-minutes-by-claim-id.usecase';
import { MinuteEntity } from 'src/app/features/minutes/domain/entities/minute.entity';
import { MinuteModel } from 'src/app/features/minutes/data/models/minute.model';
import { Location } from '@angular/common';
import { AudiencesService } from 'src/app/features/audiences/services/audiences.service';
import { UserData } from 'src/app/features/user/domain/entities/user-data.entity';
import { GetMyDataUseCase } from 'src/app/features/user/domain/usecases/get-my-data.usecase';

@Component({
  selector: 'app-claim-view-worker',
  templateUrl: './claim-view-worker.component.html',
  styleUrls: ['./claim-view-worker.component.css'],
  providers: [MessageService, Toast, ConfirmationService],
})
export class ClaimViewWorkerComponent implements OnInit {
  claimId?: string | null;
  claim?: ClaimEntity;
  provinces: ProvinceEntity[] = [];
  claimetProvince?: ProvinceEntity;
  claimedProvince?: ProvinceEntity;
  userRol?: string;
  private AES = new AESEncryptDecryptService();
  buttons: boolean = false;
  newStatusClaimUpdate?: UpdateClaimStatusModel;
  labelText: string = 'Aceptar';
  rejectDialogVisible: boolean = false;
  rejectionReason: string = '';
  audienciaDialogVisible: boolean = false;
  minuteDialogVisible: boolean = false;

  pdfExtraDocumantationList?: string;
  pdfDNIList?: string[];
  pdfPayCheckList?: string[];
  pdfUrl?: string = '';
  displayModal: boolean = false;
  displayModalImage: boolean = false;
  isLoading: boolean = false;

  minutesForClaim?: MinuteEntity[];
  minuteId: any;
  userData?: UserData;

  constructor(
    private route: ActivatedRoute,
    private claimUseCase: ClaimUseCase,
    private getProvince: GetProvinceUseCase,
    private localStorageService: LocalStorageServiceBase,
    private confirmationService: ConfirmationService,
    private updateClaimStatusUseCase: UpdateClaimStatusUseCase,
    private messageService: MessageService,
    private router: Router,
    private getFileUseCase: GetFileUseCase,
    private getFileBaseUseCase: GetFileBaseUseCase,
    private getMinutesByClaimIdUseCase: GetMinutesByClaimIdUseCase,
    private location: Location,
    private audiencesService: AudiencesService,
    private myData: GetMyDataUseCase
  ) {}

  ngOnInit(): void {
    this.audiencesService.getExtraData();
    this.minuteId = this.audiencesService.getExtraData();

    this.claimId = this.route.snapshot.paramMap.get('id');
    this.userRol = this.AES.decrypt(
      this.localStorageService.loadStorageData('user_rol')
    );
    this.getClaim();
    this.loadProvinces();
    this.showButtons();
    this.getMinutesByClaimId();
    this.getMyData();
  }

  goBack(): void {
    this.location.back();
  }

  handleLoadingChange(loading: boolean) {
    this.isLoading = loading;
  }

  async getClaim(): Promise<void> {
    let result = await this.claimUseCase.execute(this.claimId!);
    if (result instanceof Failure) {
      return;
    }
    this.claim = result;
    this.pdfExtraDocumantationList = result.extraDocumentationPdf;
    this.pdfDNIList = result.claimetFileDni;
    this.pdfPayCheckList = result.claimetFilePaycheck;
  }

  async getMinutesByClaimId(): Promise<void> {
    let result = await this.getMinutesByClaimIdUseCase.execute(this.claimId!);
    if (result instanceof Failure) {
      return;
    }
    this.minutesForClaim = result;
  }

  getStatusClass(status: string | undefined): string {
    switch (status) {
      case ClaimStatus.DRAFT:
        return 'status-draft';
      case ClaimStatus.PENDING:
        return 'status-pending';
      case ClaimStatus.REFUSED:
        return 'status-refused';
      case ClaimStatus.FINALIZED:
        return 'status-finalized';
      case ClaimStatus.IN_INTERNAL_PROCESS:
        return 'status-in-internal-process';
      case ClaimStatus.AUDIENCE_SET:
      case 'Audiencia Fijada':
        return 'status-audience-set';
      case ClaimStatus.ADMITTED:
        return 'status-admited';
      default:
        return '';
    }
  }

  async getMyData(): Promise<void> {
    let result = await this.myData.execute();
    if (result instanceof Failure) {
      return;
    }
    this.userData = result;
  }

  async loadProvinces() {
    try {
      const result = await this.getProvince.execute();
      if (Array.isArray(result)) {
        this.provinces = result;
      }
    } catch (error) {
      throw new UnhandledFailure();
    }
    this.setProvinceById();
  }

  setProvinceById(): void {
    this.claimetProvince = this.provinces.find(
      (prov) => prov.id === this.claim?.claimetProvince
    );
    this.claimedProvince = this.provinces.find(
      (prov) => prov.id === this.claim?.claimedProvince
    );
  }

  showButtons() {
    if (
      this.userRol == UserRol.ADMINISTRATOR ||
      this.userRol == UserRol.CONCILIATOR
    ) {
      this.buttons = true;
    }
  }

  recibeAceptDialog(action: string): void {
    if (action == 'Admitir') {
      this.updateAdmittedStatusCheck();
    } else {
      this.updateRefucedStatusCheck();
    }
  }

  updateAdmittedStatusCheck() {
    this.labelText = 'Admitir reclamo';
    this.confirmationService.confirm({
      header: 'Admitir reclamo',
      message: `¿Desea admitir el reclamo?`,
      accept: () => {
        this.newStatusClaimUpdate = new UpdateClaimStatusModel(
          this.claimId!,
          ClaimStatus.ADMITTED
        );
        this.updateClaimStatus(this.newStatusClaimUpdate);
      },
    });
  }

  updateRefucedStatusCheck() {
    this.labelText = 'Rechazar reclamo';
    this.confirmationService.confirm({
      header: 'Rechazar reclamo',
      message: `¿Desea rechazar el reclamo?`,
      accept: () => {
        this.rejectDialogVisible = true;
      },
      reject: () => {
        this.rejectionReason = '';
      },
    });
  }

  confirmRejection() {
    if (this.rejectionReason.trim()) {
      this.newStatusClaimUpdate = new UpdateClaimStatusModel(
        this.claimId!,
        ClaimStatus.REFUSED,
        this.rejectionReason
      );
      this.updateClaimStatus(this.newStatusClaimUpdate);
      this.rejectDialogVisible = false;
      this.rejectionReason = '';
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Ocurrió un problema.',
        detail: 'Debe especificar el motivo de rechazo.',
        life: 1200,
      });
    }
    this.rejectionReason = '';
  }

  async updateClaimStatus(claim: UpdateClaimStatusEntity): Promise<void> {
    let result = await this.updateClaimStatusUseCase.execute(claim);
    if (result instanceof Failure) {
      this.messageService.add({
        severity: 'error',
        summary: 'Ocurrió un problema.',
        detail: 'Estado no actualizado.',
        life: 900,
      });
      return;
    }
    this.messageService.add({
      severity: 'success',
      summary: 'Estado actualizado',
      detail: 'El estado se actualizó exitosamente.',
    });
    this.getClaim();
  }

  newAudienceCheck() {
    this.audienciaDialogVisible = true;
  }

  newMinuteCheck() {
    this.minuteDialogVisible = true;
  }

  cancel(close: any) {
    this.audienciaDialogVisible = false;
    this.minuteDialogVisible = false;
  }

  handleShowMessage(event: {
    severity: string;
    summary: string;
    detail: string;
  }) {
    this.messageService.add({
      severity: event.severity,
      summary: event.summary,
      detail: event.detail,
    });
  }

  async viewMinutePdf(id: string, pdf: string) {
    this.displayModal = false;
    this.displayModalImage = false;
    let extraDocFiles = new GetFileBaseModel(id, pdf);
    this.pdfUrl = await this.getFileBase(extraDocFiles);

    if (this.isFileFormatPdf(`data:application/pdf;base64,${this.pdfUrl}`)) {
      this.displayModal = true;
    } else if (
      this.isImageFormat(`data:image/png;base64,${this.pdfUrl}`) ||
      this.isImageFormat(`data:image/jpeg;base64,${this.pdfUrl}`) ||
      this.isImageFormat(`data:image/webp;base64,${this.pdfUrl}`)
    ) {
      this.displayModalImage = true;
    }
  }

  isFileFormatPdf(url: string): boolean {
    return url.startsWith('data:application/pdf');
  }

  async viewImage(fileName: string) {
    try {
      let fileData = new GetFileModel(this.claim?.id!, fileName);
      this.pdfUrl = await this.getFile(fileData);

      const fileExtension = fileName.split('.').pop()?.toLowerCase();
      const isImageExt = ['jpg', 'jpeg', 'png', 'webp', 'gif'].includes(
        fileExtension || ''
      );

      if (isImageExt) {
        this.displayModalImage = true;
        this.displayModal = false;
      } else {
        this.displayModal = true;
        this.displayModalImage = false;
      }
    } catch (error) {
      console.error('Error al visualizar el archivo:', error);
      this.displayModal = false;
      this.displayModalImage = false;
    }
  }

  isImageFormat(url: string): boolean {
    try {
      const mimeType = url.split(';')[0].split(':')[1];
      return mimeType.startsWith('image/');
    } catch {
      return false;
    }
  }

  async getFile(param: GetFileModel): Promise<string> {
    let result = await this.getFileUseCase.execute(param);
    if (result instanceof Failure) {
      return result.message;
    }
    return result;
  }

  async getFileBase(param: GetFileBaseModel): Promise<string> {
    let result = await this.getFileBaseUseCase.execute(param);
    if (result instanceof Failure) {
      return result.message;
    }
    return result;
  }

  async downloadPdf(pdf: string) {
    let extraDocFiles = new GetFileModel(this.claim?.id!, pdf);
    let base64 = await this.getFile(extraDocFiles);

    let mimeType = 'application/pdf';
    let extension = 'pdf';

    switch (true) {
      case base64.startsWith('iVBORw0KGgo'):
        mimeType = 'image/png';
        extension = 'png';
        break;
      case base64.startsWith('/9j/'):
        mimeType = 'image/jpeg';
        extension = 'jpeg';
        break;
      default:
        mimeType = 'application/pdf';
        extension = 'pdf';
        break;
    }

    const link = document.createElement('a');
    link.href = `data:${mimeType};base64,${base64}`;
    link.download = `document.${extension}`;
    link.click();
  }

  async downloadPdfMinute(id: string, fileActa: string) {
    let extraDocFiles = new GetFileBaseModel(id, fileActa);
    let base64 = await this.getFileBase(extraDocFiles);

    let mimeType = 'application/pdf';
    let extension = 'pdf';

    switch (true) {
      case base64.startsWith('iVBORw0KGgo'):
        mimeType = 'image/png';
        extension = 'png';
        break;
      case base64.startsWith('/9j/'):
        mimeType = 'image/jpeg';
        extension = 'jpeg';
        break;
      default:
        mimeType = 'application/pdf';
        extension = 'pdf';
        break;
    }

    const link = document.createElement('a');
    link.href = `data:${mimeType};base64,${base64}`;
    link.download = `document.${extension}`;
    link.click();
  }

  async downloadAsPdf() {
    const element = document.getElementById('claim-view');
    const downloadButton = document.querySelector(
      'p-button[label="Descargar"]'
    );

    if (!element) {
      return;
    }

    try {
      if (downloadButton) {
        downloadButton.classList.add('hidden');
      }

      const canvas = await html2canvas(element, {
        scale: 2,
        useCORS: true,
        logging: false,
      });

      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'mm',
        format: 'a4',
      });

      const pageWidth = 210;
      const pageHeight = 297;

      const aspectRatio = canvas.width / canvas.height;
      const imgWidth = pageWidth - 20;
      const imgHeight = imgWidth / aspectRatio;
      const xPosition = 10;
      let yPosition = 10;
      let heightLeft = imgHeight;

      pdf.addImage(imgData, 'PNG', xPosition, yPosition, imgWidth, imgHeight);
      heightLeft -= pageHeight - 20;

      while (heightLeft > 0) {
        pdf.addPage();
        pdf.addImage(
          imgData,
          'PNG',
          xPosition,
          yPosition - (imgHeight - heightLeft),
          imgWidth,
          imgHeight
        );
        heightLeft -= pageHeight - 20;
      }

      pdf.save('reclamo.pdf');
    } catch (error) {
      this.messageService.add({
        severity: 'error',
        summary: 'Ocurrió un problema.',
        detail: 'Error al descargar el archivo.',
      });
    } finally {
      if (downloadButton) {
        downloadButton.classList.remove('hidden');
      }
    }
  }
}
