import { Failure } from "src/app/core/utils/failure";
import { UseCase } from "src/app/core/utils/usecase";
import { MinuteRepositoryBase } from "../repositories/minute-base.repository";
import { MinuteEntity } from "../entities/minute.entity";
import { Injectable } from "@angular/core";

@Injectable()
export class GetMinutesByClaimIdUseCase implements UseCase<string, MinuteEntity[] | Failure> {
    
    constructor(private minuteRepository: MinuteRepositoryBase) {}
   
    async execute(param: string): Promise<MinuteEntity[] | Failure> {
        return await this.minuteRepository.getMinutesByClaimId(param);
    }    
}