import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MessageService } from 'primeng/api';
import { Failure } from 'src/app/core/utils/failure';
import { GetFileModel } from 'src/app/features/claims/data/models/get-file.model';
import { ClaimCollectiveEntity } from 'src/app/features/claims/domain/entities/claim-colective.entity';
import { GetFileColectiveUseCase } from 'src/app/features/claims/domain/usecases/get-file-colective.usecase';

@Component({
  selector: 'app-claimed-details-colective',
  templateUrl: './claimed-details-colective.component.html',
  styleUrls: ['./claimed-details-colective.component.css'],
})
export class ClaimedDetailsColectiveComponent {
  @Output() claimantDetailsForm = new EventEmitter<FormGroup>();
  @Output() stepIndex: EventEmitter<number> = new EventEmitter();
  @Output() activeModal: EventEmitter<boolean> = new EventEmitter();
  @Input() claim?: ClaimCollectiveEntity;

  formGroup!: FormGroup;

  isDragging: boolean = false;
  pdfFiles: any[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private getFileUseCase: GetFileColectiveUseCase
  ) {}

  async ngOnInit(): Promise<void> {
    this.initForms();
    if (this.claim) {
      await this.initializeFormWithClaimData();
    }
  }

  private initForms(): void {
    this.formGroup = this.formBuilder.group({
      claimedActivity: [
        'AUTOTRANSPORTE DE CARGAS, LOGISTICA Y SERVICIOS DE SANTA FE',
        Validators.required,
      ],
      claimedCollectiveAgreement: [
        'CAMIONEROS - SANTA FE',
        Validators.required,
      ],
      claimedAssociation: [
        'SINDICATO DE CONDUCTORES DE CAMIONES OBREROS Y EMPLEADOS DEL TRANSPORTE AUTOMOTOR DE CARGAS LOGÍSTICA Y SERVICIOS DE LA PROVINCIA DE SANTA FE',
        Validators.required,
      ],
      affectedWorkers: ['', Validators.required],
      claimedEmail: ['', [Validators.required, Validators.email]],
      claimedFiles: [, [this.CountValidatorPaycheck]],
    });
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes['claim'] && changes['claim'].currentValue) {
      await this.initializeFormWithClaimData();
    }
  }

  async initializeFormWithClaimData(): Promise<void> {
    if (!this.formGroup) {
      return;
    }

    this.formGroup.patchValue({
      claimedActivity:
        this.claim?.claimedActivity ||
        'AUTOTRANSPORTE DE CARGAS, LOGISTICA Y SERVICIOS DE SANTA FE',
      claimedCollectiveAgreement:
        this.claim?.claimedCollectiveAgreement || 'CAMIONEROS - SANTA FE',
      claimedAssociation:
        this.claim?.claimedAssociation ||
        'SINDICATO DE CONDUCTORES DE CAMIONES OBREROS Y EMPLEADOS DEL TRANSPORTE AUTOMOTOR DE CARGAS LOGÍSTICA Y SERVICIOS DE LA PROVINCIA DE SANTA FE',
      affectedWorkers: this.claim?.affectedWorkers || '',
      claimedEmail: this.claim?.claimedEmail || '',
    });
    await this.checkFiles();
  }

  private async checkFiles(): Promise<void> {
    try {
      this.pdfFiles = await Promise.all(
        (this.claim?.claimedFiles || []).map(async (fileName, index) => {
          const base64 = await this.getFile(
            new GetFileModel(this.claim!.id!, fileName)
          );
          return base64
            ? {
                name: `Archivo ${index + 1}`,
                base64: `data:application/pdf;base64,${base64}`,
              }
            : null;
        })
      ).then((files) => files.filter((file) => file !== null));

      this.formGroup.get('claimedFiles')?.setValue(this.pdfFiles);
    } catch (error) {
      console.error('Error al inicializar archivos:', error);
      this.formGroup.get('claimedFiles')?.setValue([]);
    }
  }

  async getFile(param: GetFileModel): Promise<string> {
    let result = await this.getFileUseCase.execute(param);
    if (result instanceof Failure) {
      return result.message;
    }
    return result;
  }

  private CountValidatorPaycheck(
    control: AbstractControl
  ): { [key: string]: any } | null {
    return control.value?.length >= 1 && control.value?.length <= 12
      ? null
      : { fileCount: true };
  }

  onDragOver(event: Event) {
    event.preventDefault();
    this.isDragging = true;
  }

  onDropSuccess(event: any, file: string): any {
    const files = event.dataTransfer?.files;

    if (!files || files.length === 0) return;

    event.preventDefault();
    this.isDragging = false;
    this.onFileChange(files);
  }

  private onFileChange(files: any) {
    const newFiles = Array.from(files);
    this.pdfFiles = (this.pdfFiles || []).concat(newFiles);
  }

  onChange(event: any, file: string): any {
    const input = event.target as HTMLInputElement;
    const files = event.target.files;
    const fileForm = file;

    if (files.length > 0) {
      if (this.pdfFiles.length > 11) {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail:
            'Solo puedes subir hasta 12 archivos (JPG, WEBP, HEIF o PDF).',
        });
        return false;
      }

      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        if (
          ![
            'image/jpeg',
            'image/png',
            'image/webp',
            'image/heif',
            'application/pdf',
          ].includes(file.type)
        ) {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Solo se permiten archivos JPG, WEBP, HEIF o PDF.',
          });
          return false;
        }

        this.convertToBase64(file, fileForm);
      }

      input.value = '';
    }
  }

  private async convertToBase64(file: any, fileForm: string) {
    const reader = new FileReader();
    reader.onload = () => {
      const base64String = reader.result?.toString();
      if (base64String) {
        this.assignFilesReceipt(file, base64String);
      }
    };
    reader.onerror = (error) => {
      console.error('FileReader error:', error);
    };
    reader.readAsDataURL(file);
  }

  private assignFilesReceipt(file: any, base64String: string) {
    if (this.pdfFiles.length >= 12) return;

    this.pdfFiles.push({ name: file.name, base64: base64String });

    const filePaycheckBase64Array = this.pdfFiles.map((file) => file.base64);
    this.formGroup.get('claimedFiles')?.setValue(filePaycheckBase64Array);
    this.formGroup.get('claimedFiles')?.markAsTouched();
    this.formGroup.get('claimedFiles')?.updateValueAndValidity();
  }

  deleteFilePfd(index: number) {
    this.pdfFiles.splice(index, 1);
    const fileReceiptBase64Array = this.pdfFiles.map((file) => file.base64);
    this.formGroup
      .get('claimedFiles')
      ?.setValue(fileReceiptBase64Array.length ? fileReceiptBase64Array : null);
    this.formGroup.get('claimedFiles')?.markAsTouched();
    this.formGroup.get('claimedFiles')?.markAsDirty();
  }

  hasErrors(controlName: string, errorType: string) {
    return (
      this.formGroup.get(controlName)?.hasError(errorType) &&
      this.formGroup.get(controlName)?.touched
    );
  }

  nextStep() {
    this.claimantDetailsForm.emit(this.formGroup);
    this.stepIndex.emit(2);
  }

  back() {
    this.stepIndex.emit(0);
  }

  cancel() {
    this.claimantDetailsForm.emit(this.formGroup);
    this.activeModal.emit(true);
  }
}
