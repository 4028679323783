import { Entity } from "src/app/core/utils/entity";

export abstract class ForgotPasswordSendEmailEntity extends Entity {
    constructor(
        public email: string
    ){
        super();
    }
    
    abstract getPath():string;
}