import { UseCase } from "src/app/core/utils/usecase";
import { UserData } from "../entities/user-data.entity";
import { Failure } from "src/app/core/utils/failure";
import { UserRepositoryBase } from "../repositories/user-base.repository";
import { Injectable } from "@angular/core";
import { ComisionUser } from "../entities/comision-user.entity";

@Injectable()
export class UpdateUserUseCase implements UseCase<ComisionUser, string | Failure> {
    constructor(private UserRepository: UserRepositoryBase) { }

    async execute(param: ComisionUser): Promise<string | Failure> {
        return await this.UserRepository.updateUser(param);
    }
}
