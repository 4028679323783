import { Component, Input, OnInit } from '@angular/core';
import { AudienceEntity } from '../../../domain/entities/audience.entity';
import { ActivatedRoute, Router } from '@angular/router';
import { AudienceUseCase } from '../../../domain/usecases/get-audience.usecase';
import { Failure } from 'src/app/core/utils/failure';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { Toast } from 'primeng/toast';
import { UserData } from 'src/app/features/user/domain/entities/user-data.entity';
import { UserService } from 'src/app/features/user/services/user.service';
import { AudiencePagination } from '../../../domain/entities/audience-pagination.entity';
import { GetPaginationAudiencesUseCase } from '../../../domain/usecases/get-pagination-audiences.usecase';
import { AudiencePaginationParamsModel } from '../../../data/models/audience-pagination-params.model';
import { TableLazyLoadEvent } from 'primeng/table';
import { UserRol } from 'src/app/core/enums/user-rol.enum';
import { LocalStorageServiceBase } from 'src/app/core/services/localStorage-serviceBase';
import AESEncryptDecryptService from 'src/app/core/utils/crypto';
import { GetNextAudienceOfConciliatorUseCase } from '../../../domain/usecases/get-next-audience-of-conciliator.usecase';
import { GetPaginationAudiencesofConciliatorUseCase } from '../../../domain/usecases/get-pagination-Conciliator-audiences.usecase';

@Component({
  selector: 'app-audience-table-next',
  templateUrl: './audience-table-next.component.html',
  styleUrls: ['./audience-table-next.component.css'],
  providers: [MessageService, Toast, ConfirmationService]
})
export class AudienceTableComponentNext implements OnInit {
  @Input() audiences!: AudiencePagination | undefined;
  @Input() userData?: UserData;

  defaultRows: number = 5;
  defaultPage: number = 0;
  public totalQuantity!: number;
  public first: number = 0;
  public itemsPerPage: number = 5;
  public amountRegistersPage = this.itemsPerPage;
  private AES = new AESEncryptDecryptService();
  rol: string = this.AES.decrypt(this.localStorageService.loadStorageData('user_rol'));
  userId: string = this.AES.decrypt(this.localStorageService.loadStorageData('user_id'));
  nextAudienceOfConciliator?: AudienceEntity;
  nextAudienceDate?: Date;
  isComisionMemberRol: boolean = false;
  isAdministratorRol: boolean = false;
  isConciliatorRol: boolean = false;
  isComisionRol: boolean = false;
  nextAudience: boolean = false;

  allAudiences: AudienceEntity[] = [];
  paginatedData: AudiencePagination | undefined = undefined;
  isLoading: boolean = true;
  homeView: boolean = false;

  audience?: AudienceEntity
  audienceId?: string | null;

  public items: MenuItem[] = [];
  public singleItem: MenuItem[] = [
    {
      label: 'Ver reclamo',
      icon: 'pi pi-eye',
      command: () => this.viewClaim(),
    },
  ];

  public twoOptionsItems: MenuItem[] = [
    {
      label: 'Link a Google Meet',
      icon: 'pi pi-trash',
      command: () => this.showOptionPopup(),
    },
    {
      label: 'Ver reclamo',
      icon: 'pi pi-calendar-plus',
      command: () => this.viewClaim(),
    }
  ];

  constructor(
    private getPaginationAudiencesUseCase: GetPaginationAudiencesUseCase,
    private getNextAudienceOfConciliatorUseCase: GetPaginationAudiencesofConciliatorUseCase,
    private userService: UserService,
    private router: Router,
    private audienceUseCase: AudienceUseCase,
    private messageService: MessageService,
    private localStorageService: LocalStorageServiceBase,
    private confirmationService: ConfirmationService,) { }

  ngOnInit(): void {
    this.setRol();
    this.suscribeToUserGetMe();
    if (this.isConciliatorRol) {
      this.getNextAudiencesOfConciliator(this.defaultPage, this.defaultRows, this.userId);
    }
    if (this.isAdministratorRol || this.isComisionMemberRol || this.isComisionRol){
      this.getAudiencesPaginated(this.defaultPage, this.defaultRows);
    }

    this.checkHomeView();
  }

  setRol(){
    switch (this.rol) {
      case UserRol.COMISSION:
        return this.isComisionRol = true;
      case UserRol.COMISSION_MEMBER:
        return this.isComisionMemberRol = true;
      case UserRol.ADMINISTRATOR:
        return this.isAdministratorRol = true;
      case UserRol.CONCILIATOR:
        return this.isConciliatorRol = true;
      default:
        return;
    }
  }

  suscribeToUserGetMe(): void {
    this.userService.userData$.subscribe((user: UserData) => {
      this.userData = this.userService.userData;
    });
  }

  async getAudiencesPaginated(page: number, quantity: number): Promise<void> {
    let params = new AudiencePaginationParamsModel(page, quantity);
    let result = await this.getPaginationAudiencesUseCase.execute(params);
    if (result instanceof Failure) {
      return;
    }
    this.allAudiences = this.sortByDate(result.response);
    this.paginatedData = result;
    this.totalQuantity = result.totalQuantity;
    if(this.totalQuantity != null || this.totalQuantity != ""){
      this.nextAudience = true;
    }
  }

  async getNextAudiencesOfConciliator(page: number, quantity: number, IdUser:string): Promise<void> {
    let params = new AudiencePaginationParamsModel(page, quantity, this.userId);
    let result = await this.getNextAudienceOfConciliatorUseCase.execute(params);
    if (result instanceof Failure) {
      return;
    }
    this.allAudiences = this.sortByDate(result.response);
    this.paginatedData = result;
    this.totalQuantity = result.totalQuantity;
    if(this.totalQuantity != null || this.totalQuantity != ""){
      this.nextAudience = true;
    }
  }

  async getAudience(): Promise<void> {
    let result = await this.audienceUseCase.execute(this.audienceId!);
    if (result instanceof Failure) {
      return;
    }
    this.audience = result;
  }

  changePage(event: any) {
    this.first = event.first; this.defaultPage = event.page + 1;
    this.amountRegistersPage = event.rows;
    this.itemsPerPage = event.rows
    if (this.isConciliatorRol) {
      this.getNextAudiencesOfConciliator(this.defaultPage, this.defaultRows, this.userId);
    }
    if (this.isAdministratorRol || this.isComisionMemberRol || this.isComisionRol){
      this.getAudiencesPaginated(this.defaultPage, this.defaultRows);
    }
  }

  loadRecords(event: TableLazyLoadEvent) {
    this.getAudiencesPaginated(event.first || this.defaultPage, event.rows || this.defaultRows);
  }

  sortByDate(claims: AudienceEntity[]): AudienceEntity[] {
    return claims.sort((a, b) => {
      const dateA = new Date(a.date).getTime();
      const dateB = new Date(b.date).getTime();
      return dateB - dateA;
    });
  }

  selectAmountComisionItems() {
    switch (this.userData?.rol) {
      case UserRol.CONCILIATOR:
        this.items = this.twoOptionsItems;
        break;
      case UserRol.ADMINISTRATOR:
        this.items = this.twoOptionsItems;
        break;
      case UserRol.COMISSION_MEMBER:
        this.items = this.singleItem;
        break;
      default:
        this.items = this.twoOptionsItems;
        break;
    }
  }

  selectAudience(id: string) {
    this.audienceId = id;
    this.getAudience();
  }

  checkHomeView(): void {
    if (this.router.url.includes('/main/home')) {
      this.homeView = true;
    }
  }

  viewClaim() { };
  showOptionPopup() { };
}
