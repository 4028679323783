<div class="card flex justify-content-center w-full relative">
  <p-toast />
  <p-confirmDialog #cd>
    <ng-template pTemplate="headless" let-message class="congirmDialog">
      <div
        class="flex flex-column align-items-center p-5 surface-overlay border-round"
      >
        <span class="labelTitle1">
          {{ message.header }}
        </span>
        <div class="flex justify-content-center align-items-center gap-2">
          <i class="pi pi-exclamation-circle"></i>
          <p class="text-base">{{ message.message }}</p>
        </div>
        <div
          class="flex flex-row justify-content-center align-items-center gap-2 mt-3"
        >
          <ng-container class="col-12">
            <button
              pButton
              label="Cancelar"
              (click)="cd.reject()"
              class="btn-cancelar col-6"
              style="
                border: 1px solid #263245;
                background-color: white;
                color: #263245;
                font-size: 0.8rem;
              "
            ></button>
            <button
              pButton
              label="Aceptar"
              (click)="cd.accept()"
              class="btn-siguiente col-6 popup"
              style="
                background-color: #263245;
                border-color: #263245;
                color: white !important;
                font-size: 0.8rem;
              "
            ></button>
          </ng-container>
        </div>
      </div>
    </ng-template>
  </p-confirmDialog>
</div>
<div>
  <p-dialog
    header="Fijar Audiencia"
    [(visible)]="audienciaDialogVisible"
    [modal]="true"
    [closable]="false"
    [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
    [draggable]="false"
  >
    <app-audience
      (closePopup)="cancel($event)"
      (createAudienceOk)="createAudienceOk($event)"
      [claim]="claim"
      (showMessage)="handleShowMessage($event)"
    ></app-audience>
  </p-dialog>
</div>

<div *ngIf="!userClaims">
  <ng-container class="empty-home-container">
    <div class="flex flex-column justify-content-center align-items-center">
      <img
        id="image"
        src="../../../../../../assets/img/main/file-searching.svg"
        alt=""
      />
      <p class="mt-4 text-lg font-normal">
        Aún no tiene reclamos laborales para visualizar
      </p>
    </div>
  </ng-container>
</div>

<div *ngIf="userClaims" class="grid w-full relative p-3">
  <p-tabView class="w-full">
    <p-tabPanel class="w-full">
      <ng-template pTemplate="header">
        <div>Reclamos individuales</div>
      </ng-template>

      <ng-template pTemplate="content">
        <p-table
          [tableStyle]="{ 'min-width': '100%' }"
          [rowHover]="true"
          *ngIf="skeleton"
          [value]="skeletons"
        >
          <ng-template pTemplate="header">
            <tr class="font-bold text-xs">
              <th style="width: 20%">Reclamante</th>
              <th style="width: 20%">Reclamado</th>
              <th style="width: 15%">Nº de reclamo</th>
              <th style="width: 15%">Nº de expte.</th>
              <th style="width: 15%">Conciliador</th>
              <th style="width: 15%">Estado</th>
              <th></th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-skeleton>
            <tr>
              <td class="text-center"><p-skeleton></p-skeleton></td>
              <td class="text-center"><p-skeleton></p-skeleton></td>
              <td class="text-center"><p-skeleton></p-skeleton></td>
              <td class="text-center"><p-skeleton></p-skeleton></td>
              <td class="text-center"><p-skeleton></p-skeleton></td>
              <td class="text-center"><p-skeleton></p-skeleton></td>
              <td class="text-center"><p-skeleton></p-skeleton></td>
            </tr>
          </ng-template>
        </p-table>

        <p-table
          #dtReclamosIndi
          *ngIf="userClaims && !skeleton"
          [value]="userClaims"
          [tableStyle]="{ 'min-width': '100%' }"
        >
          <ng-template pTemplate="caption" class="col-12" *ngIf="!homeView">
            <app-claim-filter
              class="modal-content"
              (filters)="onFiltersApplied($event)"
              (cancel)="onFilterCancel()"
            ></app-claim-filter>
          </ng-template>

          <ng-template pTemplate="header">
            <tr class="font-bold text-xs">
              <th style="width: 20%">Reclamante</th>
              <th style="width: 20%">Reclamado</th>
              <th style="width: 15%">Nº de reclamo</th>
              <th style="width: 15%">Nº de expte.</th>
              <th
                [ngClass]="isAdministratorRol ? 'text-center' : 'text-left'"
                style="width: 15%"
              >
                Conciliador
              </th>
              <th class="text-center" style="width: 15%">Estado</th>
              <th></th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-claim>
            <tr class="table-row">
              <td class="text-left">
                <ng-container
                  *ngIf="
                    claim.nameLastNameOrBusinessNameClaimet;
                    else showClaimantName
                  "
                >
                  {{ claim.nameLastNameOrBusinessNameClaimet | truncate }}
                </ng-container>
                <ng-template #showClaimantName>
                  {{
                    claim.claimentName + " " + claim.claimentLastName | truncate
                  }}
                </ng-template>
              </td>
              <td class="text-left">
                {{ claim.nameLastNameOrBusinessNameClaimed | truncate }}
              </td>
              <td class="text-left">{{ claim.number_of_claim }}</td>
              <td class="text-left">{{ claim.expediente_claim }}</td>
              <td
                class="text-left"
                *ngIf="isComisionMemberRol || isConciliatorRol || isComisionRol"
              >
                {{ claim.conciliatorFullName | truncate }}
              </td>
              <td
                class="text-left"
                style="width: 10%"
                *ngIf="isAdministratorRol"
              >
                <p-dropdown
                  [options]="conciliators"
                  class="conciliator"
                  (onClick)="dropdownClick(claim)"
                  (onChange)="updateConciliatorCheck($event)"
                  appendTo="body"
                  [placeholder]="
                    claim.conciliatorFullName
                      ? claim.conciliatorFullName
                      : 'No asignado'
                  "
                  [disabled]="!isConciliatorChangeAllowed(claim.status)"
                >
                  <ng-template pTemplate="selectedItem" let-selectedOption>
                    <span style="text-align: end"
                      >{{
                        selectedOption.name + " " + selectedOption.last_name
                          | truncate
                      }}
                    </span>
                  </ng-template>
                  <ng-template let-state pTemplate="item">
                    <span [ngClass]="{ 'no-assigned-option': !state.id }">
                      {{ state.name + " " + state.last_name | truncate }}
                    </span>
                  </ng-template>
                </p-dropdown>
              </td>

              <td
                style="width: 10%"
                *ngIf="isComisionMemberRol || isComisionRol"
              >
                <p [ngClass]="getStatusClass(claim.status)">
                  {{ claim.status | claimStatusDisplay }}
                </p>
              </td>
              <td
                style="width: 10%"
                *ngIf="isAdministratorRol || isConciliatorRol"
              >
                <span
                  *ngIf="
                    claim.status !== 'EnTramiteInterno' &&
                    claim.status !== 'AudienciaFijada'
                  "
                  [ngClass]="getStatusClass(claim.status)"
                  >{{ claim.status | claimStatusDisplay }}
                </span>
                <p-dropdown
                  *ngIf="
                    claim.status === 'EnTramiteInterno' ||
                    claim.status === 'AudienciaFijada'
                  "
                  [options]="status"
                  [ngClass]="getStatusClassForPrincipalView(claim.status)"
                  class="status-dropdown"
                  [placeholder]="claim.status | claimStatusDisplay"
                  (onClick)="dropdownClick(claim)"
                  (onChange)="updateStatusCheck($event)"
                  appendTo="body"
                >
                  <ng-template pTemplate="selectedItem" let-selectedOption>
                    <span
                      [ngClass]="getStatusClass(selectedOption ?? claim.status)"
                      >{{ claim.status }}
                    </span>
                  </ng-template>
                  <ng-template let-state pTemplate="item">
                    <span class="px-3" [ngClass]="getStatusClass(state)"
                      >{{ state | claimStatusDisplay }}
                    </span>
                  </ng-template>
                </p-dropdown>
              </td>

              <td>
                <p-menu
                  #menu
                  [popup]="true"
                  [model]="items"
                  appendTo="body"
                ></p-menu>
                <button
                  type="button"
                  pButton
                  icon="pi pi-ellipsis-v"
                  (click)="
                    selectMenuItemsByRol(claim.status);
                    menu.toggle($event);
                    selectClaim(claim.id)
                  "
                  class="p-button-rounded p-button-text"
                  style="width: 2.5rem"
                ></button>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="10">
                <div class="flex flex-column align-items-center">
                  <img
                    src="../../../../../../assets/img/main/file-searching.svg"
                    alt="imagen de archivo"
                    style="width: 200px; height: 250px"
                  />
                  <p>No se encontraron resultados para la busqueda</p>
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>

        <p-paginator
          *ngIf="!homeView || !pagination.totalQuantity"
          (onPageChange)="changePage($event)"
          [first]="(pagination.actualPage - 1) * pagination.itemsPerPage"
          [rows]="pagination.itemsPerPage"
          [totalRecords]="pagination.totalQuantity"
          [rowsPerPageOptions]="[5, 10, 25, 50]"
        >
        </p-paginator>
      </ng-template>
    </p-tabPanel>

    <p-tabPanel class="w-full">
      <ng-template pTemplate="header">
        <div>Reclamos colectivos</div>
      </ng-template>
      <ng-template pTemplate="content">
        <app-claims-colective-table></app-claims-colective-table>
      </ng-template>
    </p-tabPanel>

    <p-tabPanel class="w-full">
      <ng-template pTemplate="header">
        <div>Reclamos plurindividuales</div>
      </ng-template>
      <ng-template pTemplate="content">
        <app-claims-multi-single-table></app-claims-multi-single-table>
      </ng-template>
    </p-tabPanel>
  </p-tabView>
</div>
