import { GetFileEntity } from "../../domain/entities/get-file.entity";

export class GetFileModel extends GetFileEntity {
    
    override getPath(): string {
        let url: string = '?';
        url += `id=${this.claimId}`
        url += `&file_name=${this.fileName}`
        return url;
    }

    override toJson(): Map<string, any> {
        let json: Map<string, any> = new Map();
        json.set('id', this.claimId);
        json.set('file_name', this.fileName);
        return json;
    }
}