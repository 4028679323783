
<div class="w-8 sm:w-8 md:w-8 lg:w-7">
    <h2 class="mb-0">Crear cuenta</h2>
    <p> {{selectedRol}} </p>
    
    <div class="information">
        <img src="../../../../../../../assets/img/register/check-account.png" alt="">
        <span>Su registro ha sido exitoso. Pronto recibirá un correo electrónico para validar su cuenta.</span>
    </div>
</div>
