<div class="card flex justify-content-center w-full relative">
  <div class="w-full">
    <form
      [formGroup]="companyForm"
      class="w-full"
      (ngSubmit)="onSubmit($event)"
    >
      <div class="grid">
        <!-- Razon Social, CUIT, Correo electrónico -->
        <div class="col-12 md:col-4 xl:col-3">
          <label class="labelTitle" for="companyName">Razón social <span class="label"> *</span></label>
          <input
            id="companyName"
            placeholder="Razón social"
            type="text"
            pInputText
            formControlName="companyName"
            [ngClass]="{
              'ng-invalid ng-dirty':
                hasErrors('companyName', 'required') ||
                hasErrors('companyName', 'maxlength')
            }"
          />
          <div
            class="text-danger p-error"
            *ngIf="hasErrors('companyName', 'required')"
          >
            Este campo es obligatorio
          </div>
          <div
            class="text-danger p-error"
            *ngIf="hasErrors('companyName', 'maxlength')"
          >
            Este campo no puede poseer más de 50 caracteres
          </div>
        </div>

        <div class="col-12 md:col-4 xl:col-3">
          <label class="labelTitle" for="cuit">CUIT<span class="label"> *</span></label>
          <p-inputMask
            mask="99-99999999-9"
            placeholder="99-99999999-9"
            formControlName="cuit"
            [ngClass]="{
              'ng-invalid ng-dirty': hasErrors('cuit', 'required')
            }"
          ></p-inputMask>
          <div
            class="text-danger p-error"
            *ngIf="hasErrors('cuit', 'required')"
          >
            Este campo es obligatorio
          </div>
        </div>

        <div class="col-12 md:col-4 xl:col-3">
          <label class="labelTitle" for="email">Correo electrónico<span class="label"> *</span></label>
          <input
            id="email"
            placeholder="Correo electrónico"
            type="email"
            pInputText
            formControlName="email"
            [ngClass]="{
              'ng-invalid ng-dirty':
                hasErrors('email', 'required') ||
                hasErrors('email', 'maxlength')
            }"
          />
          <div
            class="text-danger p-error"
            *ngIf="hasErrors('email', 'required')"
          >
            Este campo es obligatorio
          </div>
        </div>

        <!--  Teléfono,  -->

        <div class="col-12 md:col-4 xl:col-3">
          <label class="labelTitle" for="phone">Teléfono<span class="label"> *</span></label>
          <input
            id="phone"
            placeholder="Teléfono"
            type="tel"
            pInputText
            formControlName="phone"
            [ngClass]="{
              'ng-invalid ng-dirty':
                hasErrors('phone', 'required') ||
                hasErrors('phone', 'maxlength')
            }"
          />
          <div
            class="text-danger p-error"
            *ngIf="hasErrors('phone', 'required')"
          >
            Este campo es obligatorio
          </div>
          <div
            class="text-danger p-error"
            *ngIf="hasErrors('phone', 'minlength')"
          >
            Este campo no puede poseer menos de 9 caracteres
          </div>
          <div
            class="text-danger p-error"
            *ngIf="hasErrors('phone', 'maxlength')"
          >
            Este campo no puede poseer más de 15 caracteres
          </div>
          <div
            class="text-danger p-error"
            *ngIf="hasErrors('phone', 'pattern')"
          >
            El campo solo acepta números.
          </div>
        </div>

        <!-- Calle, Número, Piso/Depto, Torre, Código postal -->
        <div class="col-12 md:col-4 xl:col-3">
          <label class="labelTitle" for="street">Calle<span class="label"> *</span></label>
          <input
            id="street"
            placeholder="Calle"
            type="text"
            pInputText
            formControlName="street"
            [ngClass]="{
              'ng-invalid ng-dirty':
                hasErrors('street', 'required') ||
                hasErrors('street', 'maxlength')
            }"
          />
          <div
            class="text-danger p-error"
            *ngIf="hasErrors('street', 'required')"
          >
            Este campo es obligatorio
          </div>
        </div>
        <div class="col-12 md:col-4 xl:col-3">
          <label class="labelTitle" for="number">Número<span class="label"> *</span></label>
          <input
            id="number"
            placeholder="Número"
            type="text"
            pInputText
            formControlName="number"
            [ngClass]="{
              'ng-invalid ng-dirty':
                hasErrors('number', 'required') ||
                hasErrors('number', 'maxlength')
            }"
          />
          <div
            class="text-danger p-error"
            *ngIf="hasErrors('number', 'required')"
          >
            Este campo es obligatorio
          </div>
          <div
            class="text-danger p-error"
            *ngIf="hasErrors('number', 'pattern')"
          >
            El campo solo acepta números.
          </div>
        </div>
        <div class="col-12 md:col-4 xl:col-3">
          <label class="labelTitle" for="floorApartment">Piso/Depto</label>
          <input
            id="floorApartment"
            placeholder="Piso/Depto"
            type="text"
            pInputText
            formControlName="floorApartment"
            [ngClass]="{
              'ng-invalid ng-dirty':
                hasErrors('floorApartment', 'required') ||
                hasErrors('floorApartment', 'maxlength')
            }"
          />
        </div>
        <div class="col-12 md:col-4 xl:col-3">
          <label class="labelTitle" for="tower">Torre</label>
          <input
            id="tower"
            placeholder="Torre"
            type="text"
            pInputText
            formControlName="tower"
            [ngClass]="{
              'ng-invalid ng-dirty':
                hasErrors('tower', 'required') ||
                hasErrors('tower', 'maxlength')
            }"
          />
        </div>
        <div class="col-12 md:col-4 xl:col-3">
          <label class="labelTitle" for="postalCode">Código postal<span class="label"> *</span></label>
          <input
            id="postalCode"
            placeholder="Código postal"
            type="text"
            pInputText
            formControlName="postalCode"
            [ngClass]="{
              'ng-invalid ng-dirty':
                hasErrors('postalCode', 'required') ||
                hasErrors('postalCode', 'maxlength')
            }"
          />
          <div
            class="text-danger p-error"
            *ngIf="hasErrors('postalCode', 'required')"
          >
            Este campo es obligatorio
          </div>
          <div
            class="text-danger p-error"
            *ngIf="hasErrors('postalCode', 'pattern')"
          >
            El campo solo acepta números.
          </div>
        </div>

        <!-- Barrio, Provincia, Localidad -->
        <div class="col-12 md:col-4 xl:col-3">
          <label class="labelTitle" for="neighborhood">Barrio<span class="label"> *</span></label>
          <input
            id="neighborhood"
            placeholder="Barrio"
            type="text"
            pInputText
            formControlName="neighborhood"
            [ngClass]="{
              'ng-invalid ng-dirty':
                hasErrors('neighborhood', 'required') ||
                hasErrors('neighborhood', 'maxlength')
            }"
          />
          <div
            class="text-danger p-error"
            *ngIf="hasErrors('neighborhood', 'required')"
          >
            Este campo es obligatorio
          </div>
        </div>
        <div class="col-12 md:col-4 xl:col-3">
          <label class="labelTitle" for="province">Provincia<span class="label"> *</span></label>
          <p-dropdown
            id="province"
            [options]="provinces"
            optionLabel="name"
            optionValue="name"
            placeholder="Provincia"
            formControlName="province"
            placeholder="Seleccione la provincia"
            [ngClass]="{
              'ng-invalid ng-dirty':
                hasErrors('province', 'required') ||
                hasErrors('province', 'maxlength')
            }"
          ></p-dropdown>
          <div
            class="text-danger p-error"
            *ngIf="hasErrors('province', 'required')"
          >
            Este campo es obligatorio
          </div>
        </div>
        <div class="col-12 md:col-4 xl:col-3">
          <label class="labelTitle" for="city">Localidad<span class="label"> *</span></label>
          <p-dropdown
            [options]="localities"
            optionLabel="name"
            optionValue="name"
            [showClear]="false"
            id="city"
            placeholder="Localidad"
            formControlName="city"
            placeholder="Seleccione la localidad"
            [ngClass]="{
              'ng-invalid ng-dirty':
                hasErrors('city', 'required') || hasErrors('city', 'maxlength')
            }"
          ></p-dropdown>
          <div
            class="text-danger p-error"
            *ngIf="hasErrors('city', 'required')"
          >
            Este campo es obligatorio
          </div>
        </div>
        <div class="grid w-full flex justify-content-end mt-7">
          <div class="col-6 md:col-2 lg:col-2 xl:col-2">
            <p-button
              (click)="cancel()"
              label="Cancelar"
              class="btn-cancelar sm:text-xs"
            />
          </div>

          <div class="col-6 md:col-2 lg:col-2 xl:col-2 pr-0">
            <p-button
              label="Guardar"
              class="btn-siguiente"
              [disabled]="companyForm.invalid"
              type="submit"
            ></p-button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
