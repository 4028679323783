import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-step-two-inputs',
  templateUrl: './step-two-inputs.component.html',
  styleUrls: ['./step-two-inputs.component.css']
})
export class StepTwoInputsComponent implements OnInit {
  @Input() initialData: any = {};
  @Input() selectedRol?: string;
  @Input() selectedeDoYouAct?: string;

  @Output() data : EventEmitter<FormGroup> = new EventEmitter(); 
  @Output() back : EventEmitter<number> = new EventEmitter(); 

  registerFormTwoImputs: FormGroup = this.formBuilder.group({
    company: [' ' , [Validators.required, Validators.maxLength(100)]],
    company_cuil: [' ' , [Validators.required]],
    comission_email: [' ' , [Validators.required, Validators.email]],
    comission_position: [' ' , [Validators.required]]
  })

  constructor( private formBuilder: FormBuilder){}

  ngOnInit() {
    this.setValidatorsBasedOnRole();
    if (this.initialData) {
      this.registerFormTwoImputs.patchValue(this.initialData);
    }
  }

  nextStep(){
    if (this.registerFormTwoImputs.invalid) return;
    this.data.emit(this.registerFormTwoImputs.value);    
  }

  previusStep(){
    this.back.emit(1);
  }

  hasErrors(controlName: string, errorType: string) {
    return (
      this.registerFormTwoImputs.get(controlName)?.hasError(errorType) &&
      this.registerFormTwoImputs.get(controlName)?.touched
    );
  }

  setValidatorsBasedOnRole() {
    if (this.selectedeDoYouAct !== 'Miembro de comision directiva') {
      this.registerFormTwoImputs.get('comission_position')?.clearValidators();
      this.registerFormTwoImputs.get('comission_position')?.updateValueAndValidity();
      this.registerFormTwoImputs.get('comission_email')?.clearValidators();
      this.registerFormTwoImputs.get('comission_email')?.updateValueAndValidity();
    } else {
      this.registerFormTwoImputs.get('company')?.clearValidators();
      this.registerFormTwoImputs.get('company')?.updateValueAndValidity();
      this.registerFormTwoImputs.get('company_cuil')?.clearValidators();
      this.registerFormTwoImputs.get('company_cuil')?.updateValueAndValidity();
    }
  }
}
