<p-button
  class="btn-new-claim"
  label="Nuevo reclamo laboral"
  icon="pi pi-plus"
  (click)="showDialog()"
></p-button>

<p-dialog
  header="Tipo de reclamo"
  [modal]="true"
  [(visible)]="visible"
  [style]="dialogStyle"
  [breakpoints]="{'960px': '95vw'}"
  [closable]="true"
  [closeOnEscape]="true"
>
  <div class="claim-type-container">
    <div class="grid">
      <div class="col-12 sm:col-4">
        <div
          class="claim-type-option flex flex-column align-items-center justify-content-center p-2 cursor-pointer"
          (click)="selectClaimType('individual')"
        >
          <img
            src="../../../../../../assets/img/main/single.svg"
            class="text-2xl mb-2"
          />
          <span class="claim-label text-center">Reclamo individual</span>
        </div>
      </div>

      <div class="col-12 sm:col-4">
        <div
          class="claim-type-option flex flex-column align-items-center justify-content-center p-0"
          (click)="selectClaimType('plurindividual')"
        >
          <img
            src="../../../../../../assets/img/main/pair.svg"
            class="text-2xl my-3"
          />
          <span class="claim-label text-center">Reclamo plurindividual</span>
        </div>
      </div>

      <div class="col-12 sm:col-4">
        <div
          class="claim-type-option flex flex-column align-items-center justify-content-center p-2 cursor-pointer"
          (click)="selectClaimType('colectivo')"
        >
          <img
            src="../../../../../../assets/img/main/group.svg"
            class="text-2xl my-3"
          />
          <span class="claim-label text-center">Reclamo colectivo</span>
        </div>
      </div>
    </div>
  </div>
</p-dialog>
