<div *ngIf="buttons">
  <div
    *ngIf="claim?.status === 'Pendiente' && rol === 'Administrador'"
    class="grid w-full flex justify-content-end mt-3"
  >
    <div class="col-6 md:col-3 lg:col-3 xl:col-3">
      <p-button
        (click)="onGoBack()"
        label="Volver"
        class="btn-cancelar sm:text-xs"
      />
    </div>
    <div class="col-6 md:col-3 lg:col-3 xl:col-3">
      <p-button
        (click)="onRecibeAceptDialog('Rechazar')"
        label="Rechazar reclamo"
        class="btn-cancelar sm:text-xs"
      />
    </div>
    <div class="col-6 md:col-3 lg:col-3 xl:col-3">
      <p-button
        (click)="onRecibeAceptDialog('Admitir')"
        [style]="{ background: '#263245' }"
        label="Admitir reclamo"
        class="sm:text-xs"
      />
    </div>
  </div>
  <div
    *ngIf="
      claim?.status === 'AudienciaFijada' ||
      claim?.status === 'EnTramiteInterno'
    "
    class="grid w-full flex justify-content-end mt-3"
  >
    <div class="col-6 md:col-3 lg:col-3 xl:col-3">
      <p-button
        (click)="onGoBack()"
        label="Volver"
        class="btn-cancelar sm:text-xs"
      />
    </div>
    <div class="col-6 md:col-3 lg:col-3 xl:col-3">
      <p-button
        (click)="onNewAudienceCheck()"
        label="Fijar audiencia"
        class="btn-cancelar sm:text-xs"
      />
    </div>
    <div *ngIf="minuteId" class="col-6 md:col-3 lg:col-3 xl:col-3">
      <p-button
        (click)="onNewMinuteCheck()"
        [style]="{ background: '#263245' }"
        label="Subir acta"
        class="sm:text-xs"
      />
    </div>
  </div>
  <div
    *ngIf="claim?.status === 'Admitido'"
    class="grid w-full flex justify-content-end mt-3"
  >
    <div class="col-6 md:col-3 lg:col-3 xl:col-3">
      <p-button
        (click)="onGoBack()"
        label="Volver"
        class="btn-cancelar sm:text-xs"
      />
    </div>
    <div class="col-6 md:col-3 lg:col-3 xl:col-3">
      <p-button
        (click)="onNewAudienceCheck()"
        [style]="{ background: '#263245' }"
        label="Fijar audiencia"
        class="sm:text-xs"
      />
    </div>
  </div>
</div>
