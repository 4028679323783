<p-toast />
<form
  [formGroup]="formGroup"
  (ngSubmit)="nextStep()"
  class="w-full relative mt-3"
>
  <section class="personalInformation">
    <span class="titleSection">Datos del sindicato</span>

    <div class="grid w-full mb-2 mt-3">
      <div class="col-12 md:col-12 lg:col-6 xl:col-4 sm:col-6">
        <label class="labelTitle">Actividad <span class="span">*</span></label>
        <input
          [readOnly]="true"
          class="disabled"
          pInputText
          formControlName="claimedActivity"
          placeholder="Actividad"
        />
      </div>

      <div class="col-12 md:col-12 lg:col-6 xl:col-4 sm:col-6">
        <label class="labelTitle"
          >Convenio Colectivo<span class="span">*</span></label
        >
        <input
          [readOnly]="true"
          class="disabled"
          pInputText
          formControlName="claimedCollectiveAgreement"
          placeholder="Convenio Colectivo"
        />
      </div>

      <div class="col-12 md:col-12 lg:col-6 xl:col-4 sm:col-6">
        <label class="labelTitle"
          >Asociación sindical<span class="span">*</span></label
        >
        <input
          [readOnly]="true"
          class="disabled"
          pInputText
          formControlName="claimedAssociation"
          placeholder="Asociación sindical"
        />
      </div>

      <div class="col-12 md:col-12 lg:col-6 xl:col-4 sm:col-6">
        <label class="labelTitle"
          >Cantidad de trabajadores afectados por la medida<span class="span"
            >*</span
          ></label
        >
        <input
          [appNumberOnlyNoLength]="15"
          placeholder="Cantidad de trabajadores"
          pInputText
          formControlName="affectedWorkers"
          maxlength="15"
          [ngClass]="{
            'ng-invalid ng-dirty': hasErrors('affectedWorkers', 'required')
          }"
        />
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('affectedWorkers', 'required')"
        >
          Este campo es obligatorio
        </div>
      </div>
      <div class="col-12 md:col-12 lg:col-6 xl:col-4 sm:col-6">
        <label class="labelTitle"
          >Correo electronico<span class="span">*</span></label
        >
        <input
          placeholder="Correo electronico"
          type="text"
          pInputText
          formControlName="claimedEmail"
          type="email"
          maxlength="100"
          [ngClass]="{
            'ng-invalid ng-dirty': hasErrors('claimedEmail', 'required')
          }"
        />
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('claimedEmail', 'required')"
        >
          Este campo es obligatorio
        </div>
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('claimedEmail', 'email')"
        >
          Formato de email incorrecto
        </div>
      </div>
    </div>
  </section>

  <section class="attachedFiles mt-3">
    <span class="titleSection">Archivos adjuntos</span>
    <div class="grid w-full mt-3">
      <div class="col-12">
        <label class="labelTitle">Documentos<span class="span">*</span></label>
        <p class="descriptionContent mb-0">
          Cargue el documento que acredite la representación que invoca.
        </p>
      </div>

      <div class="col-12 md:col-8 lg:col-8 xl:col-6">
        <div
          [ngClass]="
            isDragging
              ? 'isDragging dropzone-container h-6rem col-12 '
              : 'dropzone-container h-6rem col-12 '
          "
          (dragover)="onDragOver($event)"
          (drop)="onDropSuccess($event, 'receipt')"
        >
          <input
            #inputFilePfd
            type="file"
            multiple="false"
            (change)="onChange($event, 'receipt')"
            accept=".pdf, .jpg, .png"
          />

          <div
            class="w-full flex align-items-center justify-content-between col-12"
          >
            <img
              class="hidden md:block"
              src="../../../../../../../assets/img/main/file-update.svg"
            />
            <span class="descriptionContent hidden md:block"
              >Arrastre sus documentos aquí o</span
            >
            <p-button
              pRipple
              (click)="inputFilePfd.click()"
              #upload
              class="btnUploadFile mr-1"
              >Elegir archivo</p-button
            >
          </div>
        </div>
        <span class="descriptionContent"
          >Archivos permitidos: JPG / JPEG / WEBP / HEIF / PDF / PNG
        </span>
      </div>
      <div class="col-12 md:col-12 lg:col-6 xl:col-6">
        <div
          class="text-danger p-error"
          *ngIf="
            formGroup.get('claimedFiles')?.hasError('fileCount') &&
            formGroup.get('claimedFiles')?.touched
          "
        >
          Se deben subir entre 1 y 12 archivos.
        </div>
        <div
          class="conteiner-UploadRight-pdf"
          *ngFor="let pdf of pdfFiles; let i = index"
        >
          <img
            src="../../../../../../../assets/img/main/icon-image.svg"
            alt=""
            class="icon-imagen"
          />
          <span class="descriptionContent">{{ pdf.name }}</span>
          <p-button
            icon="pi pi-times"
            [rounded]="true"
            [text]="true"
            severity="danger"
            (onClick)="deleteFilePfd(i)"
          ></p-button>
        </div>
      </div>
    </div>
  </section>

  <div class="grid w-full flex justify-content-end">
    <div class="col-6 md:col-2 lg:col-2 xl:col-2">
      <p-button
        (click)="cancel()"
        label="Cancelar"
        class="btn-cancelar sm:text-xs"
      />
    </div>

    <div class="col-6 md:col-2 lg:col-2 xl:col-2">
      <p-button
        (click)="back()"
        label="Atrás"
        class="btn-cancelar sm:text-xs"
      />
    </div>

    <div class="col-6 md:col-2 lg:col-2 xl:col-2">
      <p-button
        label="Siguiente"
        class="btn-siguiente"
        type="submit"
        [disabled]="formGroup.invalid"
      ></p-button>
    </div>
  </div>
</form>
