import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Failure } from 'src/app/core/utils/failure';
import { ForgotPasswordGenerateNewModel } from 'src/app/features/auth/data/models/forgot-password-generate-new.model';
import { ForgotPasswordGenerateNewUseCase } from 'src/app/features/auth/domain/usecases/forgot-password-generate-new.useCase';

type ValidatorKeys = 'uppercase' | 'lowercase' | 'minlength' | 'number' | 'specialChar';

@Component({
  selector: 'app-generate-new-password',
  templateUrl: './generate-new-password.component.html',
  styleUrls: ['./generate-new-password.component.css']
})
export class GenerateNewPasswordComponent implements OnInit{
  isLoading: boolean = false;
  showPassword: boolean = false;
  sendNewPAsswordOk: boolean = false;
  token: string = '';
  email: string = '';

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private messageService: MessageService,
    private forgotPAsswordGenerateNewUseCase: ForgotPasswordGenerateNewUseCase,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.newPasswordForgotForm.get('newPassword')?.valueChanges.subscribe(value => {
      this.checkPasswordValidators(value);
    });
  }



  newPasswordForgotForm: FormGroup = this.formBuilder.group({
    email: [''],
    token: [''],
    newPassword: [, Validators.required],
    repeatedPassword: [, Validators.required]
  }, {
    validators: this.passwordMatchValidator
  });


  async changePassword() {
    if (this.newPasswordForgotForm.invalid || this.newPasswordForgotForm.get('newPassword')?.hasError('invalidPassword')) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Por favor, complete el formulario correctamente.' });
      return;
    }
    this.isLoading = true;
    let result: string | Failure = await this.forgotPAsswordGenerateNewUseCase.execute(this.createForgotPasswordGenerateModel());
    if (result instanceof Failure) {
      this.isLoading = false;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: result.message });
    } else {
      this.isLoading = false;
      this.sendNewPAsswordOk = true;
    }
  }

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }

  checkPasswordValidators(value: string) {
    const validators: { [key in ValidatorKeys]: boolean } = {
      uppercase: /[A-Z]/.test(value),
      lowercase: /[a-z]/.test(value),
      minlength: value.length >= 8,
      number: /[0-9]/.test(value),
      specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(value)
    };

    let allValid = true;

    for (const key in validators) {
      if (validators.hasOwnProperty(key)) {
        const element = document.getElementById(key);
        if (validators[key as ValidatorKeys]) {
          element?.classList.add('valid');
          element?.classList.remove('invalid');
        } else {
          element?.classList.add('invalid');
          element?.classList.remove('valid');
          allValid = false;
        }
      }
    }

    if (allValid) {
      this.newPasswordForgotForm.get('newPassword')?.setErrors(null);
    } else {
      this.newPasswordForgotForm.get('newPassword')?.setErrors({ 'invalidPassword': true });
    }
    this.newPasswordForgotForm.updateValueAndValidity();
  }

  passwordMatchValidator( control: AbstractControl){
    return control.get('newPassword')?.value === control.get('repeatedPassword')?.value
    ? null
    : { mismatch: true}
  }

  createForgotPasswordGenerateModel(): ForgotPasswordGenerateNewModel {
    this.extractParams();
    return new ForgotPasswordGenerateNewModel(
      this.email,
      this.token,
      this.newPasswordForgotForm.get('newPassword')?.value,
      this.newPasswordForgotForm.get('repeatedPassword')?.value
    );
  }

  extractParams(): void {
    this.route.queryParams.subscribe(params => {
      this.token = params['token'];
      this.email = params['email'];
    });
  }

}
