import { CreateAudienceEntity } from "../../domain/entities/create-audience.entity";

export class CreateAudienceModel extends CreateAudienceEntity {
    override toJson(): Map<string, any> {
        let json: Map<string, any> = new Map();
        json.set('date', this.date);
        json.set('time', this.time);
        json.set('audience_title', this.audienceTitle);
        json.set('claimant_id', this.claimetId);
        json.set('claim_id', this.claimId);
        json.set('link_google_meet', this.linkGoogleMeet);
        return json;
    }

    static fromJson(data: Map<string,any>): CreateAudienceEntity {
        return new CreateAudienceModel(
            data.get('date'),
            data.get('time'),
            data.get('audience_title'),
            data.get('claimant_id'),
            data.get('claim_id'),
            data.get('link_google_meet'),
        );
    }

}
