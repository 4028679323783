import { UseCase } from "src/app/core/utils/usecase";
import { Failure } from "src/app/core/utils/failure";
import { UserRepositoryBase } from "../repositories/user-base.repository";
import { Injectable } from "@angular/core";
import { UserData } from "../entities/user-data.entity";

@Injectable()
export class UserUseCase implements UseCase<string, UserData | Failure> {

    constructor(private userRepository: UserRepositoryBase) {}

    async execute(param: string): Promise<UserData | Failure> {
        return await this.userRepository.getUser(param);
    }
}
