import { DeleteUserEntity } from "../../domain/entities/delete-user.entity";

export class DeleteUserModel extends DeleteUserEntity {
    override toJson(): Map<string, any> {
        let json: Map<string, any> = new Map();
        json.set('id', this.id);
        return json;
    }

}
