import { UseCase } from "src/app/core/utils/usecase";
import { DeleteUserEntity } from "../entities/delete-user.entity";
import { Failure } from "src/app/core/utils/failure";
import { UserRepositoryBase } from "../repositories/user-base.repository";
import { Injectable } from "@angular/core";

@Injectable()
export class DeleteUserUseCase implements UseCase<DeleteUserEntity, string | Failure> {
    constructor(private userRepository: UserRepositoryBase){}

    async execute(param: DeleteUserEntity): Promise<string | Failure> {
        return await this.userRepository.deleteUser(param);
    }

}
