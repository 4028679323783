import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-step-mail-validation',
  templateUrl: './step-mail-validation.component.html',
  styleUrls: ['./step-mail-validation.component.css']
})
export class StepMailValidationComponent {
  @Input() selectedRol?: string;

}
