import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Failure } from 'src/app/core/utils/failure';
import { ClaimEntity } from 'src/app/features/claims/domain/entities/claim.entity';
import { CreateMinuteUseCase } from '../../../domain/usecases/create-minute.usecase';
import { MinuteModel } from '../../../data/models/minute.model';
import { MinutePdfModel } from '../../../data/models/minutePdf.moodel';

interface Base64File {
  name: string;
  base64: string | ArrayBuffer | null;
  fileType: string;
}

@Component({
  selector: 'app-minute',
  templateUrl: './minute.component.html',
  styleUrls: ['./minute.component.css']
})
export class MinuteComponent {
  @Output() showMessage = new EventEmitter<{ severity: string, summary: string, detail: string }>();
  @Output() closePopup: EventEmitter<boolean> = new EventEmitter();
  @Input() claim?: ClaimEntity;
  @Output() loadingChange = new EventEmitter<boolean>();
  @Output() refresh = new EventEmitter<boolean>();

  isDragging: boolean = false;
  minuteFile: any[] = [];
  selectedFile?: any;
  disableFileImageUpload: boolean = false;
  isLoading: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private messageService: MessageService,
    private createMinuteUseCase: CreateMinuteUseCase,
  ) { }

  minuteForm: FormGroup = this.formBuilder.group({
    file: ['', [Validators.required]],
  });


  async createMinute(): Promise<void> {  
    this.isLoading = true;
    this.loadingChange.emit(this.isLoading);   
    let result = await this.createMinuteUseCase.execute(this.createNewMinuteModel());    
    this.isLoading = false;
    this.loadingChange.emit(this.isLoading); 
    if(result instanceof Failure) {
      this.showMessage.emit({
        severity: 'error',
        summary: 'Error',
        detail: result.message,
      });
      return;
    }
    this.showMessage.emit({
      severity: 'success',
      summary: 'Audiencia creada',
      detail: 'La audiencia se creó exitosamente.',
    });
    setTimeout(() => {
      this.router.navigate(['/main/claims']);
    }, 1000);
    this.refresh.emit();
    this.cancel();
  }


  cancel() {
    this.closePopup.emit(true);
  }

  onDragOver(event: Event) {
    event.preventDefault();
    this.isDragging = true;
  }

  onDropSuccess(event: any) {
    event.preventDefault();
    this.isDragging = false;
    this.onFileChange(event.dataTransfer.files);
  }

  onFileChange(files: FileList) {
    this.minuteFile = [];
    Array.from(files).forEach(file => {
        if (file.type === 'application/pdf') {
            this.convertToBase64(file, 'pdf');
        } else {
            this.showMessage.emit({
                severity: 'error',
                summary: 'Tipo de archivo no válido',
                detail: 'Por favor, cargue solo archivos PDF.'
            });
        }
    });
}


  onChange(event: any, fileType: string) {
    const input = event.target as HTMLInputElement;
    const files = event.target.files;
    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        this.convertToBase64(files[i], fileType);
      }
      input.value = '';
    }
  }

  convertToBase64(file: File, fileType: string) {
    const reader = new FileReader();
    reader.onload = () => {
      const base64String = reader.result?.toString();
      if (base64String) {
        const base64File: Base64File = {
          name: file.name,
          base64: base64String,
          fileType,
        };
        this.minuteFile.push(base64File);
        this.updateFormFiles();
      }
    };
    reader.readAsDataURL(file);
  }

  updateFormFiles() {
    const fileBase64Array = this.minuteFile.map((file) => file.base64);
    this.minuteForm.get("file")?.setValue(fileBase64Array);
    this.minuteForm.get('file')?.updateValueAndValidity();
    this.checkFileImageCount();
  }

  private checkFileImageCount() {
    this.disableFileImageUpload = this.minuteFile.length >= 1;
  }

  deleteFileImage(index: number) {
    this.minuteFile.splice(index, 1);
    const fileDniBase64Array = this.minuteFile.map((image) => image.base64);
    this.minuteForm.get("file")?.setValue(fileDniBase64Array.length ? fileDniBase64Array : null);
    this.minuteForm.get("file")?.markAsTouched();
    this.minuteForm.get("file")?.markAsDirty();
    this.checkFileImageCount();
  }

  createNewMinuteModel(): MinutePdfModel {
    const currentDate = new Date().toISOString();
    const fileArray = this.minuteForm.get('file')?.value;
    return new MinutePdfModel(
      fileArray,
      this.claim?.id!,
      currentDate
    )
  }

}
