import { UseCase } from "src/app/core/utils/usecase";
import { CompanyEntity } from "../entities/company.entity";
import { Failure } from "src/app/core/utils/failure";
import { CompanyrepositoryBase } from "../repositories/company-base.repository";
import { Injectable } from "@angular/core";

@Injectable()
export class CreateCompanyUseCase implements UseCase<CompanyEntity, string | Failure> {
    constructor(private companiyRepository: CompanyrepositoryBase) {}

    async execute(param: CompanyEntity): Promise<string | Failure> {
        return await this.companiyRepository.createCompany(param);
    }
}