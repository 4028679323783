import { Entity } from "src/app/core/utils/entity";

export abstract class ResetPasswordEntity extends Entity {
    constructor(
        public id: string,
        public email: string,
        public password: string,
        public newPassword: string,
        public repeatedPassword: string
    ) {
        super();
    }
}