import { ComisionUser } from "../../domain/entities/comision-user.entity";

export class ComisionUserModel extends ComisionUser {

    override toJson(): Map<string, any> {
        let map: Map<string, any> = new Map<string, any>();
        map.set("rol", this.rol);
        map.set("full_name", this.fullName);
        map.set("name", this.name);
        map.set("last_name", this.lastName);
        map.set("cuil", this.cuil);
        map.set("phone", this.phone);
        map.set("dni", this.dni);
        map.set("province", this.province);
        map.set("city", this.city);
        map.set("street", this.street);
        map.set("number", this.number);
        map.set("postal_code", this.postalCode);
        map.set("email", this.email);
        map.set("password", this.password);
        map.set("is_administrator", this.is_administrator);
        map.set("is_comision", this.is_comision);
        map.set("is_conciliator", this.is_conciliator);
        map.set("neighborhood", this.neighborhood);
        map.set("floor_apartment", this.floor_apartment);
        map.set("tower", this.tower);
        map.set("id", this.id);
        map.set("departments_id", this.departments_id);
        return map;
    }

    static fromJson(data: Map<string, any>): ComisionUser {
        return new ComisionUserModel(
            data.get('rol'),
            data.get('full_name'),
            data.get('name'),
            data.get('last_name'),
            data.get('cuil'),
            data.get('phone'),
            data.get('dni'),
            data.get('province'),
            data.get('city'),
            data.get('street'),
            data.get('number'),
            data.get('postal_code'),
            data.get('email'),
            data.get('password'),
            data.get('is_administrator'),
            data.get('is_comision'),
            data.get('is_conciliator'),
            data.get('neighborhood'),
            data.get('floor_apartment'),
            data.get('tower'),
            data.get('id'),
            data.get('departments_id')
        );
    }
}