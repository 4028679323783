import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Rol } from 'src/app/core/interfaces/user.interface';
import { FormInitialaizerFactory } from '../../../services/form-initializer-factory';

@Component({
  selector: 'app-step-company-cuil',
  templateUrl: './step-company-cuil.component.html',
  styleUrls: ['./step-company-cuil.component.css']
})
export class StepCompanyCuilComponent {
  @Input() initialData: any = {};
  @Output() company : EventEmitter<FormGroup> = new EventEmitter(); 
  @Output() back : EventEmitter<number> = new EventEmitter(); 
  @Input() selectedRol!: Rol;
  
  registerFormCompany: FormGroup = this.formBuilder.group({});

  constructor(
    private formBuilder: FormBuilder,
    private formInitializerFactory: FormInitialaizerFactory
  ){}

  ngOnInit() {
    this.initForm();
    if (this.initialData) {
      this.registerFormCompany.patchValue(this.initialData);
    }
  }

  nextStep(){
    if (this.registerFormCompany.invalid) return;
    this.company.emit(this.registerFormCompany.value);
  }

  previusStep(){
    this.back.emit(1);
  }

  hasErrors(controlName: string, errorType: string) {
    return (
      this.registerFormCompany.get(controlName)?.hasError(errorType) &&
      this.registerFormCompany.get(controlName)?.touched
    );
  }
  initForm(): void {
    let formInitializer = this.formInitializerFactory.getFormInitializer(this.selectedRol); 
    this.registerFormCompany = formInitializer();
  }

  companyField() : boolean {
    return this.selectedRol.toString() == Rol[Rol.Empleador]
  }
}
