import { Failure, UnhandledFailure, InvalidAuthentication, NotFoundFailure,InvalidRepeatedPassword, BadRequestFailure, ExistingResourceFailure, InvalidGrant, ForbiddenFailure, InvalidActualPasswordFailure, NotFoundAccount } from "../../../../core/utils/failure";
import { Injectable } from "@angular/core";
import { Credentials } from "../../domain/entities/credentials.entity";
import { Session } from "../../domain/entities/session.entity";
import { User } from "../../domain/entities/user.entity";
import { AuthRepositoryBase } from "../../domain/repositories/auth.repositorybase";
import { AuthRemoteDataSourceBase } from "../data-source/auth-remote.datasource";
import { ResetPasswordEntity } from "../../domain/entities/reset-password.entity";
import { ForgotPasswordSendEmailEntity } from "../../domain/entities/forgot-password-send-email.entity";
import { FrogotPasswordGenerateNewEntity } from "../../domain/entities/forgot-password-generate-new.entity";

@Injectable()
export class AuthRepository extends AuthRepositoryBase {
  errors: Map<string, Failure> = new Map();

  constructor(private remote: AuthRemoteDataSourceBase) {
    super();
    this.initErrors();
  }

  private initErrors(): void {
    this.errors.set('404', new NotFoundFailure());
    this.errors.set('400', new BadRequestFailure());
    this.errors.set('401', new InvalidAuthentication());
  }

  override async login(credentials: Credentials): Promise<Session | Failure> {
    try {
      return await this.remote.login(credentials)
    } catch (error: any) {
      if (error.error = 'invalid_grant') {
        return new InvalidGrant();
      }
      let errorCode: number = error.status;
      return this.errors.get(errorCode.toString())!;
    }
  }

  override async register(user: User): Promise<boolean | Failure> {
    try {
      const result = await this.remote.register(user);
      const code = result.get('code');
      return code === 200 ? true : new UnhandledFailure(result.get('message'), code);
    } catch (error: any) {
      return new UnhandledFailure(error.error.message, error.error.code);
    }
  }

  override async resetPassword(resetPassword: ResetPasswordEntity): Promise<string | Failure> {
    try {
      return await this.remote.resetPassword(resetPassword)
    } catch (error: any) {
      let response = error.error;
      let message: string = response.message;
      let code: number = response.code;
      if (code == 400) return new BadRequestFailure(message);
      if (code == 403) return new InvalidActualPasswordFailure();
      return new UnhandledFailure();
    }
  }

  override async forgotPasswordSendEmail(email: ForgotPasswordSendEmailEntity): Promise<string | Failure> {
    try {
      return await this.remote.forgotPasswordSendEmail(email);
    } catch(error: any) {
      return new NotFoundAccount();
    }
  }

  override async forgotPasswordGenerateNew(password: FrogotPasswordGenerateNewEntity): Promise<string | Failure> {
    try {
      return await this.remote.forgotPasswordGenerateNew(password);
    } catch ( error: any) {
      return new InvalidRepeatedPassword();
    }
  }

}
