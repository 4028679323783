<form
  [formGroup]="formGroup"
  (submit)="nextStep()"
  class="w-full relative mt-6"
>
  <section class="">
    <div class="grid w-full mt-1">
      <label class="labelTitle"
        >Objeto de reclamo<span class="span">*</span>
      </label>
      <div class="grid mt-3">
        <div
          *ngFor="let object of objectsOftheClaim"
          class="col-12 md:col-6 lg:col-3 xl:col-3 mb-1 flex align-items-center"
        >
          <p-checkbox
            [disabled]="object.disabled"
            (click)="disableIndemnificationCheck(object)"
            [label]="object.name"
            [value]="object.name"
            [(ngModel)]="activeChecks"
            formControlName="objectOfClaim"
          />
        </div>
      </div>
    </div>

    <div class="grid flex flex-column mt-4" *ngIf="this.activeMessages">
      <form [formGroup]="companyForm">
        <div class="col-12 flex align-items-center py-0">
          <div class="flex items-center">
            <span class="span pr-1">*</span>
            <input
              pInputText
              placeholder="CUIT Cedente"
              [appNumberOnlyNoLength]="15"
              maxlength="13"
              formControlName="cuitCedente"
            />
          </div>
          <p-button
            (onClick)="searchCompany(companyForm, 'errorMessageCompanyForm')"
            icon="pi pi-search"
            [rounded]="true"
            size="small"
            class="ml-2"
          />
        </div>
        <div class="col-12">
          <input
            class="ml-3"
            pInputText
            placeholder="Razón Social"
            value="{{ formGroup.get('razon_social_assignor')?.value }}"
          />
          <div
            *ngIf="errorMessageCompanyForm"
            class="text-red-500 text-sm mt-1"
          >
            {{ errorMessageCompanyForm }}
          </div>
        </div>
      </form>
      <form [formGroup]="companyForm1">
        <div class="col-12 flex align-items-center pb-0">
          <div class="flex items-center">
            <span class="span pr-1">*</span>
            <input
              pInputText
              maxlength="13"
              placeholder="CUIT Cesionario"
              [appNumberOnlyNoLength]="15"
              formControlName="cuitCedente"
            />
          </div>
          <p-button
            (onClick)="searchCompany(companyForm1, 'errorMessageCompanyForm1')"
            icon="pi pi-search"
            [rounded]="true"
            size="small"
            class="ml-2"
          />
        </div>
        <div class="col-12">
          <input
            class="ml-3"
            pInputText
            placeholder="Razón social"
            value="{{ formGroup.get('razon_social_assignee')?.value }}"
          />
          <div
            *ngIf="errorMessageCompanyForm1"
            class="text-red-500 text-sm mt-1"
          >
            {{ errorMessageCompanyForm1 }}
          </div>
        </div>
      </form>
    </div>
  </section>
  <p-divider />
  <div class="grid w-full mt-4">
    <div class="col-12 md:col-6 lg:col-6 xl:col-6 sm:col-6">
      <label class="labelTitle"
        >Cantidad de trabajadores del establecimiento<span class="span"
          >*</span
        ></label
      >
      <input
        [appNumberOnlyNoLength]="15"
        placeholder="Cantidad de trabajadores"
        pInputText
        formControlName="workersCount"
        maxlength="15"
        [ngClass]="{
          'ng-invalid ng-dirty': hasErrors('companyPhone', 'required')
        }"
      />
      <div
        class="text-danger p-error"
        *ngIf="hasErrors('workersCount', 'required')"
      >
        Este campo es obligatorio
      </div>
    </div>
  </div>
  <p-divider />

  <div class="grid w-full mt-4">
    <div class="col-12">
      <label class="labelTitle"
        >Seleccionar la opción<span class="span">*</span>
      </label>
    </div>
    <div
      class="col-12 md:col-4 lg:col-6 xl:col-6 flex flex-column xl:flex-row md:flex-row lg:flex-row gap-1"
    >
      <div *ngFor="let a of ownRight" class="flex align-items-center">
        <p-radioButton
          [inputId]="a.key"
          [value]="a.name"
          formControlName="selectedOwnRight"
        ></p-radioButton>
        <label [for]="a.key" class="text-sm ml-2 gap-1 mr-3">{{
          a.name
        }}</label>
      </div>
      <div
        class="text-danger p-error mb-1"
        *ngIf="hasErrors('selectedOwnRight', 'required')"
      >
        Este campo es obligatorio
      </div>
    </div>
  </div>

  <div *ngIf="showLawyerInputs" class="grid w-full mb-2 mt-3">
    <div class="col-12 md:col-4 lg:col-4 xl:col-4 sm:col-6">
      <label class="labelTitle"
        >Apellido y Nombre<span class="span">*</span></label
      >
      <input
        pInputText
        formControlName="fullNameLawyer"
        maxlength="50"
        (keydown)="onKeyDown($event)"
        [ngClass]="{
          'ng-invalid ng-dirty': hasErrors('lawyerFullName', 'required')
        }"
      />
      <div
        class="text-danger p-error"
        *ngIf="hasErrors('lawyerFullName', 'required')"
      >
        Este campo es obligatorio
      </div>
      <div
        class="text-danger p-error"
        *ngIf="hasErrors('lawyerFullName', 'maxlength')"
      >
        Este campo no puede poseer más de 50 caracteres
      </div>
      <div
        class="text-danger p-error"
        *ngIf="hasErrors('lawyerFullName', 'pattern')"
      >
        El campo solo acepta letras.
      </div>
    </div>

    <div class="col-12 md:col-4 lg:col-4 xl:col-4 sm:col-6">
      <label class="labelTitle"
        >Número de matrícula<span class="span">*</span></label
      >
      <input
        formControlName="registrationNumber"
        pInputText
        maxlength="10"
        [ngClass]="{
          'ng-invalid ng-dirty':
            hasErrors('registrationNumber', 'required') ||
            hasErrors('registrationNumber', 'maxlength')
        }"
      />

      <div
        class="text-danger p-error"
        *ngIf="hasErrors('lawyerRegistrationNumber', 'required')"
      >
        Este campo es obligatorio
      </div>
      <div
        class="text-danger p-error"
        *ngIf="hasErrors('lawyerRegistrationNumber', 'maxlength')"
      >
        Este campo no puede poseer más de 10 caracteres
      </div>
    </div>

    <div class="col-12 md:col-4 lg:col-4 xl:col-4">
      <label class="labelTitle"
        >Correo electrónico del abogado<span class="span">*</span></label
      >
      <input
        formControlName="lawyerEmail"
        type="text"
        maxlength="100"
        pInputText
        [ngClass]="{
          'ng-invalid ng-dirty': hasErrors('lawyerEmail', 'required')
        }"
      />
      <div
        class="text-danger p-error"
        *ngIf="hasErrors('lawyerEmail', 'required')"
      >
        Este campo es obligatorio
      </div>
      <div
        class="text-danger p-error"
        *ngIf="hasErrors('lawyerEmail', 'email')"
      >
        Formato de email incorrecto
      </div>
      <div
        class="text-danger p-error"
        *ngIf="hasErrors('lawyerEmail', 'maxlength')"
      >
        Excede el límite de caracteres
      </div>
    </div>
  </div>
  <p-divider />

  <div class="col-12 mt-5">
    <div class="flex align-items-center gap-2">
      <span class="span">*</span>
      <p-checkbox
        (onChange)="onConsentChange($event)"
        [binary]="true"
        formControlName="consent"
        class="check-acceptsElectronicDomicile"
        label="El domicilio electrónico constituido a través de la indicación de un correo electrónico, se considerará a todos los efectos jurídicos como domicilio constituído, siendo válidas y vinculantes las notificaciones, emplazamientos, comunicaciones y citaciones que se practiquen en el mismo, gozando de plena validez y eficacia su constitución."
      />
    </div>
  </div>
  <p-divider />
  <div class="grid w-full flex justify-content-end mt-2">
    <div class="col-12 md:col-2 lg:col-2 xl:col-2">
      <p-button
        (click)="cancel()"
        label="Cancelar"
        class="btn-cancelar sm:text-xs"
      />
    </div>

    <div class="col-6 md:col-2 lg:col-2 xl:col-2">
      <p-button
        label="Atrás"
        (click)="back()"
        label="Atrás"
        class="btn-cancelar sm:text-xs"
      />
    </div>
    <div class="col-12 md:col-2 lg:col-2 xl:col-2">
      <p-button
        [label]="isEdit ? 'Guardar cambios' : 'Generar Reclamo'"
        class="btn-siguiente"
        type="submit"
        [disabled]="formGroup.invalid"
      ></p-button>
    </div>
  </div>
</form>
