import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Toast } from 'primeng/toast';
import { Failure } from 'src/app/core/utils/failure';
import { UserData } from 'src/app/features/user/domain/entities/user-data.entity';
import { GetMyDataUseCase } from 'src/app/features/user/domain/usecases/get-my-data.usecase';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ClaimCollectiveEntity } from 'src/app/features/claims/domain/entities/claim-colective.entity';
import { CreateClaimColectiveUseCase } from 'src/app/features/claims/domain/usecases/create-cliam-colective.usecase';
import { ClaimColectiveUseCase } from 'src/app/features/claims/domain/usecases/get-claim-colective-usecase';
import { UpdateClaimColectiveUseCase } from 'src/app/features/claims/domain/usecases/update-claim-colective.usecase';
import { ClaimCollectiveModel } from 'src/app/features/claims/data/models/claim-colective.model';
import AESEncryptDecryptService from 'src/app/core/utils/crypto';
import { LocalStorageServiceBase } from 'src/app/core/services/localStorage-serviceBase';

@Component({
  selector: 'app-claim-colective',
  templateUrl: './claim-colective.component.html',
  styleUrls: ['./claim-colective.component.css'],
  providers: [MessageService, Toast, ConfirmationService],
})
export class ClaimColectiveComponent implements OnInit {
  claim?: ClaimCollectiveEntity;
  isNewClaim: boolean = false;
  formComplete: number = 0;
  activeIndex: number = 0;
  claimForm!: FormGroup;
  userData?: UserData;
  isLoading: boolean = false;
  claimId?: string | null;

  step1Form!: FormGroup;
  step2Form!: FormGroup;
  step3Form!: FormGroup;
  private AES = new AESEncryptDecryptService();

  constructor(
    private formBuilder: FormBuilder,
    private myData: GetMyDataUseCase,
    private messageService: MessageService,
    private createClaimUseCase: CreateClaimColectiveUseCase,
    private router: Router,
    private route: ActivatedRoute,
    private claimUseCase: ClaimColectiveUseCase,
    private updateClaimUseCase: UpdateClaimColectiveUseCase,
    private location: Location,
    private confirmationService: ConfirmationService,
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.checkIsNew();
  }

  private initForm(): void {
    this.claimForm = this.formBuilder.group({
      userId: ['', Validators.required],
      rol: ['', Validators.required],
      id: ['', Validators.required],
      status: ['', Validators.required],
      claimType: ['', Validators.required],
      numberOfClaim: ['', Validators.required],
      startDate: ['', Validators.required],
      confirmInformation: ['', Validators.required],
      isBorrador: [false, Validators.required],
      conciliatorName: ['', Validators.required],
    });
  }

  checkIsNew() {
    if (this.router.url.includes('main/new')) {
      this.isNewClaim = true;
    } else {
      this.claimId = this.route.snapshot.paramMap.get('id');
      this.getClaim();
    }
    this.getMyData();
  }

  async getMyData(): Promise<void> {
    let result = await this.myData.execute();
    if (result instanceof Failure) {
      return;
    }
    this.userData = result;
  }

  async getClaim(): Promise<void> {
    if (!this.claimId) return;
    let result = await this.claimUseCase.execute(this.claimId!);
    if (result instanceof Failure) {
      return;
    }
    this.claim = result;
  }

  receiveData(data: FormGroup) {
    Object.keys(data.controls).forEach((key) => {
      if (!this.claimForm.contains(key)) {
        this.claimForm.addControl(key, data.controls[key]);
      }
    });

    this.formComplete++;
  }

  activeStep(index: number) {
    this.activeIndex = index;
  }

  nextStep(): void {
    if (this.activeIndex < 2) {
      this.activeIndex++;
    }
  }

  onSubmit(data: FormGroup) {
    if (this.isNewClaim) {
      this.createClaim(data);
    } else if (
      this.claim?.status === 'Pendiente' ||
      this.claim?.status === 'Borrador'
    ) {
      this.updateClaim(data);
    }
  }

  async createClaim(data: FormGroup): Promise<void> {
    this.isLoading = true;
    Object.keys(data.controls).forEach((key) => {
      if (!this.claimForm.contains(key)) {
        this.claimForm.addControl(key, data.controls[key]);
      }
    });
    this.loadData();
    try {
      let result = await this.createClaimUseCase.execute(this.createNewClaim());

      if (result instanceof Failure) {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: result.message,
        });
        return;
      }
      this.messageService.add({
        severity: 'success',
        summary: 'Reclamo creado',
        detail: 'Su nuevo reclamo se creó exitosamente.',
      });

      setTimeout(() => {
        this.router.navigate(['/main/home']);
      }, 1100);
    } catch (error) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error inesperado',
        detail: 'Ha ocurrido un error. Inténtelo nuevamente.',
      });
    } finally {
      this.isLoading = false;
    }
  }

  async updateClaim(data: FormGroup): Promise<void> {
    Object.keys(data.controls).forEach((key) => {
      if (!this.claimForm.contains(key)) {
        this.claimForm.addControl(key, data.controls[key]);
      }
    });
    this.loadData();
    this.setFilesToSupportFormat();
    this.isLoading = true;
    try {
      let result = await this.updateClaimUseCase.execute(this.createNewClaim());
      if (result instanceof Failure) {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: result.message,
        });
        return;
      }
      this.messageService.add({
        severity: 'success',
        summary: 'Reclamo actualizado',
        detail: 'Su reclamo se actualizó exitosamente.',
      });
      setTimeout(() => {
        this.router.navigate(['/main/home']);
      }, 2000);
    } catch (error) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error inesperado',
        detail: 'Ha ocurrido un error. Inténtelo nuevamente.',
      });
    } finally {
      this.isLoading = false;
    }
  }

  setFilesToSupportFormat() {
    const handleFileOrBase64 = (value: any): string[] => {
      const result: string[] = [];
      if (!value) return result;

      if (Array.isArray(value)) {
        value.forEach((item) => {
          if (typeof item === 'string' && item.includes('base64')) {
            result.push(item);
          } else if (item?.base64) {
            result.push(item.base64);
          } else if (typeof item === 'string') {
            result.push(item);
          }
        });
      } else if (typeof value === 'string' && value.includes('base64')) {
        result.push(value);
      } else if (value?.base64) {
        result.push(value.base64);
      } else if (typeof value === 'string') {
        result.push(value);
      }
      return result;
    };

    const extraDocFiles = handleFileOrBase64(
      this.claimForm.get('claimedFiles')?.value
    );

    if (extraDocFiles.length > 0) {
      this.claimForm.get('claimedFiles')?.setValue(extraDocFiles);
    }
  }

  loadData() {
    this.claimForm.get('rol')?.setValue(this.userData?.rol);
    this.claimForm.get('userId')?.setValue(this.userData?.id);
    if (this.claim?.status !== 'Borrador' || this.claim?.status !== undefined) {
      this.claimForm.get('id')?.setValue(this.claim?.id);
      this.claimForm.get('status')?.setValue(this.claim?.status);
      this.claimForm.get('claimType')?.setValue(this.claim?.claimType);
      this.claimForm.get('numberOfClaim')?.setValue(this.claim?.numberOfClaim);
      this.claimForm
        .get('confirmInformation')
        ?.setValue(this.claim?.confirmInformation);
      this.claimForm.get('isBorrador')?.setValue(this.claim?.isBorrador);
      this.claimForm.get('startDate')?.setValue(this.claim?.startDate);
    }
  }

  prevStep(): void {
    if (this.activeIndex > 0) {
      this.activeIndex--;
    }
  }

  getCurrentStepForm(): FormGroup {
    switch (this.activeIndex) {
      case 0:
        return this.step1Form;
      case 1:
        return this.step2Form;
      case 2:
        return this.step3Form;
      default:
        return this.step1Form;
    }
  }

  cancel(isActive: boolean) {
    if (this.claim?.status === 'Borrador' || this.claim?.status === undefined) {
      if (!isActive) return;
      this.confirmationService.confirm({
        header: 'Salir del reclamo',
        message:
          'Su progreso se guardara automáticamente como borrador si desea salir. Podrá retomar cuando lo desee.',
        accept: async () => {
          try {
            await this.saveDraft(this.claimForm.value);
          } catch (error) {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail:
                'No se pudo guardar el borrador. Por favor intente nuevamente.',
            });
          }
        },
      });
    } else {
      this.confirmationService.confirm({
        header: 'Salir del reclamo',
        message:
          '¿Estás seguro de que deseas salir del reclamo? Se perderán los cambios no guardados.',
        accept: async () => {
          this.router.navigate([this.location.back()]);
        },
      });
    }
  }

  async saveDraft(data: FormGroup): Promise<void> {
    this.isLoading = true;
    try {
      const originalValidators = new Map();
      Object.keys(this.claimForm.controls).forEach((key) => {
        originalValidators.set(key, this.claimForm.get(key)?.validator);
        this.claimForm.get(key)?.clearValidators();
        this.claimForm.get(key)?.updateValueAndValidity({ emitEvent: false });
      });
      this.claimForm.patchValue(data);
      this.loadData();
      this.claimForm.patchValue({
        ...data.value,
        isBorrador: true,
      });

      this.setFilesToSupportFormat();
      let result;
      if (this.claimId) {
        this.claimForm.patchValue({ id: this.claimId });
        result = await this.updateClaimUseCase.execute(this.createNewClaim());
      } else {
        result = await this.createClaimUseCase.execute(this.createNewClaim());
      }

      if (result instanceof Failure) {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: result.message || 'Ocurrió un error al guardar el borrador',
        });
        return;
      }

      this.messageService.add({
        severity: 'success',
        summary: 'Guardado',
        detail: this.claimId
          ? 'Borrador actualizado exitosamente'
          : 'Borrador guardado exitosamente',
        life: 3000,
      });

      setTimeout(() => {
        this.router.navigate(['/main/claims']);
      }, 1100);
    } catch (error) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Ocurrió un error al guardar el borrador',
      });
    } finally {
      this.isLoading = false;
    }
  }

  createNewClaim(): ClaimCollectiveModel {
    return new ClaimCollectiveModel(
      this.claimForm.get('id')?.value ?? '',
      this.claimForm.get('userId')?.value ?? '',
      this.claimForm.get('rol')?.value ?? '',
      this.claimForm.get('razonSocial')?.value ?? '',
      this.claimForm.get('cuitCompany')?.value ?? '',
      this.claimForm.get('companyEmail')?.value ?? '',
      this.claimForm.get('companyPhone')?.value ?? '',
      this.claimForm.get('streetCompany')?.value ?? '',
      this.claimForm.get('numberCompany')?.value ?? '',
      this.claimForm.get('floorApartmentCompany')?.value ?? '',
      this.claimForm.get('towerCompany')?.value ?? '',
      this.claimForm.get('postalCodeCompany')?.value ?? '',
      this.claimForm.get('provinceCompany')?.value ?? '',
      this.claimForm.get('cityCompany')?.value ?? '',
      this.claimForm.get('claimantTypePerformance')?.value ?? '',
      this.claimForm.get('departmentId')?.value ?? '',
      this.claimForm.get('claimedActivity')?.value ?? '',
      this.claimForm.get('claimedCollectiveAgreement')?.value ?? '',
      this.claimForm.get('claimedAssociation')?.value ?? '',
      this.claimForm.get('affectedWorkers')?.value ?? '',
      this.claimForm.get('claimedEmail')?.value ?? '',
      this.claimForm.get('claimedFiles')?.value ?? [],
      this.claimForm.get('objectOfClaim')?.value ?? [],
      this.claimForm.get('selectedOwnRight')?.value ?? '',
      this.claimForm.get('workersCount')?.value ?? '',
      this.claimForm.get('fullNameLawyer')?.value ?? '',
      this.claimForm.get('registrationNumber')?.value ?? '',
      this.claimForm.get('lawyerEmail')?.value ?? '',
      this.claimForm.get('consent')?.value ?? false,
      this.claimForm.get('cuit_assignor')?.value ?? '',
      this.claimForm.get('razon_social_assignor')?.value ?? '',
      this.claimForm.get('cuit_assignee')?.value ?? '',
      this.claimForm.get('razon_social_assignee')?.value ?? '',
      this.claimForm.get('claimType')?.value ?? '',
      this.claimForm.get('confirmInformation')?.value ?? false,
      this.claimForm.get('startDate')?.value ?? '',
      this.claimForm.get('numberOfClaim')?.value ?? '',
      this.claimForm.get('isBorrador')?.value ?? false,
      this.claimForm.get('status')?.value ?? '',
      this.claimForm.get('conciliatorName')?.value ?? ''
    );
  }
}
