import { UseCase } from "src/app/core/utils/usecase";
import { Failure } from "src/app/core/utils/failure";
import { Injectable } from "@angular/core";
import { CompanyrepositoryBase } from "../repositories/company-base.repository";
import { CompanyEntity } from "../entities/company.entity";

@Injectable()
export class GetCompanyCuilUseCase implements UseCase<string, CompanyEntity | Failure> {

    constructor(private claimRepository: CompanyrepositoryBase) {}

    async execute(param: string): Promise<CompanyEntity | Failure> {
        return await this.claimRepository.getCompanyCuil(param);
    }
}