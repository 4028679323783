import { FrogotPasswordGenerateNewEntity } from "../../domain/entities/forgot-password-generate-new.entity";

export class ForgotPasswordGenerateNewModel extends FrogotPasswordGenerateNewEntity {
    override toJson(): Map<string, any> {
        let json: Map<string, any> = new Map();
        json.set('email', this.email);
        json.set('token', this.token);
        json.set('new_password', this.newPassword);
        json.set('repeated_password', this.repeatedPassword);
        return json;
    }

}