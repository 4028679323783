import { UseCase } from "src/app/core/utils/usecase";
import { CompanyEntity } from "../entities/company.entity";
import { Failure } from "src/app/core/utils/failure";
import { CompanyrepositoryBase } from "../repositories/company-base.repository";
import { Injectable } from "@angular/core";

@Injectable()
export class GetCompanyUseCase implements UseCase<string, CompanyEntity | Failure> {
    
    constructor(private companyRepository: CompanyrepositoryBase) {}
   
    async execute(param: string): Promise<CompanyEntity | Failure> {
        return await this.companyRepository.getCompany(param);
    }    
}