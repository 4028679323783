import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Toast } from 'primeng/toast';
import { ClaimStatus } from 'src/app/core/enums/claim-status.enum';
import { ProvinceEntity } from 'src/app/core/services/location/domain/entities/province.entity';
import { GetProvinceUseCase } from 'src/app/core/services/location/domain/usecases/get-province.usecase';
import { Failure, UnhandledFailure } from 'src/app/core/utils/failure';
import { GetFileModel } from 'src/app/features/claims/data/models/get-file.model';
import { ClaimCollectiveEntity } from 'src/app/features/claims/domain/entities/claim-colective.entity';
import { ClaimColectiveUseCase } from 'src/app/features/claims/domain/usecases/get-claim-colective-usecase';
import { GetFileColectiveUseCase } from 'src/app/features/claims/domain/usecases/get-file-colective.usecase';
import { MinuteEntity } from 'src/app/features/minutes/domain/entities/minute.entity';
import { GetMinutesByClaimIdUseCase } from 'src/app/features/minutes/domain/usecases/get-minutes-by-claim-id.usecase';
import { Location } from '@angular/common';
import { UserRol } from 'src/app/core/enums/user-rol.enum';
import { UpdateClaimStatusModel } from 'src/app/features/claims/data/models/update-claim-status.model';
import { UpdateClaimStatusEntity } from 'src/app/features/claims/domain/entities/update-claim-satus.entity';
import { LocalStorageServiceBase } from 'src/app/core/services/localStorage-serviceBase';
import AESEncryptDecryptService from 'src/app/core/utils/crypto';
import { UpdateCollectiveClaimStatusUseCase } from 'src/app/features/claims/domain/usecases/update-collective-status.usecase';

@Component({
  selector: 'app-claim-view-colective',
  templateUrl: './claim-view-colective.component.html',
  styleUrls: ['./claim-view-colective.component.css'],
  providers: [MessageService, Toast, ConfirmationService],
})
export class ClaimViewColectiveComponent implements OnInit {
  private AES = new AESEncryptDecryptService();

  claim?: ClaimCollectiveEntity;
  claimForAudience?: ClaimCollectiveEntity;
  claimId?: string | null;
  provinces: ProvinceEntity[] = [];
  claimetProvince?: ProvinceEntity;
  files?: string[];
  displayModal: boolean = false;
  displayModalImage: boolean = false;
  pdfUrl?: string = '';
  minutesForClaim?: MinuteEntity[];
  minuteId: any;
  userRol?: string;
  buttons: boolean = false;

  newStatusClaimUpdate?: UpdateClaimStatusModel;
  labelText: string = 'Aceptar';
  rejectDialogVisible: boolean = false;
  rejectionReason: string = '';
  audienciaDialogVisible: boolean = false;
  minuteDialogVisible: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private claimUseCase: ClaimColectiveUseCase,
    private getProvince: GetProvinceUseCase,
    private getFileUseCase: GetFileColectiveUseCase,
    private getMinutesByClaimIdUseCase: GetMinutesByClaimIdUseCase,
    private location: Location,
    private confirmationService: ConfirmationService,
    private updateClaimStatusUseCase: UpdateCollectiveClaimStatusUseCase,
    private messageService: MessageService,
    private localStorageService: LocalStorageServiceBase
  ) {}

  ngOnInit() {
    this.claimId = this.route.snapshot.paramMap.get('id');
    this.userRol = this.AES.decrypt(
      this.localStorageService.loadStorageData('user_rol')
    );
    this.getClaim();
    this.loadProvinces();
    this.getMinutesByClaimId();
    this.showButtons();
  }

  async getClaim(): Promise<void> {
    let result = await this.claimUseCase.execute(this.claimId!);
    if (result instanceof Failure) {
      return;
    }
    this.claim = result;
    this.claimForAudience = Object.assign({}, result);
    this.files = result.claimedFiles;
  }

  async getMinutesByClaimId(): Promise<void> {
    let result = await this.getMinutesByClaimIdUseCase.execute(this.claimId!);
    if (result instanceof Failure) {
      return;
    }
    this.minutesForClaim = result;
  }

  showButtons() {
    if (
      this.userRol == UserRol.ADMINISTRATOR ||
      this.userRol == UserRol.CONCILIATOR
    ) {
      this.buttons = true;
    }
  }

  async viewImage(fileName: string) {
    try {
      let fileData = new GetFileModel(this.claim?.id!, fileName);
      this.pdfUrl = await this.getFile(fileData);

      const fileExtension = fileName.split('.').pop()?.toLowerCase();
      const isImageExt = ['jpg', 'jpeg', 'png', 'webp', 'gif'].includes(
        fileExtension || ''
      );

      if (isImageExt) {
        this.displayModalImage = true;
        this.displayModal = false;
      } else {
        this.displayModal = true;
        this.displayModalImage = false;
      }
    } catch (error) {
      this.displayModal = false;
      this.displayModalImage = false;
    }
  }
  async getFile(param: GetFileModel): Promise<string> {
    let result = await this.getFileUseCase.execute(param);
    if (result instanceof Failure) {
      return result.message;
    }
    return result;
  }

  getStatusClass(status: string | undefined): string {
    switch (status) {
      case ClaimStatus.DRAFT:
        return 'status-draft';
      case ClaimStatus.PENDING:
        return 'status-pending';
      case ClaimStatus.REFUSED:
        return 'status-refused';
      case ClaimStatus.FINALIZED:
        return 'status-finalized';
      case ClaimStatus.IN_INTERNAL_PROCESS:
        return 'status-in-internal-process';
      case ClaimStatus.AUDIENCE_SET:
      case 'Audiencia Fijada':
        return 'status-audience-set';
      case ClaimStatus.ADMITTED:
        return 'status-admited';
      default:
        return '';
    }
  }

  goBack(): void {
    this.location.back();
  }

  recibeAceptDialog(action: string): void {
    if (action == 'Admitir') {
      this.updateAdmittedStatusCheck();
    } else {
      this.updateRefucedStatusCheck();
    }
  }

  newAudienceCheck() {
    this.audienciaDialogVisible = true;
  }

  newMinuteCheck() {
    this.minuteDialogVisible = true;
  }

  cancel(close: any) {
    this.audienciaDialogVisible = false;
    this.minuteDialogVisible = false;
  }

  handleShowMessage(event: {
    severity: string;
    summary: string;
    detail: string;
  }) {
    this.messageService.add({
      severity: event.severity,
      summary: event.summary,
      detail: event.detail,
    });
  }

  updateAdmittedStatusCheck() {
    this.labelText = 'Admitir reclamo';
    this.confirmationService.confirm({
      header: 'Admitir reclamo',
      message: `¿Desea admitir el reclamo?`,
      accept: () => {
        this.newStatusClaimUpdate = new UpdateClaimStatusModel(
          this.claimId!,
          ClaimStatus.ADMITTED
        );
        this.updateClaimStatus(this.newStatusClaimUpdate);
      },
    });
  }

  async updateClaimStatus(claim: UpdateClaimStatusEntity): Promise<void> {
    let result = await this.updateClaimStatusUseCase.execute(claim);
    if (result instanceof Failure) {
      this.messageService.add({
        severity: 'error',
        summary: 'Ocurrió un problema.',
        detail: 'Estado no actualizado.',
        life: 900,
      });
      return;
    }
    this.messageService.add({
      severity: 'success',
      summary: 'Estado actualizado',
      detail: 'El estado se actualizó exitosamente.',
    });
    this.getClaim();
  }

  updateRefucedStatusCheck() {
    this.labelText = 'Rechazar reclamo';
    this.confirmationService.confirm({
      header: 'Rechazar reclamo',
      message: `¿Desea rechazar el reclamo?`,
      accept: () => {
        this.rejectDialogVisible = true;
      },
      reject: () => {
        this.rejectionReason = '';
      },
    });
  }

  confirmRejection() {
    if (this.rejectionReason.trim()) {
      this.newStatusClaimUpdate = new UpdateClaimStatusModel(
        this.claimId!,
        ClaimStatus.REFUSED,
        this.rejectionReason
      );
      this.updateClaimStatus(this.newStatusClaimUpdate);
      this.rejectDialogVisible = false;
      this.rejectionReason = '';
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Ocurrió un problema.',
        detail: 'Debe especificar el motivo de rechazo.',
        life: 1200,
      });
    }
    this.rejectionReason = '';
  }

  async loadProvinces() {
    try {
      const result = await this.getProvince.execute();
      if (Array.isArray(result)) {
        this.provinces = result;
      }
    } catch (error) {
      throw new UnhandledFailure();
    }
    this.setProvinceById();
  }

  setProvinceById(): void {
    this.claimetProvince = this.provinces.find(
      (prov) => prov.id === this.claim?.provinceCompany
    );
  }
}
