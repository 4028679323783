import { UserData } from "../../domain/entities/user-data.entity";

export class UserDataModel extends UserData {

    override toJson(): Map<string, any> {
        throw new Error("Method not implemented.");
    }

    static fromJson(data: Map<string, any>): UserData {
        return new UserDataModel(
            data.get('id'),
            data.get('rol'),
            data.get('name'),
            data.get('last_name'),
            data.get('cuil'),
            data.get('gender'),
            data.get('phone'),
            data.get('birth_date'),
            data.get('civil_status'),
            data.get('province'),
            data.get('city'),
            data.get('street'),
            data.get('number'),
            data.get('floor_apartment'),
            data.get('postal_code'),
            data.get('company'),
            data.get('company_cuil'),
            data.get('email'),
            data.get('dni'),
            data.get('neighborhood'),
            data.get('tower'),
            data.get('claims'),
            data.get('razon_social'),
            data.get('tipo_actuacion'),
            data.get('cargo_empresa'),
            data.get('cuit'),
            data.get('phone_company'),
        );
    }
}