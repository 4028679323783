import { UseCase } from "src/app/core/utils/usecase";
import { DeleteClaimEntity } from "../entities/delete-claim.entity";
import { Failure } from "src/app/core/utils/failure";
import { ClaimRepositoryBase } from "../repositories/claim-base.repository";
import { Injectable } from "@angular/core";

@Injectable()
export class DeleteClaimUseCase implements UseCase<DeleteClaimEntity, string | Failure> {
    constructor(private claimRepository: ClaimRepositoryBase){}
    
    async execute(param: DeleteClaimEntity): Promise<string | Failure> {
        return await this.claimRepository.deleteClaim(param);
    }

}