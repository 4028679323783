import { PaginationParams } from "src/app/core/interfaces/pagination-params.interface";
import { UseCase } from "src/app/core/utils/usecase";
import { AudiencePagination } from "../entities/audience-pagination.entity";
import { Failure } from "src/app/core/utils/failure";
import { AudienceRepositoryBase } from "../repositories/aucience-base.repository";
import { AudiencePaginationParams } from "../entities/audience-pagination-params.entity";
import { Injectable } from "@angular/core";

@Injectable()
export class GetNextAudienceOfConciliatorUseCase implements UseCase<PaginationParams, AudiencePagination | Failure> {

    constructor(private audienceRepository: AudienceRepositoryBase) {}

    async execute(param: AudiencePaginationParams): Promise<AudiencePagination | Failure> {
        return await this.audienceRepository.getNextAudienceOfConciliator(param);
    }
}
