import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserData } from 'src/app/features/user/domain/entities/user-data.entity';
import { UserService } from 'src/app/features/user/services/user.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Toast } from 'primeng/toast';
import { Failure } from 'src/app/core/utils/failure';
import AESEncryptDecryptService from 'src/app/core/utils/crypto';
import { LocalStorageServiceBase } from 'src/app/core/services/localStorage-serviceBase';
import { UserPagination } from '../../../domain/entities/user-pagination.entity';
import { UserUseCase } from '../../../domain/usecases/get-user.usecase';
import { GetProvinceUseCase } from 'src/app/core/services/location/domain/usecases/get-province.usecase';
import { ProvinceEntity } from 'src/app/core/services/location/domain/entities/province.entity';
import { GetLocationsUseCase } from 'src/app/core/services/location/domain/usecases/get-locations.usecase';
import { LocationEntity } from 'src/app/core/services/location/domain/entities/location.entity';
import { UserRol } from 'src/app/core/enums/user-rol.enum';
import { CreateComisionUserUseCase } from '../../../domain/usecases/create-user.usecase';
import { ComisionUserModel } from '../../../data/models/comision-user.model';
import { UpdateUserUseCase } from '../../../domain/usecases/update-user.usecase';
import { Location } from '@angular/common';



interface Localities {
  id: number;
  name: string;
}

@Component({
  selector: 'app-newUser',
  templateUrl: './newUser.component.html',
  styleUrls: ['./newUser.component.css'],
  providers: [MessageService, Toast, ConfirmationService]
})
export class newUserComponent implements OnInit {
  @Output() usersform: EventEmitter<FormGroup> = new EventEmitter();
  @Input() users?: UserPagination;
  @Input() userData?: UserData;
  @Output() activeUsers: EventEmitter<boolean> = new EventEmitter();

  defaultRows: number = 5;
  defaultPage: number = 0;
  public totalQuantity: number = 0;
  public first: number = 0;
  public itemsPerPage: number = 5;
  public amountRegistersPage = this.itemsPerPage;
  isEditMode: boolean = false;

  provinces: ProvinceEntity[] = [];
  localities: Localities[] = [];
  roles: string[] = [UserRol.ADMINISTRATOR, UserRol.COMISSION_MEMBER, UserRol.CONCILIATOR];

  usersList: UserData[] = [];
  paginatedData: UserPagination | undefined = undefined;
  selectedUserId?: string | null;
  isLoading: boolean = true;
  private AES = new AESEncryptDecryptService();
  homeView: boolean = false;
  userId?: string;

  isNewUser: boolean = true;

  is_administrator: boolean = false;
  is_comision: boolean = false;
  is_conciliator: boolean = false;

  constructor(private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private UserUseCase: UserUseCase,
    private getProvince: GetProvinceUseCase,
    private getLocations: GetLocationsUseCase,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private localStorageService: LocalStorageServiceBase,
    private createComisionUserUseCase: CreateComisionUserUseCase,
    private updateComisionUserUseCase: UpdateUserUseCase,
    private location: Location) { }

  ngOnInit(): void {
    this.loadProvinces()
    this.usersForm.get('province')?.valueChanges.subscribe((selectedProvince: any) => {

      if (selectedProvince && selectedProvince.id) {
        this.loadLocalities(selectedProvince.id);
        this.usersForm.patchValue({ provincia: selectedProvince.name });
      }
      this.loadLocalities(selectedProvince);
    });

    this.userId = this.AES.decrypt(this.localStorageService.loadStorageData('user_id'));
    if (this.router.url.includes('main/user/update')) {
      this.isNewUser = false;
      this.isEditMode = true;
      this.selectedUserId = this.route.snapshot.paramMap.get('id');
      this.getUser();
      this.loadLocalities(this.usersForm.get('city')?.value);
      setTimeout(() => this.initializeFormWithClaimData(), 500);
    }
  }
  private initializeFormWithClaimData(): void {
    if (!this.userData) return;
    if (this.isEditMode) {
      this.usersForm.patchValue({
        lastname: this.userData.lastName || '',
        name: this.userData.name || '',
        cuil: this.userData.cuil || '',
        dni: this.userData.dni || '',
        email: this.userData.email || '',
        phone: this.userData.phone || '',
        rol: this.userData.rol || '',
        street: this.userData.street || '',
        number: this.userData.number || '',
        floorApartment: this.userData.floorApartment || '',
        postalCode: this.userData.postalCode || '',
        neighborhood: this.userData.neighborhood || '',
        province: this.getProvinceIdByName(this.userData.province) || '',
        city: this.userData.city || '',
        tower: this.userData.tower || ''
      });
    }
  }

  private getProvinceIdByName(provinceName: string): string | undefined {
    const province = this.provinces.find(p => p.name === provinceName);

    return province ? province.id : undefined;
  }

  usersForm: FormGroup = this.formBuilder.group({
    lastname: ['', [Validators.required, Validators.maxLength(50), Validators.pattern(/^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]*$/)]],
    name: ['', [Validators.required, Validators.maxLength(50), Validators.pattern(/^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]*$/)]],
    cuil: ['', [Validators.required]],
    email: ['', [Validators.required, Validators.email, Validators.maxLength(100)]],
    phone: ['', [Validators.required, Validators.minLength(7), Validators.maxLength(15), Validators.pattern(/^[0-9]*$/)]],
    rol: ['', Validators.required],
    province: ['', [Validators.required]],
    neighborhood: ['', [Validators.required, Validators.maxLength(50)]],
    street: ['', [Validators.required, Validators.maxLength(50)]],
    number: ['', [Validators.required, Validators.maxLength(5), Validators.pattern(/^[0-9]*$/)]],
    postalCode: ['', [Validators.required, Validators.maxLength(10), Validators.minLength(4), Validators.pattern(/^[0-9]*$/)]],
    floorApartment: ['', [Validators.maxLength(10)]],
    tower: ['', [Validators.maxLength(30)]],
    city: ['', Validators.required],
    dni: [, [Validators.required,  Validators.maxLength(8), Validators.pattern(/^[0-9]*$/)]],
  });

  async getUser(): Promise<void> {
    let result = await this.UserUseCase.execute(this.selectedUserId!);
    if (result instanceof Failure) {
      return;
    }
    this.userData = result;
    this.initializeFormWithClaimData();
  }

  async loadProvinces() {
    const result = await this.getProvince.execute();
    if (Array.isArray(result)) {
      this.provinces = result;
    }
  }

  async loadLocalities(provinceId: string) {
    const localities = await this.getLocations.execute(provinceId);
    if (Array.isArray(localities)) {
      this.localities = this.putIdToLocalitiesList(localities);
    }
  }


  putIdToLocalitiesList(list: LocationEntity[]): Localities[] {
    let listWithId: Localities[] = [];
    for (let i = 0; i < list.length; i++) {
      let city: Localities = {
        id: i,
        name: list[i].name
      };
      listWithId.push(city);
    }
    return listWithId;
  }

  selectUser(id: string) {
    this.selectedUserId = id;
    this.getUser();
  }

  hasErrors(controlName: string, errorType: string) {
    return (
      this.usersForm.get(controlName)?.hasError(errorType) &&
      this.usersForm.get(controlName)?.touched
    );
  }

  onSubmit(data: FormGroup) {
    if (this.isNewUser) {
      this.createUser(data);
    } else {
      this.updateUser(data);
    }
  }

  async createUser(data: FormGroup): Promise<void> {

    this.usersForm.patchValue(data);
    this.setRolNewComisionUser();
    let result = await this.createComisionUserUseCase.execute(this.createNewComisionUser());
    if(result instanceof Failure) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: result.message,
      });
      return;
    }
    this.messageService.add({
      severity: 'success',
      summary: 'Usuario creado',
      detail: 'Nuevo usuario se creo exitosamente.',
    });
    setTimeout(() => {
      this.router.navigate(['/main/home']);
    }, 1100);
  }

  async updateUser(data: FormGroup): Promise<void> {
    this.usersForm.patchValue(data);
    this.setRolNewComisionUser();
    let result = await this.updateComisionUserUseCase.execute(this.createNewComisionUser());
    if(result instanceof Failure) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: result.message,
      });
      return;
    }
    this.messageService.add({
      severity: 'success',
      summary: 'Usuario actualizado',
      detail: 'El usuario se actualizó exitosamente.',
    });
    setTimeout(() => {
      this.router.navigate([this.location.back()]);
    }, 1100);
  }

  cancel() {
    this.location.back();
  }

  createNewComisionUser(): ComisionUserModel {
    return new ComisionUserModel(
      this.usersForm.get('rol')?.value,
      this.usersForm.get('lastname')?.value + ' ' + this.usersForm.get('name')?.value ,
      this.usersForm.get('name')?.value,
      this.usersForm.get('lastname')?.value,
      this.usersForm.get('cuil')?.value,
      this.usersForm.get('phone')?.value,
      this.usersForm.get('dni')?.value,
      this.usersForm.get('province')?.value,
      this.usersForm.get('city')?.value,
      this.usersForm.get('street')?.value,
      this.usersForm.get('number')?.value.toString(),
      this.usersForm.get('postalCode')?.value.toString(),
      this.usersForm.get('email')?.value,
      this.usersForm.get('dni')?.value.toString(),
      this.is_administrator,
      this.is_comision,
      this.is_conciliator,
      this.usersForm.get('neighborhood')?.value,
      this.usersForm.get('floorApartment')?.value,
      this.usersForm.get('tower')?.value,
      this.selectedUserId!,
    )
  }

  setRolNewComisionUser(){
    let rol: string = this.usersForm.get('rol')?.value;
    switch (rol) {
      case UserRol.ADMINISTRATOR:
        this.is_administrator = true;
        break;
      case UserRol.COMISSION_MEMBER:
        this.is_comision = true;
        break;
      case UserRol.CONCILIATOR:
        this.is_conciliator = true;
        break;
      default:
        this.is_comision = true;
        break;
    }
  }

}
