import { Component } from '@angular/core';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.css']
})
export class ConfigurationComponent {
  activeIndex: number = 0;

  activeStep(index: number) {
    this.activeIndex = index
  }

}
