import { UseCase } from 'src/app/core/utils/usecase';
import { UpdateClaimConciliatorEntity } from '../entities/update-claim-conciliator.entity';
import { Failure } from 'src/app/core/utils/failure';
import { Injectable } from '@angular/core';
import { ClaimMultiRepositoryBase } from '../repositories/claim-multi-base.repository';

@Injectable()
export class UpdateClaimMultiConciliatorUseCase
  implements UseCase<UpdateClaimConciliatorEntity, string | Failure>
{
  constructor(private claimRepository: ClaimMultiRepositoryBase) {}

  async execute(
    param: UpdateClaimConciliatorEntity
  ): Promise<string | Failure> {
    return await this.claimRepository.updateClaimConciliator(param);
  }
}
