import { User } from '../../domain/entities/user.entity';

export class UserModel extends User {
  constructor(
    rol: string,
    name: string,
    last_name: string,
    cuil: string,
    gender: string,
    phone: string,
    birth_date: Date,
    civil_status: string,
    province: string,
    city: string,
    street: string,
    number: number,
    floor_apartment: string,
    postal_code: number,
    company: string,
    company_cuil: string,
    email: string,
    password: string,
    comission_position: string,
    comission_email: string,
    is_employee: boolean,
    is_employer: boolean,
    is_conciliator: boolean,
    tower?: string,
    type_responsibility?: string,
    phone_company?: string,
    tipo_actuacion?: string,
    cargo_empresa?: string,
    razon_social?: string,
    cuit?: string,

  ) {
    super(
      rol,
      name,
      last_name,
      cuil,
      gender,
      phone,
      birth_date,
      civil_status,
      province,
      city,
      street,
      number,
      floor_apartment,
      postal_code,
      company,
      company_cuil,
      email,
      password,
      comission_position,
      comission_email,
      is_employee,
      is_employer,
      is_conciliator,
      tower,
      type_responsibility,
      phone_company,
      tipo_actuacion,
      cargo_empresa,
      razon_social,
      cuit,
    );
  }

  override toJson(): Map<string, any> {
    let map: Map<string, any> = new Map<string, any>();
    map.set('rol', this.rol);
    map.set('name', this.name);
    map.set('last_name', this.last_name);
    map.set('cuil', this.cuil);
    map.set('gender', this.gender);
    map.set('phone', this.phone);
    map.set('birth_date', this.birth_date);
    map.set('civil_status', this.civil_status);
    map.set('province', this.province);
    map.set('city', this.city);
    map.set('street', this.street);
    map.set('number', this.number);
    map.set('floor_apartment', this.floor_apartment);
    map.set('tower', this.tower);
    map.set('postal_code', this.postal_code);
    map.set('company', this.company);
    map.set('company_cuil', this.company_cuil);
    map.set('email', this.email);
    map.set('password', this.password);
    map.set('comission_position', this.password);
    map.set('comission_email', this.password);
    map.set('is_employee', this.is_employee);
    map.set('is_employer', this.is_employer);
    map.set('is_conciliator', this.is_conciliator);
    map.set('type_responsibility', this.type_responsibility);
    map.set('phone_company', this.phone_company);
    map.set('tipo_actuacion', this.tipo_actuacion);
    map.set('cargo_empresa', this.cargo_empresa);
    map.set('razon_social', this.razon_social);
    map.set('cuit', this.cuit);
    return map;
  }

  static fromJson(param: Map<string, any>): User {
    return new UserModel(
      param.get('rol'),
      param.get('name'),
      param.get('last_name'),
      param.get('cuil'),
      param.get('gender'),
      param.get('phone'),
      new Date(param.get('birth_date')),
      param.get('civil_status'),
      param.get('province'),
      param.get('city'),
      param.get('street'),
      Number(param.get('number')),
      param.get('floor_apartment'),
      Number(param.get('postal_code')),
      param.get('company'),
      param.get('company_cuil'),
      param.get('email'),
      param.get('password'),
      param.get('comission_position'),
      param.get('comission_email'),
      param.get('is_employee'),
      param.get('is_employer'),
      param.get('is_conciliator'),
      param.get('tower'),
      param.get('type_responsibility'),
      param.get('phone_company'),
      param.get('tipo_actuacion'),
      param.get('cargo_empresa'),
      param.get('razon_social'),
      param.get('cuit'),
    );
  }
}
