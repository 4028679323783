import { UseCase } from "src/app/core/utils/usecase";
import { UpdateClaimStatusEntity } from "../entities/update-claim-satus.entity";
import { Failure } from "src/app/core/utils/failure";
import { ClaimRepositoryBase } from "../repositories/claim-base.repository";
import { Injectable } from "@angular/core";

@Injectable()
export class UpdateClaimStatusUseCase implements UseCase<UpdateClaimStatusEntity, string | Failure> {
    constructor(private claimRepository: ClaimRepositoryBase) { }

    async execute(param: UpdateClaimStatusEntity): Promise<string | Failure> {
        return await this.claimRepository.updateClaimStatus(param);
    }
}