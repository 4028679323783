import { Component } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { LocalStorageServiceBase } from 'src/app/core/services/localStorage-serviceBase';
import { CreateClaimColectiveUseCase } from 'src/app/features/claims/domain/usecases/create-cliam-colective.usecase';
import { ClaimColectiveUseCase } from 'src/app/features/claims/domain/usecases/get-claim-colective-usecase';
import { UpdateClaimColectiveUseCase } from 'src/app/features/claims/domain/usecases/update-claim-colective.usecase';
import { UserData } from 'src/app/features/user/domain/entities/user-data.entity';
import { GetMyDataUseCase } from 'src/app/features/user/domain/usecases/get-my-data.usecase';
import { Location } from '@angular/common';
import { Toast } from 'primeng/toast';
import { ClaimMultiEntity } from 'src/app/features/claims/domain/entities/claim-multi.entity';
import { Failure } from 'src/app/core/utils/failure';
import { ClaimMultiModel } from 'src/app/features/claims/data/models/claim-multi.model';
import { CreateMultiCompanyUseCase } from 'src/app/features/company/domain/usecases/create-multi-company.usecase';
import { CreateClaimMultiUseCase } from 'src/app/features/claims/domain/usecases/create-claim-multi.usecase';

@Component({
  selector: 'app-claim-multi-single',
  templateUrl: './claim-multi-single.component.html',
  styleUrls: ['./claim-multi-single.component.css'],
  providers: [MessageService, Toast, ConfirmationService],
})
export class ClaimMultiSingleComponent {
  claim?: ClaimMultiEntity;
  isNewClaim: boolean = false;
  activeIndex: number = 0;
  claimForm!: FormGroup;
  userData?: UserData;
  isLoading: boolean = false;
  claimId?: string | null;

  constructor(
    private formBuilder: FormBuilder,
    private myData: GetMyDataUseCase,
    private messageService: MessageService,
    private createClaimUseCase: CreateClaimMultiUseCase,
    private router: Router,
    private route: ActivatedRoute,
    private claimUseCase: ClaimColectiveUseCase,
    private updateClaimUseCase: UpdateClaimColectiveUseCase,
    private location: Location,
    private confirmationService: ConfirmationService,
    private localStorageService: LocalStorageServiceBase
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.checkIsNew();
  }

  private initForm(): void {
    this.claimForm = this.formBuilder.group({
      userId: ['', Validators.required],
      rol: ['', Validators.required],
      id: ['', Validators.required],
      status: ['', Validators.required],
      claimType: ['', Validators.required],
      numberOfClaim: ['', Validators.required],
      startDate: ['', Validators.required],
      confirmInformation: ['', Validators.required],
      isBorrador: [false, Validators.required],
      consent: [false, Validators.requiredTrue],
    });
  }

  checkIsNew() {
    if (this.router.url.includes('main/new')) {
      this.isNewClaim = true;
    }
    // else {
    //   this.claimId = this.route.snapshot.paramMap.get('id');
    //   this.getClaim();
    // }
    this.getMyData();
  }

  async getMyData(): Promise<void> {
    let result = await this.myData.execute();
    if (result instanceof Failure) {
      return;
    }
    this.userData = result;
  }

  receiveData(data: FormGroup) {
    Object.keys(data.controls).forEach((key) => {
      if (!this.claimForm.contains(key)) {
        this.claimForm.addControl(key, data.controls[key]);
      }
    });
  }

  formComplete(data?: any) {
    let formGroup: FormGroup;

    const controls: { [key: string]: AbstractControl } = {};
    Object.keys(data).forEach((key) => {
      controls[key] = new FormControl(data[key]);
    });
    formGroup = new FormGroup(controls);

    Object.keys(formGroup.controls).forEach((key) => {
      if (!this.claimForm.contains(key)) {
        this.claimForm.addControl(key, formGroup.controls[key]);
      }
    });
    this.createClaim(data);
  }

  activeStep(index: number) {
    this.activeIndex = index;
  }

  nextStep(): void {
    if (this.activeIndex < 2) {
      this.activeIndex++;
    }
  }

  async createClaim(data: FormGroup): Promise<void> {
    this.isLoading = true;
    this.loadData();
    try {
      let result = await this.createClaimUseCase.execute(this.createNewClaim());

      if (result instanceof Failure) {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: result.message,
        });
        return;
      }
      this.messageService.add({
        severity: 'success',
        summary: 'Reclamo creado',
        detail: 'Su nuevo reclamo se creó exitosamente.',
      });

      setTimeout(() => {
        this.router.navigate(['/main/home']);
      }, 1100);
    } catch (error) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error inesperado',
        detail: 'Ha ocurrido un error. Inténtelo nuevamente.',
      });
    } finally {
      this.isLoading = false;
    }
  }

  loadData() {
    this.claimForm.get('rol')?.setValue(this.userData?.rol);
    this.claimForm.get('userId')?.setValue(this.userData?.id);
    // if (this.claim?.status !== 'Borrador' || this.claim?.status !== undefined) {
    //   this.claimForm.get('id')?.setValue(this.claim?.id);
    //   this.claimForm.get('status')?.setValue(this.claim?.status);
    //   this.claimForm.get('claimType')?.setValue(this.claim?.claimType);
    //   this.claimForm.get('numberOfClaim')?.setValue(this.claim?.numberOfClaim);
    //   this.claimForm
    //     .get('confirmInformation')
    //     ?.setValue(this.claim?.confirmInformation);
    //   this.claimForm.get('isBorrador')?.setValue(this.claim?.isBorrador);
    //   this.claimForm.get('startDate')?.setValue(this.claim?.startDate);
    // }
  }

  cancel(isActive: boolean) {
    // if (this.claim?.status === 'Borrador' || this.claim?.status === undefined) {
    //   if (!isActive) return;
    //   this.confirmationService.confirm({
    //     header: 'Salir del reclamo',
    //     message:
    //       'Su progreso se guardara automáticamente como borrador si desea salir. Podrá retomar cuando lo desee.',
    //     accept: async () => {
    //       try {
    //         await this.saveDraft(this.claimForm.value);
    //       } catch (error) {
    //         this.messageService.add({
    //           severity: 'error',
    //           summary: 'Error',
    //           detail:
    //             'No se pudo guardar el borrador. Por favor intente nuevamente.',
    //         });
    //       }
    //     },
    //   });
    // } else {
    //   this.confirmationService.confirm({
    //     header: 'Salir del reclamo',
    //     message:
    //       '¿Estás seguro de que deseas salir del reclamo? Se perderán los cambios no guardados.',
    //     accept: async () => {
    //       this.router.navigate([this.location.back()]);
    //     },
    //   });
    // }
  }

    createNewClaim(): ClaimMultiModel {
      return new ClaimMultiModel(
        this.claimForm.get('id')?.value ?? '',
        this.claimForm.get('userId')?.value ?? '',
        this.claimForm.get('rol')?.value ?? '',
        this.claimForm.get('razonSocial')?.value ?? '',
        this.claimForm.get('companyCuit')?.value ?? '',
        this.claimForm.get('companyEmail')?.value ?? '',
        this.claimForm.get('companyPhone')?.value ?? '',
        this.claimForm.get('streetCompany')?.value ?? '',
        this.claimForm.get('numberCompany')?.value ?? '',
        this.claimForm.get('floorApartmentCompany')?.value ?? '',
        this.claimForm.get('towerCompany')?.value ?? '',
        this.claimForm.get('postalCodeCompany')?.value ?? '',
        this.claimForm.get('provinceCompany')?.value ?? '',
        this.claimForm.get('cityCompany')?.value ?? '',
        this.claimForm.get('claimantTypePerformance')?.value ?? '',
        this.claimForm.get('departmentId')?.value ?? '',

        this.claimForm.get('lastName')?.value ?? '',
        this.claimForm.get('name')?.value ?? '',
        this.claimForm.get('cuil')?.value ?? '',
        this.claimForm.get('claimantPosition')?.value ?? '',
        this.claimForm.get('phone')?.value ?? '',
        this.claimForm.get('email')?.value ?? '',
        this.claimForm.get('birthday')?.value ?? '',
        this.claimForm.get('file')?.value ?? [],

        this.claimForm.get('employees')?.value ?? [],

        this.claimForm.get('selecterObjectOfClaim')?.value ?? [],
        this.claimForm.get('textClaimDetails')?.value ?? '',
        this.claimForm.get('extraDocumentationPdf')?.value ?? '',
        this.claimForm.get('selectedOwnRight')?.value ?? '',
        this.claimForm.get('lawyerFullName')?.value ?? '',
        this.claimForm.get('lawyerRegistrationNumber')?.value ?? '',
        this.claimForm.get('lawyerEmail')?.value ?? '',

        this.claimForm.get('consent')?.value ?? false,

        this.claimForm.get('confirmInformation')?.value ?? false,
        this.claimForm.get('startDate')?.value ?? '',
        this.claimForm.get('numberOfClaim')?.value ?? '',
        this.claimForm.get('isBorrador')?.value ?? false,
      );
    }
}
