import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { TableLazyLoadEvent } from 'primeng/table';
import { ClaimStatus } from 'src/app/core/enums/claim-status.enum';
import { UserRol } from 'src/app/core/enums/user-rol.enum';
import { Failure } from 'src/app/core/utils/failure';
import { ClaimsPaginationParamsModel } from 'src/app/features/claims/data/models/claim-pagination-params.model';
import { ClaimData } from 'src/app/features/claims/domain/entities/claim-data.entity';
import { ClaimPagination } from 'src/app/features/claims/domain/entities/claim-pagination.entity';
import { GetPaginatedClaimsUseCase } from 'src/app/features/claims/domain/usecases/get-paginated-claims.usecase';
import { ClaimServiceService } from 'src/app/features/claims/services/claim-service.service';
import { UserData } from 'src/app/features/user/domain/entities/user-data.entity';
import { UserService } from 'src/app/features/user/services/user.service';
import { ClaimEntity } from '../../../domain/entities/claim.entity';
import { Toast } from 'primeng/toast';
import { Router } from '@angular/router';
import { ClaimUseCase } from '../../../domain/usecases/get-claim.usecase';
import { GetPaginatedAllClaimsUseCase } from '../../../domain/usecases/get-pagination-all-claims.usecase';
import { LocalStorageServiceBase } from 'src/app/core/services/localStorage-serviceBase';
import AESEncryptDecryptService from 'src/app/core/utils/crypto';
import { LocalStorageService } from 'src/app/core/services/local-storage.implementation.service';
import { GetAllConciliatorsUseCase } from 'src/app/features/user/domain/usecases/get-all-conciliators.usecase';
import { UpdateClaimStatusUseCase } from '../../../domain/usecases/update-claim-status.usecase';
import { UpdateClaimStatusEntity } from '../../../domain/entities/update-claim-satus.entity';
import { UpdateClaimStatusModel } from '../../../data/models/update-claim-status.model';
import { UpdateClaimConciliatorModel } from '../../../data/models/update-claim-consciliator.model';
import { UpdateClaimConciliatorEntity } from '../../../domain/entities/update-claim-conciliator.entity';
import { UpdateClaimConciliatorUseCase } from '../../../domain/usecases/update-claim-conciliator.usecase';
import { GetPaginatedAllClaimsByConciliatorUseCase } from '../../../domain/usecases/get-pagination-all-cliams-by-conciliator.usecase';
import { ClaimFilterModel } from '../../../data/models/claim-filter.model';
import { filter } from 'rxjs';

@Component({
  selector: 'app-claims-comision-table',
  templateUrl: './claims-comision-table.component.html',
  styleUrls: ['./claims-comision-table.component.css'],
  providers: [MessageService, Toast, ConfirmationService],
})
export class ClaimsComisionTableComponent implements OnInit {
  claims!: ClaimPagination | undefined;
  @Input() userData?: UserData;

  public status: string[] = [
    ClaimStatus.PENDING,
    ClaimStatus.REFUSED,
    ClaimStatus.ADMITTED,
    ClaimStatus.AUDIENCE_SET,
    ClaimStatus.IN_INTERNAL_PROCESS,
    ClaimStatus.FINALIZED,
  ];

  public conciliators: UserData[] = [];

  defaultRows: number = 5;
  defaultPage: number = 0;
  public totalQuantity!: number;
  public first: number = 0;
  public itemsPerPage: number = 5;
  public amountRegistersPage = this.itemsPerPage;

  userClaims?: ClaimData[];
  paginatedData: ClaimPagination | undefined = undefined;
  selectedClaimId?: string;
  isLoading: boolean = true;
  claim?: ClaimEntity;
  tableOrImg: boolean = false;
  newClaims?: number;
  private AES = new AESEncryptDecryptService();
  homeView: boolean = false;
  rol?: string;
  isComisionMemberRol: boolean = false;
  isAdministratorRol: boolean = false;
  isConciliatorRol: boolean = false;
  isComisionRol: boolean = false;
  newStatusClaimUpdate?: UpdateClaimStatusModel;
  originalStatus: string | null = null;
  newConciliatorClaimUpdate?: UpdateClaimConciliatorModel;
  userId?: string;
  audienciaDialogVisible: boolean = false;
  isLoadingSpinner: boolean = false;
  showFilter: boolean = false;
  filters?: ClaimFilterModel;

  public items: MenuItem[] = [];

  public threeOptionsItems: MenuItem[] = [
    {
      label: 'Ver reclamo',
      icon: 'pi pi-eye ',
      command: () => this.viewClaim(),
    },
    {
      label: 'Fijar nueva audiencia',
      icon: 'pi pi-calendar-plus',
      command: () => this.setNewAudience(),
    },
  ];
  public twoOptionsItems: MenuItem[] = [
    {
      label: 'Ver reclamo',
      icon: 'pi pi-eye ',
      command: () => this.viewClaim(),
    },
    {
      label: 'Fijar audiencia',
      icon: 'pi pi-calendar-plus',
      command: () => this.setNewAudience(),
    },
  ];

  public singleItem: MenuItem[] = [
    {
      label: 'Ver reclamo',
      icon: 'pi pi-eye',
      command: () => this.viewClaim(),
    },
  ];

  constructor(
    private getPaginatedClaimsUseCase: GetPaginatedClaimsUseCase,
    private getPaginatedAllClaimsUseCase: GetPaginatedAllClaimsUseCase,
    private userService: UserService,
    private claimService: ClaimServiceService,
    private router: Router,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private claimUseCase: ClaimUseCase,
    private localStorageService: LocalStorageServiceBase,
    private getAllConciliatorsUseCase: GetAllConciliatorsUseCase,
    private updateClaimStatusUseCase: UpdateClaimStatusUseCase,
    private updateClaimConciliatorUseCase: UpdateClaimConciliatorUseCase,
    private getAllPAginatedClaimsofConciliatorUseCase: GetPaginatedAllClaimsByConciliatorUseCase
  ) {}

  ngOnInit(): void {
    this.userId = this.AES.decrypt(
      this.localStorageService.loadStorageData('user_id')
    );
    this.rol = this.AES.decrypt(
      this.localStorageService.loadStorageData('user_rol')
    );
    this.setRol();
    if (
      this.isAdministratorRol ||
      this.isComisionRol ||
      this.isComisionMemberRol
    ) {
      this.initClaimsOfAdministrador();
    }
    if (this.isConciliatorRol) {
      this.initClaims();
    }
    if (this.router.url.includes('main/home')) {
      this.homeView = true;
    }
    if (this.rol == UserRol.ADMINISTRATOR) {
      this.getAllConciliators();
    }
  }

  handleLoadingChange(loading: boolean) {
    this.isLoadingSpinner = loading;
  }

  setRol() {
    switch (this.rol) {
      case UserRol.COMISSION:
        return (this.isComisionRol = true);
      case UserRol.COMISSION_MEMBER:
        return (this.isComisionMemberRol = true);
      case UserRol.ADMINISTRATOR:
        return (this.isAdministratorRol = true);
      case UserRol.CONCILIATOR:
        return (this.isConciliatorRol = true);
      default:
        return;
    }
  }

  suscribeToUserGetMe(): void {
    this.userService.userData$.subscribe((user: UserData) => {
      this.initUserData();
    });
  }

  initUserData(): void {
    this.userData = this.userService.userData;
  }

  initClaims(): void {
    if (!this.userId) return;
    this.getDataPaginated(this.defaultPage, this.defaultRows);
  }

  initClaimsOfAdministrador(): void {
    this.getDataPaginatedOfAdministrador(this.defaultPage, this.defaultRows);
  }

    

  loadRecords(event: TableLazyLoadEvent) {
    this.getDataPaginated(
      event.first || this.defaultPage,
      event.rows || this.defaultRows
    );
  }

  async getDataPaginated(
    page: number,
    quantity: number
  ): Promise<void> {
    let params = new ClaimsPaginationParamsModel(page, quantity);
    let result;
    params.userId =   this.userId;
    if (this.isConciliatorRol) {
      result = await this.getAllPAginatedClaimsofConciliatorUseCase.execute(
        params
      );
    } else {
      result = await this.getPaginatedAllClaimsUseCase.execute(params);
    }

    if (result instanceof Failure) {
      return;
    }
    this.processClaimsResult(result);
  }

  async getDataPaginatedOfAdministrador(
    page: number,
    quantity: number,
    filters?: ClaimFilterModel
  ): Promise<void> {
    let params = new ClaimsPaginationParamsModel(page, quantity, undefined, filters);
    let result;

    if (this.isConciliatorRol) {
      result = await this.getAllPAginatedClaimsofConciliatorUseCase.execute(
        params
      );
    } else {
      result = await this.getPaginatedAllClaimsUseCase.execute(params);
    }

    if (result instanceof Failure) {
      return;
    }

    this.processClaimsResult(result);
  }

  async getDataPaginatedByConciliatorId(
    userId: string,
    page: number,
    quantity: number
  ): Promise<void> {
    let params = new ClaimsPaginationParamsModel(page, quantity, userId);
    let result = await this.getAllPAginatedClaimsofConciliatorUseCase.execute(
      params
    );
    if (result instanceof Failure) {
      return;
    }
    this.processClaimsResult(result);
  }

  private processClaimsResult(result: ClaimPagination): void {
    this.userClaims = this.sortByDate(result.response);
    this.paginatedData = result;
    this.claimService.claimsData = result.response;
    this.totalQuantity = result.totalQuantity;
    this.countNewClaims(result.response);
  }

  updateStatusCheck(event: any) {
    this.confirmationService.confirm({
      header: 'Actualizar estado',
      message:
        `¿Está seguro de que desea actualizar el estado del reclamo?`,
      accept: () => {
        this.newStatusClaimUpdate = new UpdateClaimStatusModel(this.selectedClaimId!, event.value);
        this.updateClaimStatus(this.newStatusClaimUpdate);
      },
      reject: () => {
        if (this.isConciliatorRol) {
          this.getDataPaginated(
            this.defaultPage,
            this.itemsPerPage
          );
        }
        if (this.isAdministratorRol) {
          this.getDataPaginatedOfAdministrador(this.defaultPage, this.itemsPerPage);
        }
      }
    });
  }


  async getClaim(): Promise<void> {
    let result = await this.claimUseCase.execute(this.selectedClaimId!);
    if (result instanceof Failure) {
      return;
    }
    this.claim = result;
  }

  async getAllConciliators(): Promise<void> {
    const result = await this.getAllConciliatorsUseCase.execute();
    if (result instanceof Failure) {
      return;
    }
    const noAssignedOption: UserData = {
      id: '',
      name: 'No Asignado',
      lastName: '',
      rol: '',
      cuil: '',
      gender: '',
      birthDate: new Date(),
      province: '',
      phone: '',
      email: '',
      civilStatus: '',
      city: '',
      street: '',
      number: '',
      floorApartment: '',
      postalCode: 0,
      company: '',
      companyCuil: '',
      dni: '',
      neighborhood: '',
      toJson: function (): Map<string, any> {
        throw new Error('Function not implemented.');
      }
    };

    this.conciliators = [noAssignedOption, ...result];
  }

  async updateClaimStatus(claim: UpdateClaimStatusEntity): Promise<void> {
    let result = await this.updateClaimStatusUseCase.execute(claim);
    if (result instanceof Failure) {
      this.messageService.add({
        severity: 'error',
        summary: 'Ocurrió un problema.',
        detail: 'Estado no actualizado.',
        life: 900,
      });
      if (this.isConciliatorRol) {
        this.getDataPaginated(
          this.defaultPage,
          this.itemsPerPage
        );
      }
      if (this.isAdministratorRol) {
        this.getDataPaginatedOfAdministrador(this.defaultPage, this.itemsPerPage);
      }
      return;
    }
    this.messageService.add({
      severity: 'success',
      summary: 'Estado actualizado',
      detail: 'El estado se actualizó exitosamente.',
    });
    if (this.isConciliatorRol) {
      this.getDataPaginated(
        this.defaultPage,
        this.itemsPerPage
      );
    }
    if (this.isAdministratorRol) {
      this.getDataPaginatedOfAdministrador(this.defaultPage, this.itemsPerPage);
    }
  }

  async updateClaimConciliator(
    claim: UpdateClaimConciliatorEntity
  ): Promise<void> {
    let result = await this.updateClaimConciliatorUseCase.execute(claim);
    if (result instanceof Failure) {
      this.messageService.add({
        severity: 'error',
        summary: 'Ocurrió un problema.',
        detail: 'Conciliador no actualizado.',
        life: 900,
      });
      this.getDataPaginated(
        this.defaultPage,
        this.itemsPerPage
      );
      return;
    }
    this.messageService.add({
      severity: 'success',
      summary: 'Conciliador actualizado',
      detail: 'El conciliador se actualizó exitosamente.',
    });
    setTimeout(() => {
      this.getDataPaginated(
        this.defaultPage,
        this.itemsPerPage
      );
    }, 1500);
  }

  dropdownClick(claim: ClaimData) {
    this.updateStatusOptionsByStatusClaim(claim.status);
    this.originalStatus = claim.status;
    this.selectedClaimId = claim.id;
  }

  updateConciliatorCheck(event: any) {
    const conciliatorName = event.value.id ? `${event.value.name} ${event.value.last_name}` : 'eliminar el conciliador actual';

    this.confirmationService.confirm({
      header: 'Actualizar conciliador',
      message: `¿Está seguro de que desea ${event.value.id ? 'actualizar el conciliador actual por' : ''} ${conciliatorName}?`,
      accept: () => {
        this.newConciliatorClaimUpdate = new UpdateClaimConciliatorModel(
          this.selectedClaimId!,
          event.value.id
        );
        this.updateClaimConciliator(this.newConciliatorClaimUpdate);
      },
      reject: () => {
        this.getDataPaginatedOfAdministrador(this.defaultPage, this.itemsPerPage);
      },
    });
  }

  isConciliatorChangeAllowed(status: string): boolean {
    const allowedStatuses = ['Pendiente', 'Admitido'];
    return allowedStatuses.includes(status);
  }

  countNewClaims(claims: ClaimData[]): void {
    let count: number = 0;
    claims.forEach((claim) => {
      if (claim.status == ClaimStatus.PENDING) {
        count += 1;
      }
    });
    this.newClaims = count;
  }

  changePage(event: any) {
    this.first = event.first;
    this.defaultPage = event.page + 1;
    this.amountRegistersPage = event.rows;
    this.itemsPerPage = event.rows;
    if (this.isComisionRol ||
      this.isComisionMemberRol || this.isAdministratorRol) {
      this.getDataPaginatedOfAdministrador(this.defaultPage, this.itemsPerPage, this.filters);
    }
    if (
      this.isConciliatorRol
    ) {
      this.getDataPaginated(
        this.defaultPage,
        this.itemsPerPage
      );
    }
  }

  getStatusClass(status: string): string {
    switch (status) {
      case ClaimStatus.DRAFT:
        return 'status-draft';
      case ClaimStatus.PENDING:
        return 'status-pending';
      case ClaimStatus.REFUSED:
        return 'status-refused';
      case ClaimStatus.FINALIZED:
        return 'status-finalized';
      case ClaimStatus.IN_INTERNAL_PROCESS:
        return 'status-in-internal-process';
      case ClaimStatus.AUDIENCE_SET:
      case 'Audiencia Fijada':
        return 'status-audience-set';
      case ClaimStatus.ADMITTED:
        return 'status-admited';
      default:
        return '';
    }
  }

  getStatusClassForPrincipalView(status: string): string {
    switch (status) {
      case ClaimStatus.DRAFT:
        return 'status-draft-principal';
      case ClaimStatus.PENDING:
        return 'status-pending-principal';
      case ClaimStatus.REFUSED:
        return 'status-refused-principal';
      case ClaimStatus.FINALIZED:
        return 'status-finalized-principal';
      case ClaimStatus.IN_INTERNAL_PROCESS:
        return 'status-in-internal-process-principal';
      case ClaimStatus.AUDIENCE_SET:
      case 'Audiencia Fijada':
        return 'status-audience-set-principal';
      case ClaimStatus.ADMITTED:
        return 'status-admited-principal';
      case ClaimStatus.ADMITTED:
        return 'status-admited';
      default:
        return '';
    }
  }

  sortByDate(claims: ClaimData[]): ClaimData[] {
    return claims.sort((a, b) => {
      const dateA = new Date(a.startDate).getTime();
      const dateB = new Date(b.startDate).getTime();
      return dateB - dateA;
    });
  }

  selectMenuItemsByRol(status: string) {
    if (
      this.rol === UserRol.ADMINISTRATOR ||
      this.rol === UserRol.CONCILIATOR
    ) {
      this.selectAmountComisionItems(status);
    } else {
      this.items = this.singleItem;
    }
  }

  selectAmountComisionItems(status: string) {
    switch (status) {
      case ClaimStatus.AUDIENCE_SET:
      case ClaimStatus.IN_INTERNAL_PROCESS:
      case 'Audiencia fijada':
      case 'En tramite interno':
        this.items = this.threeOptionsItems;
        break;
      case ClaimStatus.ADMITTED:
        this.items = this.twoOptionsItems;
        break;
      case ClaimStatus.PENDING:
      case ClaimStatus.FINALIZED:
      case ClaimStatus.REFUSED:
        this.items = this.singleItem;
        break;
      default:
        this.items = this.singleItem;
        break;
    }
  }

  updateStatusOptionsByStatusClaim(status: string) {
    switch (status) {
      case ClaimStatus.PENDING:
        this.status = [ClaimStatus.REFUSED, ClaimStatus.ADMITTED];
        break;
      case ClaimStatus.IN_INTERNAL_PROCESS:
        this.status = [ClaimStatus.FINALIZED];
        break;
      case ClaimStatus.AUDIENCE_SET:
        this.status = [ClaimStatus.IN_INTERNAL_PROCESS];
        break;
      default:
        this.status = [];
        break;
    }
  }

  cancel(close: any) {
    this.audienciaDialogVisible = false;
  }

  createAudienceOk(close: any) {
    if (this.isAdministratorRol) {
      this.initClaimsOfAdministrador();
    }
    this.initClaims();
  }

  handleShowMessage(event: {
    severity: string;
    summary: string;
    detail: string;
  }) {
    this.messageService.add({
      severity: event.severity,
      summary: event.summary,
      detail: event.detail,
    });
  }

  refreshGetAll(){
    location.reload();
  }

  selectClaim(id: string) {
    this.selectedClaimId = id;
    this.getClaim();
  }

  deleteClaim() {}
  viewClaim() {
    if (this.selectedClaimId) {
      this.router.navigate(['/main/claims', this.selectedClaimId]);
    }
  }
  setNewAudience() {
    this.audienciaDialogVisible = true;
  }

  toogleFilter() {
    this.showFilter = !this.showFilter;
  }

  applyFilters(filters: ClaimFilterModel) {
    this.filters = filters;
    this.defaultPage = 0;
    this.getDataPaginatedOfAdministrador(this.defaultPage, this.defaultRows, this.filters);
  }
}
