import { UseCase } from "src/app/core/utils/usecase";
import { DeleteAudienceEntity } from "../entities/delete-audience.entity";
import { Failure } from "src/app/core/utils/failure";
import { AudienceRepositoryBase } from "../repositories/aucience-base.repository";
import { Injectable } from "@angular/core";

@Injectable()
export class DeleteAudienceUseCase implements UseCase<DeleteAudienceEntity, string | Failure> {
    constructor(private audienceRepository: AudienceRepositoryBase){}
    
    async execute(param: DeleteAudienceEntity): Promise<string | Failure> {
        return await this.audienceRepository.deleteAudience(param);
    }
}