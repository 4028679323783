<form [formGroup]="minuteForm" (ngSubmit)="createMinute()" class="w-full h-full">
    <div class="grid w-full">

        <p class="col-12 text-xs font-medium m-0 pt-0">Cargue el documento a continuación.</p>
        <div class="col-12">
            <div [ngClass]="
                      isDragging
                        ? 'isDragging dropzone-container h-5rem col-12 '
                        : 'dropzone-container h-5rem col-12  '
                    " (dragover)="onDragOver($event)" (drop)="onDropSuccess($event)">
                <input #inputFile type="file" multiple="true" (change)="onChange($event, 'pdf')" accept=".pdf"
                    maxFileSize="1000000" [disabled]="disableFileImageUpload" />

                <div class="w-full flex align-items-center justify-content-between col-12">
                    <img class="hidden md:block" src="../../../../../../../assets/img/main/file-update.svg" />
                    <span class="descriptionContent hidden md:block">Arrastre sus documentos aquí o</span>
                    <p-button pRipple (click)="inputFile.click()" #upload class="btnUploadFile mr-1"
                        [disabled]="disableFileImageUpload">Elegir archivo</p-button>

                </div>
            </div>
            <span class="descriptionContent">Archivos permitidos: .pdf</span>
        </div>

        <div class="col-12 md:col-4 lg:col-4 xl:col-6 conteiner-UploadRight">
            <div class="imageContainer" *ngFor="let image of minuteFile; let i = index">
                <img src="../../../../../../../assets/img/main/icon-pdf.svg" alt="" class="icon-imagen" />
                <span class="descriptionContent">{{ image.name }}</span>
                <p-button class="closeButton" icon="pi pi-times" [rounded]="true" [text]="true" severity="danger"
                    (onClick)="deleteFileImage(i)"></p-button>
            </div>
        </div>
    </div>

    <div class="grid w-full flex justify-content-end ml-1 mt-3">
        <div class="col-3">
            <button type="button" pButton label="Cancelar" class="btn-cancelar" (click)="cancel()"
                style="background-color: rgb(238, 233, 233); color: #263245; border: 1px ridge #263245;"></button>
        </div>
        <div class="col-3">
            <button type="submit" pButton label="Subir acta" class="btn-siguiente"
                [disabled]="minuteForm.invalid"></button>
        </div>
    </div>

</form>
