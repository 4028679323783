import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appNumberOnlyNoLength]',
})
export class NumberOnlyNoLengthDirective {
  @Input() appNumberOnlyNoLength!: number;
  @HostListener('keypress', ['$event'])
  onKeyPress(event: KeyboardEvent): void {
    const input = event.key;
    const isNumber = /^\d$/.test(input);

    if (!isNumber) {
      event.preventDefault();
    }
  }
}