import { UseCase } from "src/app/core/utils/usecase";
import { Failure } from "src/app/core/utils/failure";
import { ClaimRepositoryBase } from "../repositories/claim-base.repository";
import { Injectable } from "@angular/core";
import { ClaimEntity } from "../entities/claim.entity";

@Injectable()
export class ClaimUseCase implements UseCase<string, ClaimEntity | Failure> {

    constructor(private claimRepository: ClaimRepositoryBase) {}

    async execute(param: string): Promise<ClaimEntity | Failure> {
        return await this.claimRepository.getClaim(param);
    }
}