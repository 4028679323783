import { DeleteCompanyEntity } from "../../domain/entities/delete-company.entity";

export class DeleteCompanyModel extends DeleteCompanyEntity {
    override toJson(): Map<string, any> {
        let json: Map<string, any> = new Map();
        json.set('id', this.id);
        return json;
    }

}
