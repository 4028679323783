import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Toast } from 'primeng/toast';
import { ProvinceEntity } from 'src/app/core/services/location/domain/entities/province.entity';
import { GetLocationsUseCase } from 'src/app/core/services/location/domain/usecases/get-locations.usecase';
import { GetProvinceUseCase } from 'src/app/core/services/location/domain/usecases/get-province.usecase';
import { ClaimEntity } from 'src/app/features/claims/domain/entities/claim.entity';
import typeOfRemuneration from '../../../../../../../../core/jsons/typeOfRemuneration.json';
import { LocationEntity } from 'src/app/core/services/location/domain/entities/location.entity';
import { dateControl } from 'src/app/core/utils/date-validator';

interface Localities {
  id: number;
  name: string;
}

interface TypeRemeneration {
  id: number;
  type: string;
}

@Component({
  selector: 'app-claimed-details-employer',
  templateUrl: './claimed-details-employer.component.html',
  styleUrls: ['./claimed-details-employer.component.css'],
  providers: [MessageService, Toast, ConfirmationService],
})
export class ClaimedDetailsEmployerComponent {
  @Output() claimed: EventEmitter<FormGroup> = new EventEmitter();
  @Output() stepIndex: EventEmitter<number> = new EventEmitter();
  @Output() activeModal: EventEmitter<boolean> = new EventEmitter();

  @Input() claim?: ClaimEntity;

  provinces: ProvinceEntity[] = [];
  localities: Localities[] = [];
  showConditionalReason: boolean = false;
  showConditionalCause: boolean = false;

  typeRemunerations: TypeRemeneration[] = [...typeOfRemuneration];
  maxDateIngreso: Date | undefined;
  maxDate: Date | undefined;
  minDate!: Date;
  minDateIngress!: Date;
  minDateForEgressDate: Date | null = null;
  isEditMode: boolean = false;

  typeOfJob: any[] = [
    { name: 'Registrado', key: 'register' },
    { name: 'Parcialmente registrado', key: 'partRegister' },
  ];

  currentEmploymentsStatus: any[] = [
    { id: 1, name: 'Vigente' },
    { id: 2, name: 'Disuelta' },
  ];

  reasonsForDisengagement: any[] = [
    { id: 1, name: 'Renuncia' },
    { id: 2, name: 'Despido indirecto' },
    { id: 3, name: 'Mutuo acuerdo' },
    { id: 4, name: 'Despido' },
  ];

  causesDisengagement: any[] = [
    { id: 1, name: 'Invocación de causa' },
    { id: 2, name: 'Sin causa' },
    { id: 3, name: 'Articulo 247' },
  ];

  constructor(
    private formBuilder: FormBuilder,
    private getProvince: GetProvinceUseCase,
    private getLocations: GetLocationsUseCase
  ) {
    this.maxDateIngreso = new Date();
    this.maxDateIngreso.setMonth(this.maxDateIngreso.getMonth() - 1);
  }

  async ngOnInit(): Promise<void> {
    await this.initializeComponent();
    this.formClaimedDetails
      .get('claimetCurrentEmploymentStatus')
      ?.valueChanges.subscribe((status) => {
        const date = this.formClaimedDetails.get('claimetEgressDate');

        if (status === 'Disuelta') {
          this.toggleDateValidator(status === 'Disuelta');
          date?.setValidators([Validators.required]);
        } else {
          date?.clearValidators();
        }

        date?.updateValueAndValidity();
      });
    this.minDateIngress = new Date();
    this.minDateIngress.setMonth(this.minDateIngress.getMonth() - 1);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['claim'] && changes['claim'].currentValue) {
      this.isEditMode = true;
      this.initializeFormWithClaimData();
      this.loadLocalities(
        this.formClaimedDetails.get('claimedProvince')?.value
      );
    }
    this.formClaimedDetails
      .get('claimetDateAdmission')
      ?.valueChanges.subscribe((date) => {
        if (date) {
          this.minDateForEgressDate = new Date(date);
        } else {
          this.minDateForEgressDate = null;
        }
      });
  }

  formClaimedDetails: FormGroup = this.formBuilder.group({
    claimedNameLastNameOrBusinessName: [
      '',
      [
        Validators.required,
        Validators.maxLength(50),
        Validators.pattern(/^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]*$/),
      ],
    ],
    claimedCuil: ['', Validators.required],
    claimedEmail: [
      '',
      [Validators.required, Validators.email, Validators.maxLength(100)],
    ],
    claimedPhone: [
      '',
      [
        Validators.required,
        Validators.minLength(7),
        Validators.maxLength(15),
        Validators.pattern(/^[0-9]+$/),
      ],
    ],
    claimedStreet: ['', [Validators.required, Validators.maxLength(50)]],
    claimedNumber: ['', [Validators.required, Validators.maxLength(5)]],
    claimedFloorApartment: ['', [Validators.maxLength(10)]],
    claimedTower: ['', [Validators.maxLength(30)]],
    claimedPostalCode: [
      '',
      [
        Validators.required,
        Validators.maxLength(10),
        Validators.minLength(4),
        Validators.pattern(/^[0-9]*$/),
      ],
    ],
    claimedProvince: ['', Validators.required],
    claimedCity: ['', Validators.required],
    claimetSelectedTypeOfJob: ['', [Validators.required]],
    claimetCurrentEmploymentStatus: ['', [Validators.required]],
    claimetReasonForDisengagement: [''],
    claimetCauseDisengagement: [''],
    claimetWorkday: [
      '',
      [
        Validators.required,
        Validators.maxLength(80),
        Validators.pattern(/^[a-zA-Z0-9\s-]*$/),
      ],
    ],
    claimetGrossRemuneration: [
      null,
      [Validators.required, Validators.maxLength(10)],
    ],
    claimetTypeRemuneration: ['', [Validators.required]],
    claimetDateAdmission: ['', Validators.required],
    claimetEgressDate: [],
  });

  async initializeComponent(): Promise<void> {
    await this.loadProvinces();

    this.formClaimedDetails.controls[
      'claimetCurrentEmploymentStatus'
    ].valueChanges.subscribe((selectedValue) => {
      this.showConditionalReason = selectedValue === 'Disuelta';
      this.showConditionalCause = false;
    });

    this.formClaimedDetails.controls[
      'claimetReasonForDisengagement'
    ].valueChanges.subscribe((selectedValue) => {
      this.showConditionalCause = selectedValue === 'Despido';
    });

    this.formClaimedDetails
      .get('claimedProvince')
      ?.valueChanges.subscribe((selectedProvince: any) => {
        this.loadLocalities(selectedProvince);
        this.formClaimedDetails.patchValue(
          { provincia: selectedProvince },
          { emitEvent: false }
        );
      });
  }

  private initializeFormWithClaimData(): void {
    if (!this.claim) return;
    if (this.isEditMode) {
      this.formClaimedDetails.patchValue({
        claimedNameLastNameOrBusinessName:
          this.claim.claimedNameLastNameOrBusinessName || '',
        claimedCuil: this.claim.claimedCuil || '',
        claimedEmail: this.claim.claimedEmail || '',
        claimedPhone: this.claim.claimedPhone || '',
        claimedStreet: this.claim.claimedStreet || '',
        claimedNumber: this.claim.claimedNumber || '',
        claimedFloorApartment: this.claim.claimedFloorApartment || '',
        claimedTower: this.claim.claimedTower || '',
        claimedPostalCode: this.claim.claimedPostalCode || '',
        claimedNeighborhood: this.claim.claimedNeighborhood || '',
        claimedProvince: this.claim.claimedProvince || '',
        claimedCity: this.claim.claimedCity || '',
        claimetSelectedTypeOfJob: this.claim.claimetSelectedTypeOfJob || '',
        claimetCurrentEmploymentStatus:
          this.claim.claimetCurrentEmploymentStatus || '',
        claimetReasonForDisengagement:
          this.claim.claimetReasonForDisengagement || '',
        claimetCauseDisengagement: this.claim.claimetCauseDisengagement || '',
        claimetWorkday: this.claim.claimetWorkday || '',
        claimetGrossRemuneration: this.claim.claimetGrossRemuneration || '',
        claimetTypeRemuneration: this.claim.claimetTypeRemuneration || '',
        claimetDateAdmission: this.claim.claimetDateAdmission || '',
        claimetEgressDate: this.claim.claimetEgressDate || '',
      });
      this.formClaimedDetails
        .get('claimetDateAdmission')
        ?.setValue(
          this.parseDate(
            this.formClaimedDetails.get('claimetDateAdmission')?.value
          )
        );
      this.formClaimedDetails
        .get('claimetEgressDate')
        ?.setValue(
          this.parseDate(
            this.formClaimedDetails.get('claimetEgressDate')?.value
          )
        );
    }
  }

  hasErrors(controlName: string, errorType: string) {
    return (
      this.formClaimedDetails.get(controlName)?.hasError(errorType) &&
      this.formClaimedDetails.get(controlName)?.touched
    );
  }

  nextStep() {
    if (this.formClaimedDetails.invalid) return;
    this.claimed.emit(this.formClaimedDetails.value);
    this.stepIndex.emit(2);
  }

  cancel() {
    this.claimed.emit(this.formClaimedDetails.value);
    this.activeModal.emit(true);
  }

  back() {
    this.stepIndex.emit(0);
  }

  async loadProvinces() {
    try {
      const result = await this.getProvince.execute();
      if (Array.isArray(result)) {
        this.provinces = result;
      }
    } catch (error) {
      console.error('Error fetching provinces', error);
    }
  }

  async loadLocalities(provinceId: string) {
    try {
      const localities = await this.getLocations.execute(provinceId);
      if (Array.isArray(localities)) {
        this.localities = this.putIdToLocalitiesList(localities);
      }
    } catch (error) {
      console.error('Error fetching localities', error);
    }
  }

  parseDate(date: string): Date {
    if (!date) {
      return new Date(1930, 0, 1);
    }
    let part = date.split(/\/| |:/);
    return new Date(
      parseInt(part[2]),
      parseInt(part[0]) - 1,
      parseInt(part[1]),
      parseInt(part[3]),
      parseInt(part[4]),
      parseInt(part[5])
    );
  }

  putIdToLocalitiesList(list: LocationEntity[]): Localities[] {
    let listWithId: Localities[] = [];
    for (let i = 0; i < list.length; i++) {
      let city: Localities = {
        id: i,
        name: list[i].name,
      };
      listWithId.push(city);
    }
    return listWithId;
  }

  private toggleDateValidator(enable: boolean): void {
    if (enable) {
      this.formClaimedDetails.setValidators(
        dateControl('claimetDateAdmission', 'claimetEgressDate')
      );
    } else {
      this.formClaimedDetails.clearValidators();
    }
    this.formClaimedDetails.updateValueAndValidity();
  }
}
