<div class="">
  <p-toast />
  <div>
    <p-dialog
      header="Subir acta"
      [(visible)]="minuteDialogVisible"
      [modal]="true"
      [closable]="false"
      [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
    >
      <app-minute
        (loadingChange)="handleLoadingChange($event)"
        (closePopup)="cancel($event)"
        [claim]="claim"
        [audienceId]="audienceId"
        (showMessage)="handleShowMessage($event)"
        (refresh)="refreshGetAll()"
      ></app-minute>
    </p-dialog>
  </div>
  <p-confirmDialog #cd>
    <ng-template pTemplate="headless" let-message>
      <div
        class="flex flex-column align-items-center justify-content-center p-5 surface-overlay border-round"
      >
        <span class="col-12 font-semibold text-xl">
          {{ message.header }}
        </span>
        <div
          class="col-12 flex align-items-center justify-content-center gap-3"
        >
          <i class="pi pi-exclamation-circle text-lg"></i>
          <p class="">{{ message.message }}</p>
        </div>
        <div class="col-12 flex gap-3 justify-content-center">
          <button
            pButton
            label="Cancelar"
            (click)="cd.reject()"
            class="btn-cancelar"
            style="
              border: 1px solid #263245;
              background-color: white;
              color: #263245;
              font-size: 0.8rem;
            "
          ></button>
          <button
            pButton
            label="Eliminar audiencia"
            (click)="cd.accept()"
            class="btn-siguiente"
            style="
              background-color: #e9ecf2;
              color: #263245;
              border-color: #000000;
              font-size: 0.8rem;
            "
          ></button>
        </div>
      </div>
    </ng-template>
  </p-confirmDialog>
</div>

<div class="col-12">
  <ng-container *ngIf="!allResults" class="empty-home-container">
    <div class="flex flex-column align-items-center">
      <img
        id="image"
        src="../../../../../../assets/img/main/file-searching.svg"
        alt=""
      />
      <p>Ud. no tiene fecha de audiencia fijada</p>
    </div>
  </ng-container>
</div>

<div *ngIf="allResults" class="grid w-full relative p-3">
  <p-table
    [tableStyle]="{ 'min-width': '100%' }"
    class="col-12"
    [rowHover]="true"
    *ngIf="skeleton"
    [value]="skeletons"
  >
    <ng-template pTemplate="header">
      <tr class="font-semibold text-xs">
        <th class="text-left">Fecha</th>
        <th class="text-left">Hora</th>
        <th *ngIf="!homeView" class="text-left">Título de audiencia</th>
        <th *ngIf="!homeView" class="text-left">Conciliador</th>
        <th class="text-center">N° de reclamo</th>
        <th *ngIf="!homeView" class="text-center">N° de expediente</th>
        <th class="text-left">Reclamante</th>
        <th class="text-left">Reclamado</th>
        <th></th>
        <th></th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-skeleton>
      <tr>
        <td class="text-center"><p-skeleton></p-skeleton></td>
        <td class="text-center"><p-skeleton></p-skeleton></td>
        <td class="text-center"><p-skeleton></p-skeleton></td>
        <td class="text-center"><p-skeleton></p-skeleton></td>
        <td class="text-center"><p-skeleton></p-skeleton></td>
        <td class="text-center"><p-skeleton></p-skeleton></td>
        <td class="text-center"><p-skeleton></p-skeleton></td>
        <td class="text-center" *ngIf="!homeView"><p-skeleton></p-skeleton></td>
        <td class="text-center" *ngIf="!homeView"><p-skeleton></p-skeleton></td>
        <td class="text-center" *ngIf="!homeView"><p-skeleton></p-skeleton></td>
      </tr>
    </ng-template>
  </p-table>

  <p-table
    *ngIf="!skeleton"
    #dtAudience
    (onLazyLoad)="loadRecords($event)"
    [tableStyle]="{ 'min-width': '100%' }"
    [value]="allAudiences"
    class="col-12"
    [rowHover]="true"
  >
    <ng-template pTemplate="caption" class="col-12" *ngIf="!homeView">
      <app-audience-filter
        class="modal-content"
        (filters)="onFiltersApplied($event)"
        (cancel)="onFilterCancel()"
      ></app-audience-filter>
    </ng-template>

    <ng-template pTemplate="header">
      <tr class="font-semibold text-xs">
        <th class="text-left">Fecha</th>
        <th class="text-left">Hora</th>
        <th *ngIf="!homeView" class="text-left">Título de audiencia</th>
        <th *ngIf="!homeView" class="text-left">Conciliador</th>
        <th class="text-left">N° de reclamo</th>
        <th *ngIf="!homeView" class="text-left">N° de expediente</th>
        <th class="text-left">Reclamante</th>
        <th class="text-left">Reclamado</th>
        <th></th>
        <th></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-audience>
      <tr class="table-row">
        <td class="text-left">
          {{ audience.date | date : "dd/MM/yyyy" }}
        </td>
        <td class="text-left">{{ audience.time }}hs.</td>
        <td class="text-left" *ngIf="!homeView">
          <span [pTooltip]="audience.audienceTitle" tooltipPosition="top">
            {{ audience.audienceTitle | truncate }}
          </span>
        </td>
        <td class="text-left" *ngIf="!homeView">
          {{ audience.conciliator }}
        </td>
        <td class="text-left">{{ audience.numberOfClaim }}</td>
        <td class="text-left" *ngIf="!homeView">
          {{ audience.proceedings }}
        </td>
        <td class="text-left">
          <span
            [pTooltip]="
              (audience.claimetName ?? '') +
              ' ' +
              (audience.claimetLastname ?? '')
            "
            tooltipPosition="top"
          >
            {{
              (audience.claimetName ?? "") +
                " " +
                (audience.claimetLastname ?? "") | truncate
            }}
          </span>
        </td>
        <td class="text-left">
          <span
            [pTooltip]="audience.claimedNameLastNameOrBusinessName"
            tooltipPosition="top"
          >
            {{ audience.claimedNameLastNameOrBusinessName | truncate }}
          </span>
        </td>
        <td class="text-right p-0">
          <img
            *ngIf="audience.acta_id"
            src="../../../../../../assets/img/main/icon-pdf.svg"
            class="icon-pdf"
          />
        </td>
        <td class="col-12 sm:col-6 md:col-1 text-center p-0">
          <p-menu #menu [popup]="true" [model]="items" appendTo="body"></p-menu>
          <button
            type="button"
            pButton
            icon="pi pi-ellipsis-v"
            (click)="
              selectAmountComisionItems();
              menu.toggle($event);
              selectAudience(audience.id, audience.claimId)
            "
            class="p-button-rounded p-button-text"
          ></button>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="10">
          <div class="flex flex-column align-items-center">
            <img
              src="../../../../../../assets/img/main/file-searching.svg"
              alt="imagen de archivo"
              style="width: 200px; height: 250px"
            />
            <p>No se encontraron resultados para la busqueda</p>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <p-paginator
    class="col-12"
    *ngIf="!homeView"
    (onPageChange)="changePage($event)"
    [first]="first"
    [rows]="defaultRows"
    [totalRecords]="totalQuantity"
    [rowsPerPageOptions]="[5, 10, 25, 50]"
  >
  </p-paginator>
</div>
