export interface User {
    rol: Rol;
    name: string;
    last_name: string;
    cuil: string
    gender: Gender;
    phone: string;
    birth_date: Date;
    civil_status: CivilStatus;
    province: string;
    city: string;
    street: string;
    number: number;
    floor_apartment: string;
    tower: string;
    postal_code: string;
    company: string;
    company_cuil: string;
    email: string;
    password: string;
    is_employee: boolean,
    is_employer: boolean,
    is_conciliator: boolean,
    is_superAdmin: boolean
}

export enum Rol {
  Trabajador, Empleador , 'Representante gremial'
}

export enum CivilStatus {
    Soltero, Casado, 'No seleccionado'
}

export enum Gender {
    Masculino, Femenino, Otro, 'No seleccionado'
}
