import { UseCase } from 'src/app/core/utils/usecase';
import { Failure } from 'src/app/core/utils/failure';
import { Injectable } from '@angular/core';
import { ClaimMultiRepositoryBase } from '../repositories/claim-multi-base.repository';
import { MultiPagination } from '../entities/multi-pagination.entity';
import { ClaimsPaginationParams } from '../entities/claim-pagination-params.entity';
import { PaginationParams } from 'src/app/core/interfaces/pagination-params.interface';

@Injectable()
export class GetMultiSingleClaimsUseCase
  implements UseCase<PaginationParams, MultiPagination | Failure>
{
  constructor(private claimRepository: ClaimMultiRepositoryBase) {}

  async execute(
    param: ClaimsPaginationParams
  ): Promise<MultiPagination | Failure> {
    return await this.claimRepository.getMultiClaims(param);
  }
}
