import { HttpHeaders, HttpParams } from "@angular/common/http";
import { DataConstraints } from "./data-constraints";
import { Entity } from "../utils/entity";

export abstract class HttpServiceBase {
  abstract get(url: string, headers?: HttpHeaders): Promise<Map<string, any>>;
  abstract getAll(url: string): Promise<Map<string, any>[]>;
  abstract post(url: string, data: DataConstraints, headers?: HttpHeaders): Promise<Map<string, any>>;
  abstract postWihtFormData(url: string, data: FormData, headers?: HttpHeaders): Promise<Map<string, any>>;
  abstract put(url: string, data: Entity, headers?: HttpHeaders): Promise<Map<string, any>>;
  abstract delete(url: string, data: Entity, headers?: HttpHeaders): Promise<Map<string, any>>;
}


