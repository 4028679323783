<form
  [formGroup]="registerFormPersonal"
  (ngSubmit)="nextStep()"
  class="w-8 sm:w-8 md:w-8 lg:w-7"
  id="container-form"
>
  <h2 class="mb-0">Crear cuenta</h2>
  <p>{{ selectedRol }}</p>

  <div class="grid mt-1">
    <div class="custom-input col-12 md:col-6 lg:col-6">
      <label for="last_name" class="label">Apellido*</label>
      <input
        pInputText
        id="last_name"
        aria-describedby="username-help"
        placeholder="Ingrese su apellido"
        formControlName="last_name"
        class="w-full"
        maxlength="50"
        [ngClass]="{
          'ng-invalid ng-dirty':
            hasErrors('last_name', 'required') ||
            hasErrors('last_name', 'maxlength')
        }"
      />
      <div
        class="text-danger p-error"
        *ngIf="hasErrors('last_name', 'required')"
      >
        Este campo es obligatorio
      </div>
      <div
        class="text-danger p-error"
        *ngIf="hasErrors('last_name', 'maxlength')"
      >
        Este campo no puede poseer más de 50 caracteres
      </div>
      <div
        class="text-danger p-error"
        *ngIf="hasErrors('last_name', 'pattern')"
      >
        El campo solo acepta letras. Los números no son permitidos.
      </div>
    </div>
    <div class="custom-input col-12 md:col-6 lg:col-6">
      <label for="name" class="label">Nombre*</label>
      <input
        pInputText
        id="name"
        aria-describedby="username-help"
        placeholder="Ingrese su nombre"
        formControlName="name"
        class="w-full"
        maxlength="50"
        [ngClass]="{
          'ng-invalid ng-dirty':
            hasErrors('name', 'required') || hasErrors('name', 'maxlength')
        }"
      />
      <div class="text-danger p-error" *ngIf="hasErrors('name', 'required')">
        Este campo es obligatorio
      </div>
      <div class="text-danger p-error" *ngIf="hasErrors('name', 'maxlength')">
        Este campo no puede poseer más de 50 caracteres
      </div>
      <div class="text-danger p-error" *ngIf="hasErrors('name', 'pattern')">
        El campo solo acepta letras. Los números no son permitidos.
      </div>
    </div>
  </div>

  <div class="grid mt-1">
    <div class="custom-input col-12 md:col-12 lg:col-12 flex flex-column">
      <label for="cuil" class="label">CUIL*</label>
      <p-inputMask
        class="inputLarge"
        mask="99-99999999-9"
        placeholder="99-99999999-9"
        formControlName="cuil"
        [ngClass]="{
          'ng-invalid ng-dirty': hasErrors('cuil', 'required')
        }"
      ></p-inputMask>
      <div class="text-danger p-error" *ngIf="hasErrors('cuil', 'required')">
        Este campo es obligatorio
      </div>
    </div>
  </div>

  <div class="grid mt-1">
    <div
      class="custom-input col-12 md:col-12 lg:col-12"
      *ngIf="selectedRol === 'Empleador'"
    >
      <label for="cargo_empresa" class="label"
        >Cargo que ocupa en la empresa*</label
      >
      <input
        pInputText
        id="cargo_empresa"
        aria-describedby="username-help"
        placeholder="Ingrese su cargo"
        formControlName="cargo_empresa"
        class="w-full"
        maxlength="50"
        [ngClass]="{
          'ng-invalid ng-dirty':
            hasErrors('cargo_empresa', 'required') ||
            hasErrors('cargo_empresa', 'maxlength')
        }"
      />
      <div
        class="text-danger p-error"
        *ngIf="hasErrors('cargo_empresa', 'required')"
      >
        Este campo es obligatorio
      </div>
      <div class="text-danger p-error" *ngIf="hasErrors('name', 'maxlength')">
        Este campo no puede poseer más de 50 caracteres
      </div>
      <div class="text-danger p-error" *ngIf="hasErrors('name', 'pattern')">
        El campo solo acepta letras. Los números no son permitidos.
      </div>
    </div>
  </div>

  <div class="grid mt-1">
    <div class="custom-input col-12 md:col-12 lg:col-12">
      <label for="birth_date" class="label">Fecha de nacimiento*</label>
      <p-calendar
        class="inputLarge"
        [iconDisplay]="'input'"
        [showIcon]="true"
        inputId="icondisplay"
        [maxDate]="maxDate"
        [minDate]="minDate"
        placeholder="Ingrese su fecha de nacimiento"
        dateFormat="dd/mm/yy"
        formControlName="birth_date"
      ></p-calendar>
      <div
        class="text-danger p-error"
        *ngIf="hasErrors('birth_date', 'required')"
      >
        Este campo es obligatorio
      </div>
      <div
        class="text-danger p-error"
        *ngIf="hasErrors('birth_date', 'underage')"
      >
        Debes tener al menos 18 años
      </div>
    </div>
  </div>

  <div class="grid mt-1">
    <div class="custom-input col-12 md:col-6 lg:col-6">
      <label for="gender " class="label">Sexo*</label>
      <p-dropdown
        id="dropdownSexo"
        [options]="genders"
        optionLabel="name"
        placeholder="Seleccione..."
        formControlName="gender"
      ></p-dropdown>
      <div class="text-danger p-error" *ngIf="hasErrors('gender', 'required')">
        Este campo es obligatorio
      </div>
    </div>
    <div class="custom-input col-12 md:col-6 lg:col-6">
      <label for="civil_status" class="label">Estado civil*</label>
      <p-dropdown
        id="dropdownEstadoCivil"
        [options]="civilStates"
        optionLabel="name"
        placeholder="Seleccione..."
        formControlName="civil_status"
      ></p-dropdown>
      <div
        class="text-danger p-error"
        *ngIf="hasErrors('civil_status', 'required')"
      >
        Este campo es obligatorio
      </div>
    </div>
  </div>

  <div class="grid mt-1">
    <div class="custom-input col-12 md:col-12 lg:col-12">
      <label for="phone" class="label">Número de teléfono*</label>
      <input
        pInputText
        id="phone"
        aria-describedby="username-help"
        placeholder="Ingrese telefono"
        formControlName="phone"
        maxlength="15"
        [ngClass]="{
          'ng-invalid ng-dirty':
            hasErrors('phone', 'required') ||
            hasErrors('phone', 'maxlength') ||
            hasErrors('phone', 'pattern')
        }"
      />
      <div class="text-danger p-error" *ngIf="hasErrors('phone', 'required')">
        Este campo es obligatorio
      </div>
      <div class="text-danger p-error" *ngIf="hasErrors('phone', 'minlength')">
        Este campo no puede poseer menos de 9 caracteres
      </div>
      <div class="text-danger p-error" *ngIf="hasErrors('phone', 'maxlength')">
        Este campo no puede poseer más de 15 caracteres
      </div>
      <div class="text-danger p-error" *ngIf="hasErrors('phone', 'pattern')">
        El campo solo acepta números. Las letras no son permitidas.
      </div>
    </div>
  </div>

  <p-button
    class="nextButton"
    label="Siguiente"
    [disabled]="registerFormPersonal.invalid"
    type="submit"
  ></p-button>
  <span class="backLink"><a (click)="previousStep()">Volver</a></span>
</form>
