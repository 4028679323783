import { Injectable } from "@angular/core";
import { ProvinceEntity } from "../../domain/entities/province.entity";
import { environment } from "src/environments/environment.dev";
import { HttpServiceBase } from "../../../http.service";
import { ProvinceModel } from "../models/province.model";
import { LocationEntity } from "../../domain/entities/location.entity";
import { LocationModel } from "../models/locations.model";

export abstract class ProvinceRemoteDataSourceBase {
    abstract getProvinces(): Promise<ProvinceEntity[]>;
    abstract getLocations(id: string): Promise<LocationEntity[]>;
}

@Injectable()
export class ProvinceRemoteDataSource extends ProvinceRemoteDataSourceBase{
    private url: string = `${environment.apiUrl}`;

    constructor(private httpService: HttpServiceBase){
        super()
    }


    private mapProvince(provinces: any){
        let provincesMapped:ProvinceEntity[] = [];

        for (let province of provinces) {
            let provinceMap = new Map<string,any>(Object.entries(province));
            provincesMapped.push(ProvinceModel.fromJson(provinceMap));
        }
        return provincesMapped        
    }

    override async getProvinces(): Promise<ProvinceEntity[]> {
        let result = await this.httpService.get(`${this.url}/Province/filters`);
        return this.mapProvince(result.get('response'));
    }

    private mapLocation(locations: any) {
        let locationsMapped: LocationEntity[] = [];
        
        for (let location of locations) {
            locationsMapped.push(LocationModel.fromJson(location));
        }
        return locationsMapped
    }

    override async getLocations(id: string): Promise<LocationEntity[]> {
        let result = await this.httpService.get(`${this.url}/Province/locations/filters?id=${id}`);
        let locations = result.get('response').locations        
        return this.mapLocation(locations);
    }
}