import { Failure } from 'src/app/core/utils/failure';
import { ClaimCollectiveEntity } from '../entities/claim-colective.entity';
import { ClaimsPaginationParams } from '../entities/claim-pagination-params.entity';
import { ColectivePagination } from '../entities/colective-pagination.entity';
import { UpdateClaimStatusEntity } from '../entities/update-claim-satus.entity';
import { UpdateClaimConciliatorEntity } from '../entities/update-claim-conciliator.entity';
import { GetFileEntity } from '../entities/get-file.entity';
import { DeleteClaimEntity } from '../entities/delete-claim.entity';

export abstract class ClaimColectiveRepositoryBase {
  abstract getClaim(id: string): Promise<ClaimCollectiveEntity | Failure>;
  abstract createClaim(claim: ClaimCollectiveEntity): Promise<string | Failure>;
  abstract updateClaim(claim: ClaimCollectiveEntity): Promise<string | Failure>;
  abstract getFile(params: GetFileEntity): Promise<string | Failure>;
  abstract getColectiveClaims(
    params: ClaimsPaginationParams
  ): Promise<ColectivePagination | Failure>;
  abstract updateClaimStatus(
    claim: UpdateClaimStatusEntity
  ): Promise<string | Failure>;
  abstract updateClaimConciliator(
    claim: UpdateClaimConciliatorEntity
  ): Promise<string | Failure>;
  abstract deleteClaim(
    deleteClaim: DeleteClaimEntity
  ): Promise<string | Failure>;
}
