import { PaginationParams } from 'src/app/core/interfaces/pagination-params.interface';
import { UseCase } from 'src/app/core/utils/usecase';
import { Failure } from 'src/app/core/utils/failure';
import { ClaimsPaginationParams } from '../entities/claim-pagination-params.entity';
import { Injectable } from '@angular/core';
import { ClaimColectiveRepositoryBase } from '../repositories/cliam-colective-base.repository';
import { ColectivePagination } from '../entities/colective-pagination.entity';

@Injectable()
export class GetColectiveClaimsUseCase
  implements UseCase<PaginationParams, ColectivePagination | Failure>
{
  constructor(private claimRepository: ClaimColectiveRepositoryBase) {}

  async execute(
    param: ClaimsPaginationParams
  ): Promise<ColectivePagination | Failure> {
    return await this.claimRepository.getColectiveClaims(param);
  }
}
