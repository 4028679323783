import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { GetProvinceUseCase } from 'src/app/core/services/location/domain/usecases/get-province.usecase';
import { UpdateUserAdminUseCase } from '../../../domain/usecases/update-user-admin.usecase';
import { ProvinceEntity } from 'src/app/core/services/location/domain/entities/province.entity';
import { GetLocationsUseCase } from 'src/app/core/services/location/domain/usecases/get-locations.usecase';
import { LocationEntity } from 'src/app/core/services/location/domain/entities/location.entity';
import { GetMyDataUseCase } from '../../../domain/usecases/get-my-data.usecase';
import { Failure } from 'src/app/core/utils/failure';
import { UserData } from '../../../domain/entities/user-data.entity';
import { ConfirmationService, MessageService } from 'primeng/api';
import { UserAdminModel } from '../../../data/models/user-admin.model';
import { Toast } from 'primeng/toast';
import { Router } from '@angular/router';

interface Localities {
  id: number;
  name: string;
}

@Component({
  selector: 'app-update-user-config',
  templateUrl: './update-user-config.component.html',
  styleUrls: ['./update-user-config.component.css'],
  providers: [MessageService, Toast, ConfirmationService],
})
export class UpdateUserConfigComponent implements OnInit {
  personalDataForm: FormGroup = this.formBuilder.group({
    last_name: ['', [Validators.required, Validators.maxLength(25)]],
    name: ['', [Validators.required, Validators.maxLength(20)]],
    cuil: ['', [Validators.required, Validators.pattern(/^\d{11}$/)]],
    birth_date: ['', Validators.required],
    gender: ['', Validators.required],
    civil_status: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    phone: [
      '',
      [
        Validators.required,
        Validators.minLength(9),
        Validators.maxLength(15),
        Validators.pattern(/^[0-9]*$/),
      ],
    ],
    number: ['', [Validators.required, Validators.maxLength(5)]],
    floor_apartment: ['', [Validators.maxLength(10)]],
    street: ['', Validators.required],
    province: ['', Validators.required],
    city: ['', Validators.required],
    postal_code: [
      '',
      [
        Validators.required,
        Validators.maxLength(4),
        Validators.minLength(4),
        Validators.pattern(/^\d{4}$/),
      ],
    ],
    tower: [''],
    selectedUserId: [''],
    dni: [''],
    rol: [''],
  });

  userData?: UserData;

  sexOptions = ['Masculino', 'Femenino', 'Otro'];
  civilStatusOptions = [
    { label: 'Soltero/a', value: 'Soltero/a' },
    { label: 'Casado/a', value: 'Casado/a' },
    { label: 'Divorciado/a', value: 'Divorciado/a' },
    { label: 'Viudo/a', value: 'Viudo/a' },
  ];

  provinces: ProvinceEntity[] = [];
  localities: Localities[] = [];
  rolUser: string = '';
  isFormModified: boolean = false;
  initialFormValues: any;
  isLoading = false;

  constructor(
    private formBuilder: FormBuilder,
    private getProvince: GetProvinceUseCase,
    private updateUser: UpdateUserAdminUseCase,
    private getLocations: GetLocationsUseCase,
    private location: Location,
    private myData: GetMyDataUseCase,
    private messageService: MessageService,
    private router: Router
  ) {}

  async ngOnInit() {
    await this.initializeComponent();
    this.personalDataForm.valueChanges.subscribe(() => {
      if (!this.initialFormValues) {
        this.initialFormValues = this.personalDataForm.value;
      }
      this.checkFormModification();
    });
  }

  async initializeComponent(): Promise<void> {
    await this.loadProvinces();
    await this.getMyData();

    this.personalDataForm
      .get('province')
      ?.valueChanges.subscribe((selectedProvince: any) => {
        this.loadLocalities(selectedProvince);
        this.personalDataForm.patchValue(
          { provincia: selectedProvince },
          { emitEvent: false }
        );
      });
    await this.loadUserData();
  }

  logout(): void {
    this.messageService.add({
      severity: 'warn',
      summary: 'Cerrando sesión',
      detail: 'Tu sesión ha sido cerrada debido al cambio de email.',
    });
    sessionStorage.clear();
    localStorage.clear();
    setTimeout(() => {
      this.router.navigate(['/auth/login']);
    }, 2500);
  }

  checkFormModification() {
    const currentValues = this.personalDataForm.value;
    this.isFormModified = Object.keys(currentValues).some((key) => {
      if (this.personalDataForm.get(key)?.disabled) {
        return false;
      }
      return (
        JSON.stringify(currentValues[key]) !==
        JSON.stringify(this.initialFormValues[key])
      );
    });
  }

  async getMyData(): Promise<void> {
    let result = await this.myData.execute();
    if (result instanceof Failure) {
      return;
    }
    this.userData = result;
    this.checkRol();
  }

  checkRol() {
    this.rolUser = this.userData!.rol;
    if (
      this.rolUser === 'Administrador' ||
      this.rolUser === 'Conciliador' ||
      this.rolUser === 'Miembro de la comisión'
    ) {
      this.personalDataForm.get('birth_date')?.disable();
      this.personalDataForm.get('gender')?.disable();
      this.personalDataForm.get('civil_status')?.disable();
    }
  }

  putIdToLocalitiesList(list: LocationEntity[]): Localities[] {
    let listWithId: Localities[] = [];
    for (let i = 0; i < list.length; i++) {
      let city: Localities = {
        id: i,
        name: list[i].name,
      };
      listWithId.push(city);
    }
    return listWithId;
  }

  async loadLocalities(provinceId: string) {
    try {
      const localities = await this.getLocations.execute(provinceId);
      if (Array.isArray(localities)) {
        this.localities = this.putIdToLocalitiesList(localities);
      }
    } catch (error) {
      console.error('Error fetching localities', error);
    }
  }

  async loadProvinces() {
    try {
      const result = await this.getProvince.execute();

      if (Array.isArray(result)) {
        this.provinces = result;
        if (this.userData?.province) {
          await this.loadLocalities(this.userData.province);
        }
      }
    } catch (error) {
      console.error('Error fetching provinces', error);
    }
  }

  async loadUserData(): Promise<void> {
    if (this.userData) {
      const sanitizeValue = (value: string | null | undefined): string | null =>
        value ? value.replace(/-/g, '') : null;

      const getDniFromCuil = (cuil: string | null): string | null => {
        if (cuil && cuil.length === 11) {
          return cuil.substring(2, cuil.length - 1);
        }
        return null;
      };

      const parsedBirthDate = this.userData.birthDate
        ? new Date(this.userData.birthDate)
        : null;

      await this.loadLocalities(this.userData.province);

      this.personalDataForm.patchValue({
        last_name: this.userData.lastName,
        name: this.userData.name,
        cuil: sanitizeValue(this.userData.cuil),
        dni: getDniFromCuil(sanitizeValue(this.userData.cuil)),
        birth_date: parsedBirthDate,
        gender: this.userData.gender,
        civil_status: this.userData.civilStatus,
        email: this.userData.email,
        phone: this.userData.phone,
        number: this.userData.number,
        floor_apartment: this.userData.floorApartment,
        street: this.userData.street,
        province: this.userData.province,
        city: this.userData.city,
        postal_code: this.userData.postalCode,
        tower: '',
        selectedUserId: this.userData.id,
        rol: this.userData.rol,
      });

      this.initialFormValues = this.personalDataForm.value;
    }
  }
  cancel() {
    this.location.back();
  }

  hasErrors(controlName: string, errorType: string): boolean | undefined {
    const control = this.personalDataForm.get(controlName);
    return control?.hasError(errorType) && (control.dirty || control.touched);
  }

  async updateUserData(): Promise<void> {
    const currentEmail = this.personalDataForm.get('email')?.value;
    const originalEmail = this.userData?.email;
    const emailChanged = currentEmail !== originalEmail;
    this.isLoading = true;

    try {
      const response = await this.updateUser.execute(this.createUserModel());

      if (response instanceof Failure) {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: response.message,
        });
        return;
      }

      this.messageService.add({
        severity: 'success',
        summary: 'Usuario actualizado',
        detail: 'El usuario se actualizó exitosamente.',
      });

      if (emailChanged) {
        setTimeout(() => this.logout(), 3000);
      } else {
        setTimeout(() => this.location.back(), 1500);
      }
    } catch (error) {
      console.error('Error al actualizar usuario:', error);
      this.messageService.add({
        severity: 'error',
        summary: 'Error inesperado',
        detail: 'Ocurrió un error inesperado al actualizar el usuario.',
      });
    } finally {
      this.isLoading = false;
    }
  }

  createUserModel(): UserAdminModel {
    const rawFormData = this.personalDataForm.getRawValue();

    const id = rawFormData.selectedUserId;
    const rol = rawFormData.rol;
    const name = rawFormData.name;
    const last_name = rawFormData.last_name;
    const cuil = rawFormData.cuil?.replace(/-/g, '');
    const phone = rawFormData.phone?.replace(/-/g, '');
    const dni = rawFormData.dni;
    const province = rawFormData.province;
    const city = rawFormData.city;
    const street = rawFormData.street;
    const number = rawFormData.number.toString();
    const floor_apartment = rawFormData.floor_apartment;
    const postal_code = rawFormData.postal_code.toString();
    const email = rawFormData.email;
    const tower = rawFormData.tower;

    if (
      this.rolUser === 'Administrador' ||
      this.rolUser === 'Conciliador' ||
      this.rolUser === 'Miembro de la comisión'
    ) {
      const isComision = this.rolUser === 'Miembro de la comisión';
      const isConciliator = this.rolUser === 'Conciliador';
      const isAdministrator = this.rolUser === 'Administrador';

      return new UserAdminModel(
        rol,
        name,
        last_name,
        cuil,
        phone,
        dni,
        province,
        city,
        street,
        number,
        floor_apartment,
        postal_code,
        email,
        tower,
        id,
        undefined,
        undefined,
        undefined,
        isConciliator,
        isComision,
        isAdministrator
      );
    } else {
      return new UserAdminModel(
        rol,
        name,
        last_name,
        cuil,
        phone,
        dni,
        province,
        city,
        street,
        number,
        floor_apartment,
        postal_code,
        email,
        tower,
        rawFormData.selectedUserId,
        rawFormData.gender,
        rawFormData.birth_date
          ? new Date(rawFormData.birth_date).toISOString()
          : '',
        rawFormData.civil_status
      );
    }
  }
}
