import { ClaimMultiEntity } from '../../domain/entities/claim-multi.entity';
import { EmployeesModel } from './claim-employer.model';

export class ClaimMultiModel extends ClaimMultiEntity {
  override toJson(): Map<string, any> {
    let json: Map<string, any> = new Map();
    json.set('id', this.id);
    json.set('user_id', this.userId);
    json.set('rol', this.rol);
    json.set('razon_social', this.razonSocial);
    json.set('company_cuit', this.companyCuit);
    json.set('company_email', this.companyEmail);
    json.set('company_phone', this.companyPhone);
    json.set('street_company', this.streetCompany);
    json.set('number_company', this.numberCompany);
    json.set('floor_apartment_company', this.floorApartmentCompany);
    json.set('tower_company', this.towerCompany);
    json.set('postal_code_company', this.postalCodeCompany);
    json.set('province_company', this.provinceCompany);
    json.set('city_company', this.cityCompany);
    json.set('claimant_type_performance', this.claimantTypePerformance);
    json.set('department_id', this.departmentId);
    
    // Representación de la empresa
    json.set('last_name', this.lastName);
    json.set('name', this.name);
    json.set('cuil', this.cuil);
    json.set('claimant_position', this.claimantPosition);
    json.set('phone', this.phone);
    json.set('email', this.email);
    json.set('birthday', this.birthday);
    // Archivos
    json.set('file', this.file);

    // Segundo step: Datos de reclamado
    json.set('employees', Array.isArray(this.employees) 
    ? this.employees.map(emp => {
        if (!(emp instanceof EmployeesModel)) {
          // Convierte EmployeesDto a EmployeesModel
          const model = new EmployeesModel(
            emp.nameClaimed,
            emp.claimedCuil,
            emp.claimedEmail,
            emp.claimedStreet,
            emp.claimedNumber,
            emp.claimedFloorApartment,
            emp.claimedTower,
            emp.claimedPostalCode,
            emp.claimedProvince,
            emp.claimedCity,
            emp.claimedDni
          );
          return model.toJson();
        }
        return emp.toJson();
      }) 
    : []);
    // Tercer step: Detalle de reclamo
    json.set('object_of_claim', this.selecterObjectOfClaim);
    json.set('text_claim_details', this.textClaimDetails);
    json.set('extra_documentation', this.extraDocumentationPdf);
    json.set('selected_own_right', this.selectedOwnRight);
    json.set('full_name_lawyer', this.lawyerFullName);
    json.set('registration_number', this.lawyerRegistrationNumber);
    json.set('lawyer_email', this.lawyerEmail);
    json.set('consent', this.consent);

    // Datos de la entidad del reclamo
    json.set('confirm_information', this.confirmInformation);
    json.set('start_date', this.startDate);
    json.set('number_of_claim', this.numberOfClaim);
    json.set('is_borrador', this.isBorrador);

    return json;
  }

  static fromJson(data: Map<string, any>): ClaimMultiEntity {
    return new ClaimMultiModel(
      data.get('id'),
      data.get('user_id'),
      data.get('rol'),
      data.get('razon_social'),
      data.get('company_cuit'),
      data.get('company_email'),
      data.get('company_phone'),
      data.get('street_company'),
      data.get('number_company'),
      data.get('floor_apartment_company'),
      data.get('tower_company'),
      data.get('postal_code_company'),
      data.get('province_company'),
      data.get('city_company'),
      data.get('claimant_type_performance'),
      data.get('department_id'),

      // Representación de la empresa
      data.get('last_name'),
      data.get('name'),
      data.get('cuil'),
      data.get('claimant_position'),
      data.get('phone'),
      data.get('email'),
      data.get('birthday'),

      // Archivos
      data.get('file') || [],

      // Segundo step: Datos de reclamado
      (data.get('employees') || []).map((emp: Map<string, any>) => EmployeesModel.fromJson(emp)),

      // Tercer step: Detalle de reclamo
      data.get('object_of_claim') || [],
      data.get('text_claim_details'),
      data.get('extra_documentation') || [],
      data.get('selected_own_right'),
      data.get('full_name_lawyer'),
      data.get('registration_number'),
      data.get('lawyer_email'),
      data.get('consent'),

      // Datos de la entidad del reclamo
      data.get('confirm_information'),
      data.get('start_date'),
      data.get('number_of_claim'),
      data.get('is_borrador')
    );
  }
}
