import { Session } from "src/app/features/auth/domain/entities/session.entity";



export abstract class LocalStorageServiceBase {
    constructor() {}

    abstract read(key: string): string | null | undefined ;
    abstract save(session: Session): void;
    abstract delete(): void; 
    abstract isAuthenticated(): boolean;
    abstract isTokenExpired(): boolean;
    abstract loadStorageData(itemName: string): string; 
    abstract saveStorageData(itemName: string, data: any, session?: boolean): void;
}