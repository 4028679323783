import { Injectable } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Injectable({
    providedIn: 'root'
})
export class FormInitialaizer {

    constructor(private form: FormBuilder){}

    initEmployeeForm(): FormGroup{
        return this.form.group({
            company: [ , [Validators.required, Validators.maxLength(100)]],
            company_cuil: [ , [Validators.required]],
          })
    }

    initCompanyForm(): FormGroup{
        return this.form.group({
            razon_social: [ , [Validators.required, Validators.maxLength(100)]],
            cuit: [ , [Validators.required]],
            phone: [ ,[Validators.minLength(9), Validators.maxLength(15), Validators.pattern(/^[0-9]*$/)]]
        })
    }
}