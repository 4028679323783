import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

enum DoYouActCompany {
  actúoEnNombrePropio,
  soyRepresentanteDeLaEmpresa,
}

enum DoYouActUnionRepresentative {
  delegadoDeLaEmpresa,
  miembroDeComisionDirectiva,
}

interface interfaceDoYouAct {
  name: string;
  act: DoYouActCompany | DoYouActUnionRepresentative;
}

@Component({
  selector: 'app-typeResponsibility',
  templateUrl: './typeResponsibility.component.html',
  styleUrls: ['./typeResponsibility.component.css'],
})
export class TypeResponsibilityComponent implements OnInit {
  @Output() act: EventEmitter<FormGroup> = new EventEmitter();
  @Output() back: EventEmitter<number> = new EventEmitter();
  @Input() selectedRol?: string;
  @Input() initialData: any = {};

  formTypeResponsibility: FormGroup;

  companyTypeResponsibility: interfaceDoYouAct[] = [
    {
      name: 'Actúo en nombre propio',
      act: DoYouActCompany.actúoEnNombrePropio,
    },
    {
      name: 'Soy representante de la Empresa',
      act: DoYouActCompany.soyRepresentanteDeLaEmpresa,
    },
  ];

  unionRepresentativeTypeResponsibility: interfaceDoYouAct[] = [
    {
      name: 'Delegado de la empresa',
      act: DoYouActUnionRepresentative.delegadoDeLaEmpresa,
    },
    {
      name: 'Miembro de comision directiva',
      act: DoYouActUnionRepresentative.miembroDeComisionDirectiva,
    },
  ];

  constructor(private form: FormBuilder) {
    this.formTypeResponsibility = this.form.group({});
  }
  
  ngOnInit() {
    if (this.selectedRol === "Empleador") {
      this.formTypeResponsibility = this.form.group({
        tipo_actuacion: ['', [Validators.required]],
      });
    } else {
      this.formTypeResponsibility = this.form.group({
        type_responsibility: ['', [Validators.required]],
      });
    }
  
    if (this.initialData.type_responsibility) {
      this.formTypeResponsibility
        .get('type_responsibility')
        ?.setValue(this.initialData.type_responsibility);
    }
  }
  

  nextStep() {
    if (this.formTypeResponsibility.invalid) return;
    this.act.emit(this.formTypeResponsibility.value);
  }

  previousStep() {
    this.back.emit(1);
  }
}
