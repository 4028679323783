import { Component, OnInit } from '@angular/core';
import { ClaimEntity } from '../../../domain/entities/claim.entity';
import { ActivatedRoute } from '@angular/router';
import { ClaimUseCase } from '../../../domain/usecases/get-claim.usecase';
import { Failure } from 'src/app/core/utils/failure';
import { ClaimCollectiveEntity } from '../../../domain/entities/claim-colective.entity';
import { ClaimColectiveUseCase } from '../../../domain/usecases/get-claim-colective-usecase';

@Component({
  selector: 'app-claim-view',
  templateUrl: './claim-view.component.html',
  styleUrls: ['./claim-view.component.css'],
})
export class ClaimViewComponent implements OnInit {
  claimId?: string | null;
  claim?: ClaimEntity | ClaimCollectiveEntity;

  constructor(
    private route: ActivatedRoute,
    private claimUseCase: ClaimUseCase,
    private claimCollectiveUseCase: ClaimColectiveUseCase
  ) {}

  ngOnInit(): void {
    this.claimId = this.route.snapshot.paramMap.get('id');
    this.getClaim();
  }

  async getClaim(): Promise<void> {
    if (!this.claimId) return;
    const useCases = [this.claimUseCase, this.claimCollectiveUseCase];
    for (const useCase of useCases) {
      const result = await useCase.execute(this.claimId);
      if (!(result instanceof Failure)) {
        this.claim = result;
        return;
      }
    }
  }

  get claimRol(): string | null {
    return (this.claim as ClaimEntity)?.Rol ?? null;
  }

  get claimType(): string | null {
    return (this.claim as ClaimCollectiveEntity)?.claimType ?? null;
  }

  isCollectiveClaim(): boolean {
    return (
      this.claim instanceof ClaimCollectiveEntity ||
      this.claimType === 'Colectivo'
    );
  }
}
