import { Component } from '@angular/core';

@Component({
  selector: 'app-main-base',
  templateUrl: './main-base.component.html',
  styleUrls: ['./main-base.component.css']
})
export class MainBaseComponent  {
  constructor() {
  }

  ngOnInit() {
  }

}
