<div *ngIf="isLoading" class="spinner-container">
    <p-progressSpinner></p-progressSpinner>
</div>
<p-toast [showTransformOptions]="'translateY(100%)'" [showTransitionOptions]="'1000ms'"
    [hideTransitionOptions]="'1000ms'" [showTransformOptions]="'translateX(100%)'" />
<form [formGroup]="resetPasswordForm" class="mt-2" (ngSubmit)="changePassword()">

    <span class="titleSection mt-2 mb-2">Cambiar contraseña</span>

    <div class="grid my-1">
        <div class="custom-input col-12 md:col flex flex-column">
            <label for="password" id="label-span" class="labelTitle mb-1">Contraseña actual <span class="label"> *</span></label>
            <span class="p-input-icon-right">
                <i style="color: #667085" [ngClass]="visibility.password ? 'pi pi-eye' : 'pi pi-eye-slash'"
                    (click)="togglePasswordVisibility('password')"></i>
                <input pInputText id="password" type="{{ visibility.password ? 'text' : 'password' }}" maxlength="32"
                    placeholder="Ingrese su contraseña actual" autocomplete="off" formControlName="password" />
            </span>
        </div>
        <div class="custom-input col-12 md:col flex flex-column">
            <label for="username" id="laber-span" class="labelTitle mb-1">Nueva contraseña <span class="label"> *</span></label>
            <span class="p-input-icon-right">
                <i style="color: #667085" [ngClass]="visibility.newPassword ? 'pi pi-eye' : 'pi pi-eye-slash'"
                    (click)="togglePasswordVisibility('newPassword')"></i>
                <input pInputText id="newPassword" type="{{ visibility.newPassword ? 'text' : 'password' }}" maxlength="32"
                    placeholder="Ingrese la nueva contraseña" autox complete="off" formControlName="newPassword" />
            </span>
            <div class="text-danger p-error text-sm" *ngIf="hasFormError('sameAsOldPassword')">
                La nueva contraseña no puede ser igual a la contraseña actual.
            </div>
        </div>
        <div class="custom-input col-12 md:col flex flex-column">
            <label for="repeatedPassword" id="laber-span" class="labelTitle mb-1">Repetir nueva contraseña <span class="label"> *</span></label>
            <span class="p-input-icon-right">
                <i style="color: #667085" [ngClass]="visibility.confirmPassword ? 'pi pi-eye' : 'pi pi-eye-slash'"
                    (click)="togglePasswordVisibility('confirmPassword')"></i>
                <input pInputText id="repeatedPassword" type="{{ visibility.confirmPassword ? 'text' : 'password' }}" maxlength="32"
                    placeholder="Repita la nueva contraseña" autocomplete="off" formControlName="repeatedPassword"
                    [ngClass]="{ 'input-error': hasFormError('passwordMismatch') }"/>
            </span>
            <div class="text-danger p-error text-sm" *ngIf="hasFormError('passwordMismatch')">
                Las contraseñas no coinciden.
            </div>
        </div>

        <div class="col-12 flex flex-column">
            <span class="mt-2 passValidators" id="uppercase"><i class="pi pi-check-circle"></i> Al menos un carácter en
                mayúscula</span>
            <span class="mt-2 passValidators" id="lowercase"><i class="pi pi-check-circle"></i> Al menos un carácter en
                minúscula</span>
            <span class="mt-2 passValidators" id="minlength"><i class="pi pi-check-circle"></i> Mínimo 8
                caracteres</span>
            <span class="mt-2 passValidators" id="number"><i class="pi pi-check-circle"></i> Mínimo un número</span>
            <span class="mt-2 passValidators" id="specialChar"><i class="pi pi-check-circle"></i> Mínimo un carácter
                especial</span>
        </div>
              <!-- Botones -->
      <div class="grid w-full flex justify-content-end mt-7">
        <div class="col-6 md:col-2 lg:col-2 xl:col-2">
          <p-button
            (click)="cancel()"
            label="Cancelar"
            class="btn-cancelar sm:text-xs"
          />
        </div>


        <div class="col-6 md:col-2 lg:col-2 xl:col-2 pr-0">
          <p-button
            label="Guardar Cambios"
            class="btn-siguiente"
            type="submit"
            [disabled]="resetPasswordForm.invalid"
          ></p-button>
        </div>
      </div>


    </div>
</form>
