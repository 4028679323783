import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function OfAgeValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) return null;

    const today = new Date();
    const birthDate = new Date(control.value);
    const age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    const dayDiff = today.getDate() - birthDate.getDate();

    if (
      age > 18 ||
      (age === 18 && monthDiff > 0) ||
      (age === 18 && monthDiff === 0 && dayDiff >= 0)
    ) {
      return null;
    } else {
      return { underage: true };
    }
  };
}
