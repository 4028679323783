import { PaginationParams } from "src/app/core/interfaces/pagination-params.interface";
import { UseCase } from "src/app/core/utils/usecase";
import { UserPagination } from "../entities/user-pagination.entity";
import { Failure } from "src/app/core/utils/failure";
import { UserRepositoryBase } from "../repositories/user-base.repository";
import { Injectable } from "@angular/core";
import { UserPaginationParams } from "../entities/user-pagination-params.entity";

@Injectable()
export class GetPaginatedUserUseCase implements UseCase<PaginationParams, UserPagination | Failure> {

  constructor(private userRepository : UserRepositoryBase) { }

  async execute(param: UserPaginationParams): Promise<UserPagination | Failure> {
    return await this.userRepository.getPaginationUser(param);
  }
}
