import { MultiCompanyFileEntity } from "../../domain/entities/multi-company-file.entity";

export class MultiCompanyFileModel extends MultiCompanyFileEntity {
    
    override toJson(): Map<string, any> {
        let map: Map<string, any> = new Map<string, any>();
        map.set("file", this.file);
       return map;
    }

}