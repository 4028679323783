import { ProvinceEntity } from "../../domain/entities/province.entity";

export class ProvinceModel extends ProvinceEntity{

    override toJson(): Map<string, any> {
        throw new Error("Method not implemented.");  
    }

    static fromJson(data: Map<string, any>): ProvinceEntity {        
        return new ProvinceModel(
         data.get("id"),
         data.get("name")
        )
    }
}