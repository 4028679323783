import { Failure } from "src/app/core/utils/failure";
import { UseCase } from "src/app/core/utils/usecase";
import { ClaimRepositoryBase } from "../repositories/claim-base.repository";
import { Injectable } from "@angular/core";

@Injectable()
export class GetPendingQuantityClaimsUseCase implements UseCase<undefined, string | Failure> {

    constructor(private claimRepository: ClaimRepositoryBase) {}

    async execute(): Promise<string | Failure> {
        return await this.claimRepository.getAllPendingClaims();
    }
}