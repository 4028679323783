import { Entity } from "src/app/core/utils/entity";

export abstract class CompanyPaginationParams extends Entity {

    constructor(
        public page:number,
        public quantity:number,
        public userId?:string,
    ){
        super();
    }

    abstract getPath():string;
}
