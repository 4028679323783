<p-toast />
<form
  [formGroup]="formClaimDetails"
  (submit)="nextStep()"
  class="w-full relative mt-6"
>
  <section class="">
    <div class="grid w-full mt-1">
      <div class="col-12 md:col-2 lg:col-2 xl:col-2 mb-1 white-space-nowrap">
        <label class="text-sm font-bold mr-3"
          >Objeto de reclamo<span class="span">*</span>
        </label>
      </div>
      <div class="col-12 md:col-10 lg:col-10 xl:col-10">
        <div class="grid">
          <div
            *ngFor="let object of objectOfClaim"
            class="col-12 md:col-6 lg:col-3 xl:col-3 mb-1 flex align-items-center"
          >
            <p-checkbox
              [disabled]="object.disabled"
              (click)="disableIndemnificationCheck(object)"
              [label]="object.name"
              [value]="object.name"
              [(ngModel)]="activeChecks"
              formControlName="selecterObjectOfClaim"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="" *ngIf="this.activeMessages">
      <div class="information">
        <img
          src="../../../../../../../assets/img/login/informationIcon.png"
          alt=""
        />
        <span class="menssageSpan"
          >Recuerde que ud. deberá acompañar de manera obligatoria la misiva
          postal donde se comunica la decisión de someterse voluntariamente al
          procedimiento de autocomposición de conflictos.</span
        >
      </div>
      <div class="confirmInformation mt-2">
        <p-checkbox
          formControlName="confirmInformation"
          [(ngModel)]="checked"
          (onChange)="onCheckboxChange()"
          [binary]="true"
          inputId="binary"
        />
        <label
          >Por medio de la presente, manifiesto en forma libre, voluntaria e
          informada, mi expresa conformidad a someterme ante el procedimiento
          convencional de autocomposición de conflictos, en virtud de lo
          previsto en los artículos 27°, 33° y 34° del Reglamento de
          Operatividad y Gestión del Sistema Solidario de Protección frente al
          Despido Arbitrario, gozando de plena validez y eficacia en todos los
          términos allí contenidos.</label
        >
      </div>
    </div>
  </section>
  <p-divider />

  <section class="extra mt-6">
    <div class="grid w-full mt-2">
      <div class="col-12">
        <label class="text-sm font-bold mr-3"
          >Seleccionar la opción<span class="span">*</span>
        </label>
      </div>
      <div
        class="col-12 md:col-4 lg:col-6 xl:col-6 flex flex-column xl:flex-row md:flex-row lg:flex-row gap-1"
      >
        <div *ngFor="let a of ownRight" class="flex align-items-center">
          <p-radioButton
            [inputId]="a.key"
            [value]="a.name"
            formControlName="selectedOwnRight"
          ></p-radioButton>
          <label [for]="a.key" class="text-sm ml-2 gap-1 mr-3">{{
            a.name
          }}</label>
        </div>
        <div
          class="text-danger p-error mb-1"
          *ngIf="hasErrors('selectedOwnRight', 'required')"
        >
          Este campo es obligatorio
        </div>
      </div>
    </div>

    <div *ngIf="showLawyerInputs" class="grid w-full mb-2 mt-3">
      <div class="col-12 md:col-4 lg:col-4 xl:col-4 sm:col-6">
        <label class="labelTitle"
          >Apellido y Nombre<span class="span">*</span></label
        >
        <input
          pInputText
          maxlength="50"
          (keydown)="onKeyDown($event)"
          formControlName="lawyerFullName"
          [ngClass]="{
            'ng-invalid ng-dirty': hasErrors('lawyerFullName', 'required')
          }"
        />
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('lawyerFullName', 'required')"
        >
          Este campo es obligatorio
        </div>
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('lawyerFullName', 'maxlength')"
        >
          Este campo no puede poseer más de 50 caracteres
        </div>
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('lawyerFullName', 'pattern')"
        >
          El campo solo acepta letras.
        </div>
      </div>

      <div class="col-12 md:col-4 lg:col-4 xl:col-4 sm:col-6">
        <label class="labelTitle"
          >Número de matrícula<span class="span">*</span></label
        >
        <input
          pInputText
          formControlName="lawyerRegistrationNumber"
          maxlength="10"
          [ngClass]="{
            'ng-invalid ng-dirty':
              hasErrors('lawyerRegistrationNumber', 'required') ||
              hasErrors('lawyerRegistrationNumber', 'maxlength')
          }"
        />

        <div
          class="text-danger p-error"
          *ngIf="hasErrors('lawyerRegistrationNumber', 'required')"
        >
          Este campo es obligatorio
        </div>
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('lawyerRegistrationNumber', 'maxlength')"
        >
          Este campo no puede poseer más de 10 caracteres
        </div>
      </div>

      <div class="col-12 md:col-4 lg:col-4 xl:col-4">
        <label class="labelTitle"
          >Correo electrónico del abogado<span class="span">*</span></label
        >
        <input
          type="text"
          maxlength="100"
          pInputText
          formControlName="lawyerEmail"
          [ngClass]="{
            'ng-invalid ng-dirty': hasErrors('lawyerEmail', 'required')
          }"
        />
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('lawyerEmail', 'required')"
        >
          Este campo es obligatorio
        </div>
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('lawyerEmail', 'email')"
        >
          Formato de email incorrecto
        </div>
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('lawyerEmail', 'maxlength')"
        >
          Excede el límite de caracteres
        </div>
      </div>
    </div>

    <div class="grid w-full mt-3">
      <div class="col-12">
        <label class="labelTitle"
          >Documentación extra<span class="span"></span
        ></label>
        <p class="descriptionContent">
          Cargue documentos que puedan servir de respaldo a la denuncia
        </p>
      </div>

      <div class="col-12 md:col-8 lg:col-8 xl:col-6">
        <div
          [ngClass]="
            isDragging
              ? 'isDragging dropzone-container h-6rem col-12'
              : 'dropzone-container h-6rem col-12'
          "
          (dragover)="onDragOver($event)"
          (drop)="onDropSuccess($event)"
        >
          <input
            #inputFilePfd
            accept=".pdf, .jpg, .png"
            id="inputFilePfd"
            type="file"
            (change)="onChange($event)"
          />
          <div
            class="w-full flex align-items-center justify-content-between col-12"
          >
            <img
              class="hidden md:block"
              src="../../../../../../../assets/img/main/file-update.svg"
            />
            <span class="descriptionContent hidden md:block"
              >Arrastre sus documentos aquí o</span
            >
            <p-button
              pRipple
              (click)="inputFilePfd.click()"
              #upload
              class="btnUploadFile mr-1"
              >Elegir archivo</p-button
            >
          </div>
        </div>
        <span class="descriptionContent">Archivos permitidos:  JPG / JPEG / WEBP / HEIF / PDF / PNG</span>
      </div>

      <div class="col-12 md:col-4 lg:col-4 xl:col-6 conteiner-UploadRight-pdf">
        <div
          class="text-danger p-error"
          *ngIf="
            formClaimDetails
              .get('extraDocumentationPdf')
              ?.hasError('fileCount') &&
            formClaimDetails.get('extraDocumentationPdf')?.touched
          "
        >
          Se deben subir entre 1 y 12 archivos.
        </div>
        <div
          class="imageContainer"
          *ngFor="let file of pdfFiles; let i = index"
        >
          <img
            src="../../../../../../../assets/img/main/icon-image.svg"
            alt=""
            class="icon-imagen"
          />
          <span class="descriptionContent">{{ file.name }}</span>
          <p-button
            icon="pi pi-times"
            [rounded]="true"
            [text]="true"
            severity="danger"
            (click)="deleteFile(i)"
          ></p-button>
        </div>
      </div>
    </div>
  </section>
  <p-divider />
  <section class="mb-6 mt-6">
    <div class="divTitleLabel">
      <span class="labelTitle"
        >Detalles del reclamo<span class="span">*</span></span
      >
    </div>
    <div class="textDetail grid w-full">
      <textarea
        rows="5"
        cols="30"
        maxlength="5000"
        pInputTextarea
        formControlName="textClaimDetails"
        [autoResize]="true"
        placeholder="Realice una descripción clara y detallada de su reclamo"
      >
      </textarea>
      <div
        class="text-danger p-error"
        *ngIf="hasErrors('textClaimDetails', 'required')"
      >
        Este campo es obligatorio
      </div>
    </div>
  </section>

  <div class="grid w-full flex justify-content-end mt-2">
    <div class="col-12 md:col-2 lg:col-2 xl:col-2">
      <p-button
        (click)="cancel()"
        label="Cancelar"
        class="btn-cancelar sm:text-xs"
      />
    </div>

    <div class="col-12 md:col-2 lg:col-2 xl:col-2">
      <p-button
        (click)="back()"
        label="Atrás"
        class="btn-cancelar sm:text-xs"
      />
    </div>
    <div class="col-12 md:col-2 lg:col-2 xl:col-2">
      <p-button
        [label]="isEditMode ? 'Guardar cambios' : 'Generar Reclamo'"
        class="btn-siguiente"
        [disabled]="
          isEditMode
            ? formClaimDetails.invalid || (activeMessages && !checked)
            : formClaimDetails.invalid ||
              (activeMessages && !checked) ||
              formComplete < 2
        "
        type="submit"
      ></p-button>
    </div>
  </div>
</form>
