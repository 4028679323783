<section class="flex justify-content-center w-full relative p-4 pb-1">
  <div class="grid w-full flex justify-content-center w-full relative">
    <div class="col-9 flex flex-column">

<!-- azul -->
<div class="notification-sign p-2 border-round col-12">
  <div class="flex flex-column">
      <span class="col-12 text-xl pb-0 font-semibold">Bienvenidos a SICOL - Un Sistema de la Comisión
          Paritaria</span>
      <span class="col-12 text-xl pt-1 font-semibold">Permanente de Relaciones Laborales</span>
  </div>
  <div class="grid p-2">
      <div class="col-6 flex align-items-center gap-2">
          <button label="Añadir Usuario" class="btn" routerLink="/main/createUsers">Añadir Usuario
          </button>
          <button label="Añadir Empresa" class="btn" routerLink="/main/newCompany">Añadir Empresa
          </button>
      </div>

  </div>

</div>
 <!-- azul -->

  <!-- Tabla de Usuarios -->
  <div class="homeSA grid home-shadow flex flex-column mt-4 col-12">
    <span class="col-12 text-xl pb-0 font-semibold">Últimos usuarios añadidos</span>
    <div class="grid p-2">
      <div class="col-12">
        <!-- Componente de Tabla de Usuarios -->
        <app-users></app-users>
      </div>
    </div>
  </div>

      <!-- Tabla de Empresas -->
      <div class="homeSA grid home-shadow flex flex-column mt-4 col-12">
        <div class="flex flex-column">
          <span class="col-12 text-xl pb-0 font-semibold">Últimas empresas añadidas</span>
        </div>
        <div class="grid p-2">
          <div class="col-12">
            <!-- Componente de Tabla de Empresas -->
            <app-company-table></app-company-table>
          </div>
        </div>
      </div>
    </div>
  </div>

</section>
