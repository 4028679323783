import { Failure } from 'src/app/core/utils/failure';
import { ClaimMultiEntity } from '../entities/claim-multi.entity';
import { ClaimsPaginationParams } from '../entities/claim-pagination-params.entity';
import { MultiPagination } from '../entities/multi-pagination.entity';
import { UpdateClaimConciliatorEntity } from '../entities/update-claim-conciliator.entity';

export abstract class ClaimMultiRepositoryBase {
  abstract createClaim(claim: ClaimMultiEntity): Promise<string | Failure>;
  abstract getMultiClaims(
    params: ClaimsPaginationParams
  ): Promise<MultiPagination | Failure>;
  abstract updateClaimConciliator(
    claim: UpdateClaimConciliatorEntity
  ): Promise<string | Failure>;
}
