import { Entity } from "src/app/core/utils/entity";

export abstract class GetFileEntity extends Entity {
    constructor(
        public claimId: string,
        public fileName: string
    ){
        super();
    }
    
    abstract getPath():string;
}