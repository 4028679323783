import { UseCase } from "src/app/core/utils/usecase";
import { DeleteCompanyEntity } from "../entities/delete-company.entity";
import { Failure } from "src/app/core/utils/failure";
import { CompanyrepositoryBase } from "../repositories/company-base.repository";
import { Injectable } from "@angular/core";

@Injectable()
export class DeleteCompanyUseCase implements UseCase<DeleteCompanyEntity, string | Failure> {
    constructor(private companyRepository: CompanyrepositoryBase){}

    async execute(param: DeleteCompanyEntity): Promise<string | Failure> {
        return await this.companyRepository.deleteCompany(param);
    }

}