import { Failure } from "src/app/core/utils/failure";
import { CompanyEntity } from "../../domain/entities/company.entity";
import { CompanyPaginationParams } from "../../domain/entities/company-pagination-params";
import { CompanyPagination } from "../../domain/entities/company-pagination";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment.dev";
import { HttpServiceBase } from "src/app/core/services/http.service";
import { LocalStorageServiceBase } from "src/app/core/services/localStorage-serviceBase";
import { HttpHeaders } from "@angular/common/http";
import { CompanyPaginationModel } from "../models/company-pagination.model";
import { DeleteCompanyEntity } from "../../domain/entities/delete-company.entity";
import { MultiCompanyFileEntity } from "../../domain/entities/multi-company-file.entity";

export abstract class CompanyRemoteDataSourceBase {
    abstract getCompany(id: string): Promise<CompanyEntity | Failure>;
    abstract getCompanyCuil(id: string): Promise<CompanyEntity | Failure>;
    abstract getPaginatedCompanies(params: CompanyPaginationParams): Promise<CompanyPagination | Failure>;
    abstract createCompany(company: CompanyEntity): Promise<string | Failure>;
    abstract updateCompany(company: CompanyEntity): Promise<string | Failure>;
    abstract deleteCompany(deleteCompany: DeleteCompanyEntity): Promise<string | Failure>;
    abstract createMultiCompany(companies: FormData): Promise<string | Failure>;

}

@Injectable()
export class CompanyRemoteDataSource extends CompanyRemoteDataSourceBase {

    private url: string = `${environment.apiUrl}/Company`;

    constructor(private httpService: HttpServiceBase,
        private localStorageService: LocalStorageServiceBase) {
        super();
    }


    override async getCompany(id: string): Promise<Failure | CompanyEntity> {
        let headers = new HttpHeaders().set('Authorization', `Bearer ${this.localStorageService.read('access_token')}`);
        let result = await this.httpService.get(`${this.url}/get_company?id=${id}`, headers);
        return result.get('response');
    }

    override async getCompanyCuil(id: string): Promise<Failure | CompanyEntity> {
        let headers = new HttpHeaders().set('Authorization', `Bearer ${this.localStorageService.read('access_token')}`);
        let result = await this.httpService.get(`${this.url}/get_company_by_cuil?cuit=${id}`, headers);
        return result.get('response');
    }

    override async getPaginatedCompanies(params: CompanyPaginationParams): Promise<Failure | CompanyPagination> {
        let headers = new HttpHeaders().set('Authorization', `Bearer ${this.localStorageService.read('access_token')}`);
        let result = await this.httpService.get(`${this.url}/get_all_company${params.getPath()}`, headers);
        let response = result.get('response');
        let map = new Map<string, any>(Object.entries(response));
        return CompanyPaginationModel.fromJson(map);
    }

    override async createCompany(company: CompanyEntity): Promise<string | Failure> {
        let headers = new HttpHeaders().set('Authorization', `Bearer ${this.localStorageService.read('access_token')}`);
        let result = await this.httpService.post(`${this.url}`, company, headers);
        return result.get('response');
    }

    override async updateCompany(company: CompanyEntity): Promise<string | Failure> {
        let headers = new HttpHeaders().set('Authorization', `Bearer ${this.localStorageService.read('access_token')}`);
        let result = await this.httpService.put(`${this.url}/update_company?id=${company.id}`, company, headers);
        return result.get('response');
    }

    override async deleteCompany(deleteCompany: DeleteCompanyEntity): Promise<string | Failure> {
        let headers = new HttpHeaders().set('Authorization', `Bearer ${this.localStorageService.read('access_token')}`);
        let result = await this.httpService.delete(`${this.url}/delete_company`, deleteCompany, headers);
        return result.get('response');
    }

    override async createMultiCompany(companies: FormData): Promise<string | Failure> {
        let headers = new HttpHeaders().set('Authorization', `Bearer ${this.localStorageService.read('access_token')}`);
        let result = await this.httpService.postWihtFormData(`${this.url}/massive`, companies, headers);
        return result.get('response');
    }

}