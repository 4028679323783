<div class="w-full">
  <p-toast />
  <p-confirmDialog #cd>
    <ng-template pTemplate="headless" let-message class="confirmDialog">
      <div
        class="flex flex-column align-items-center p-5 surface-overlay border-round"
      >
        <span class="labelTitle1">
          {{ message.header }}
        </span>
        <div class="flex justify-content-center align-items-center gap-2">
          <i class="pi pi-exclamation-circle"></i>
          <p class="text-base">{{ message.message }}</p>
        </div>
        <div
          class="flex flex-row justify-content-center align-items-center gap-2 mt-3"
        >
          <ng-container class="col-12">
            <button
              pButton
              label="Cancelar"
              (click)="cd.reject()"
              class="btn-cancelar col-6"
              style="
                border: 1px solid #263245;
                background-color: white;
                color: #263245;
                font-size: 0.8rem;
              "
            ></button>
            <button
              pButton
              label="Aceptar"
              (click)="cd.accept()"
              class="btn-siguiente col-6"
              style="
                background-color: #263245;
                border-color: #263245;
                font-size: 0.8rem;
              "
            ></button>
          </ng-container>
        </div>
      </div>
    </ng-template>
  </p-confirmDialog>
  <p-accordion>
    <p-accordionTab header="Datos del Trabajador" [selected]="true">
      <form
        [formGroup]="formWorker"
        class="grid p-fluid"
        (ngSubmit)="onSubmit()"
      >
        <div class="col-12 md:col-6 lg:col-3">
          <div class="p-field">
            <label for="lastName"
              >Apellido y nombre<span class="span">*</span></label
            >
            <input
              id="lastName"
              type="text"
              pInputText
              formControlName="nameClaimed"
              placeholder="Apellido y nombre"
              [ngClass]="{
                'ng-invalid ng-dirty': hasErrors('nameClaimed', 'required')
              }"
            />
            <div
              class="text-danger p-error"
              *ngIf="hasErrors('nameClaimed', 'required')"
            >
              Este campo es obligatorio
            </div>
          </div>
        </div>

        <div class="col-12 md:col-6 lg:col-3">
          <div class="p-field">
            <label for="cuil">CUIL<span class="span">*</span></label>
            <p-inputMask
              class="inputLarge"
              mask="99-99999999-9"
              placeholder="99-99999999-9"
              formControlName="claimedCuil"
              [ngClass]="{
                'ng-invalid ng-dirty': hasErrors('claimedCuil', 'required')
              }"
            ></p-inputMask>
            <div
              class="text-danger p-error"
              *ngIf="hasErrors('claimedCuil', 'required')"
            >
              Este campo es obligatorio
            </div>
          </div>
        </div>

        <div class="col-12 md:col-6 lg:col-3">
          <div class="p-field">
            <label>Correo electrónico <span class="span">*</span></label>
            <input
              placeholder="Correo electrónico"
              formControlName="claimedEmail"
              class="disabled"
              type="text"
              pInputText
              maxlength="50"
              [ngClass]="{
                'ng-invalid ng-dirty':
                  hasErrors('claimedEmail', 'required') ||
                  hasErrors('claimedEmail', 'maxlength') ||
                  hasErrors('claimedEmail', 'email')
              }"
            />

            <div
              class="text-danger p-error"
              *ngIf="hasErrors('claimedEmail', 'required')"
            >
              Este campo es obligatorio
            </div>
            <div
              class="text-danger p-error"
              *ngIf="hasErrors('claimedEmail', 'email')"
            >
              Formato de email incorrecto
            </div>
          </div>
        </div>

        <div class="col-12 md:col-6 lg:col-3">
          <div class="p-field">
            <label for="street">Calle<span class="span">*</span></label>
            <input
              id="street"
              type="text"
              pInputText
              formControlName="claimedStreet"
              placeholder="Calle"
              [ngClass]="{
                'ng-invalid ng-dirty': hasErrors('claimedStreet', 'required')
              }"
            />
            <div
              class="text-danger p-error"
              *ngIf="hasErrors('claimedStreet', 'required')"
            >
              Este campo es obligatorio
            </div>
          </div>
        </div>

        <div class="col-12 md:col-6 lg:col-3">
          <div class="p-field">
            <label for="number">Número<span class="span">*</span></label>
            <input
              id="number"
              type="text"
              pInputText
              formControlName="claimedNumber"
              placeholder="Número"
              [appNumberOnlyNoLength]="6"
              maxlength="6"
              [ngClass]="{
                'ng-invalid ng-dirty': hasErrors('claimedNumber', 'required')
              }"
            />
            <div
              class="text-danger p-error"
              *ngIf="hasErrors('claimedNumber', 'required')"
            >
              Este campo es obligatorio
            </div>
          </div>
        </div>

        <div class="col-12 md:col-6 lg:col-3">
          <div class="p-field">
            <label for="floor">Piso/Depto</label>
            <input
              id="floor"
              type="text"
              pInputText
              formControlName="claimedFloorApartment"
              placeholder="Piso/Depto"
            />
          </div>
        </div>

        <div class="col-12 md:col-6 lg:col-3">
          <div class="p-field">
            <label for="tower">Torre</label>
            <input
              id="tower"
              type="text"
              pInputText
              formControlName="claimedTower"
              placeholder="Torre"
            />
          </div>
        </div>

        <div class="col-12 md:col-6 lg:col-3">
          <div class="p-field">
            <label for="postalCode"
              >Código postal<span class="span">*</span></label
            >
            <input
              id="postalCode"
              type="text"
              pInputText
              formControlName="claimedPostalCode"
              placeholder="Código postal"
              [appNumberOnlyNoLength]="6"
              maxlength="6"
              [ngClass]="{
                'ng-invalid ng-dirty': hasErrors(
                  'claimedPostalCode',
                  'required'
                )
              }"
            />
            <div
              class="text-danger p-error"
              *ngIf="hasErrors('claimedPostalCode', 'required')"
            >
              Este campo es obligatorio
            </div>
          </div>
        </div>

        <div class="col-12 md:col-6 lg:col-3">
          <div class="p-field">
            <label for="province">Provincia<span class="span">*</span></label>
            <p-dropdown
              id="province"
              [options]="provinces"
              formControlName="claimedProvince"
              optionLabel="name"
              optionValue="id"
              placeholder="Seleccione su provincia"
              [ngClass]="{
                'ng-invalid ng-dirty': hasErrors('claimedProvince', 'required')
              }"
            >
            </p-dropdown>
            <div
              class="text-danger p-error"
              *ngIf="hasErrors('claimedProvince', 'required')"
            >
              Este campo es obligatorio
            </div>
          </div>
        </div>

        <div class="col-12 md:col-6 lg:col-3">
          <div class="p-field">
            <label for="location">Localidad<span class="span">*</span></label>
            <p-dropdown
              id="location"
              [options]="localities"
              formControlName="claimedCity"
              optionLabel="name"
              optionValue="name"
              placeholder="Seleccione su localidad"
              [ngClass]="{
                'ng-invalid ng-dirty': hasErrors('claimedCity', 'required')
              }"
            >
            </p-dropdown>
            <div
              class="text-danger p-error"
              *ngIf="hasErrors('claimedCity', 'required')"
            >
              Este campo es obligatorio
            </div>
          </div>
        </div>

        <section class="attachedFiles p-2 mt-4 w-full">
          <div class="grid w-full mt-1">
            <div class="col">
              <span class="titleSection">Archivos adjuntos</span>
            </div>
          </div>

          <div class="grid w-full">
            <div class="col-12">
              <label class="labelTitle"
                >DNI frente y dorso<span class="span">*</span></label
              >
              <p class="descriptionContent mb-0">
                Cargue el frente y dorso de su Documento Nacional de Identidad
              </p>
            </div>

            <div class="col-12 md:col-8 lg:col-8 xl:col-6">
              <div
                [ngClass]="
                  isDragging
                    ? 'isDragging dropzone-container h-6rem col-12 '
                    : 'dropzone-container h-6rem col-12  '
                "
                (dragover)="onDragOver($event)"
                (drop)="onDropSuccess($event, 'dni')"
              >
                <input
                  #inputFile
                  type="file"
                  multiple="true"
                  (change)="onChange($event, 'dni')"
                  accept=".pdf, .jpg, .png"
                  maxFileSize="1000000"
                />

                <div
                  class="w-full flex align-items-center justify-content-between col-12"
                >
                  <img
                    class="hidden md:block"
                    src="../../../../../../../assets/img/main/file-update.svg"
                  />
                  <span class="descriptionContent hidden md:block"
                    >Arrastre sus documentos aquí o</span
                  >
                  <p-button
                    pRipple
                    (click)="inputFile.click()"
                    #upload
                    class="btnUploadFile mr-1"
                    >Elegir archivo</p-button
                  >
                </div>
              </div>
              <span class="descriptionContent"
                >Archivos permitidos: JPG / JPEG / WEBP / HEIF / PDF / PNG
              </span>
            </div>

            <div
              class="col-12 md:col-4 lg:col-4 xl:col-6 conteiner-UploadRight"
            >
              <div
                class="text-danger p-error"
                *ngIf="
                  formWorker.get('claimetFileDni')?.hasError('fileCountDni') &&
                  formWorker.get('claimetFileDni')?.touched
                "
              >
                Se debe subir al menos 1 archivo.
              </div>
              <div
                class="imageContainer"
                *ngFor="let image of imageFiles; let i = index"
              >
                <img
                  src="../../../../../../../assets/img/main/icon-image.svg"
                  alt=""
                  class="icon-imagen"
                />
                <span class="descriptionContent">{{ image.name }}</span>
                <p-button
                  icon="pi pi-times"
                  [rounded]="true"
                  [text]="true"
                  severity="danger"
                  (onClick)="deleteFileImage(i)"
                ></p-button>
              </div>
            </div>
          </div>
        </section>

        <div class="col-12">
          <div class="grid">
            <div class="col-12 md:col-9 lg:col-9 xl:col-10"></div>
            <div class="col-12 md:col-3 lg:col-3 xl:col-2">
              <p-button
                class="btn-siguiente"
                label="Agregar Trabajador"
                (click)="addWorker()"
                [disabled]="formWorker.invalid || workers.length > 19"
              />
            </div>
          </div>
        </div>
      </form>
    </p-accordionTab>
  </p-accordion>

  <!-- TABLA DE LOS TRABAJADORES QUE SE VAN AGREGANDO EN EL FORMULARIO -->
  <!-- <p-card class="card"> -->
  <p-table
    [value]="workers"
    [responsive]="true"
    class="mt-3"
    *ngIf="workers.length"
  >
    <ng-template pTemplate="header">
      <tr>
        <th>Apellido y Nombre</th>
        <th>CUIL</th>
        <th>Domicilio</th>
        <th>Código Postal</th>
        <th>Provincia</th>
        <th>Localidad</th>
        <th>DNI frente y dorso</th>
        <th></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-worker>
      <tr>
        <td>{{ worker.nameClaimed }}</td>
        <td>{{ worker.claimedCuil }}</td>
        <td>{{ worker.claimedStreet }} {{ worker.claimedNumber }}</td>
        <td>{{ worker.claimedPostalCode }}</td>
        <td>{{ getProvinceName(worker.claimedProvince) }}</td>
        <td>{{ worker.claimedCity }}</td>
        <td>
          <img
            *ngFor="let dniImage of worker.claimedDni"
            src="../../../../../../assets/img/main/icon-pdf.svg"
            class="dni-image"
            style="max-width: 100px; margin-right: 5px"
          />
        </td>
        <td>
          <p-button
            icon="pi pi-trash"
            (click)="removeWorker(worker)"
            size="small"
            class="btn-icon"
          />
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="footer"> </ng-template>
  </p-table>

  <div class="grid w-full flex justify-content-end mt-6">
    <div class="col-6 md:col-2 lg:col-2 xl:col-2">
      <p-button
        (click)="cancel()"
        label="Cancelar"
        class="btn-cancelar sm:text-xs"
      />
    </div>
    <div class="col-6 md:col-2 lg:col-2 xl:col-2">
      <p-button
        (click)="back()"
        label="Atrás"
        class="btn-cancelar sm:text-xs"
      />
    </div>

    <div class="col-6 md:col-2 lg:col-2 xl:col-2">
      <p-button
        label="Siguiente"
        class="btn-siguiente"
        (click)="onSubmit()"
        [disabled]="workers.length < 1 || workers.length > 20"
      ></p-button>
    </div>
  </div>
</div>
