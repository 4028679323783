<p-toast />
<form
  [formGroup]="formGroup"
  (ngSubmit)="nextStep()"
  class="w-full relative mt-3"
>
  <section class="personalInformation">
    <span class="titleSection">Datos empresa</span>

    <div class="grid w-full mb-2 mt-3">
      <div class="col-12 md:col-4 lg:col-3 xl:col-3 sm:col-6">
        <label class="labelTitle"
          >Razón social <span class="span">*</span></label
        >
        <input
          pInputText
          formControlName="razonSocial"
          placeholder="Razón social"
          maxlength="50"
          [ngClass]="{
            'ng-invalid ng-dirty': hasErrors('razonSocial', 'required')
          }"
        />
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('razonSocial', 'required')"
        >
          Este campo es obligatorio
        </div>
      </div>

      <div class="col-12 md:col-4 lg:col-3 xl:col-3 sm:col-6">
        <label class="labelTitle">CUIT<span class="span">*</span></label>
        <p-inputMask
          class="inputLarge"
          mask="99-99999999-9"
          placeholder="99-99999999-9"
          formControlName="cuitCompany"
          [ngClass]="{
            'ng-invalid ng-dirty': hasErrors('cuitCompany', 'required')
          }"
        ></p-inputMask>
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('cuitCompany', 'required')"
        >
          Este campo es obligatorio
        </div>
      </div>

      <div class="col-12 md:col-4 lg:col-3 xl:col-3 sm:col-6">
        <label class="labelTitle"
          >Correo electronico<span class="span">*</span></label
        >
        <input
          placeholder="Correo electronico"
          type="text"
          pInputText
          formControlName="companyEmail"
          type="email"
          maxlength="100"
          [ngClass]="{
            'ng-invalid ng-dirty': hasErrors('companyEmail', 'required')
          }"
        />
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('companyEmail', 'required')"
        >
          Este campo es obligatorio
        </div>
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('companyEmail', 'email')"
        >
          Formato de email incorrecto
        </div>
      </div>

      <div class="col-12 md:col-4 lg:col-3 xl:col-3 sm:col-6">
        <label class="labelTitle">Teléfono<span class="span">*</span></label>
        <input
          placeholder="Teléfono"
          pInputText
          formControlName="companyPhone"
          maxlength="15"
          [appNumberOnlyNoLength]="15"
          [ngClass]="{
            'ng-invalid ng-dirty':
              hasErrors('companyPhone', 'required') ||
              hasErrors('companyPhone', 'minlength') ||
              hasErrors('companyPhone', 'maxlength')
          }"
        />
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('companyPhone', 'required')"
        >
          Este campo es obligatorio
        </div>
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('companyPhone', 'minlength')"
        >
          Este campo no puede poseer menos de 7 caracteres.
        </div>
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('companyPhone', 'maxlength')"
        >
          Este campo no puede poseer más de 15 caracteres.
        </div>
      </div>
      <div class="col-12 md:col-4 lg:col-3 xl:col-3 sm:col-6">
        <label class="labelTitle">Calle<span class="span">*</span></label>
        <input
          placeholder="Calle"
          type="text"
          maxlength="50"
          pInputText
          formControlName="streetCompany"
          [ngClass]="{
            'ng-invalid ng-dirty': hasErrors('streetCompany', 'required')
          }"
        />
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('streetCompany', 'required')"
        >
          Este campo es obligatorio
        </div>
      </div>
      <div class="col-12 md:col-4 lg:col-3 xl:col-3 sm:col-6">
        <label class="labelTitle">Número<span class="span">*</span></label>
        <input
          [appNumberOnlyNoLength]="15"
          placeholder="Número"
          type="text"
          pInputText
          formControlName="numberCompany"
          maxlength="5"
          [ngClass]="{
            'ng-invalid ng-dirty': hasErrors('numberCompany', 'required')
          }"
        />
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('numberCompany', 'required')"
        >
          Este campo es obligatorio
        </div>
      </div>
      <div class="col-12 md:col-4 lg:col-3 xl:col-3 sm:col-6">
        <label class="labelTitle">Piso/Depto</label>
        <input
          placeholder="Piso/Depto"
          type="text"
          pInputText
          formControlName="floorApartmentCompany"
          maxlength="10"
        />
      </div>

      <div class="col-12 md:col-4 lg:col-3 xl:col-3 sm:col-6">
        <label class="labelTitle">Torre</label>
        <input
          placeholder="Torre"
          type="text"
          pInputText
          formControlName="towerCompany"
          maxlength="30"
        />
      </div>

      <div class="col-12 md:col-4 lg:col-3 xl:col-3 sm:col-6">
        <label class="labelTitle"
          >Código postal<span class="span">*</span></label
        >
        <input
          [appNumberOnlyNoLength]="15"
          type="text"
          placeholder="Código postal"
          pInputText
          formControlName="postalCodeCompany"
          maxlength="10"
          [ngClass]="{
            'ng-invalid ng-dirty':
              hasErrors('postalCodeCompany', 'required') ||
              hasErrors('postalCodeCompany', 'maxlength') ||
              hasErrors('postalCodeCompany', 'minlength')
          }"
        />
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('postalCodeCompany', 'required')"
        >
          Este campo es obligatorio
        </div>
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('postalCodeCompany', 'minlength')"
        >
          El campo solo acepta un máximo de 10 dígitos.
        </div>
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('postalCodeCompany', 'minlength')"
        >
          El campo solo acepta un mínimo de 4 dígitos.
        </div>
      </div>
      <div class="col-12 md:col-4 lg:col-3 xl:col-3 sm:col-6">
        <label class="labelTitle" for="claimetProvince" optionLabel="name"
          >Provincia<span class="span">*</span></label
        >
        <p-dropdown
          id="claimetProvince"
          [options]="provinces"
          optionLabel="name"
          optionValue="id"
          placeholder="Seleccione su Provincia"
          formControlName="provinceCompany"
          [ngClass]="{
            'ng-invalid ng-dirty': hasErrors('provinceCompany', 'required')
          }"
        >
        </p-dropdown>
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('provinceCompany', 'required')"
        >
          Este campo es obligatorio
        </div>
      </div>
      <div class="col-12 md:col-4 lg:col-3 xl:col-3 sm:col-6">
        <label class="labelTitle">Localidad<span class="span">*</span></label>
        <p-dropdown
          [options]="localities"
          optionLabel="name"
          optionValue="name"
          [showClear]="false"
          placeholder="Seleccione su localidad"
          formControlName="cityCompany"
          [ngClass]="{
            'ng-invalid ng-dirty': hasErrors('cityCompany', 'required')
          }"
        >
        </p-dropdown>
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('cityCompany', 'required')"
        >
          Este campo es obligatorio
        </div>
      </div>
      <div class="col-12 md:col-4 lg:col-3 xl:col-3 sm:col-6">
        <label class="labelTitle"
          >Departamento laboral<span class="span">*</span></label
        >
        <p-dropdown
          [options]="departments"
          placeholder="Seleccione una opción"
          optionLabel="name"
          optionValue="id"
          formControlName="departmentId"
          [ngClass]="{
            'ng-invalid ng-dirty': hasErrors('departmentId', 'required')
          }"
        />
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('departmentId', 'required')"
        >
          Este campo es obligatorio
        </div>
      </div>
      <div class="col-12 md:col-4 lg:col-3 xl:col-3 sm:col-6">
        <label class="labelTitle">Cargo<span class="span">*</span></label>
        <input
          placeholder="Cargo"
          formControlName="claimantTypePerformance"
          pInputText
          maxlength="50"
          [ngClass]="{
            'ng-invalid ng-dirty': hasErrors(
              'claimantTypePerformance',
              'required'
            )
          }"
        />
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('claimantTypePerformance', 'required')"
        >
          Este campo es obligatorio
        </div>
      </div>
    </div>
  </section>

  <div class="grid w-full flex justify-content-end">
    <div class="col-6 md:col-2 lg:col-2 xl:col-2">
      <p-button
        (click)="cancel()"
        label="Cancelar"
        class="btn-cancelar sm:text-xs"
      />
    </div>

    <div class="col-6 md:col-2 lg:col-2 xl:col-2">
      <p-button
        label="Siguiente"
        class="btn-siguiente"
        type="submit"
        [disabled]="formGroup.invalid"
      ></p-button>
    </div>
  </div>
</form>
