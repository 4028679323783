<section>
  <!-- empty claims -->
  <div *ngIf="!userClaims">
    <ng-container>
      <div class="emptyAudienciaText">
        <img id="image" src="../../../../../../assets/img/main/file-searching.svg" alt="imagen de archivo"/>
        <p>Ud. no ha generado ningún reclamo <br> o no tiene fecha de audiencia fijada</p>
        <p-button *ngIf="homeView" class="btn-new-claim" label="Nuevo reclamo laboral" icon="pi pi-plus" routerLink="/main/newLaboralClaim" />
      </div>
    </ng-container>
  </div>
  <!-- delete claim -->
  <p-confirmDialog #cd>
    <ng-template pTemplate="headless" let-message>
      <div class="flex flex-column align-items-center p-5 surface-overlay border-round">
        <div class="border-circle inline-flex justify-content-center align-items-center">
          <i class="pi pi-exclamation-circle"></i>
        </div>
        <span class="font-bold text-2xl block mb-2 mt-4">
                {{ message.header }}
            </span>
        <p class="mb-0">{{ message.message }}</p>
        <div class="flex align-items-center gap-3 mt-4 col-12">
          <button pButton label="Cancelar" (click)="cd.reject()" class="btn-cancelar" style="border-color: #263245;
          background-color: white;
          color: #263245;
          font-size: 0.8rem;">
          </button>
          <button pButton label="Eliminar reclamo" (click)="cd.accept()" class="btn-siguiente" style="
              background-color: #C93B3B;
              border-color: #C93B3B;
              font-size: 0.8rem;">
          </button>
        </div>
      </div>
    </ng-template>
  </p-confirmDialog>


  <!-- claim table -->
  <div *ngIf="userClaims">

    <div *ngIf="!homeView" class="col-12 md:col-4 p-input-icon-left mb-3">
      <p-iconField iconPosition="left">
        <p-inputIcon>
          <i class="pi pi-search"></i>
        </p-inputIcon>
        <input #inputElement
               pInputText
               type="text"
               placeholder="Buscar por Reclamado, Número de Reclamo, Fecha de inicio, Estado "
               (input)="dtClaims.filterGlobal(inputElement.value, 'contains')"
               style="width: 100%"
        />
      </p-iconField>
    </div>

    <p-table #dtClaims
             (onLazyLoad)="loadRecords($event)"
             [value]="userClaims"
             currentPageReportTemplate="{first}-{last} resultados | Total {totalRecords}"
             [tableStyle]="{ 'min-width': '40rem' }"
             [globalFilterFields]="['nameLastNameOrBusinessNameClaimed', 'proceedings', 'startDate', 'status']">
      <ng-template pTemplate="header">
        <tr class="font-bold text-xs sm:text-md">
          <th class="text-center">Reclamado</th>
          <th class="text-center">Número de Reclamo</th>
          <th class="text-center">Fecha de inicio</th>
          <th class="text-center">Estado</th>
          <th class="text-center"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-claim>
        <tr class="table-row text-xs sm:text-sm">
          <td class="text-center">{{ claim.nameLastNameOrBusinessNameClaimed}}</td>
          <td class="text-center">{{ claim.proceedings }}</td>
          <td class="text-center">{{ claim.startDate | date: 'dd/MM/yyyy' }}</td>
          <td class="text-center">
            <p [ngClass]="getStatusClass(claim.status)">{{ claim.status | claimStatusDisplay }}</p>
          </td>
          <td class="text-center">
            <p-menu #menu [popup]="true" [model]="items" appendTo="body"></p-menu>
            <button type="button" pButton icon="pi pi-ellipsis-v"
              (click)="selectMenuItemsByRol(claim.status); menu.toggle($event); selectClaim(claim.id)"
              class="p-button-rounded p-button-text"></button>
          </td>
        </tr>
      </ng-template>
    </p-table>

    <p-paginator *ngIf="!homeView" (onPageChange)="changePage($event)" [first]="first" [rows]="defaultRows"
      [totalRecords]="totalQuantity" [rowsPerPageOptions]="[5, 10, 25, 50]">
    </p-paginator>
  </div>
</section>
