import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';

export function dateControl(
  firstDate: string,
  secondDate: string
): ValidatorFn {
  return (group: AbstractControl): ValidationErrors | null => {
    if (!(group instanceof FormGroup)) {
      return null;
    }

    const f = group.controls[firstDate];
    const t = group.controls[secondDate];

    if (f && t && t.value && f.value > t.value) {
      return { dateErr: 'La fecha seleccionada es inválida.' };
    }

    return null;
  };
}
