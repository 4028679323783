import { UseCase } from "src/app/core/utils/usecase";
import { MultiCompanyFileEntity } from "../entities/multi-company-file.entity";
import { Failure } from "src/app/core/utils/failure";
import { CompanyrepositoryBase } from "../repositories/company-base.repository";
import { Injectable } from "@angular/core";

@Injectable()
export class CreateMultiCompanyUseCase implements UseCase<FormData, string | Failure> {
    constructor(private companiyRepository: CompanyrepositoryBase) {}

    async execute(param: FormData): Promise<string | Failure> {
        return await this.companiyRepository.createMultiCompany(param);
    }
}