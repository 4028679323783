import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })

export class AudiencesService {
  private extraData: string = "";

  setExtraData(data: any) {
    this.extraData = data;
  }

  getExtraData() {
    return this.extraData;
  }
}
