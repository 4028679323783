import { Injectable } from "@angular/core";
import { FormInitialaizer } from "./form-initializer";
import { Rol } from "src/app/core/interfaces/user.interface";
import { FormGroup } from "@angular/forms";

@Injectable({
    providedIn: 'root'
})
export class FormInitialaizerFactory{
    private initializerMap = {
        [Rol.Trabajador]: () => this.formInitializer.initEmployeeForm(),
        [Rol.Empleador]: () => this.formInitializer.initCompanyForm(),
        [Rol['Representante gremial']]: () => this.formInitializer.initCompanyForm(),
      }
    
    constructor(private formInitializer: FormInitialaizer){}

    getFormInitializer(rol: Rol): () => FormGroup {
        return this.initializerMap[rol] || this.initializerMap[Rol.Empleador];
      }
}