export enum UserRol {
    WORKER = 'Trabajador',
    EMPLOYER = 'Empleador',
    UNION_REPRESENTATIVE = 'Representante gremial',
    COMISSION = 'Comisión',
    ADMINISTRATOR = 'Administrador',
    CONCILIATOR = 'Conciliador',
    COMISSION_MEMBER = 'Miembro de la comisión',
    SUPER_ADMIN = 'Super Admin'
}
