import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserRol } from 'src/app/core/enums/user-rol.enum';
import { LocalStorageServiceBase } from 'src/app/core/services/localStorage-serviceBase';
import AESEncryptDecryptService from 'src/app/core/utils/crypto';
import { UserData } from 'src/app/features/user/domain/entities/user-data.entity';
import { UserService } from 'src/app/features/user/services/user.service';

@Component({
  selector: 'app-myLaborClaims',
  templateUrl: './myLaborClaims.component.html',
  styleUrls: ['./myLaborClaims.component.css'],
})
export class MyLaborClaims implements OnInit {
  tableOrImg: boolean = true;
  userData?: UserData;
  rol?: string;
  isCommisionRol: boolean = true;
  titleText: string = 'Reclamos laborales';
  private AES = new AESEncryptDecryptService();

  constructor(
    private userService: UserService,
    private localStorageService: LocalStorageServiceBase,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getUserData();
    this.userData = !this.userService.userData
      ? undefined
      : this.userService.userData;
    this.rol = this.AES.decrypt(
      this.localStorageService.loadStorageData('user_rol')
    );
    this.checkUserRol();
  }

  getUserData(): void {
    this.userService.userData$.subscribe((userData) => {
      if (!userData) return;
      this.userData = userData;
      this.rol = this.userData?.rol;
    });
  }

  checkUserRol(): void {
    if (
      this.rol == UserRol.WORKER ||
      this.rol == UserRol.EMPLOYER ||
      this.rol == UserRol.UNION_REPRESENTATIVE
    ) {
      this.isCommisionRol = false;
      this.titleText = 'Mis reclamos laborales';
    }
  }
}
