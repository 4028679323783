import { Injectable } from '@angular/core';
import { UserRemoteDataSourceBase } from '../../data/data-sources/user-remote.datasource';
import { UserPaginationParams } from '../entities/user-pagination-params.entity';
import { UserPagination } from '../entities/user-pagination.entity';
import { Failure } from 'src/app/core/utils/failure';

@Injectable({
  providedIn: 'root'
})
export class GetPaginatedAllUserUseCase {
  constructor(private userRemoteDataSource: UserRemoteDataSourceBase) {}

  async execute(params: UserPaginationParams): Promise<UserPagination | Failure> {
    return this.userRemoteDataSource.getAllPaginationUser(params);
  }
}
