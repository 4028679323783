import { UseCase } from "src/app/core/utils/usecase";
import { Failure } from "src/app/core/utils/failure";
import { UserRepositoryBase } from "../repositories/user-base.repository";
import { Injectable } from "@angular/core";
import { UserData } from "../entities/user-data.entity";

@Injectable()
export class UserIdUseCase implements UseCase<{ id: string; email: string }, UserData | Failure> {

  constructor(private userRepository: UserRepositoryBase) {}

  async execute(params: { id: string; email: string }): Promise<UserData | Failure> {
    return await this.userRepository.getMyDataID(params.id, params.email);
  }
}
