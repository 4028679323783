<div class="">
  <p-toast />
  <p-confirmDialog #cd>
      <ng-template pTemplate="headless" let-message>
          <div class="flex flex-column align-items-center justify-content-center p-5 surface-overlay border-round ">
              <span class="col-12 font-semibold text-xl ">
                  {{ message.header }}
              </span>
              <div class="col-12 flex align-items-center justify-content-center gap-3">
                  <i class="pi pi-exclamation-circle text-lg"></i>
                  <p class="">{{ message.message }}</p>
              </div>
              <div class="col-12 flex gap-3 justify-content-end">
                  <button pButton label="Cancelar" (click)="cd.reject()" class="btn-cancelar " style="border-color: #263245;
                  background-color: white;
                  color: #263245;
                  font-size: 0.8rem;">
                  </button>
                  <button pButton label="Eliminar audiencia" (click)="cd.accept()" class="btn-siguiente " style="
                      background-color: #C93B3B;
                      border-color: #C93B3B;
                      font-size: 0.8rem;">
                  </button>
              </div>
          </div>
      </ng-template>
  </p-confirmDialog>
</div>

<div *ngIf="!nextAudience" class="col-12">
<ng-container>
<div class="emptyAudienciaText">
        <img id="image" src="../../../../../../assets/img/main/file-searching.svg" alt="" />
        <p>Ud. no tiene fecha de audiencia fijada</p>
</div>
</ng-container>
</div>

<div *ngIf="nextAudience" class="grid w-full relative p-3">
  <p-table (onLazyLoad)="loadRecords($event)" [tableStyle]="{ 'min-width': '100%'}" [value]="allAudiences"
      currentPageReportTemplate="{first}-{last} resultados | Total {totalRecords}" class="col-12">
      <ng-template pTemplate="header">
          <tr class="font-semibold text-xs">
              <th class="col-6 sm:col-3 md:col-1">Fecha</th>
              <th class="col-6 sm:col-3 md:col-1">Hora</th>
              <th *ngIf="!homeView" class="col-12 sm:col-6 md:col-3">Título de audiencia</th>
              <th *ngIf="!homeView" class="col-12 sm:col-6 md:col-3">Conciliador</th>
              <th class="col-6 sm:col-3 md:col-1">N° de reclamo</th>
              <th *ngIf="!homeView" class="col-12 sm:col-6 md:col-2">N° de expediente</th>
              <th class="col-12 sm:col-6 md:col-2">Reclamante</th>
              <th class="col-12 sm:col-6 md:col-2">Reclamado</th>
              <th class="col-12 sm:col-6 md:col-1"></th>
          </tr>
      </ng-template>
      <ng-template pTemplate="body" let-audience>
          <tr class="table-row">
              <td class="col-6 sm:col-3 md:col-1">{{ audience.date | date: 'dd/MM/yyyy' }}</td>
              <td class="col-6 sm:col-3 md:col-1">{{ audience.time }}hs.</td>
              <td *ngIf="!homeView" class="col-12 sm:col-6 md:col-3">{{ audience.audienceTitle }}</td>
              <td *ngIf="!homeView" class="col-12 sm:col-6 md:col-3">{{ audience.conciliator }}</td>
              <td class="col-6 sm:col-3 md:col-1">{{ audience.numberOfClaim }}</td>
              <td *ngIf="!homeView" class="col-12 sm:col-6 md:col-2">{{ audience.proceedings }}</td>
              <td class="col-12 sm:col-6 md:col-2">{{ audience.claimetName }} {{ audience.claimetLastname}}</td>
              <td class="col-12 sm:col-6 md:col-2">{{ audience.claimedNameLastNameOrBusinessName }}</td>
              <td class="col-12 sm:col-6 md:col-1 text-right">
                  <p-menu #menu [popup]="true" [model]="items" appendTo="body"></p-menu>
                  <button type="button" pButton icon="pi pi-ellipsis-v"
                      (click)="selectAmountComisionItems(); menu.toggle($event); selectAudience(audience.id)"
                      class="p-button-rounded p-button-text"></button>
              </td>
          </tr>
      </ng-template>
  </p-table>
  <p-paginator class="col-12" *ngIf="!homeView" (onPageChange)="changePage($event)" [first]="first" [rows]="defaultRows"
      [totalRecords]="totalQuantity" [rowsPerPageOptions]="[5, 10, 25, 50]">
  </p-paginator>
</div>
