<form [formGroup]="formClaimedDetails" (ngSubmit)="nextStep()" class="w-full h-full relative">
    <section class="kindOfPerson">

        <div class="grid w-full mt-1">
            <div class="col-12 md:col-3 lg:col-3 xl:col-3">
                <label class="text-sm font-bold mr-3 ">Tipo de persona: </label>
            </div>
            <div class="col-12 md:col-6 lg:col-6 xl:col-6 flex flex-column xl:flex-row md:flex-row lg:flex-row gap-1">
                <div *ngFor="let person of typeOfPerson" class=" flex align-items-center gap-1">
                    <p-radioButton [inputId]="person.key" [value]="person.name"
                        formControlName="claimedTypeOfPerson"></p-radioButton>
                    <label [for]="person.key" class=" ml-2 gap-1 mr-3 text-xs font-bold ">{{
                        person.name
                        }}</label>
                </div>
            </div>
        </div>
    </section>

    <section class="inputSection">

        <div class="grid w-full	mb-2 mt-2">

            <div class=" col-12 md:col-12 lg:col-6 xl:col-6 sm:col-12 ">
                <label class="labelTitle">Apellido y nombre o Razón social*</label>
                <input type="text" pInputText formControlName="claimedNameLastNameOrBusinessName" maxlength="50"
                  [ngClass]="{'ng-invalid ng-dirty': hasErrors('claimedNameLastNameOrBusinessName', 'required') || hasErrors('claimedNameLastNameOrBusinessName', 'maxlength') || hasErrors('claimedNameLastNameOrBusinessName', 'pattern')}" />
                <div class="text-danger p-error" *ngIf="hasErrors('claimedNameLastNameOrBusinessName', 'required')">
                  Este campo es obligatorio
                </div>
                <div class="text-danger p-error" *ngIf="hasErrors('claimedNameLastNameOrBusinessName', 'maxlength')">
                  Este campo no puede superar los 50 caracteres.
                </div>
                <div class="text-danger p-error" *ngIf="hasErrors('claimedNameLastNameOrBusinessName', 'pattern')">
                  El campo solo acepta letras.
                </div>
            </div>

            <div class=" col-12 md:col-12 lg:col-6 xl:col-6 sm:col-12">
                <label class="labelTitle">CUIL/CUIT*</label>
                <p-inputMask class="inputLarge" mask="99-99999999-9" placeholder="99-99999999-9"
                    formControlName="claimedCuil" [ngClass]="{
                    'ng-invalid ng-dirty': hasErrors('claimedCuil', 'required')
                  }"></p-inputMask>
                <div class="text-danger p-error" *ngIf="hasErrors('claimedCuil', 'required')">
                    Este campo es obligatorio
                </div>

            </div>
        </div>


        <div class="grid w-full	mb-2 flex align-items-baseline ">
            <div class=" col-12 md:col-6 lg:col-6 xl:col-6 sm:col-6 ">
                <label class="labelTitle">Correo electrónico*</label>
                <input type="email" pInputText formControlName="claimedEmail"
                    [ngClass]="{'ng-invalid ng-dirty': hasErrors('claimedEmail', 'required') || hasErrors('claimedEmail', 'maxlength') || hasErrors('claimedEmail', 'email')}" />
                <div class="text-danger p-error" *ngIf="hasErrors('claimedEmail', 'required')">
                    Este campo es obligatorio
                </div>
                <div class="text-danger p-error" *ngIf="hasErrors('claimedEmail', 'email')">
                    Formato de email incorrecto
                </div>
            </div>

            <div class=" col-12 md:col-6 lg:col-6 xl:col-6 sm:col-6 flex flex-column">
                <label class="labelTitle">Teléfono*</label>
                <input pInputText formControlName="claimedPhone" maxlength="15" [ngClass]="{
                          'ng-invalid ng-dirty': hasErrors('claimedPhone', 'required') || hasErrors('claimedPhone', 'minlength')
                          || hasErrors('claimedPhone', 'maxlength') || hasErrors('claimedPhone', 'pattern')}" />

                <div class="text-danger p-error" *ngIf="hasErrors('claimedPhone', 'required')">
                  Este campo es obligatorio
                </div>
                <div class="text-danger p-error" *ngIf="hasErrors('claimedPhone', 'minlength')">
                  Este campo no puede poseer menos de 9 caracteres
                </div>
                <div class="text-danger p-error" *ngIf="hasErrors('claimedPhone', 'maxlength')">
                  Este campo no puede poseer más de 15 caracteres
                </div>
                <div class="text-danger p-error" *ngIf="hasErrors('claimedPhone', 'pattern')">
                  El campo solo acepta números. Las letras no son permitidas.
                </div>
            </div>
        </div>

        <div class="grid w-full mb-2 flex align-items-baseline ">
            <div class=" col-12 md:col-8 lg:col-4 xl:col-4 sm:col-9 ">
                <label class="labelTitle">Calle*</label>
                <input type="text" pInputText formControlName="claimedStreet" maxlength="50"
                    [ngClass]="{'ng-invalid ng-dirty': hasErrors('claimedStreet', 'required') || hasErrors('claimedStreet', 'maxlength')}" />
                  <div class="text-danger p-error" *ngIf="hasErrors('claimedStreet', 'required')">
                    Este campo es obligatorio
                  </div>
            </div>

            <div class=" col-12 md:col-4 lg:col-2 xl:col-2 sm:col-3 ">
                <label class="labelTitle" for="claimedNumber">Número*</label>
                <p-inputNumber class="inputLarge" mode="decimal" inputId="withoutgrouping" [useGrouping]="false"
                formControlName="claimedNumber" maxlength="5"
                [ngClass]="{'ng-invalid ng-dirty': hasErrors('claimedNumber', 'required') || hasErrors('claimedNumber', 'maxlength')}">
              </p-inputNumber>
              <div class="text-danger p-error" *ngIf="hasErrors('claimedNumber', 'required')">
                Este campo es obligatorio
              </div>
            </div>

            <div class=" col-12 md:col-4 lg:col-2 xl:col-2 sm:col-4 flex flex-column ">
                <label class="labelTitle">Piso/Depto</label>
                <input type="text" pInputText formControlName="claimedFloorApartment" maxlength="10" />
            </div>
            <div class=" col-12 md:col-4 lg:col-2 xl:col-2 sm:col-4 flex flex-column ">
                <label class="labelTitle">Torre</label>
                <input type="text" pInputText formControlName="claimedTower" maxlength="30" />
            </div>
            <div class=" col-12 md:col-4 lg:col-2 xl:col-2 sm:col-4 flex flex-column ">
                <label class="labelTitle">Código postal*</label>
                <input type="text" pInputText formControlName="claimedPostalCode" maxlength="10"
                [ngClass]="{
                'ng-invalid ng-dirty': hasErrors('claimedPostalCode', 'required') || hasErrors('claimedPostalCode', 'maxlength')|| hasErrors('claimedPostalCode', 'minlength')}">
            <div class="text-danger p-error" *ngIf="hasErrors('claimedPostalCode', 'required')">
              Este campo es obligatorio
            </div>
            <div class="text-danger p-error" *ngIf="hasErrors('claimedPostalCode', 'minlength')">
              El campo solo acepta un máximo de 10 dígitos.
            </div>
            <div class="text-danger p-error" *ngIf="hasErrors('claimedPostalCode', 'minlength')">
              El campo solo acepta un mínimo de 4 dígitos.
            </div>
            </div>
        </div>

        <div class="grid w-full mb-2 ">

            <div class=" col-12 md:col-4 lg:col-4 xl:col-4 sm:col-12 ">
                <label class="labelTitle">Barrio*</label>
                <input type="text" pInputText formControlName="claimedNeighborhood"
                    maxlength="50" />
            </div>

            <div class="col-12 md:col-4 lg:col-4 xl:col-4 sm:col-6">
                <label class="labelTitle" for="claimedProvince" optionLabel="name">Provincia*</label>
                <p-dropdown id="claimedProvince" [options]="provinces" optionLabel="name" optionValue="id" placeholder="Seleccione su Provincia"
                    formControlName="claimedProvince"
                    [ngClass]="{'ng-invalid ng-dirty': hasErrors('claimedProvince', 'required')}">
                </p-dropdown>
                <div class="text-danger p-error" *ngIf="hasErrors('claimedProvince', 'required')">
                    Este campo es obligatorio
                </div>
            </div>

            <div class="col-12 md:col-4 lg:col-4 xl:col-4 sm:col-6">
                <label class="labelTitle" for="claimedCity">Localidad*</label>
                <p-dropdown [options]="localities" optionLabel="name" optionValue="name" [showClear]="false"
                    placeholder="Seleccione su localidad" formControlName="claimedCity"
                    [ngClass]="{'ng-invalid ng-dirty': hasErrors('claimedCity', 'required')}">
                </p-dropdown>
                <div class="text-danger p-error" *ngIf="hasErrors('claimedCity', 'required')">
                    Este campo es obligatorio
                </div>
            </div>
        </div>
    </section>

    <div class="grid w-full flex justify-content-end mt-3">
        <div class="col-6 md:col-2 lg:col-2 xl:col-2 ">
            <p-button (click)="cancel()" label="Cancelar" class="btn-cancelar sm:text-xs" />
        </div>

        <div class="col-6 md:col-2 lg:col-2 xl:col-2 ">
            <p-button (click)="back()" label="Atrás" class="btn-cancelar sm:text-xs" />
        </div>

        <div class="col-6 md:col-2 lg:col-2 xl:col-2 ">
            <p-button label="Siguiente" class="btn-siguiente " [disabled]="formClaimedDetails.invalid" type="submit"></p-button>
        </div>
    </div>
</form>
