import { UseCase } from "src/app/core/utils/usecase";
import { Failure } from "src/app/core/utils/failure";
import { Injectable } from "@angular/core";
import { ClaimCollectiveEntity } from "../entities/claim-colective.entity";
import { ClaimColectiveRepositoryBase } from "../repositories/cliam-colective-base.repository";

@Injectable()
export class ClaimColectiveUseCase implements UseCase<string, ClaimCollectiveEntity | Failure> {

    constructor(private claimRepository: ClaimColectiveRepositoryBase) {}

    async execute(param: string): Promise<ClaimCollectiveEntity | Failure> {
        return await this.claimRepository.getClaim(param);
    }
}