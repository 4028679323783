import { Component, EventEmitter, Output, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Rol } from 'src/app/core/interfaces/user.interface';
import { CalendarTranslationService } from 'src/app/core/services/translate-calendar.service';
import { OfAgeValidator } from 'src/app/core/utils/validator-birth';

@Component({
  selector: 'app-step-personal-information',
  templateUrl: './step-personal-information.component.html',
  styleUrls: ['./step-personal-information.component.css'],
})
export class StepPersonalInformationComponent {
  @Input() initialData: any = {};
  @Output() personales: EventEmitter<FormGroup> = new EventEmitter();
  @Output() back: EventEmitter<number> = new EventEmitter();
  @Input() selectedRol!: string;
  maxDate: Date;
  minDate: Date = new Date(1930, 0, 1);

  civilStates = [
    { name: 'Soltero/a' },
    { name: 'Casado/a' },
    { name: 'Divorciado/a' },
    { name: 'Viudo/a' },
  ];

  genders = [{ name: 'Masculino' }, { name: 'Femenino' }, { name: 'Otro' }];

  registerFormPersonal: FormGroup = this.formBuilder.group({
    name: [
      ,
      [
        Validators.required,
        Validators.maxLength(50),
        Validators.pattern(/^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]*$/),
      ],
    ],
    last_name: [
      ,
      [
        Validators.required,
        Validators.maxLength(50),
        Validators.pattern(/^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]*$/),
      ],
    ],
    cuil: [, [Validators.required, Validators.maxLength(13)]],
    gender: [, Validators.required],
    phone: [
      ,
      [
        Validators.required,
        Validators.minLength(9),
        Validators.maxLength(15),
        Validators.pattern(/^[0-9]*$/),
      ],
    ],
    birth_date: ['', [Validators.required, OfAgeValidator()]], // Correcto ✅
    civil_status: [, Validators.required],
  });

  constructor(
    private formBuilder: FormBuilder,
    private calendarTranslationService: CalendarTranslationService
  ) {
    this.maxDate = new Date();
  }

  ngOnInit() {
    if (this.initialData) {
      if (this.selectedRol === 'Empleador') {
        this.registerFormPersonal.addControl(
          'cargo_empresa',
          this.formBuilder.control('', [
            Validators.required,
            Validators.maxLength(100),
          ])
        );
      }

      this.registerFormPersonal.patchValue(this.initialData);

      this.registerFormPersonal
        .get('gender')
        ?.setValue(
          this.genders.find((g) => g.name === this.initialData.gender)
        );
      this.registerFormPersonal
        .get('civil_status')
        ?.setValue(
          this.civilStates.find((c) => c.name === this.initialData.civil_status)
        );
    }
    this.calendarTranslationService.configureCalendarTranslations();
  }

  nextStep() {
    if (this.registerFormPersonal.invalid) return;
    this.setValues();
    this.personales.emit(this.registerFormPersonal.value);
  }

  previousStep() {
    this.back.emit(1);
  }

  hasErrors(controlName: string, errorType: string) {
    return (
      this.registerFormPersonal.get(controlName)?.hasError(errorType) &&
      this.registerFormPersonal.get(controlName)?.touched
    );
  }

  setValues() {
    const genero = this.registerFormPersonal.get('gender')?.value?.name;
    this.registerFormPersonal.get('gender')?.setValue(genero);
    const civil = this.registerFormPersonal.get('civil_status')?.value?.name;
    this.registerFormPersonal.get('civil_status')?.setValue(civil);
    const positionCompany =
      this.registerFormPersonal.get('cargo_empresa')?.value;
    this.registerFormPersonal.get('cargo_empresa')?.setValue(positionCompany);
  }
}
