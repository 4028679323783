import { UseCase } from "src/app/core/utils/usecase";
import { Failure } from "src/app/core/utils/failure";
import { Injectable } from "@angular/core";
import { ClaimMultiEntity } from "../entities/claim-multi.entity";
import { ClaimMultiRepositoryBase } from "../repositories/claim-multi-base.repository";

@Injectable()
export class CreateClaimMultiUseCase implements UseCase<ClaimMultiEntity, string | Failure> {
    constructor(private claimRepository: ClaimMultiRepositoryBase) {}

    async execute(param: ClaimMultiEntity): Promise<string | Failure> {
        return await this.claimRepository.createClaim(param);
    }
}
