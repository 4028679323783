import { Failure } from "../../../../core/utils/failure";
import { Credentials } from "../entities/credentials.entity";
import { FrogotPasswordGenerateNewEntity } from "../entities/forgot-password-generate-new.entity";
import { ForgotPasswordSendEmailEntity } from "../entities/forgot-password-send-email.entity";
import { ResetPasswordEntity } from "../entities/reset-password.entity";
import { Session } from "../entities/session.entity";
import { User } from "../entities/user.entity";

export abstract class AuthRepositoryBase {
  abstract login(credentials: Credentials): Promise<Session | Failure>;
  abstract register(user: User): Promise<boolean | Failure>;
  abstract resetPassword(password:ResetPasswordEntity): Promise<string | Failure>;
  abstract forgotPasswordSendEmail(email: ForgotPasswordSendEmailEntity): Promise<string | Failure>;
  abstract forgotPasswordGenerateNew(password: FrogotPasswordGenerateNewEntity): Promise<string | Failure>;
}