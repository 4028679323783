import { UseCase } from "src/app/core/utils/usecase";
import { Failure } from "src/app/core/utils/failure";
import { Injectable } from "@angular/core";
import { ClaimColectiveRepositoryBase } from "../repositories/cliam-colective-base.repository";
import { ClaimCollectiveEntity } from "../entities/claim-colective.entity";
import { GetFileEntity } from "../entities/get-file.entity";

@Injectable()
export class GetFileColectiveUseCase implements UseCase<GetFileEntity, string | Failure> {

    constructor(private claimRepository: ClaimColectiveRepositoryBase) {}

    async execute(param: GetFileEntity): Promise<string | Failure> {
        return await this.claimRepository.getFile(param);
    }
}