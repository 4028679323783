import { UserData } from '../../domain/entities/user-data.entity';
import { UserPagination } from "../../domain/entities/user-pagination.entity";
import { UserDataModel } from './user-data.model';

export class UserPaginationModel extends UserPagination {
    override toJson(): Map<string, any> {
      throw new Error("Method not implemented.");
    }

    static fromJson(data: Map<string, any>): UserPagination {
      let user = this.getUserModels(data);
      return new UserPaginationModel(
        user,
        data.get('numberOfPages'),
        data.get('totalQuantity')
      );
    }

    private static  getUserModels(data:Map<string,any>): UserData[]{
      let response = data.get('response');
      let positions : UserData[] = [];

      for (const UserData of response) {
        let entries = Object.entries(UserData);
        let map = new Map<string, any>(entries);
        positions.push(UserDataModel.fromJson(map));
      }
      return positions;
    }
  }
