<form [formGroup]="registerFormLocation" (ngSubmit)="nextStep()" class="w-8 sm:w-8 md:w-8 lg:w-7" id="container-form">

    <h2 class="mb-0">Crear cuenta</h2>
    <p> {{selectedRol}} </p>

    <div class="grid mt-1">
        <div class="custom-input col-12 md:col-12 lg:col-12 flex flex-column ">
            <label for="province" optionLabel="name" >Provincia*</label>
            <p-dropdown 
                id="province"
                [options]="provinces" 
                optionLabel="name" 
                placeholder="Seleccione su Provincia"
                formControlName="province"
                [ngClass]="{'ng-invalid ng-dirty': hasErrors('province', 'required')}">
            </p-dropdown>
                <div class="text-danger p-error" *ngIf="hasErrors('province', 'required')">
                    Este campo es obligatorio
                </div>
        </div>
    </div>

    <div class="grid mt-1">
        <div class="custom-input col-12 md:col-12 lg:col-12 flex flex-column ">
            <label for="city">Localidad*</label>
            <p-dropdown [options]="localities" optionLabel="name" [showClear]="false" placeholder="Seleccione su localidad"
                formControlName="city" [ngClass]="{'ng-invalid ng-dirty': hasErrors('city', 'required')}"></p-dropdown>
            <div class="text-danger p-error" *ngIf="hasErrors('city', 'required')">
                Este campo es obligatorio
            </div>
        </div>
    </div>

    <div class="grid mt-1">
        <div class="custom-input col-12 md:col-12 lg:col-12 flex flex-column ">
            <label for="street">Calle*</label>
            <input pInputText id="street" aria-describedby="username-help" placeholder="Calle" formControlName="street"
                maxlength="50" [ngClass]="{'ng-invalid ng-dirty': hasErrors('street', 'required')}" />
            <div class="text-danger p-error" *ngIf="hasErrors('street', 'required')">
                Este campo es obligatorio
            </div>
        </div>
    </div>

    <div class="grid mt-1">
        <div class="custom-input col-12 md:col-6 lg:col-6">
            <label for="number">Número*</label>
            <p-inputNumber mode="decimal" inputId="withoutgrouping" [useGrouping]="false"
                placeholder="Numero de calle" formControlName="number" maxlength="5"
                [ngClass]="{'ng-invalid ng-dirty': hasErrors('number', 'required')}"> </p-inputNumber>
            <div class="text-danger p-error" *ngIf="hasErrors('number', 'required')">
                Este campo es obligatorio
            </div>
        </div>
        <div class="custom-input col-12 md:col-6 lg:col-6">
            <label for="floor_apartment">Piso/Depto</label>
            <input pInputText id="floor_apartment" aria-describedby="username-help"
                placeholder="Piso/Depto" formControlName="floor_apartment" maxlength="10" />
        </div>
    </div>

    <div class="grid mt-1">
        <div class="custom-input col-12 md:col-6 lg:col-6">
            <label for="tower">Torre</label>
            <input pInputText id="tower" aria-describedby="username-help" placeholder="Torre"
                formControlName="tower" maxlength="30"/>
        </div>
        <div class="custom-input col-12 md:col-6 lg:col-6">
            <label for="postal_code">Código postal*</label>
            <input type="text" pInputText formControlName="postal_code"
                maxlength="10" [ngClass]="{'ng-invalid ng-dirty': hasErrors('postal_code', 'required')}" />
            <div class="text-danger p-error" *ngIf="hasErrors('postal_code', 'required')">
                Este campo es obligatorio
            </div>
        </div>
    </div>

    <p-button class="nextButton" label="Siguiente"  [disabled]="registerFormLocation.invalid" type="submit"></p-button>
    <span class="backLink"><a (click)="previusStep()">Volver</a></span>
</form>