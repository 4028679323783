import { Component } from '@angular/core';

@Component({
  selector: 'app-createUsers',
  templateUrl: './createUsers.component.html',
  styleUrls: ['./createUsers.component.css']
})
export class createUsersComponent {
  tableOrImg: boolean = true;
}
