import { Failure } from 'src/app/core/utils/failure';
import { ClaimEntity } from '../entities/claim.entity';
import { ClaimPagination } from '../entities/claim-pagination.entity';
import { ClaimsPaginationParams } from '../entities/claim-pagination-params.entity';
import { DeleteClaimEntity } from '../entities/delete-claim.entity';
import { GetFileEntity } from '../entities/get-file.entity';
import { UpdateClaimStatusEntity } from '../entities/update-claim-satus.entity';
import { UpdateClaimConciliatorEntity } from '../entities/update-claim-conciliator.entity';
import { GetFileBaseEntity } from '../entities/get-file-base.entity';

export abstract class ClaimRepositoryBase {
  abstract getClaim(id: string): Promise<ClaimEntity | Failure>;
  abstract getPaginationClaims(
    params: ClaimsPaginationParams
  ): Promise<ClaimPagination | Failure>;
  abstract getAllPaginationClaims(
    params: ClaimsPaginationParams
  ): Promise<ClaimPagination | Failure>;
  abstract updateClaim(claim: ClaimEntity): Promise<string | Failure>;
  abstract deleteClaim(
    deleteClaim: DeleteClaimEntity
  ): Promise<string | Failure>;
  abstract createClaim(claim: ClaimEntity): Promise<string | Failure>;
  abstract getFile(params: GetFileEntity): Promise<string | Failure>;
  abstract getFileBase(params: GetFileBaseEntity): Promise<string | Failure>;
  abstract getAllPendingClaims(): Promise<string | Failure>;
  abstract updateClaimStatus(
    claim: UpdateClaimStatusEntity
  ): Promise<string | Failure>;
  abstract updateClaimConciliator(
    claim: UpdateClaimConciliatorEntity
  ): Promise<string | Failure>;
  abstract getAllPaginationClaimsByConciliatorId(
    params: ClaimsPaginationParams
  ): Promise<ClaimPagination | Failure>;
}
