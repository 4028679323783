import { Entity } from '../../../../core/utils/entity';

export abstract class User extends Entity {
  constructor(
    public rol: string,
    public name: string,
    public last_name: string,
    public cuil: string,
    public gender: string,
    public phone: string,
    public birth_date: Date,
    public civil_status: string,
    public province: string,
    public city: string,
    public street: string,
    public number: number,
    public floor_apartment: string,
    public postal_code: number,
    public company: string,
    public company_cuil: string,
    public email: string,
    public password: string,
    public comission_position: string,
    public comission_email: string,
    public is_employee: boolean,
    public is_employer: boolean,
    public is_conciliator: boolean,
    public tower?: string,
    public type_responsibility?: string,
    public phone_company?: string,    
    public tipo_actuacion?: string,
    public cargo_empresa?: string,
    public razon_social?: string,
    public cuit?: string,
  ) {
    super();
  }
}
