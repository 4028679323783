<div class="flex align-items-center gap-2 mb-1">
  <div class="w-auto md:w-6 lg:w-6">
    <p-iconField iconPosition="left">
      <p-inputIcon>
        <i class="pi pi-search"></i>
      </p-inputIcon>
      <input
        #inputElement
        pInputText
        type="text"
        placeholder="Buscar por Reclamante, Reclamado, Título de audiencia, Nº de reclamo, Nº de expediente"
        class="w-full"
        [(ngModel)]="generalFilter"
        (input)="applyFilters()"
      />
    </p-iconField>
  </div>
  <p-button
    (onClick)="op.toggle($event)"
    icon="pi pi-sliders-h icon-filter"
    label="Filtros"
  />
  <p-overlayPanel
    showTransitionOptions=".50s cubic-bezier(0, 0, 0.2, 1)"
    appendTo="body"
    #op
  >
    <ng-template pTemplate="content">
      <div class="flex flex-column gap-4">
        <div class="w-full">
          <div class="flex flex-column w-full">
            <label
              style="font-size: small; font-weight: bold; margin-bottom: 10px"
              for=""
              >Fecha de Inicio</label
            >
            <p-calendar
              class="calendar"
              [(ngModel)]="selectedDate"
              [iconDisplay]="'input'"
              [showIcon]="true"
              dateFormat="dd/mm/yy"
              inputId="icondisplay"
              placeholder="Seleccione la fecha de inicio"
            />
          </div>
        </div>
        <div class="w-full" *ngIf="this.userId !== 'Conciliador'">
          <div class="flex flex-column w-full">
            <label
              style="font-size: small; font-weight: bold; margin-bottom: 10px"
              for=""
              >Departamentos de Santa Fe</label
            >
            <p-dropdown
              class="drop"
              [options]="departmentList"
              [(ngModel)]="selectedDepartment"
              optionValue="department_number"
              optionLabel="name"
              placeholder="Seleccione el departamento"
              filter="false"
            />
          </div>
        </div>
        <div class="w-full" *ngIf="this.userId !== 'Conciliador'">
          <div class="flex flex-column w-full">
            <label
              style="font-size: small; font-weight: bold; margin-bottom: 10px"
              for=""
              >Conciliador</label
            >
            <p-dropdown
              class="drop"
              [options]="conciliatorList"
              [(ngModel)]="selectedconciliator"
              optionValue="id"
              optionLabel="name"
              placeholder="Seleccione el conciliador"
              filter="false"
            />
          </div>
        </div>
      </div>
      <div class="container-buttons">
        <button class="btn-light cancel" (click)="cleanFilter()">
          Limpiar filtros
        </button>
        <button class="btn-plus" (click)="applyFilters()">
          Aplicar filtros
        </button>
      </div>
    </ng-template>
  </p-overlayPanel>
  <button
    class="btn-clean"
    *ngIf="hasFilters()"
    title="Limpiar filtros"
    (click)="cleanFilter()"
  >
    <i class="pi pi-times"></i>
  </button>
</div>
