import { Entity } from "src/app/core/utils/entity";

export abstract class CompanyEntity extends Entity {
    constructor(
        public razon_social: string,
        public cuit: string,
        public phone: string,
        public province: string,
        public city: string,
        public street: string,
        public number: string,
        public postal_code: string,
        public email: string,
        public neighborhood: string,
        public floor_apartment?: string,
        public tower?: string,
        public id?: string       
    ) {
        super();
    }
}