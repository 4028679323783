<div *ngIf="isLoading" class="spinner-container">
  <p-progressSpinner></p-progressSpinner>
</div>

<form [formGroup]="registerForm">
  <app-step-rol
    *ngIf="formStep === 1"
    (rol)="reciveData($event)"
  ></app-step-rol>

  <ng-container *ngIf="selectedRol">
    <app-step-personal-information
      class="flex justify-content-center marginBox"
      *ngIf="
        (selectedRol === 'Trabajador' && formStep === 2) ||
        (selectedRol === 'Empleador' && formStep === 5) ||
        (selectedRol === 'Representante gremial' && formStep === 3)
      "
      [initialData]="registerForm.value"
      (personales)="reciveData($event)"
      (back)="previousStep($event)"
      [selectedRol]="registerForm.get('rol')?.value"
    ></app-step-personal-information>

    <app-step-location
      class="flex justify-content-center marginBox"
      *ngIf="
        (selectedRol === 'Trabajador' && formStep === 3) ||
        (selectedRol === 'Empleador' && formStep === 4) ||
        (selectedRol === 'Representante gremial' && formStep === 4)
      "
      [initialData]="registerForm.value"
      (location)="reciveData($event)"
      (back)="previousStep($event)"
      [selectedRol]="registerForm.get('rol')?.value"
    ></app-step-location>

    <app-step-mail-password
      class="flex justify-content-center marginBox"
      *ngIf="
        (selectedRol === 'Trabajador' && formStep === 4) ||
        (selectedRol === 'Empleador' && formStep === 6) ||
        (selectedRol === 'Representante gremial' && formStep === 6)
      "
      [initialData]="registerForm.value"
      (emailPassword)="register($event)"
      (back)="previousStep($event)"
      [selectedRol]="registerForm.get('rol')?.value"
    ></app-step-mail-password>

    <app-step-mail-validation
      class="flex justify-content-center marginBox"
      *ngIf="
        (selectedRol === 'Trabajador' && formStep === 5) ||
        (selectedRol === 'Empleador' && formStep === 7) ||
        (selectedRol === 'Representante gremial' && formStep === 7)
      "
      [selectedRol]="registerForm.get('rol')?.value"
    ></app-step-mail-validation>

    <app-typeResponsibility
      class="flex justify-content-center marginBoxRep"
      *ngIf="
        (selectedRol === 'Empleador' && formStep === 2) ||
        (selectedRol === 'Representante gremial' && formStep === 2)
      "
      [initialData]="registerForm.value"
      [selectedRol]="registerForm.get('rol')?.value"
      (act)="reciveData($event)"
      (back)="previousStep($event)"
    ></app-typeResponsibility>

    <app-step-company-cuil
      class="flex justify-content-center marginBoxEmp"
      *ngIf="selectedRol === 'Empleador' && formStep === 3"
      [initialData]="registerForm.value"
      (company)="reciveData($event)"
      (back)="previousStep($event)"
      [selectedRol]="registerForm.get('rol')?.value"
    ></app-step-company-cuil>

    <app-step-two-inputs
      class="flex justify-content-center marginBox"
      *ngIf="selectedRol === 'Representante gremial' && formStep === 5"
      [selectedRol]="selectedRol"
      [selectedeDoYouAct]="selectedTypeResponsibility"
      [initialData]="registerForm.value"
      (data)="reciveData($event)"
      (back)="previousStep($event)"
    ></app-step-two-inputs>

    <ng-container *ngIf="isStepValid()">
      <div class="stepper">
        <div
          *ngFor="let step of getSteps(); let i = index"
          [class.step]="true"
          [class.active]="formStep === step"
        ></div>
      </div>
    </ng-container>
  </ng-container>
</form>

<p-toast></p-toast>
