import { Injectable } from '@angular/core';
import { ClaimData } from '../domain/entities/claim-data.entity';
import { ClaimEntity } from '../domain/entities/claim.entity';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClaimServiceService {

  private claims?: ClaimData[];
  private claim?: ClaimEntity;
  claims$: Subject<ClaimData[]> = new Subject();

  constructor() { }

  get claimsData(): ClaimData[] | undefined {
    return this.claims;
  }

  set claimsData(claimData: ClaimData[]){
    this.claims = claimData;
    this.claims$.next(claimData)
  }

  get claimEntity(): ClaimEntity | undefined {
    return this.claim;
  }

  set claimEntity(claim: ClaimEntity){
    this.claim = claim;
  }
}
