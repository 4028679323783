import { UpdateClaimStatusEntity } from "../../domain/entities/update-claim-satus.entity";

export class UpdateClaimStatusModel extends UpdateClaimStatusEntity {

    override toJson(): Map<string, any> {
        let json: Map<string, any> = new Map();
        json.set('id', this.claimId);
        json.set('status', this.status);
        json.set('reason_for_rejection', this.reasonForRejection);
        return json;
    }
}