<p-toast></p-toast>
<div class="form-container">
  <form [formGroup]="formLogin" (ngSubmit)="onLogin()">
    <div class="grid">
      <h2 class="col-12 md:col-12">Iniciar sesión</h2>

      <div class="col-12 md:col-12">
        <label class="font-semibold text-sm">Correo electrónico</label>
      </div>

      <div class="col-12 md:col-10">
        <input
          pInputText
          type="email"
          maxlength="40"
          autocomplete="off"
          placeholder="ejemplo@gmail.com"
          formControlName="email"
          [ngClass]="{
            'ng-invalid ng-dirty': hasErrors('email', 'required')
          }"
        />
        <div
          class="text-danger p-error text-sm"
          *ngIf="hasErrors('email', 'required')"
        >
          Este campo es requerido
        </div>
      </div>

      <div class="col-12 md:col-12">
        <label class="font-semibold text-sm">Contraseña</label>
      </div>

      <div class="col-12 md:col-10">
        <span class="p-input-icon-right flex flex-column">
          <i
            style="color: #667085"
            [ngClass]="showPassword ? 'pi pi-eye' : 'pi pi-eye-slash'"
            (click)="togglePasswordVisibility()"
          ></i>
          <input
            class="h-2rem w-full"
            pInputText
            type="{{ showPassword ? 'text' : 'password' }}"
            maxlength="32"
            placeholder="Escribir Contraseña"
            autocomplete="off"
            formControlName="password"
            (keydown.enter)="onLogin()"
            [ngClass]="{
              'ng-invalid ng-dirty': hasErrors('password', 'required')
            }"
          />
        </span>
        <div class="p-error text-sm" *ngIf="hasErrors('password', 'required')">
          Este campo es requerido
        </div>
      </div>
      <div class="col-12 md:col-12"></div>

      <!--   DEJAR COMENTADO HASTA QUE TENGAMOS TIEMPO DE CAMBIAR LAS STOR

   <div class="col-12 md:col-5">
        <input [disabled]="!formLogin.valid" type="checkbox" id="checkboxRememberMe" (change)="savePass($event)"
               [checked]="inputCheckbox" class="pb-0"/>
        <label class="font-semibold text-sm pb-0 text-500">Recordarme</label>
      </div>-->
      <div class="col-12 md:col-5"></div>
      <div *ngIf="!isLoading" class="col-12 md:col-5 flex justify-content-end">
        <a
          class="no-underline text-sm font-semibold pb-0 forgotPasswordLink"
          routerLink="/auth/send-validation-email"
          >¿Olvidaste tu contraseña?</a
        >
      </div>

      <div *ngIf="!isLoading" class="col-12 md:col-10">
        <p-button
          class="w-full p-button-sm"
          label="Ingresar"
          id="btn-submit"
          (keydown.enter)="onLogin()"
          (onClick)="onLogin()"
          [disabled]="formLogin.invalid"
        ></p-button>
      </div>

      <div *ngIf="isLoading" class="col-12 md:col-10 xl:col-10 d-flex mt-2">
        <app-spinner></app-spinner>
      </div>

      <div *ngIf="!isLoading" class="col-12 md:col-8">
        <span class="text-sm">¿Aún no tienes una cuenta? </span>
        <span class="registrate"
          ><a
            class="no-underline text-sm font-bold"
            id="text"
            routerLink="/auth/register"
            >Registrate.</a
          ></span
        >
      </div>
    </div>
  </form>
</div>
