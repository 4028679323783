import { Injectable } from "@angular/core";
import { Failure } from "../../../../core/utils/failure";
import { Credentials } from "../entities/credentials.entity";
import { Session } from "../entities/session.entity";
import { AuthRepositoryBase } from "../repositories/auth.repositorybase";
import { UseCase } from "src/app/core/utils/usecase";

@Injectable()
export class LoginUseCase implements UseCase<Credentials, Session | Failure >{
  constructor(private authRepositoryBase: AuthRepositoryBase ) {}

  async execute(credentials: Credentials): Promise<Session | Failure> {
    return await this.authRepositoryBase.login(credentials);
  }
}