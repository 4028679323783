import { ClaimRepositoryBase } from "../../domain/repositories/claim-base.repository";
import { ClaimRemoteDataSourceBase } from "../data-source/claim-remote.datasource";
import { ClaimPagination } from "../../domain/entities/claim-pagination.entity";
import { BadRequestFailure, Failure, ForbiddenFailure, NotFoundFailure, UnhandledFailure } from "src/app/core/utils/failure";
import { ClaimsPaginationParams } from "../../domain/entities/claim-pagination-params.entity";
import { Injectable } from "@angular/core";
import { ClaimEntity } from "../../domain/entities/claim.entity";
import { DeleteClaimEntity } from "../../domain/entities/delete-claim.entity";
import { GetFileEntity } from "../../domain/entities/get-file.entity";
import { UpdateClaimStatusEntity } from "../../domain/entities/update-claim-satus.entity";
import { UpdateClaimConciliatorEntity } from "../../domain/entities/update-claim-conciliator.entity";

@Injectable()
export class ClaimRepository extends ClaimRepositoryBase {

  constructor(private claimRemote: ClaimRemoteDataSourceBase) {
    super();
  }

  override async getClaim(id: string): Promise<ClaimEntity | Failure> {
    try {
      return await this.claimRemote.getClaim(id);
    } catch (error: any) {
      return new UnhandledFailure(error.message, error.code);
    }
  }

  override async getPaginationClaims(params: ClaimsPaginationParams): Promise<ClaimPagination | Failure> {
    try {
      return await this.claimRemote.getPaginatedClaims(params);
    } catch (error: any) {
      return new UnhandledFailure(error.message, error.code);
    };
  }

  override async getAllPaginationClaims(params: ClaimsPaginationParams): Promise<ClaimPagination | Failure> {
    try {
      return await this.claimRemote.getAllPaginationClaims(params);
    } catch (error: any) {
      return new UnhandledFailure(error.message, error.code);
    };
  }

  override async updateClaim(claim: ClaimEntity): Promise<string | Failure> {
    try {
      return await this.claimRemote.updateClaim(claim);
    } catch (error: any) {
      return new UnhandledFailure(error.message, error.code);
    }
  }

  override async deleteClaim(deleteClaim: DeleteClaimEntity): Promise<string | Failure> {
    try {
      return await this.claimRemote.deleteClaim(deleteClaim);
    } catch (error: any) {
      return new UnhandledFailure(error.message, error.code);
    }
  }

  override async createClaim(claim: ClaimEntity): Promise<string | Failure> {
    try {
      return await this.claimRemote.createClaim(claim);
    } catch (error: any) {
      return new UnhandledFailure(error.message, error.code);
    }
  }

  override async getFile(params: GetFileEntity): Promise<string | Failure> {
    try {
      return await this.claimRemote.getFile(params);
    } catch (error: any) {
      return new UnhandledFailure(error.message, error.code);
    };
  }

  override async getAllPendingClaims(): Promise<string | Failure> {
    try {
      return await this.claimRemote.getAllPendingClaims();
    } catch (error: any) {
      return new UnhandledFailure(error.message, error.code);
    };
  }

  override async updateClaimStatus(claim: UpdateClaimStatusEntity): Promise<string | Failure> {
    try {
      return await this.claimRemote.updateClaimStatus(claim);
    } catch (error: any) {
      return new UnhandledFailure(error.message, error.code);
    };
  }

  override async updateClaimConciliator(claim: UpdateClaimConciliatorEntity): Promise<string | Failure> {
    try {
      return await this.claimRemote.updateClaimConciliator(claim);
    } catch (error: any) {
      return new UnhandledFailure(error.message, error.code);
    };
  }

  override async getAllPaginationClaimsByConciliatorId(params: ClaimsPaginationParams): Promise<Failure | ClaimPagination> {
    try {
      return await this.claimRemote.getAllPaginationClaimsByConciliatorId(params);
    } catch (error: any) {
      return new UnhandledFailure(error.message, error.code);
    };
  }
}
