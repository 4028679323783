import { LocationEntity } from "../../domain/entities/location.entity";


export class LocationModel extends LocationEntity{

    override toJson(): Map<string, any> {
        throw new Error("Method not implemented.");  
    }

    static fromJson(data: any): LocationEntity {
        return new LocationModel(data)
    }
}