import { Component, Input, OnInit } from '@angular/core';
import { UserRol } from 'src/app/core/enums/user-rol.enum';
import { LocalStorageServiceBase } from 'src/app/core/services/localStorage-serviceBase';
import AESEncryptDecryptService from 'src/app/core/utils/crypto';
import { Failure } from 'src/app/core/utils/failure';
import { AudienceEntity } from 'src/app/features/audiences/domain/entities/audience.entity';
import { GetNextHomeAudienceOfConciliatorUseCase } from 'src/app/features/audiences/domain/usecases/get-next-audience-home-of-conciliator.usecase';
import { GetNextAudienceOfConciliatorUseCase } from 'src/app/features/audiences/domain/usecases/get-next-audience-of-conciliator.usecase';
import { GetNextSetAudienceUseCase } from 'src/app/features/audiences/domain/usecases/get-next-set-audience.usecase';
import { ClaimData } from 'src/app/features/claims/domain/entities/claim-data.entity';
import { GetPendingQuantityClaimsUseCase } from 'src/app/features/claims/domain/usecases/get-pending-quantity-claims.usecase';
import { UserData } from 'src/app/features/user/domain/entities/user-data.entity';
import { UserService } from 'src/app/features/user/services/user.service';

@Component({
  selector: 'app-comision-home',
  templateUrl: './comision-home.component.html',
  styleUrls: ['./comision-home.component.css']
})
export class ComisionHomeComponent implements OnInit {
  showAudiences: boolean = true;
  @Input() userData?: UserData;
  newClaims: number = 0;
  private AES = new AESEncryptDecryptService();
  rol: string = this.AES.decrypt(this.localStorageService.loadStorageData('user_rol'));
  userId: string = this.AES.decrypt(this.localStorageService.loadStorageData('user_id'));
  usersClaims?: ClaimData[];
  nextAudienceOfConciliator?: AudienceEntity;
  nextSetAudience?: AudienceEntity;
  nextAudienceDate?: Date;
  isComisionMemberRol: boolean = false;
  isAdministratorRol: boolean = false;
  isConciliatorRol: boolean = false;
  isComisionRol: boolean = false;
  ClaimPagination: number = 0;

  constructor(
    private userService: UserService,
    private getPendingQuantityClaimsUseCase: GetPendingQuantityClaimsUseCase,
    private getNextHomeAudienceOfConciliator: GetNextHomeAudienceOfConciliatorUseCase,
    private localStorageService: LocalStorageServiceBase,
    private getNextSetAudienceUseCase: GetNextSetAudienceUseCase,
  ) { }

  ngOnInit(): void {
    this.setRol();
    this.getPendingQuantityClaims();
    if (this.isConciliatorRol) {
      this.getNextAudienceOfConciliator();
    }
    if (this.isAdministratorRol){
      this.getNextSetAudience();
    }
  }


  setRol(){
    switch (this.rol) {
      case UserRol.COMISSION:
        return this.isComisionRol = true;
      case UserRol.COMISSION_MEMBER:
        return this.isComisionMemberRol = true;
      case UserRol.ADMINISTRATOR:
        return this.isAdministratorRol = true;
      case UserRol.CONCILIATOR:
        return this.isConciliatorRol = true;
      default:
        return;
    }
  }

  getUserData(): void {
    this.userService.userData$.subscribe(userData => {
      if (!userData) return;
      this.userData = userData;
      this.rol = this.userData?.rol;
    });
  }

  async getPendingQuantityClaims(): Promise<void> {
    let result = await this.getPendingQuantityClaimsUseCase.execute();
    if (result instanceof Failure) {
      return;
    }
    this.newClaims = parseInt(result);
  }

  async getNextAudienceOfConciliator(): Promise<void> {
    let result = await this.getNextHomeAudienceOfConciliator.execute(this.userId);
    if (result instanceof Failure) {
      return;
    }
    this.nextAudienceOfConciliator = result;
    this.nextAudienceDate = new Date(result.date);
  }

  async getNextSetAudience(): Promise<void> {
    let result = await this.getNextSetAudienceUseCase.execute();
    if (result instanceof Failure) {
      return;
    }
    this.nextSetAudience = result;
    this.nextAudienceDate = new Date(result.date);
  }
}
