<p-toast />
<form
  [formGroup]="formGroup"
  (ngSubmit)="nextStep()"
  class="w-full relative mt-3"
>
  <section class="companyInformation">
    <span class="titleSection">Datos empresa</span>

    <div class="grid w-full mb-2 mt-3">
      <div class="col-12 md:col-4 lg:col-3 xl:col-3 sm:col-6">
        <label class="labelTitle"
          >Razón social <span class="span">*</span></label
        >
        <input
          pInputText
          formControlName="razonSocial"
          placeholder="Razón social"
          maxlength="50"
          [ngClass]="{
            'ng-invalid ng-dirty': hasErrors('razonSocial', 'required')
          }"
        />
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('razonSocial', 'required')"
        >
          Este campo es obligatorio
        </div>
      </div>

      <div class="col-12 md:col-4 lg:col-3 xl:col-3 sm:col-6">
        <label class="labelTitle">CUIT<span class="span">*</span></label>
        <p-inputMask
          class="inputLarge"
          mask="99-99999999-9"
          placeholder="99-99999999-9"
          formControlName="companyCuit"
          [ngClass]="{
            'ng-invalid ng-dirty': hasErrors('companyCuit', 'required')
          }"
        ></p-inputMask>
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('companyCuit', 'required')"
        >
          Este campo es obligatorio
        </div>
      </div>

      <div class="col-12 md:col-4 lg:col-3 xl:col-3 sm:col-6">
        <label class="labelTitle"
          >Correo electronico<span class="span">*</span></label
        >
        <input
          placeholder="Correo electronico"
          type="text"
          pInputText
          formControlName="companyEmail"
          type="email"
          maxlength="100"
          [ngClass]="{
            'ng-invalid ng-dirty': hasErrors('companyEmail', 'required')
          }"
        />
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('companyEmail', 'required')"
        >
          Este campo es obligatorio
        </div>
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('companyEmail', 'email')"
        >
          Formato de email incorrecto
        </div>
      </div>

      <div class="col-12 md:col-4 lg:col-3 xl:col-3 sm:col-6">
        <label class="labelTitle">Teléfono<span class="span">*</span></label>
        <input
          placeholder="Teléfono"
          pInputText
          formControlName="companyPhone"
          maxlength="15"
          [appNumberOnlyNoLength]="15"
          [ngClass]="{
            'ng-invalid ng-dirty':
              hasErrors('companyPhone', 'required') ||
              hasErrors('companyPhone', 'minlength') ||
              hasErrors('companyPhone', 'maxlength')
          }"
        />
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('companyPhone', 'required')"
        >
          Este campo es obligatorio
        </div>
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('companyPhone', 'minlength')"
        >
          Este campo no puede poseer menos de 7 caracteres.
        </div>
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('companyPhone', 'maxlength')"
        >
          Este campo no puede poseer más de 15 caracteres.
        </div>
      </div>
      <div class="col-12 md:col-4 lg:col-3 xl:col-3 sm:col-6">
        <label class="labelTitle">Calle<span class="span">*</span></label>
        <input
          placeholder="Calle"
          type="text"
          maxlength="50"
          pInputText
          formControlName="streetCompany"
          [ngClass]="{
            'ng-invalid ng-dirty': hasErrors('streetCompany', 'required')
          }"
        />
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('streetCompany', 'required')"
        >
          Este campo es obligatorio
        </div>
      </div>
      <div class="col-12 md:col-4 lg:col-3 xl:col-3 sm:col-6">
        <label class="labelTitle">Número<span class="span">*</span></label>
        <input
          [appNumberOnlyNoLength]="15"
          placeholder="Número"
          type="text"
          pInputText
          formControlName="numberCompany"
          maxlength="5"
          [ngClass]="{
            'ng-invalid ng-dirty': hasErrors('numberCompany', 'required')
          }"
        />
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('numberCompany', 'required')"
        >
          Este campo es obligatorio
        </div>
      </div>
      <div class="col-12 md:col-4 lg:col-3 xl:col-3 sm:col-6">
        <label class="labelTitle">Piso/Depto</label>
        <input
          placeholder="Piso/Depto"
          type="text"
          pInputText
          formControlName="floorApartmentCompany"
          maxlength="10"
        />
      </div>

      <div class="col-12 md:col-4 lg:col-3 xl:col-3 sm:col-6">
        <label class="labelTitle">Torre</label>
        <input
          placeholder="Torre"
          type="text"
          pInputText
          formControlName="towerCompany"
          maxlength="30"
        />
      </div>

      <div class="col-12 md:col-4 lg:col-3 xl:col-3 sm:col-6">
        <label class="labelTitle"
          >Código postal<span class="span">*</span></label
        >
        <input
          [appNumberOnlyNoLength]="15"
          type="text"
          placeholder="Código postal"
          pInputText
          formControlName="postalCodeCompany"
          maxlength="10"
          [ngClass]="{
            'ng-invalid ng-dirty':
              hasErrors('postalCodeCompany', 'required') ||
              hasErrors('postalCodeCompany', 'maxlength') ||
              hasErrors('postalCodeCompany', 'minlength')
          }"
        />
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('postalCodeCompany', 'required')"
        >
          Este campo es obligatorio
        </div>
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('postalCodeCompany', 'minlength')"
        >
          El campo solo acepta un máximo de 10 dígitos.
        </div>
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('postalCodeCompany', 'minlength')"
        >
          El campo solo acepta un mínimo de 4 dígitos.
        </div>
      </div>
      <div class="col-12 md:col-4 lg:col-3 xl:col-3 sm:col-6">
        <label class="labelTitle" for="claimetProvince" optionLabel="name"
          >Provincia<span class="span">*</span></label
        >
        <p-dropdown
          id="claimetProvince"
          [options]="provinces"
          optionLabel="name"
          optionValue="id"
          placeholder="Seleccione su Provincia"
          formControlName="provinceCompany"
          [ngClass]="{
            'ng-invalid ng-dirty': hasErrors('provinceCompany', 'required')
          }"
        >
        </p-dropdown>
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('provinceCompany', 'required')"
        >
          Este campo es obligatorio
        </div>
      </div>
      <div class="col-12 md:col-4 lg:col-3 xl:col-3 sm:col-6">
        <label class="labelTitle">Localidad<span class="span">*</span></label>
        <p-dropdown
          [options]="localities"
          optionLabel="name"
          optionValue="name"
          [showClear]="false"
          placeholder="Seleccione su localidad"
          formControlName="cityCompany"
          [ngClass]="{
            'ng-invalid ng-dirty': hasErrors('cityCompany', 'required')
          }"
        >
        </p-dropdown>
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('cityCompany', 'required')"
        >
          Este campo es obligatorio
        </div>
      </div>
      <div class="col-12 md:col-4 lg:col-3 xl:col-3 sm:col-6">
        <label class="labelTitle"
          >Departamento laboral<span class="span">*</span></label
        >
        <p-dropdown
          [options]="departments"
          placeholder="Seleccione una opción"
          optionLabel="name"
          optionValue="id"
          formControlName="departmentId"
          [ngClass]="{
            'ng-invalid ng-dirty': hasErrors('departmentId', 'required')
          }"
        />
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('departmentId', 'required')"
        >
          Este campo es obligatorio
        </div>
      </div>
      <div class="col-12 md:col-4 lg:col-3 xl:col-3 sm:col-6">
        <label class="labelTitle">Cargo<span class="span">*</span></label>
        <input
          placeholder="Cargo"
          formControlName="claimantTypePerformance"
          pInputText
          maxlength="50"
          [ngClass]="{
            'ng-invalid ng-dirty': hasErrors(
              'claimantTypePerformance',
              'required'
            )
          }"
        />
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('claimantTypePerformance', 'required')"
        >
          Este campo es obligatorio
        </div>
      </div>
    </div>
  </section>
  <div class="flex align-items-center gap-2 mt-5">
    <span class="span">*</span>
    <p-checkbox
      (onChange)="onConsentChange($event)"
      [binary]="true"
      formControlName="consent"
      class="check-acceptsElectronicDomicile"
      label="El domicilio electrónico constituido a través de la indicación de un correo electrónico, se considerará a todos los efectos jurídicos como domicilio constituído, siendo válidas y vinculantes las notificaciones, emplazamientos, comunicaciones y citaciones que se practiquen en el mismo, gozando de plena validez y eficacia su constitución."
    />
  </div>
  <p-divider></p-divider>

  <section class="personalInformation">
    <span class="titleSection">Datos personales</span>
    <div class="grid w-full mb-2 mt-3">
      <div class="col-12 md:col-4 lg:col-3 xl:col-3 sm:col-6">
        <label class="labelTitle">Apellido/s <span class="span">*</span></label>
        <input
          pInputText
          formControlName="lastName"
          placeholder="Apellido/s"
          maxlength="50"
          [ngClass]="{
            'ng-invalid ng-dirty': hasErrors('lastName', 'required')
          }"
        />
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('lastName', 'required')"
        >
          Este campo es obligatorio
        </div>
      </div>
      <div class="col-12 md:col-4 lg:col-3 xl:col-3 sm:col-6">
        <label class="labelTitle">Nombre/s <span class="span">*</span></label>
        <input
          pInputText
          formControlName="name"
          placeholder="Nombre/s"
          maxlength="50"
          [ngClass]="{
            'ng-invalid ng-dirty': hasErrors('name', 'required')
          }"
        />
        <div class="text-danger p-error" *ngIf="hasErrors('name', 'required')">
          Este campo es obligatorio
        </div>
      </div>

      <div class="col-12 md:col-4 lg:col-3 xl:col-3 sm:col-6">
        <label class="labelTitle">CUIL<span class="span">*</span></label>
        <p-inputMask
          class="inputLarge"
          mask="99-99999999-9"
          placeholder="99-99999999-9"
          formControlName="cuil"
          [ngClass]="{
            'ng-invalid ng-dirty': hasErrors('cuil', 'required')
          }"
        ></p-inputMask>
        <div class="text-danger p-error" *ngIf="hasErrors('cuil', 'required')">
          Este campo es obligatorio
        </div>
      </div>
      <div class="col-12 md:col-4 lg:col-3 xl:col-3 sm:col-6">
        <label class="labelTitle"
          >Cargo que ocupa en la empresa<span class="span">*</span></label
        >
        <input
          placeholder="Cargo"
          formControlName="claimantPosition"
          pInputText
          maxlength="50"
          [ngClass]="{
            'ng-invalid ng-dirty': hasErrors('claimantPosition', 'required')
          }"
        />
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('claimantPosition', 'required')"
        >
          Este campo es obligatorio
        </div>
      </div>
      <div class="col-12 md:col-4 lg:col-3 xl:col-3 sm:col-6">
        <label class="labelTitle">Teléfono<span class="span">*</span></label>
        <input
          placeholder="Teléfono"
          pInputText
          formControlName="phone"
          maxlength="15"
          [appNumberOnlyNoLength]="15"
          [ngClass]="{
            'ng-invalid ng-dirty':
              hasErrors('phone', 'required') ||
              hasErrors('phone', 'minlength') ||
              hasErrors('phone', 'maxlength')
          }"
        />
        <div class="text-danger p-error" *ngIf="hasErrors('phone', 'required')">
          Este campo es obligatorio
        </div>
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('phone', 'minlength')"
        >
          Este campo no puede poseer menos de 7 caracteres.
        </div>
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('phone', 'maxlength')"
        >
          Este campo no puede poseer más de 15 caracteres.
        </div>
      </div>
      <div class="col-12 md:col-4 lg:col-3 xl:col-3 sm:col-6">
        <label class="labelTitle" for="fechaNacimiento"
          >Fecha de Nacimiento<span class="span"> *</span></label
        >
        <p-calendar
          class="inputLarge"
          placeholder="DD/MM/AAAA"
          formControlName="birthday"
          [iconDisplay]="'input'"
          [showIcon]="true"
          inputId="icondisplay"
          dateFormat="dd/mm/yy"
        ></p-calendar>
        <div
          class="text-danger p-error"
          *ngIf="hasErrors('birthday', 'underage')"
        >
          Debes tener al menos 18 años
        </div>
      </div>
      <div class="col-12 md:col-4 lg:col-3 xl:col-3 sm:col-6">
        <label class="labelTitle"
          >Correo electronico<span class="span">*</span></label
        >
        <input
          placeholder="Correo electronico"
          type="text"
          pInputText
          formControlName="email"
          type="email"
          maxlength="100"
          [ngClass]="{
            'ng-invalid ng-dirty': hasErrors('email', 'required')
          }"
        />
        <div class="text-danger p-error" *ngIf="hasErrors('email', 'required')">
          Este campo es obligatorio
        </div>
        <div class="text-danger p-error" *ngIf="hasErrors('email', 'email')">
          Formato de email incorrecto
        </div>
      </div>
    </div>
  </section>
  <p-divider></p-divider>
  <section class="attachedFiles mt-3">
    <span class="titleSection">Archivos adjuntos</span>
    <div class="grid w-full mt-3">
      <div class="col-12">
        <label class="labelTitle">Documentos<span class="span">*</span></label>
        <p class="descriptionContent mb-0">
          Cargue el documento que acredite la representación que invoca.
        </p>
      </div>

      <div class="col-12 md:col-8 lg:col-8 xl:col-6">
        <div
          [ngClass]="
            isDragging
              ? 'isDragging dropzone-container h-6rem col-12 '
              : 'dropzone-container h-6rem col-12 '
          "
          (dragover)="onDragOver($event)"
          (drop)="onDropSuccess($event, 'receipt')"
        >
          <input
            #inputFilePfd
            type="file"
            multiple="false"
            (change)="onChange($event, 'receipt')"
            accept=".pdf, .jpg, .png"
          />

          <div
            class="w-full flex align-items-center justify-content-between col-12"
          >
            <img
              class="hidden md:block"
              src="../../../../../../../assets/img/main/file-update.svg"
            />
            <span class="descriptionContent hidden md:block"
              >Arrastre sus documentos aquí o</span
            >
            <p-button
              pRipple
              (click)="inputFilePfd.click()"
              #upload
              class="btnUploadFile mr-1"
              >Elegir archivo</p-button
            >
          </div>
        </div>
        <span class="descriptionContent"
          >Archivos permitidos: JPG / JPEG / WEBP / HEIF / PDF / PNG
        </span>
      </div>
      <div class="col-12 md:col-4 lg:col-4 xl:col-6">
        <div
          class="text-danger p-error"
          *ngIf="
            formGroup.get('file')?.hasError('fileCount') &&
            formGroup.get('file')?.touched
          "
        >
          Se deben subir entre 1 y 12 archivos.
        </div>
        <div
          class="conteiner-UploadRight-pdf"
          *ngFor="let pdf of pdfFiles; let i = index"
        >
          <img
            src="../../../../../../../assets/img/main/icon-image.svg"
            alt=""
            class="icon-imagen"
          />
          <span class="descriptionContent">{{ pdf.name }}</span>
          <p-button
            icon="pi pi-times"
            [rounded]="true"
            [text]="true"
            severity="danger"
            (onClick)="deleteFilePfd(i)"
          ></p-button>
        </div>
      </div>
    </div>
  </section>

  <div class="grid w-full flex justify-content-end">
    <div class="col-6 md:col-2 lg:col-2 xl:col-2">
      <p-button
        (click)="cancel()"
        label="Cancelar"
        class="btn-cancelar sm:text-xs"
      />
    </div>

    <div class="col-6 md:col-2 lg:col-2 xl:col-2">
      <p-button
        label="Siguiente"
        class="btn-siguiente"
        type="submit"
        [disabled]="formGroup.invalid"
      ></p-button>
    </div>
  </div>
</form>
